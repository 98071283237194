import React from "react";

function RewardOptionsTab({
  selectedOption,
  setSelectedOption,
  handleSubmit,
  loading,
  handleOptionChange,
}) {
  return (
    <div className="space-y-6">
      <div className="flex-start flex flex-col rounded-lg bg-white p-4 shadow-md">
        <h4 className="mb-2 text-start text-lg font-semibold text-blue-600">
          Select Reward Option:
        </h4>
        <div className="flex items-center space-x-6">
          <label className="flex cursor-pointer items-center text-black">
            <input
              type="radio"
              value="store_points"
              checked={selectedOption === "store_points"}
              onChange={handleOptionChange}
              className="mr-2 accent-blue-500"
            />
            Store earnings as{" "}
            <span className="ml-1 font-semibold text-green-500">
              360 Points
            </span>
          </label>
          <label className="flex cursor-pointer items-center text-black">
            <input
              type="radio"
              value="request_payment"
              checked={selectedOption === "request_payment"}
              onChange={handleOptionChange}
              className="mr-2 accent-green-500"
            />
            Request{" "}
            <span className="ml-1 font-semibold text-green-500">Payment</span>
          </label>
        </div>
      </div>

      {/* Submit Button */}
      <div className="text-center">
        <button
          onClick={handleSubmit}
          className={`rounded-lg px-6 py-2 text-lg font-bold text-white shadow-lg ${
            loading
              ? "cursor-not-allowed bg-gray-400"
              : "bg-gradient-to-r from-green-600 to-green-600"
          }`}
          disabled={loading}
        >
          {loading ? "Processing..." : "Get Reward"}
        </button>
      </div>
    </div>
  );
}

export default RewardOptionsTab;
