import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import logo from "../assets/images/GenZ_Logo.svg";
import "../assetFormStyle.css";
import Quiz from "../components/Quiz";
import Poll from "../components/assets/Poll";
import Text from "../components/assets/Text/Text";
import { useNavigate, useLocation } from "react-router-dom";

function AssetCreate(props) {
  const [assetName, setAssetName] = useState("");
  const [assetType, setAssetType] = useState("");
  const [caption, setCaption] = useState("");
  const [media, setMedia] = useState("");
  const [mediaUrl, setMediaUrl] = useState("");
  const [mediaType, setMediaType] = useState("");
  const [brandId, setBrandId] = useState("");
  const [allBrands, setAllBrands] = useState([]);
  const [generateAssestOption, setGenerateAssestOption] = useState("");
  const [quizOptions, setQuizOptions] = useState(null);
  const [quizsSelected, setQuizesSelected] = useState([]);
  const [poll, setPoll] = useState(null);
  const [textContent, setTextContent] = useState("");
  const [quizUrl, setQuizUrl] = useState("");
  const [audioText, setAudioText] = useState("");
  const [TTSLoading, setTTSLoading] = useState(false);
  const [user, setUser] = useState("");
  const [error, setError] = useState("");
  const [quizData, setQuizData] = useState({
    questionNumber: 0,
    language: "English",
    newsType: "",
    type: 1,
    mainTopic: "",
    subTopic: "",
    timeFrame: "",
  });

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("authToken");

    // Fetch user info
    axios.get("https://genz-staging.feeltiptop.com/api/getCurrentUserInfo", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      setUser(response.data.username);
    })
    .catch((error) => {
      console.error("Error fetching user info:", error);
    });

    // Fetch brands
    axios.get("https://genz-staging.feeltiptop.com/api/getBrands", {
      headers: {
        Authorization: "Bearer " + props.token,
      },
    })
    .then((response) => {
      let tmpbrands = response.data.map((opt) => ({
        label: opt[1],
        value: opt[0],
      }));
      setAllBrands(tmpbrands);
    })
    .catch((error) => {
      console.error(error);
    });

    if (location.state && location.state.message) {
      setError(location.state.message);
    }
  }, [props.token]);

  const handleReset = () => {
    // Reset all form state variables
    setAssetName("");
    setAssetType("");
    setCaption("");
    setMedia("");
    setMediaUrl("");
    setMediaType("");
    setBrandId("");
    setError("");
    setGenerateAssestOption("");
    setQuizOptions(null);
    setQuizesSelected([]);
    setPoll(null);
    setTextContent("");
    setQuizUrl("");
    setAudioText("");
    setTTSLoading(false);

    // Reset quiz data
    setQuizData({
      questionNumber: 0,
      language: "English",
      newsType: "",
      type: 1,
      mainTopic: "",
      subTopic: "",
      timeFrame: "",
    });

    // Reset file inputs
    const fileInputs = document.querySelectorAll('input[type="file"]');
    fileInputs.forEach(input => {
      input.value = '';
    });

    // Reset text inputs
    const textInputs = document.querySelectorAll('input[type="text"], textarea');
    textInputs.forEach(input => {
      input.value = '';
    });

    // Reset select elements
    const selects = document.querySelectorAll('select');
    selects.forEach(select => {
      select.value = '';
    });
  };

  const handleGenerateAssestOptionChange = (event) => {
    setGenerateAssestOption(event.target.value);
  };

  const handleAssetTypeChange = (event) => {
    setAssetType(event.target.value);
  };

  const handleCaptionChange = (event) => {
    setCaption(event.target.value);
  };

  const handleMediaTypeChange = (event) => {
    setMediaType(event.target.value);
    setMedia("");
    setMediaUrl("");
  };

  const handleMediaChange = (event) => {
    const file = event.target.files[0];
    if (!assetType) {
      setError("Please choose an Asset Type before selecting media.");
      return;
    }

    if (assetType === "media" || assetType === "SpeechAsset") {
      setError("");
      const reader = new FileReader();
      reader.onload = (e) => {
        setMedia(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setError("Invalid file type. Please select a valid media file.");
      setMedia(null);
    }
  };

  const handleMediaUrlChange = (event) => {
    setMediaUrl(event.target.value);
  };

  const handleAssetNameChange = (event) => {
    setAssetName(event.target.value);
  };

  const handleBrandChange = (selectedOption) => {
    setBrandId(selectedOption.value);
  };

  const generateAssests = (event, setIsloading) => {
    event.preventDefault();

    if (generateAssestOption === "quiz") {
      if (quizOptions && quizsSelected) {
        setQuizOptions(null);
        setQuizesSelected([]);
      }
      setIsloading(true);

      axios
        .get("https://genz-staging.feeltiptop.com/api/quizApi", {
          timeout: 600000,
          params: {
            questionNumber: quizData.questionNumber,
            language: quizData.language,
            newsType: quizData.newsType,
            quizType: quizData.type,
            mainTopic: quizData.mainTopic,
            subTopic: quizData.subTopic,
            timeFrame: quizData.timeFrame,
          },
        })
        .then((response) => {
          const { url, quizData: responseQuizData } = response.data;
          setQuizUrl(url);
          setQuizOptions(responseQuizData);
          setIsloading(false);
          setQuizesSelected(responseQuizData);
        })
        .catch((error) => {
          setError(
            "An error occurred when fetching your asset. Please try again later."
          );
          setIsloading(false);
        });
    }
  };

  const generateTTS = async (audioText) => {
    setTTSLoading(true);
    try {
      let response = await axios.get(
        "https://feeltiptop.com/demos/videogen/MSSpeech.php?usetiptoptts=1&txt=" +
          encodeURI(audioText),
        {
          responseType: "blob",
        }
      );
      const audioBlob = new Blob([response.data], { type: "audio/wav" });
      const audioFile = new File([audioBlob], "audio.wav", {
        type: "audio/wav",
      });
      handleMediaChange({ target: { files: [audioFile] } });

      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
      audio.play();
      setTTSLoading(false);
    } catch (error) {
      console.error("Error fetching TTS Audio:", error);
      setTTSLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!assetType || assetType === "") {
      setError("Please select an Asset Type.");
      return;
    }

    if (!brandId) {
      setError("Please select a Brand.");
      return;
    }

    let preContent;
    if (assetType === "text") {
      preContent = {
        assetType: assetType,
        html: textContent,
      };
    } else if (assetType === "poll") {
      preContent = {
        assetType: assetType,
        question: poll.question,
        selections: poll.selections,
        answer: poll.answer,
        pollType: poll.pollType,
      };
    } else if (assetType === "SpeechAsset") {
      preContent = {
        assetType: assetType,
        mediaType: mediaType,
        mediaContent: media,
      };
    } else if (assetType === "auto-asset" && generateAssestOption === "quiz") {
      preContent = {
        assetType: "quiz",
        quizzes: quizsSelected,
        apiCall: quizUrl,
      };

      for (let i = 0; i < quizsSelected.length; i++) {
        const assetData = {
          AssetName: assetName,
          AssetType: assetType,
          Caption: caption,
          AssetContent: JSON.stringify({
            assetType: generateAssestOption,
            question: quizsSelected[i].question,
            ...(quizsSelected[i].type.includes("True or False") && {
              selections: { A: "True", B: "False" },
            }),
            ...(quizsSelected[i].type.includes("Multiple Choice") && {
              selections: quizsSelected[i].selections,
            }),
            ...(quizsSelected[i].type.includes("Fill in the blank") && {
              selections: { A: "Fill in the blank" },
            }),
            answer: quizsSelected[i].answer,
            apiCall: quizUrl,
          }),
          BrandID: brandId,
        };

        axios
          .post("https://genz-staging.feeltiptop.com/api/addAsset", assetData, {
            headers: {
              Authorization: "Bearer " + props.token,
            },
          })
          .then((response) => {
            console.log("Asset created successfully!");
          })
          .catch((error) => {
            console.error("Error creating asset:", error);
            if (error.response && error.response.data.message) {
              setError(error.response.data.message);
            } else {
              setError("Error creating asset: " + error.message);
            }
          });
      }
      alert("Assets created successfully!");
      navigate(0);
      return;
    } else if (assetType === "media") {
      preContent = {
        assetType: "media",
        mediaType: mediaType,
        mediaContent: mediaType.includes("file") ? media : mediaUrl,
      };
    }

    const assetData = {
      AssetName: assetName,
      AssetType: assetType,
      Caption: caption,
      AssetContent: JSON.stringify(preContent),
      BrandID: brandId,
      MediaType:
        assetType === "media" || assetType === "SpeechAsset" ? mediaType : "",
      MediaLink: assetType === "media" && mediaType === "link" ? mediaUrl : "",
      MediaUpload:
        (assetType === "media" || assetType === "SpeechAsset") &&
        mediaType.includes("file")
          ? media
          : "",
    };

    axios
      .post("https://genz-staging.feeltiptop.com/api/addAsset", assetData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      })
      .then((response) => {
        alert("Asset # " + response.data.asset_id + " created successfully!");
        handleReset();
      })
      .catch((error) => {
        console.error("Error creating asset:", error);
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
        } else {
          setError("Error creating asset: " + error.message);
        }
      });
  };

  return (
    <div className="AssetForm p-4 bg-gray-50 min-h-screen">
      <header className="AssetForm-header mb-6">
        <img src={logo} className="Ambassco-logo w-32 h-32 mr-5 mb-4" alt="logo" />
        <div className="formTitle text-l font-bold text-center">Asset Generation Form</div>
      </header>

      <div className="text-lg mb-4">
        Hello <span className="font-bold">{user}</span> 👋
      </div>

      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-6 border border-gray-200">
        <label htmlFor="assetName" className="block mb-2 font-medium text-gray-700">
          Asset Name:
        </label>
        <input
          type="text"
          id="assetName"
          value={assetName}
          onChange={handleAssetNameChange}
          required
          className="w-full p-2 mb-4 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
        />

        <label htmlFor="assetType" className="block mb-2 font-medium text-gray-700">
          Select Asset Type:
        </label>
        <select
          id="assetType"
          value={assetType}
          onChange={handleAssetTypeChange}
          className="w-full p-2 mb-4 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
        >
          <option value="">Select Type...</option>
          <option value="media">Media</option>
          <option value="poll">Poll</option>
          <option value="text">Text</option>
          <option value="auto-asset">Auto Asset</option>
          <option value="SpeechAsset">Speech Asset</option>
        </select>

        <label htmlFor="caption" className="block mb-2 font-medium text-gray-700">
          Caption:
        </label>
        <textarea
          id="caption"
          value={caption}
          onChange={handleCaptionChange}
          className="w-full p-2 mb-4 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
        />

        {assetType === "auto-asset" && (
          <div className="mb-4">
            <select
              value={generateAssestOption}
              onChange={handleGenerateAssestOptionChange}
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
            >
              <option value="Default">Select Option..</option>
              <option value="quiz">Quiz</option>
            </select>

            {generateAssestOption === "quiz" && (
              <Quiz
                setQuizData={setQuizData}
                generateAssests={generateAssests}
                quizOptions={quizOptions}
                setQuizesSelected={setQuizesSelected}
                quizsSelected={quizsSelected}
              />
            )}
          </div>
          )}

          {assetType === "poll" && (
            <Poll setPoll={setPoll} />
          )}
  
          {assetType === "text" && (
            <Text textContent={textContent} setTextContent={setTextContent} />
          )}
  
          {assetType === "SpeechAsset" && (
            <div className="mb-4">
              <button
                onClick={() => generateTTS(caption)}
                type="button"
                disabled={!caption || TTSLoading}
                className="px-4 py-2 bg-blue-500 text-white font-medium rounded-lg hover:bg-blue-600 disabled:bg-gray-300"
              >
                {TTSLoading ? "Generating..." : "Generate Audio TTS"}
              </button>
            </div>
          )}
  
          {assetType === "media" && (
            <div className="mb-4">
              <div className="mb-4">
                <label className="inline-flex items-center mr-4">
                  <input
                    type="radio"
                    value="file"
                    checked={mediaType === "file"}
                    onChange={handleMediaTypeChange}
                    className="mr-2"
                  />
                  Upload File
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value="link"
                    checked={mediaType === "link"}
                    onChange={handleMediaTypeChange}
                    className="mr-2"
                  />
                  Provide Link
                </label>
              </div>
  
              {mediaType === "file" && (
                <div>
                  <label
                    htmlFor="mediaFile"
                    className="block mb-2 font-medium text-gray-700"
                  >
                    {media ? (
                      <img
                        src={media}
                        alt="Media File"
                        className="mediaInput w-full h-auto mb-4 rounded-lg"
                      />
                    ) : (
                      "Select Media File:"
                    )}
                  </label>
                  <input
                    type="file"
                    id="mediaFile"
                    accept="image/*, video/*, audio/*"
                    onChange={handleMediaChange}
                    className="w-full p-2 mb-4 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
                  />
                </div>
              )}
  
              {mediaType === "link" && (
                <div>
                  <label
                    htmlFor="mediaUrl"
                    className="block mb-2 font-medium text-gray-700"
                  >
                    Enter media URL:
                  </label>
                  <input
                    type="text"
                    id="mediaUrl"
                    value={mediaUrl}
                    onChange={handleMediaUrlChange}
                    placeholder="Enter media URL"
                    className="w-full p-2 mb-4 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
                  />
                </div>
              )}
            </div>
          )}
  
          <label htmlFor="brand" className="block mb-2 font-medium text-gray-700">
            Select Brand:
          </label>
          <Select
            options={allBrands}
            value={allBrands.find(brand => brand.value === brandId)}
            onChange={handleBrandChange}
            className="mb-4"
          />
  
          {error && (
            <div className="error-container bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
              <p className="error">{error}</p>
            </div>
          )}
  
          <div className="flex space-x-4">
            <button
              type="button"
              onClick={handleReset}
              className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
            >
              Reset
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
  
  export default AssetCreate;