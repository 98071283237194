import React, { useState } from 'react';

const Header = () => {
  return (
    <header className="transcript-header flex justify-between items-center px-20 py-6 border-b border-solid border-b-gray-800 max-md:px-10 max-md:py-6 max-sm:p-4">
      <div className="transcript-title text-2xl text-white">360 Transcript</div>
      <nav className="transcript-nav flex gap-6 max-sm:hidden">
        {['Dashboard', 'History', 'Settings'].map((item) => (
          <button key={item} className="transcript-nav-item text-base text-gray-400 cursor-pointer">
            {item}
          </button>
        ))}
      </nav>
    </header>
  );
};

const FileUpload = ({ onFileUpload, isLoading, uploadComplete }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [dragActive, setDragActive] = useState(false);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      setSelectedFile(file);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedFile(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedFile) {
      onFileUpload(selectedFile);
    }
  };

  return (
    <div className="transcript-upload mb-10">
      <form onSubmit={handleSubmit}>
        <div 
          className={`transcript-upload-area p-9 text-center rounded-xl border-2 border-dashed ${dragActive ? 'border-cyan-500 bg-gray-700' : 'border-gray-700 bg-gray-800'} bg-opacity-50 max-sm:p-5`}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <div className="transcript-upload-icon mb-4 text-5xl text-cyan-500">
            <i className="ti ti-cloud-upload" aria-hidden="true" />
          </div>
          <p className="transcript-upload-text mb-2 text-xl text-white">
            {selectedFile ? `Selected: ${selectedFile.name}` : "Drag & Drop your file here"}
          </p>
          <p className="transcript-upload-subtext mb-6 text-base text-gray-400">or click to browse</p>
          <label
            htmlFor="transcript-file-input"
            className="transcript-upload-button inline-flex gap-2 items-center px-6 py-3 text-base text-white bg-cyan-500 rounded-lg cursor-pointer"
          >
            <i className="ti ti-upload" aria-hidden="true" />
            <span>Choose File</span>
          </label>
          <input 
            id="transcript-file-input" 
            type="file" 
            className="sr-only" 
            aria-label="Upload file" 
            onChange={handleFileChange}
            accept=".pdf,.jpg,.jpeg,.png,.tiff"
          />
          
          {selectedFile && (
            <button
              type="submit"
              className="inline-flex gap-2 items-center px-6 py-3 text-base text-white bg-cyan-600 rounded-lg cursor-pointer ml-4"
              disabled={isLoading}
            >
              <i className="ti ti-analyze" aria-hidden="true" />
              <span>{isLoading ? "Analyzing..." : "Analyze Transcript"}</span>
            </button>
          )}
        </div>
      </form>
      
      <div className="flex gap-4 justify-center mt-6 max-sm:flex-col max-sm:items-center">
        {uploadComplete && (
          <div className="flex gap-2 items-center px-4 py-2 text-base text-green-500 bg-green-900 bg-opacity-20 rounded-lg">
            <i className="ti ti-check" aria-hidden="true" />
            <span>Analysis Complete</span>
          </div>
        )}
        {isLoading && (
          <div className="flex gap-2 items-center px-4 py-2 text-base text-cyan-500 bg-cyan-900 bg-opacity-20 rounded-lg">
            <i className="ti ti-loader animate-spin" aria-hidden="true" />
            <span>Processing...</span>
          </div>
        )}
      </div>
    </div>
  );
};

const TranscriptDisplay = ({ analysisData }) => {
  if (!analysisData) return null;
  
  const { transcript_name, summary } = analysisData;
  
  return (
    <div className="p-6 rounded-xl bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 shadow-lg">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl text-white">Analysis Results: {transcript_name}</h2>
        <div className="flex gap-3">
          <button 
            className="p-2 text-gray-400 hover:text-cyan-500 rounded-lg cursor-pointer transition-colors" 
            aria-label="Copy transcript"
            onClick={() => navigator.clipboard.writeText(summary)}
          >
            <i className="ti ti-copy" aria-hidden="true" />
          </button>
          <button 
            className="p-2 text-gray-400 hover:text-cyan-500 rounded-lg cursor-pointer transition-colors" 
            aria-label="Download transcript"
            onClick={() => {
              const blob = new Blob([summary], { type: 'text/plain' });
              const url = URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = `${transcript_name}-analysis.txt`;
              a.click();
              URL.revokeObjectURL(url);
            }}
          >
            <i className="ti ti-download" aria-hidden="true" />
          </button>
        </div>
      </div>
      <div className="p-6 rounded-lg bg-gray-900 bg-opacity-80 shadow-inner">
        <pre className="whitespace-pre-wrap text-base leading-normal text-gray-300 font-mono">
          {summary}
        </pre>
      </div>
      
      {analysisData.knowledge_scores && (
        <div className="mt-8">
          <h3 className="text-xl text-white mb-4">Knowledge Area Distribution</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {Object.entries(analysisData.knowledge_scores).map(([category, data]) => {
              if (data.course_count === 0) return null;
              return (
                <div key={category} className="p-4 bg-gray-800 rounded-lg">
                  <h4 className="text-lg text-cyan-400 mb-2">{category}</h4>
                  <div className="flex justify-between text-gray-300">
                    <span>Courses: {data.course_count}</span>
                    <span>Avg Grade: {data.avg_grade ? data.avg_grade.toFixed(2) : 'N/A'}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const TranscriptAnalysis = () => {
  const [analysisData, setAnalysisData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [uploadComplete, setUploadComplete] = useState(false);

  const handleFileUpload = async (file) => {
    setIsLoading(true);
    setError(null);
    setUploadComplete(false);
    
    try {
      const formData = new FormData();
      formData.append('file', file);
      
      const response = await fetch('https://genz-staging.feeltiptop.com/api/transcript/analyze', {
        method: 'POST',
        body: formData,
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to analyze transcript');
      }
      
      const data = await response.json();
      setAnalysisData(data);
      setUploadComplete(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main className="px-20 py-12 mx-auto my-0 max-w-4xl max-md:px-10 max-md:py-6 max-md:max-w-full max-sm:p-4 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 bg-opacity-60">
      <div className="mb-10 text-center">
        <h1 className="mb-4 text-4xl text-white">Transcript Analysis</h1>
        <p className="text-base text-gray-400">
          Upload your transcript and get an analysis of your academic strengths
        </p>
      </div>
      
      <FileUpload 
        onFileUpload={handleFileUpload} 
        isLoading={isLoading}
        uploadComplete={uploadComplete}
      />
      
      {error && (
        <div className="p-4 mb-6 text-red-500 bg-red-900 bg-opacity-20 rounded-lg">
          <p className="flex items-center">
            <i className="ti ti-alert-circle mr-2" aria-hidden="true" />
            Error: {error}
          </p>
        </div>
      )}
      
      {analysisData && <TranscriptDisplay analysisData={analysisData} />}
    </main>
  );
};

const Footer = () => {
  return (
    <footer className="flex justify-between items-center px-20 py-6 border-t border-solid border-t-gray-800 max-md:px-10 max-md:py-6 max-sm:flex-col max-sm:gap-4 max-sm:p-4 max-sm:text-center">
      <p className="text-sm text-gray-400">
        © 2025 TranscriptAI. All rights reserved.
      </p>
      <nav className="flex gap-6">
        {['Privacy Policy', 'Terms of Service'].map((item) => (
          <button key={item} className="text-base text-gray-400 cursor-pointer">
            {item}
          </button>
        ))}
      </nav>
    </footer>
  );
};

const Transcript = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
      <Header />
      <TranscriptAnalysis />
      <Footer />
    </div>
  );
};

export default Transcript;