import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const FeedSelect = ({ token }) => {
  const [feeds, setFeeds] = useState([]);
  const [selectedFeedID, setSelectedFeedID] = useState(null);
  const [error, setError] = useState("");
  const [selectedFeed, setSelectedFeed] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("https://genz-staging.feeltiptop.com/api/feeds", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.success) {
          setFeeds(response.data.data);
        } else {
          setError("Failed to fetch feeds");
        }
      })
      .catch((error) => {
        console.error("Error fetching feeds:", error.response?.data || error.message);
        setError("Error loading feeds. Please try again later.");
      });
  }, [token]);

  useEffect(() => {
    if (selectedFeedID) {
      axios
        .get(`https://genz-staging.feeltiptop.com/api/feed/${selectedFeedID}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          if (response.data.success) {
            setSelectedFeed(response.data.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching feed details:", error.response?.data || error.message);
          setError("Error loading feed details. Please try again later.");
        });
    }
  }, [selectedFeedID, token]);

  const handleFeedSelect = (feedId) => {
    setSelectedFeedID(feedId);
  };

  const handleSubmit = () => {
    if (selectedFeedID && selectedFeed) {
      navigate("/FeedConstruction", {
        state: {
          feedId: selectedFeedID,
          existingFeed: selectedFeed, 
          preSelectedAssets: selectedFeed.assets 
        },
      });
    }
  };

  const renderAssetContent = (assetContent) => {
    try {
      const content = JSON.parse(assetContent);
      switch (content.assetType) {
        case "poll":
          return (
            <div className="bg-gray-50 p-4 rounded-lg">
              <h4 className="font-medium mb-2">{content.question}</h4>
              <div className="space-y-2">
                {content.options.map((option, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <div className="w-4 h-4 rounded-full border border-gray-300" />
                    <span>{option}</span>
                  </div>
                ))}
              </div>
            </div>
          );
        case "text":
          return (
            <div
              className="prose prose-sm"
              dangerouslySetInnerHTML={{ __html: content.html }}
            />
          );
        case "media":
          return (
            <div className="relative aspect-video">
              <img
                src={content.mediaContent}
                alt="Media content"
                className="object-cover rounded-lg"
              />
            </div>
          );
        default:
          return <p>Unsupported asset type: {content.assetType}</p>;
      }
    } catch (error) {
      console.error("Error parsing asset content:", error);
      return <p>Error displaying content</p>;
    }
  };

  return (
    <div className="flex h-screen bg-gray-50">
      <div className="w-1/3 p-6 border-r border-gray-200 overflow-y-auto">
        <div className="mb-6">
          <h1 className="text-2xl font-bold mb-4">Available Feeds</h1>
          {error && <p className="text-red-500 mb-4">{error}</p>}
        </div>

        <div className="space-y-4">
          {feeds.map((feed) => (
            <div
              key={feed.FeedID}
              className={`p-4 rounded-lg border ${
                selectedFeedID === feed.FeedID
                  ? "border-blue-500 shadow-md"
                  : "border-gray-200 hover:shadow-md"
              } cursor-pointer transition-all`}
              onClick={() => handleFeedSelect(feed.FeedID)}
            >
              <div className="flex justify-between items-center mb-2">
                <h3 className="font-semibold">{feed.Name}</h3>
                <span className={`px-2 py-1 rounded-full text-xs ${
                  feed.Status === "Completed"
                    ? "bg-green-100 text-green-800"
                    : "bg-blue-100 text-blue-800"
                }`}>
                  {feed.Status}
                </span>
              </div>
              <div className="space-y-2 text-sm text-gray-500">
                <div className="flex items-center space-x-2">
                  <span>📅</span>
                  <span>Started: {new Date(feed.CreationTime).toLocaleDateString()}</span>
                </div>
                <div className="flex items-center space-x-2">
                  <span>👤</span>
                  <span>Creator: {feed.CreatorName}</span>
                </div>
                <div className="flex items-center space-x-2">
                  <span>📊</span>
                  <span>{feed.AssetCount} assets</span>
                </div>
              </div>
            </div>
          ))}
        </div>

        {selectedFeedID && (
          <div className="mt-6">
            <button
              onClick={handleSubmit}
              className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors"
            >
              Open Feed
            </button>
          </div>
        )}
      </div>

      <div className="flex-1 p-6 overflow-y-auto">
        {selectedFeed ? (
          <div className="space-y-6">
            <div className="border-b pb-4">
              <h2 className="text-2xl font-bold mb-2">{selectedFeed.Name}</h2>
              <div className="flex space-x-4 text-sm text-gray-500">
                <div className="flex items-center space-x-1">
                  <span>⏰</span>
                  <span>
                    Session Time: {selectedFeed.SessionStartTime ? 
                      new Date(selectedFeed.SessionStartTime).toLocaleString() : 
                      'Not scheduled'}
                  </span>
                </div>
                <span className={`px-2 py-1 rounded-full text-xs ${
                  selectedFeed.Status === "Completed"
                    ? "bg-green-100 text-green-800"
                    : "bg-blue-100 text-blue-800"
                }`}>
                  {selectedFeed.Status}
                </span>
              </div>
            </div>

            <div className="space-y-6">
              {selectedFeed.assets?.map((asset) => (
                <div key={asset.AssetID} className="bg-white rounded-lg border border-gray-200 shadow-sm">
                  <div className="p-4">
                    <div className="flex items-center space-x-3 mb-4">
                      {asset.BrandProfilePicture ? (
                        <img
                          src={`https://genz-staging.feeltiptop.com/${asset.BrandProfilePicture}`}
                          alt={asset.BrandName}
                          className="w-10 h-10 rounded-full"
                        />
                      ) : (
                        <div className="w-10 h-10 rounded-full bg-gray-200" />
                      )}
                      <div>
                        <h3 className="font-medium">{asset.BrandName}</h3>
                        <p className="text-sm text-gray-500">Position: {asset.Position}</p>
                      </div>
                    </div>
                    <p className="mb-4">{asset.Caption}</p>
                    {renderAssetContent(asset.AssetContent)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center h-full text-gray-500">
            Select a feed to preview its content
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedSelect;