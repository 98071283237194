import React, { useEffect, useState } from "react";
import axios from "axios";

function FeedPollSurvey({
  surveyPolls,
  assetContent,
  userID,
  feedID,
  activeAssetId,
  token,
  setToken,
}) {
  const [userResponses, setUserResponses] = useState({});
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    console.log(surveyPolls);
  }, [surveyPolls]);

  useEffect(() => {
    console.log("userResponses: ", userResponses);

    //check answers for corresponding questions
    for (let i = 0; i < surveyPolls.length; i++) {
      if (userResponses[i]) {
        console.log("Question: ", surveyPolls[i].question);
        console.log("Answer: ", userResponses[i].response);
      }
    }
  }, [userResponses]);

  const handleResponseChange = (pollIndex, selectedOption, pollQuestion) => {
    setUserResponses((prev) => ({
      ...prev,
      [pollIndex]: { question: pollQuestion, response: selectedOption },
    }));
  };

  const handleSubmit = async () => {
    // Validate all questions are answered
    if (Object.keys(userResponses).length < surveyPolls.length) {
      setError("Please answer all questions before submitting.");
      return;
    }

    setError("");
    setIsSubmitting(true);

    const pollResponseData = {
      assetID: activeAssetId,
      feedID: feedID,
      surveyResponses: userResponses,
    };

    try {
      // Mock API call to backend
      // Replace with your actual API call
      console.log("Submitting responses:", userResponses);

      // Simulate API delay
      await axios
        .post(
          "https://genz-staging.feeltiptop.com/api/survey_poll_response",
          pollResponseData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
          },
        )
        .then((res) => {
          console.log(res);
          setIsSubmitted(true);
        });

      // Success!
    } catch (err) {
      setError("Failed to submit survey. Please try again.");
      console.error("Submit error:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isSubmitted) {
    return (
      <div className="mx-auto w-full max-w-4xl p-4 text-center">
        <h1 className="mb-4 text-2xl font-bold">Thank You!</h1>
        <p className="text-lg">
          Your survey responses have been submitted successfully.
        </p>
      </div>
    );
  }
  return (
    <div className="mx-auto w-full max-w-4xl p-4">
      <h1 className="mb-6 text-2xl font-bold">Survey</h1>

      {error && (
        <div className="mb-6 rounded-lg bg-red-100 p-3 text-red-700">
          {error}
        </div>
      )}

      {/* Display survey questions */}
      {surveyPolls.map((poll, index) => (
        <div key={index} className="mb-8 rounded-lg border bg-white p-4 shadow">
          <div className="mb-4">
            <h2 className="mb-3 text-lg font-medium">
              {index + 1}. {poll.question}
            </h2>

            <div className="space-y-2">
              {/* Display options as radio buttons */}
              {Object.entries(poll.selections).map(([key, value]) => (
                <label
                  key={key}
                  className="flex cursor-pointer items-center rounded p-2 hover:bg-gray-50"
                >
                  <input
                    type="radio"
                    name={`question-${index}`}
                    value={key}
                    checked={userResponses[index]?.response === value}
                    onChange={() =>
                      handleResponseChange(index, value, poll.question)
                    }
                    className="mr-3 h-4 w-4 text-blue-600"
                  />
                  <span className="text-gray-700">{value}</span>
                </label>
              ))}
            </div>
          </div>
        </div>
      ))}

      {/* Submit button */}
      <div className="mt-6 flex justify-center">
        <button
          type="button"
          onClick={handleSubmit}
          disabled={isSubmitting}
          className={`rounded-lg px-6 py-3 font-medium text-white ${
            isSubmitting ? "bg-blue-400" : "bg-blue-600 hover:bg-blue-700"
          }`}
        >
          {isSubmitting ? "Submitting..." : "Submit Survey"}
        </button>
      </div>
    </div>
  );
}

export default FeedPollSurvey;
