import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Tab } from "@headlessui/react";
import axios from "axios";
import { FaBook, FaCalendarAlt, FaRobot, FaFileAlt } from 'react-icons/fa';
import SyllabusComponent from '../components/SyllabusComponent';

const StudyBuddy = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userId, classItem } = location.state || {};

  // ========== Tab 1: Syllabus Upload ==========
  const [selectedSyllabusFile, setSelectedSyllabusFile] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // ========== Tab 3: Cheatsheet Generation ==========
  const [selectedCheatsheetFile, setSelectedCheatsheetFile] = useState(null);
  const [margin, setMargin] = useState(10);
  const [dpi, setDpi] = useState(600);
  const [paperSize, setPaperSize] = useState("A4");
  const [customWidth, setCustomWidth] = useState("");
  const [customHeight, setCustomHeight] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [cheatsheetError, setCheatsheetError] = useState("");

  // ========== Tab (Right Column) 3: View Cheatsheets ==========
  const [cheatsheets, setCheatsheets] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchError, setSearchError] = useState("");

  // ====== Handle Syllabus (Tab 1) ======
  const handleSyllabusFileChange = (e) => {
    setSelectedSyllabusFile(e.target.files[0]);
  };

  const handleUploadSubmit = async (e) => {
    e.preventDefault();

    if (!selectedSyllabusFile) {
      alert("Please upload a file first.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedSyllabusFile);
    formData.append("user_id", userId); // Pass the userId to the backend

    setIsLoading(true);
    setErrorMessage("");

    try {
      const response = await axios.post(
        "https://genz-staging.feeltiptop.com/api/uploadSyllabus",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const result = response.data;
      if (response.status === 201 || result.success) {
        console.log("Calendar file created:", result.download_url);
        setDownloadUrl(result.download_url); // Save the download URL for rendering
        alert(result.message);
      } else {
        console.error("Backend Error:", result.message);
        setErrorMessage(result.message || "Unexpected backend error.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setErrorMessage(error.message || "Upload failed.");
    } finally {
      setIsLoading(false);
    }
  };

  // ====== Handle Cheatsheet (Tab 3) ======
  const handleCheatsheetFileChange = (e) => {
    setSelectedCheatsheetFile(e.target.files[0]);
  };

  const handleCheatsheetSubmit = async (e) => {
    e.preventDefault();
    if (!selectedCheatsheetFile) {
      alert("Please select a PDF file for your cheatsheet.");
      return;
    }

    setIsGenerating(true);
    setCheatsheetError("");

    try {
      const formData = new FormData();
      formData.append("user_id", userId);
      formData.append("file", selectedCheatsheetFile);
      formData.append("margin", margin);
      formData.append("dpi", dpi);
      formData.append("paperSize", paperSize);
      if (paperSize === "custom") {
        formData.append("customWidth", customWidth);
        formData.append("customHeight", customHeight);
      }

      const response = await fetch("https://genz-staging.feeltiptop.com/api/study/generate", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        setCheatsheetError(errorData.error || "Failed to generate cheatsheet.");
        return;
      }

      // If the server returns a PDF directly, read it as a blob for download
      const blob = await response.blob();
      const fileURL = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", "cheatsheet.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();

      window.URL.revokeObjectURL(fileURL); // Clean up
    } catch (err) {
      console.error("Cheatsheet generation error:", err);
      setCheatsheetError("An error occurred. Please try again.");
    } finally {
      setIsGenerating(false);
    }
  };

  // ====== Handle Viewing Cheatsheets (Right Column Tab 3) ======
  const fetchCheatsheets = async (query) => {
    try {
      setSearchError("");
      // Example endpoint: GET /api/study/cheatsheets?search=<query>
      // Adjust if your actual endpoint or query param differs
      const res = await fetch(
        `https://genz-staging.feeltiptop.com/api/study/cheatsheets?search=${encodeURIComponent(
          query
        )}`
      );
      if (!res.ok) {
        throw new Error("Error fetching cheatsheets");
      }
      const data = await res.json();
      setCheatsheets(data); // expecting an array of { id, filename, ... }
    } catch (err) {
      console.error("Fetch cheatsheets error:", err);
      setSearchError(err.message);
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    fetchCheatsheets(searchQuery);
  };

  // Add navigation handler
  const handleNavigateToQuestionBoard = () => {
    navigate('/questionboard', { state: { userId, classItem } });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <br />
      <br />
      <div className="flex items-center justify-between px-4">
        <div>
          <h1 className="text-3xl font-bold text-gray-900">Study Assistant</h1>
          <p className="mt-2 text-gray-600">
            {classItem?.ClassName || "No class selected"}
          </p>
        </div>
        
        
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 mt-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          {/* Left Column - Input Section */}
          <div className="rounded-lg bg-white p-6 shadow-lg">
            <Tab.Group>
              <Tab.List className="flex space-x-1 rounded-xl bg-indigo-100 p-1">
                <Tab
                  className={({ selected }) =>
                    `w-full rounded-lg py-2.5 text-sm font-medium leading-5
                    ${
                      selected
                        ? "bg-white text-indigo-700 shadow"
                        : "text-indigo-600 hover:bg-white/[0.12] hover:text-indigo-700"
                    }`
                  }
                >
                  Upload Materials
                </Tab>
                <Tab
                  className={({ selected }) =>
                    `w-full rounded-lg py-2.5 text-sm font-medium leading-5
                    ${
                      selected
                        ? "bg-white text-indigo-700 shadow"
                        : "text-indigo-600 hover:bg-white/[0.12] hover:text-indigo-700"
                    }`
                  }
                >
                  Study Goals
                </Tab>
                <Tab
                  className={({ selected }) =>
                    `w-full rounded-lg py-2.5 text-sm font-medium leading-5
                    ${
                      selected
                        ? "bg-white text-indigo-700 shadow"
                        : "text-indigo-600 hover:bg-white/[0.12] hover:text-indigo-700"
                    }`
                  }
                >
                  Cheatsheet Options
                </Tab>
                <Tab
                  className={({ selected }) =>
                    `w-full rounded-lg py-2.5 text-sm font-medium leading-5
                    ${
                      selected
                        ? "bg-white text-indigo-700 shadow"
                        : "text-indigo-600 hover:bg-white/[0.12] hover:text-indigo-700"
                    }`
                  }
                >
                  <FaFileAlt className="inline mr-1" /> Syllabus
                </Tab>
              </Tab.List>

              <Tab.Panels className="mt-4">
                {/* ====== TAB 1: Upload Materials ====== */}
                <Tab.Panel>
                  <form onSubmit={handleUploadSubmit} className="space-y-4">
                    <div>
                      <label
                        className="block text-sm font-medium text-gray-700"
                        htmlFor="syllabus-upload"
                      >
                        Upload Course Materials
                      </label>
                      <div className="mt-1">
                        <input
                          id="syllabus-upload"
                          type="file"
                          onChange={handleSyllabusFileChange}
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
                        />
                      </div>
                      <p className="mt-2 text-xs text-gray-500">
                        Upload syllabi, notes, or assignments (PDF, DOC, DOCX)
                      </p>
                    </div>
                    <button
                      type="submit"
                      className="w-full rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Upload and Analyze
                    </button>
                  </form>

                  {/* Syllabus Feedback */}
                  {isLoading && (
                    <p className="mt-4 text-indigo-600">
                      Processing your syllabus...
                    </p>
                  )}
                  {errorMessage && (
                    <p className="mt-4 text-red-600">Error: {errorMessage}</p>
                  )}
                  {downloadUrl && (
                    <div className="mt-4">
                      <p className="text-sm text-gray-600">
                        Your calendar is ready!
                      </p>
                      <a
                        href={downloadUrl}
                        download
                        className="text-indigo-600 hover:underline"
                      >
                        Click here to download the calendar
                      </a>
                    </div>
                  )}
                </Tab.Panel>

                {/* ====== TAB 2: Study Goals ====== */}
                <Tab.Panel>
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Study Goals
                      </label>
                      <textarea
                        className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:outline-none focus:ring-indigo-500"
                        rows="4"
                        placeholder="What would you like to achieve in this study session?"
                      ></textarea>
                    </div>
                    <button className="w-full rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      Set Goals
                    </button>
                  </div>
                </Tab.Panel>

                {/* ====== TAB 3: Cheatsheet Options ====== */}
                <Tab.Panel>
                  <form onSubmit={handleCheatsheetSubmit} className="space-y-4">
                    {/* Cheatsheet PDF Upload */}
                    <div>
                      <label
                        className="block text-sm font-medium text-gray-700"
                        htmlFor="cheatsheet-file-upload"
                      >
                        PDF for Cheatsheet
                      </label>
                      <div className="mt-1">
                        <input
                          id="cheatsheet-file-upload"
                          type="file"
                          accept="application/pdf"
                          onChange={handleCheatsheetFileChange}
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:outline-none focus:ring-indigo-500"
                        />
                      </div>
                      <p className="mt-2 text-xs text-gray-500">
                        Upload a PDF file to create a condensed cheatsheet
                      </p>
                    </div>

                    {/* Margin */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Margin (pixels)
                      </label>
                      <input
                        type="number"
                        className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:outline-none focus:ring-indigo-500"
                        value={margin}
                        onChange={(e) => setMargin(e.target.value)}
                      />
                    </div>

                    {/* DPI */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        DPI
                      </label>
                      <input
                        type="number"
                        className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:outline-none focus:ring-indigo-500"
                        value={dpi}
                        onChange={(e) => setDpi(e.target.value)}
                      />
                    </div>

                    {/* Paper Size */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Paper Size
                      </label>
                      <select
                        className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:outline-none focus:ring-indigo-500"
                        value={paperSize}
                        onChange={(e) => setPaperSize(e.target.value)}
                      >
                        <option value="A4">A4</option>
                        <option value="A3">A3</option>
                        <option value="custom">Custom</option>
                      </select>
                    </div>

                    {/* Custom Dimensions */}
                    {paperSize === "custom" && (
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Width (px)
                          </label>
                          <input
                            type="number"
                            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:outline-none focus:ring-indigo-500"
                            value={customWidth}
                            onChange={(e) => setCustomWidth(e.target.value)}
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Height (px)
                          </label>
                          <input
                            type="number"
                            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:outline-none focus:ring-indigo-500"
                            value={customHeight}
                            onChange={(e) => setCustomHeight(e.target.value)}
                          />
                        </div>
                      </div>
                    )}

                    <button
                      type="submit"
                      className="w-full rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Generate Cheatsheet
                    </button>
                  </form>

                  {/* Cheatsheet Feedback */}
                  {isGenerating && (
                    <p className="mt-4 text-indigo-600">Generating cheatsheet...</p>
                  )}
                  {cheatsheetError && (
                    <p className="mt-4 text-red-600">{cheatsheetError}</p>
                  )}
                </Tab.Panel>

                {/* ====== TAB 4: Syllabus Analyzer ====== */}
                <Tab.Panel>
                  <SyllabusComponent />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>

          {/* Right Column - Display Section */}
          <div className="rounded-lg bg-white p-6 shadow-lg">
            <Tab.Group>
              <Tab.List className="flex space-x-1 rounded-xl bg-indigo-100 p-1">
                <Tab
                  className={({ selected }) =>
                    `w-full rounded-lg py-2.5 text-sm font-medium leading-5
                    ${
                      selected
                        ? "bg-white text-indigo-700 shadow"
                        : "text-indigo-600 hover:bg-white/[0.12] hover:text-indigo-700"
                    }`
                  }
                >
                  Course Overview
                </Tab>
                <Tab
                  className={({ selected }) =>
                    `w-full rounded-lg py-2.5 text-sm font-medium leading-5
                    ${
                      selected
                        ? "bg-white text-indigo-700 shadow"
                        : "text-indigo-600 hover:bg-white/[0.12] hover:text-indigo-700"
                    }`
                  }
                >
                  Study Analytics
                </Tab>
                <Tab
                  className={({ selected }) =>
                    `w-full rounded-lg py-2.5 text-sm font-medium leading-5
                    ${
                      selected
                        ? "bg-white text-indigo-700 shadow"
                        : "text-indigo-600 hover:bg-white/[0.12] hover:text-indigo-700"
                    }`
                  }
                >
                  Cheatsheets
                </Tab>
              </Tab.List>
              <Tab.Panels className="mt-4">
                <Tab.Panel>
                  <div className="space-y-4">
                    <div className="rounded-lg bg-gray-50 p-4">
                      <div className="flex items-center justify-between mb-4">
                        <h3 className="text-lg font-medium text-gray-900">
                          Course Information
                        </h3>
                        <button
                          onClick={handleNavigateToQuestionBoard}
                          className="rounded-lg bg-indigo-600 px-4 py-2 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Go to Question Board
                        </button>
                      </div>
                      <div className="mt-2 space-y-2">
                        <p className="text-sm text-gray-600">
                          <span className="font-medium">Class Name:</span>{" "}
                          {classItem?.ClassName || "N/A"}
                        </p>
                        <p className="text-sm text-gray-600">
                          <span className="font-medium">Schedule:</span>
                          {classItem?.Schedule?.map((schedule, index) => (
                            <span key={index} className="block ml-4">
                              {schedule.DayOfWeek}: {schedule.StartTime} -{" "}
                              {schedule.EndTime}
                            </span>
                          )) || " N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="rounded-lg bg-gray-50 p-4">
                      <h3 className="text-lg font-medium text-gray-900">
                        Uploaded Materials
                      </h3>
                      <p className="mt-2 text-sm text-gray-600">
                        {selectedSyllabusFile
                          ? `Current file: ${selectedSyllabusFile.name}`
                          : "No materials uploaded yet"}
                      </p>
                    </div>
                  </div>
                </Tab.Panel>

                <Tab.Panel>
                  <div className="space-y-4">
                    <div className="rounded-lg bg-gray-50 p-4">
                      <h3 className="text-lg font-medium text-gray-900">
                        Study Statistics
                      </h3>
                      <p className="mt-2 text-sm text-gray-600">
                        Analytics will be displayed here once you start studying
                      </p>
                    </div>
                  </div>
                </Tab.Panel>

                {/* ===== Tab: Cheatsheets ===== */}
                <Tab.Panel>
                  <div className="space-y-4">
                    <div className="rounded-lg bg-gray-50 p-4">
                      <h3 className="text-lg font-medium text-gray-900">
                        My Cheatsheets
                      </h3>
                      <form onSubmit={handleSearchSubmit} className="mt-4 flex">
                        <input
                          type="text"
                          placeholder="Search by name..."
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          className="mr-2 flex-1 rounded-md border border-gray-300 px-3 py-2 text-sm focus:outline-none focus:ring-indigo-500"
                        />
                        <button
                          type="submit"
                          className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Search
                        </button>
                      </form>
                      {searchError && (
                        <p className="mt-2 text-red-600">Error: {searchError}</p>
                      )}

                      {/* Cheatsheet List */}
                      <div className="mt-4 space-y-2">
                        {cheatsheets.length > 0 ? (
                          cheatsheets.map((sheet) => (
                            <div
                              key={sheet.id || sheet.filename}
                              className="flex items-center justify-between rounded bg-gray-100 p-2"
                            >
                              <span className="text-sm text-gray-700">
                                {sheet.filename || "Untitled Cheatsheet"}
                              </span>
                              {/* Download Link */}
                              {sheet.download_url && (
                                <a
                                  href={sheet.download_url}
                                  download
                                  className="text-sm text-indigo-600 hover:underline"
                                >
                                  Download
                                </a>
                              )}
                            </div>
                          ))
                        ) : (
                          <p className="text-sm text-gray-500">
                            No cheatsheets found.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudyBuddy;