import React, { useState, useEffect } from "react";
import axios from "axios";

const RequestServicePage = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [points, setPoints] = useState(null);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("user_id");
  const [file, setFile] = useState(null);
  const [fileBase64, setFileBase64] = useState(null);

  useEffect(() => {
    fetchPoints();
    const params = new URLSearchParams(location.search);
    if (params.get("payment_success") === "true") {
      alert("Payment was successful! Fetching updated points...");
      fetchPoints();
    }
  }, [location]);

  const fetchPoints = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        "https://genz-staging.feeltiptop.com/api/payment/get-points",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setPoints(data.Points);
    } catch (error) {
      console.error("Error fetching points:", error);
      setMessage("Failed to load points");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Step 1: Post reaction and get the reaction_id
      const reactionResponse = await postReaction();
      const reaction_id = reactionResponse.data.reaction_id;
  
      // Ensure that file and file.type are not null before proceeding
      const formData = {
        file: fileBase64,
        type: file ? file.type : "",  // Safely handle file type
      };
  
      // Step 2: Use the reaction_id in the service request
      const serviceResponse = await axios.post(
        "https://genz-staging.feeltiptop.com/api/payment/request-service",
        { reaction_id, title, description, formData, price },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      setPoints(serviceResponse.data.new_points);
      setMessage("Service request created successfully.");
      setMessageType("success");
      setTitle("");
      setDescription("");
      setPrice("");
    } catch (error) {
      console.error("Error requesting service:", error);
      setMessage(
        error.response?.data?.error ||
        "Add points to your card before requesting"
      );
      setMessageType("error");
    }
  };  

  const postReaction = async () => {
    try {
      const reactionData = {
        userID: userId,
        assetID: 1,   
        feedID: 1,
      };

      const response = await axios.post(
        "https://genz-staging.feeltiptop.com/api/payment/service-reaction",
        reactionData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );

      return response;
    } catch (error) {
      console.error("Error posting reaction:", error);
      setMessage("Error posting reaction");
      setMessageType("error");
      throw error;
    }
  };

  const isValidFileType = (filename) => {
    return /\.(jpg|jpeg|png|gif|tiff|pdf)$/i.test(filename);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log("File:", selectedFile);
    if (selectedFile && isValidFileType(selectedFile.name)) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onload = (e) => {
        setFileBase64(e.target.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      alert(
        "Invalid file type. Please select an image file (jpg, jpeg, png, gif, tiff, pdf).",
      );
    }
  };

  const styles =
    "w-auto rounded-lg border border-gray-400  focus:ring-2 focus:ring-gray-500";

  // Determine if the submit button should be disabled
  const isSubmitDisabled =
    !title || !description || !price || isNaN(price) || price <= 0;

  return (
<div className="mx-auto max-w-xl rounded-lg bg-white p-6"> 
  <h2 className="mb-4 text-center text-3xl font-bold">Request a Service</h2>
  <p className="mb-4 text-center text-gray-600">
    Available Points: {loading ? "Loading..." : points}
  </p>
  <form onSubmit={handleSubmit} className="mx-auto w-full flex space-y-10">
   
    <div className="flex flex-col ">
      <div>
        <label htmlFor="title" className="text-black text-left w-full">
          Service Title
          <p className="text-xs text-gray-500">Give your request a title, any title.</p>
        </label>
        <input
          type="text"
          id="title"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full rounded-lg border border-gray-400 focus:ring-2 focus:ring-gray-500"
        />
        </div>
      </div>

      <div className="flex flex-col">
      <label htmlFor="description" className="text-black text-left w-full">
        Description
        <p className="text-xs text-gray-500">Imagine you're asking another person, be as specific as possible.</p>
      </label>
      <textarea
      placeholder="Description"
      value={description}
      onChange={(e) => setDescription(e.target.value)}
      className={`${styles}`}
      rows="4"
    />

      </div>

      <div className="flex flex-col">
      <label htmlFor="price" className="text-black text-left w-full">
        Additional File Upload
        <p className="text-xs text-gray-500">Any files you may want to include.</p>
      </label>
      <input
        type="file"
        id="file"
        onChange={(e)=>handleFileChange(e)}
        accept="image/jpg, image/jpeg, image/png, image/gif, image/tiff, application/pdf"
        className="w-full rounded-lg border border-gray-400 focus:ring-2 focus:ring-gray-500"
      />

      </div>

    <div className="flex flex-col">
    <label htmlFor="price" className="text-black text-left w-full">
      Price
      <p className="text-xs text-gray-500">How many points are you willing to pay for this service?</p>

    </label>

    <input
      type="number"
      placeholder="Price (360 Points)"
      value={price}
      onChange={(e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) { 
          setPrice(value);
        }
      }}
      className={styles}
      step="1" 
    />

    </div>
   

    <button
      type="submit"
      className={`w-full rounded-lg p-3 transition ${
        isSubmitDisabled
          ? "bg-gray-400"
          : "bg-blue-600 text-white hover:bg-blue-700"
      }`}
      disabled={isSubmitDisabled}
    >
      Submit
    </button>
  </form>
  {message && (
    <div
      className={`mt-4 rounded-lg p-4 ${
        messageType === "success"
          ? "bg-green-100 text-green-700"
          : "bg-red-100 text-red-700"
      }`}
    >
      {message}
    </div>
  )}
</div>
  );
};

export default RequestServicePage;