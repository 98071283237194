import { useEffect } from "react";

function CommentBox({
  request,
  comments,
  setComments,
  comment,
  setComment,
  handleSubmitComment,
  requestID,
}) {
  const getRelativeTime = (dateString) => {
    const commentDate = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now - commentDate) / 1000);

    if (diffInSeconds < 60) return `${diffInSeconds} seconds ago`;
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60)
      return `${diffInMinutes} minute${diffInMinutes > 1 ? "s" : ""} ago`;
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24)
      return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7)
      return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;

    return commentDate.toLocaleDateString(); // Returns formatted date if it's more than a week ago
  };

  return (
    <div className="fixed inset-0 z-50 flex w-full items-center justify-center bg-black bg-opacity-50 p-4">
      {/* Modal */}
      <div className="w-full max-w-md rounded-lg bg-white">
        {/* Header */}

        <div className="flex items-center justify-between border-b p-4">
          <h2 className="text-xl font-semibold">Comments</h2>
          <button
            onClick={() => setComments(null)}
            className="text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        </div>

        {/* Comments List */}
        <div className="max-h-96 overflow-y-auto p-4">
          <div className="space-y-4">
            {comments.map((comment, index) => (
              <div key={index} className="flex space-x-3">
                <div className="h-8 w-8 flex-shrink-0 rounded-full bg-gray-200"></div>
                <div>
                  <p className="text-left text-sm font-bold">
                    {comment.username}
                  </p>
                  <p className="text-left text-sm text-gray-600">
                    {comment.comment}
                  </p>
                  <p className="mt-1 text-left text-xs text-gray-400">
                    {getRelativeTime(comment.dateCommented)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Comment Input */}
        <div className="border-t p-4">
          <textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Add a comment..."
            className="w-full resize-none rounded-lg border p-2 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows={2}
          />
          <button
            onClick={(e) => handleSubmitComment(e, request.requestID)}
            ed={!comment.trim()}
            className="mt-2 w-full rounded-lg bg-blue-500 px-4 py-2 font-medium text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50"
          >
            Post to
          </button>
        </div>
      </div>
    </div>
  );
}

export default CommentBox;
