import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaSearch, FaMinus } from "react-icons/fa";

const DEFAULT_PROFILE_IMAGE = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

const MarketPlace = () => {
  const [sellers, setSellers] = useState([]);
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [allSellers, setAllSellers] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedSeller, setSelectedSeller] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [filterBy, setFilterBy] = useState("UserName");

  // For creating teams
  const [selectedUsers, setSelectedUsers] = useState([]); 
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
  const [teamName, setTeamName] = useState("");

  // For the collapsible teams sidebar and modal
  const [teams, setTeams] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  // selectedTeam now represents the team for which the modal is open
  const [selectedTeam, setSelectedTeam] = useState(null);

  // Fetch sellers and teams on component mount
  useEffect(() => {
    fetchSellers();
    fetchTeams();
  }, []);

  // If no team modal is open, display all sellers; if a team is selected, you might choose to filter or simply show the team modal.
  useEffect(() => {
    if (!selectedTeam) {
      setSellers(allSellers);
    }
  }, [selectedTeam, allSellers]);

  const fetchSellers = async () => {
    try {
      const response = await axios.get("https://genz-staging.feeltiptop.com/api/sellers/sellers");
      console.log("API Response:", response.data);

      const sellersWithTopics = await Promise.all(
        response.data.sellers.map(async (seller) => {
          const topics = await fetchUserTopics(seller.UserID);
          return { ...seller, Interests: topics };
        })
      );

      setSellers(sellersWithTopics);
      setAllSellers(sellersWithTopics);
    } catch (error) {
      console.error("Failed to fetch sellers:", error.message);
      setError("Failed to fetch sellers: " + error.message);
    }
  };

  const fetchUserTopics = async (userId) => {
    try {
      const response = await axios.get(
        `https://genz-staging.feeltiptop.com/api/dashboard/users/${userId}/topics`
      );
      console.log("API Response:", response.data);
      return response.data;
    } catch (error) {
      setError("Failed to fetch user topics: " + error.message);
      return [];
    }
  };

  // Fetch existing teams from the backend
  const fetchTeams = async () => {
    try {
      const response = await axios.get("https://genz-staging.feeltiptop.com/api/sellers/teams");
      setTeams(response.data);
    } catch (error) {
      console.error("Failed to fetch teams:", error.message);
      setError("Failed to fetch teams: " + error.message);
    }
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    if (searchTerm === "") {
      setSellers(allSellers);
    } else if (filterBy === "UserName") {
      const filteredSellers = allSellers.filter((seller) =>
        seller.UserName?.toLowerCase().includes(searchTerm)
      );
      setSellers(filteredSellers);
    } else if (filterBy === "Interests") {
      const filteredSellers = allSellers.filter((seller) =>
        seller.Interests?.some((interest) =>
          interest.toLowerCase().includes(searchTerm)
        )
      );
      setSellers(filteredSellers);
    }
  };

  const handleInterestClick = (interest) => {
    setSearchTerm(interest);
    setFilterBy("Interests");
    handleSearch({ target: { value: interest } });
  };

  // Checkbox toggling for selecting users to form a team
  const handleCheckboxChange = (userID) => {
    setSelectedUsers((prevSelected) => {
      if (prevSelected.includes(userID)) {
        // Remove user if already selected
        return prevSelected.filter((id) => id !== userID);
      } else {
        // Add user
        return [...prevSelected, userID];
      }
    });
  };

  // Open the "Create Team" modal
  const openCreateTeamModal = () => {
    setTeamName("");
    setShowCreateTeamModal(true);
  };

  // Close the "Create Team" modal
  const closeCreateTeamModal = () => {
    setShowCreateTeamModal(false);
  };

  // Submit the team creation to backend
  const handleCreateTeam = async () => {
    if (!teamName.trim() || selectedUsers.length < 2) {
      alert("Please enter a team name and select at least 2 users.");
      return;
    }

    try {
      const payload = {
        teamName: teamName.trim(),
        userIds: selectedUsers,
      };
      const response = await axios.post("https://genz-staging.feeltiptop.com/api/sellers/teams", payload);
      console.log("Team creation response:", response.data);

      // Clear selection and close modal
      setSelectedUsers([]);
      closeCreateTeamModal();

      // Re-fetch teams to update the sidebar
      fetchTeams();
      alert("Team created successfully!");
    } catch (error) {
      console.error("Failed to create team:", error.message);
      setError("Failed to create team: " + error.message);
    }
  };

  // Remove a team member from a team
  const handleRemoveTeamMember = async (teamId, userId) => {
    try {
      await axios.delete(`https://genz-staging.feeltiptop.com/api/sellers/teams/${teamId}/members/${userId}`);
      // Update the selectedTeam state by removing the member
      setSelectedTeam(prevTeam => ({
        ...prevTeam,
        members: prevTeam.members.filter(id => id !== userId)
      }));
      // Optionally, re-fetch teams to refresh the sidebar data
      fetchTeams();
    } catch (error) {
      console.error("Failed to remove team member:", error.message);
      setError("Failed to remove team member: " + error.message);
    }
  };

  // Open team modal to view team details
  const openTeamModal = (team) => {
    setSelectedTeam(team);
  };

  // Close team modal
  const closeTeamModal = () => {
    setSelectedTeam(null);
  };

  const renderView = () => {
    switch (selectedOption) {
      case "Topics":
        return (
          <div>
            <h2 className="text-xl font-semibold">
              {selectedSeller?.UserName}'s Interests:
            </h2>
            <div className="mt-2.5 flex flex-wrap gap-2.5">
              {selectedSeller?.Interests && selectedSeller.Interests.length > 0 ? (
                selectedSeller.Interests.map((interest, index) => (
                  <span
                    key={index}
                    className={`${
                      hoveredIndex === index ? "bg-gray-400" : "bg-gray-300"
                    } cursor-pointer rounded-full px-4 py-2 text-sm font-medium text-gray-800 transition-colors duration-300 ease-in-out`}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    onClick={() => handleInterestClick(interest)}
                  >
                    {interest}
                  </span>
                ))
              ) : (
                <p>No interests available.</p>
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  // Update the helper function to use UserName
  const getMemberNames = (memberIds) => {
    return memberIds
      .map(memberId => {
        const member = allSellers.find(seller => seller.UserID === memberId);
        return member?.UserName || memberId; // Fallback to ID if username not found
      })
      .join(", ");
  };

  return (
    <div className="relative flex min-h-screen">
      {/* --- Sidebar (collapsible) --- */}
      <div
        className={`
          fixed top-0 bottom-0 left-0
          w-64 bg-gray-200 p-4
          transform transition-transform duration-300
          ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"}
        `}
        style={{ zIndex: 60 }}
      >
        {/* Vertical Toggle "Tab" on the Right Edge of Sidebar */}
        <button
          className={`
            absolute top-1/2 right-[-30px]
            -translate-y-1/2 transform
            w-8 h-16
            bg-blue-500 text-white
            flex items-center justify-center
            rounded-r
            hover:bg-blue-600
            focus:outline-none
          `}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          {isSidebarOpen ? "<<" : ">>"}
        </button>

        <div className="mb-4 text-xl font-bold">Teams</div>
        {teams.map((team) => (
          <div
            key={team.teamID}
            className={`mb-2 rounded p-2 shadow cursor-pointer transition-colors duration-300 ${
              selectedTeam?.teamID === team.teamID 
                ? 'bg-blue-100 border-2 border-blue-500' 
                : 'bg-white hover:bg-gray-50'
            }`}
            onClick={() => openTeamModal(team)}
          >
            <p className="font-semibold">{team.teamName}</p>
            <p className="text-sm text-gray-600">
              Members: {getMemberNames(team.members)}
            </p>
          </div>
        ))}
      </div>

      {/* Main Content */}
      <div className="mx-auto flex max-w-screen-xl flex-col items-center p-5 ml-0 sm:ml-0 md:ml-0 lg:ml-0 w-full">
        <h1 className="mb-5 text-3xl font-bold">MarketPlace Dashboard</h1>

        {error && <p className="text-red-500">{error}</p>}

        {/* Search Bar + Filter */}
        <div className="mb-5 flex w-full flex-col items-center justify-between gap-2.5 sm:flex-row sm:items-center">
          <div className="relative w-full sm:flex-1">
            <FaSearch className="pointer-events-none absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-400" />
            <input
              className="w-full rounded-md border border-gray-300 py-2 pl-10 pr-3 text-base"
              placeholder={`Search by ${filterBy}...`}
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>

          <select
            className="rounded-md border border-gray-300 px-3 py-2 sm:flex-1 sm:max-w-[200px]"
            value={filterBy}
            onChange={(e) => setFilterBy(e.target.value)}
          >
            <option value="UserName">Name</option>
            <option value="Interests">Interests</option>
          </select>
        </div>

        {/* Seller Cards */}
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
          {sellers.map((seller) => (
            <div
              key={seller.UserID}
              className="relative mx-auto w-[300px] transform overflow-hidden rounded-lg border border-gray-200 bg-white p-6 text-center shadow-lg transition duration-300 hover:scale-105 hover:shadow-xl"
            >
              {/* Checkbox for Team Selection */}
              <input
                type="checkbox"
                className="absolute top-3 right-3 h-5 w-5"
                onChange={() => handleCheckboxChange(seller.UserID)}
                checked={selectedUsers.includes(seller.UserID)}
              />

              <div
                className="cursor-pointer"
                onClick={() => {
                  setSelectedSeller(seller);
                  setSelectedOption("Topics");
                }}
              >
                <div className="flex flex-col items-center">
                  <div className="relative mb-4 h-24 w-24">
                    <img
                      src={DEFAULT_PROFILE_IMAGE}
                      alt="Profile"
                      className="h-full w-full rounded-full border-4 border-blue-500 object-cover shadow-sm"
                    />
                  </div>
                  <h3 className="text-lg font-semibold text-gray-800">
                    {seller.UserName}
                  </h3>
                  <p className="text-sm text-gray-500">{seller.Email}</p>
                  <div className="mt-4 flex flex-col items-center space-y-1">
                    <p className="text-sm font-medium text-gray-700">
                      {seller.PhoneNumber}
                    </p>
                    <p className="text-sm text-gray-500">{seller.State}</p>
                    <p className="text-sm text-gray-500">{seller.Ethnicity}</p>
                    <p className="text-sm text-gray-500">{seller.Gender}</p>
                    <p className="text-sm text-gray-500">
                      Rating: {seller.Rating || "N/A"}
                    </p>
                    <p className="text-sm text-gray-500">
                      Age Verified: {seller.AgeVerified}
                    </p>
                    <p className="text-sm text-gray-500">
                      Points Earned: {seller.PointsEarned}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Create Team Button (only shows if >= 2 users selected) */}
        {selectedUsers.length >= 2 && (
          <button
            onClick={openCreateTeamModal}
            className="mt-6 rounded bg-green-500 px-4 py-2 text-white hover:bg-green-600"
          >
            Create Team
          </button>
        )}
      </div>

      {/* Selected Seller Modal */}
      {selectedSeller && (
        <>
          <div
            className="fixed inset-0 z-50 bg-black/30"
            onClick={() => {
              setSelectedSeller(null);
              setSelectedOption("");
            }}
          />
          <div className="fixed left-1/2 top-1/2 z-50 max-h-[90vh] w-full max-w-screen-lg -translate-x-1/2 -translate-y-1/2 transform overflow-y-auto rounded-lg bg-white p-5 shadow-lg">
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
              <h2 className="mb-5 text-2xl font-semibold">Seller Profile</h2>
              <select
                className="cursor-pointer rounded-md bg-blue-500 px-4 py-2 text-base text-white hover:bg-blue-600"
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
              >
                <option value="Topics">Topics</option>
              </select>
            </div>
            {renderView()}
          </div>
        </>
      )}

      {/* Create Team Modal */}
      {showCreateTeamModal && (
        <>
          <div
            className="fixed inset-0 z-50 bg-black/50"
            onClick={closeCreateTeamModal}
          />
          <div className="fixed left-1/2 top-1/2 z-50 w-[90%] max-w-md -translate-x-1/2 -translate-y-1/2 transform rounded bg-white p-6 shadow-lg">
            <h2 className="mb-4 text-xl font-semibold">Create New Team</h2>
            <input
              type="text"
              className="mb-4 w-full rounded border border-gray-300 p-2"
              placeholder="Team Name"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
            />
            <div className="flex justify-end">
              <button
                onClick={closeCreateTeamModal}
                className="mr-2 rounded bg-gray-300 px-4 py-2"
              >
                Cancel
              </button>
              <button
                onClick={handleCreateTeam}
                className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
              >
                Create
              </button>
            </div>
          </div>
        </>
      )}

      {/* Team Details Modal */}
      {selectedTeam && (
        <>
          <div
            className="fixed inset-0 z-50 bg-black/30"
            onClick={closeTeamModal}
          />
          <div className="fixed left-1/2 top-1/2 z-50 max-h-[80vh] w-full max-w-lg -translate-x-1/2 -translate-y-1/2 transform overflow-y-auto rounded-lg bg-white p-6 shadow-lg">
            <div className="flex items-center justify-between">
              <h2 className="text-2xl font-semibold">{selectedTeam.teamName}</h2>
              <button 
                onClick={closeTeamModal}
                className="rounded bg-red-500 px-3 py-1 text-white hover:bg-red-600"
              >
                Close
              </button>
            </div>
            <div className="mt-4">
              <h3 className="mb-2 text-lg font-medium">Team Members</h3>
              <div className="flex flex-wrap gap-4">
                {selectedTeam.members && selectedTeam.members.length > 0 ? (
                  selectedTeam.members.map((memberId, index) => {
                    const member = allSellers.find(seller => seller.UserID === memberId);
                    return (
                      <div key={index} className="relative flex flex-col items-center">
                        <img
                          src={member?.ProfilePictureURL || "https://via.placeholder.com/100"}
                          alt={member?.UserName}
                          className="h-16 w-16 rounded-full border-2 border-blue-500 object-cover"
                        />
                        <p className="mt-1 text-sm">{member?.UserName || memberId}</p>
                        <button
                          className="absolute top-0 right-0 rounded-full bg-white p-1 shadow hover:bg-gray-100"
                          onClick={() => handleRemoveTeamMember(selectedTeam.teamID, memberId)}
                        >
                          <FaMinus className="text-red-500" />
                        </button>
                      </div>
                    );
                  })
                ) : (
                  <p>No members in this team.</p>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MarketPlace;
