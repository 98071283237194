import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

/**
 * By default, Leaflet tries to load marker icon images from /images in your public folder.
 * We can override the default icon URLs to ensure markers show up correctly.
 */
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const DEFAULT_CENTER = [20, 0]; // near the equator
const DEFAULT_ZOOM = 2;        // show a world-level map initially

function UserMap({ users }) {
  // If there's no user data, or an empty array, display a simple message
  if (!users || users.length === 0) {
    return <div>No user locations found.</div>;
  }
  console.log(users);
  return (
    <div style={{ width: "100%", height: "500px" }}>
      {/* 
        MapContainer must wrap the TileLayer & Markers.
        center/zoom define where the map starts and how zoomed in it is.
      */}
      <MapContainer center={DEFAULT_CENTER} zoom={DEFAULT_ZOOM} style={{ width: "100%", height: "100%" }}>
        {/* OpenStreetMap tiles (no API key needed) */}
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">
                         OpenStreetMap</a> contributors'
        />

        {users.map((user, index) => {
          // Safeguard: if user has no lat/long, skip
          if (!user.latitude || !user.longitude) return null;

          return (
            <Marker
              key={user.UserID || index}
              position={[user.latitude, user.longitude]}
            >
              <Popup>
                {/* You can display whatever user info you want here */}
                <strong>{user.UserName || "Unknown User"}</strong>
                <br />
                {user.State || ""} {user.Country ? `, ${user.Country}` : ""}
              </Popup>
            </Marker>
          );
        })}
      </MapContainer>
    </div>
  );
}

export default UserMap;