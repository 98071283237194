import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import { addDays, subDays } from "date-fns";
import { Card, CardGroup, Form } from "react-bootstrap"; // Importing Bootstrap components
import { useNavigate } from "react-router-dom"; // Import useNavigate

const UserCalendar = ({ userId }) => {
  const [events, setEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [viewOption, setViewOption] = useState("day");
  const [selectedEvents, setSelectedEvents] = useState([]);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  // Fetch calendar events from the backend
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(
          `https://genz-staging.feeltiptop.com/api/users/${userId}/calendar-events`,
        );
        setEvents(response.data); // Assuming the backend returns the events as an array
      } catch (error) {
        console.error("Failed to fetch calendar events:", error);
      }
    };

    fetchEvents();
  }, [userId]);

  // Handle date click
  const handleDateClick = (date) => {
    setSelectedDate(date);
  };
  const goToClassSchedule = () => {
    navigate("/classschedule", {
      state: {
        userId, // Pass userId through state
      },
    });
  };

  // Handle view option change (day, week, or month)
  const handleViewChange = (e) => {
    setViewOption(e.target.value);
  };

  // Filter events based on the selected view option
  const getFilteredEvents = () => {
    if (viewOption === "day") {
      return events.filter(
        (event) =>
          new Date(event.EventDate).toDateString() ===
          selectedDate.toDateString(),
      );
    } else if (viewOption === "week") {
      const startOfWeek = subDays(selectedDate, selectedDate.getDay());
      const endOfWeek = addDays(startOfWeek, 6);
      return events.filter(
        (event) =>
          new Date(event.EventDate) >= startOfWeek &&
          new Date(event.EventDate) <= endOfWeek,
      );
    } else if (viewOption === "month") {
      const startOfMonth = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        1,
      );
      const endOfMonth = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        0,
      );
      return events.filter(
        (event) =>
          new Date(event.EventDate) >= startOfMonth &&
          new Date(event.EventDate) <= endOfMonth,
      );
    }
    return [];
  };

  const filteredEvents = getFilteredEvents();

  // Function to show a dot if the date has an event
  const tileContent = ({ date, view }) => {
    if (view === "month") {
      const hasEvent = events.some(
        (event) =>
          new Date(event.EventDate).toDateString() === date.toDateString(),
      );
      return hasEvent ? <div style={styles.calendarEventDot}></div> : null;
    }
  };

  return (
    <div style={styles.container}>
      <div>
        <h2 className="text-xl font-semibold">Your Calendar</h2>
        <br />
        <Calendar onClickDay={handleDateClick} tileContent={tileContent} />
      </div>

      <div style={styles.eventsContainer}>
        {/* Dropdown to select view by day, week, or month */}
        <div style={styles.viewOptions}>
          <Form.Group controlId="view-select">
            <Form.Label>View by:</Form.Label>
            <Form.Control
              as="select"
              onChange={handleViewChange}
              value={viewOption}
            >
              <option value="day">Day</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
            </Form.Control>
          </Form.Group>
        </div>

        {/* List of filtered events as Bootstrap cards */}
        <div style={styles.filteredEventsContainer}>
          <h4>
            Events for the {viewOption} of {selectedDate.toDateString()}:
          </h4>
          {filteredEvents.length > 0 ? (
            <CardGroup>
              {filteredEvents.map((event) => (
                <Card key={event.EventID} style={styles.eventCard}>
                  <Card.Body>
                    <Card.Title>{event.EventName}</Card.Title>
                    <Card.Text>
                      <strong>Date:</strong> {event.EventDate} <br />
                      <strong>Time:</strong> {event.EventTime}
                    </Card.Text>
                  </Card.Body>
                </Card>
              ))}
            </CardGroup>
          ) : (
            <p>No events in this {viewOption}.</p>
          )}
        </div>
      </div>
    </div>
  );
};

// CSS styles as a JavaScript object
const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
  },
  eventsContainer: {
    display: "flex",
    flexDirection: "column",
    width: "500px",
  },
  calendarEventDot: {
    width: "6px",
    height: "6px",
    backgroundColor: "red",
    borderRadius: "50%",
    margin: "auto",
    marginTop: "5px",
  },
  viewOptions: {
    marginBottom: "10px",
  },
  filteredEventsContainer: {
    marginTop: "20px",
  },
  eventCard: {
    marginBottom: "10px",
  },
  linkButton: {
    marginTop: "20px",
    backgroundColor: "#007bff",
    color: "white",
    padding: "10px 15px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default UserCalendar;
