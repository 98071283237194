import React, { useEffect, useState } from 'react';
import useToken from './useToken';
import axios from 'axios';
import { Navigate, Outlet } from 'react-router-dom';

function AdminGuard() {
  const { token } = useToken();
  const [isAdmin, setIsAdmin] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!token) {
      setIsAdmin(false);
      setLoading(false);
      return;
    }

    const checkAdminStatus = async () => {
      try {
        const response = await axios.get('https://genz-staging.feeltiptop.com/api/user/checkAdmin', { 
          headers: { Authorization: `Bearer ${token}` }
        });

        if (response.data.success) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      } catch (err) {
        console.error("Admin check failed:", err);
        setIsAdmin(false);
      } finally {
        setLoading(false);
      }
    };

    checkAdminStatus();
  }, [token]);

  if (loading) return <div>Loading...</div>;
  if (!isAdmin) return <Navigate to="/feed" replace />; // Redirect unauthorized users

  return <Outlet />; // Render child routes if the user is an admin
}

export default AdminGuard;
