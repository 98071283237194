import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import backArrow from "../assets/images/arrow_back.svg";
import "../App.css";
import axios from "axios";

function EnterUserName() {
  const [userName, setUserName] = useState("");

  const navigate = useNavigate();

  const { state } = useLocation();
  const { phoneNumber } = state;
  const [error, setError] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleBackArrow = () => {
    navigate("/signup");
  };

  const handleSubmit = () => {
    if (!buttonClicked) {
      setButtonClicked(true);
      axios
        .post("https://genz-staging.feeltiptop.com/api/user/checkUserName", {
          userName: userName,
        })
        .then((response) => {
          if (!response.data.exists) {
            navigate("/enterpassword", {
              state: { phoneNumber, userName: userName },
            });
          } else {
            setError("User Name already exists!");
            setButtonClicked(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <>
      <div className="signup-container">
        <div className="arrow-button">
          <img
            src={backArrow}
            alt="Logo"
            className="arrow"
            onClick={handleBackArrow}
          />
        </div>
        <h2 className="title-signup">Enter your user name</h2>

        <div>
          <input
            type="text"
            placeholder="your username here"
            className="input-fields"
            value={userName}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
          />
        </div>

        {error && (
          <div className="error-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke="#DB4335"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 8V12"
                stroke="#DB4335"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 16H12.01"
                stroke="#DB4335"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="error">{error}</p>
          </div>
        )}
        <button
          style={{ marginTop: "200px" }}
          className={`button ${userName.length > 4 && !buttonClicked ? "red-background" : "grey-background"}`}
          disabled={userName.length <= 4 || buttonClicked}
          secureTextEntry
          onClick={handleSubmit}
        >
          Next
        </button>
      </div>
    </>
  );
}

export default EnterUserName;
