import React, { useState, useEffect } from "react";
import axios from "axios";

const UpdateRequest = () => {
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [newPrice, setNewPrice] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const token = localStorage.getItem("token");
  const [currentPrice, setCurrentPrice] = useState(0);
  const [points, setPoints] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchData = async (url) => {
    try {
      const { data } = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      console.error(`Error fetching from ${url}:`, error);
      setMessage(`Failed to load data from ${url}`);
      setMessageType("error");
      throw error;
    }
  };

  const fetchPoints = async () => {
    const data = await fetchData(
      "https://genz-staging.feeltiptop.com/api/payment/get-points",
    );
    setPoints(data.Points);
  };

  const fetchServices = async () => {
    const data = await fetchData(
      "https://genz-staging.feeltiptop.com/api/payment/get-service-requests",
    );
    setServices(data);
  };

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      await Promise.all([fetchPoints(), fetchServices()]);
      setLoading(false);
    };
    loadData();
  }, [token]);

  const handleUpdatePrice = async (e) => {
    e.preventDefault();

    const priceToSend = parseFloat(newPrice);
    const priceDifference = priceToSend - currentPrice;

    if (isNaN(priceToSend) || priceToSend <= 0) {
      setMessage("Please enter a valid price.");
      setMessageType("error");
      return;
    }

    if (priceToSend <= currentPrice) {
      setMessage("New price must be higher than the current price.");
      setMessageType("error");
      return;
    }

    if (priceDifference > points) {
      setMessage("Price difference exceeds your current points.");
      setMessageType("error");
      return;
    }

    try {
      const sessionResponse = await axios.post(
        "https://genz-staging.feeltiptop.com/api/payment/update-price",
        {
          price: priceDifference,
          requestID: selectedService,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      setMessage(sessionResponse.data.message);
      setMessageType(
        sessionResponse.data.message === "Price updated successfully."
          ? "success"
          : "error",
      );

      // Refresh points and services after updating the price
      if (sessionResponse.data.message === "Price updated successfully.") {
        await Promise.all([fetchPoints(), fetchServices()]);
      }
    } catch (error) {
      setMessage("Failed to update price. Please try again later.");
      setMessageType("error");
    }
  };

  const handleServiceSelection = (e) => {
    const selectedId = parseInt(e.target.value, 10);
    setSelectedService(selectedId);
    setNewPrice("");

    const selected = services.find(
      (service) => service.requestID === selectedId,
    );
    setCurrentPrice(selected ? parseFloat(selected.price) : 0);
  };

  return (
    <div className="mx-auto w-full max-w-2xl rounded-lg bg-white p-6">
      <h2 className="mb-4 text-center text-3xl font-bold">
        Update Service Price
      </h2>
      <p className="mb-4 text-center text-gray-600">
        Available Points: {loading ? "Loading..." : points}
      </p>

      {message && (
        <div
          className={`mt-4 rounded-lg p-4 ${messageType === "success" ? "bg-green-100 text-green-700" : "bg-red-100 text-red-700"}`}
        >
          {message}
        </div>
      )}

      <form onSubmit={handleUpdatePrice} className="m-0 w-full space-y-4 p-0">
        <div className="space-y-2">
          <select
            value={selectedService}
            onChange={handleServiceSelection}
            className="w-full rounded-lg border border-gray-300 bg-white p-4 text-gray-900 focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Select a service</option>
            {services.map((service) => (
              <option
                key={service.requestID}
                value={service.requestID}
                className="appearance-none"
              >
                {service.title} - {service.price}
              </option>
            ))}
          </select>
        </div>

        {selectedService && (
          <div className="space-y-4">
            <input
              type="number"
              placeholder="New Price"
              value={newPrice}
              onChange={(e) => setNewPrice(e.target.value)}
              className="w-full rounded-lg border border-gray-300 bg-white p-4 text-gray-900 focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
              min={Math.ceil(currentPrice + 1)}
              step="1"
            />

            <button
              type="submit"
              className={`w-full rounded-lg p-4 font-medium transition-colors duration-200 ${
                !selectedService ||
                !newPrice ||
                isNaN(newPrice) ||
                newPrice <= 0
                  ? "cursor-not-allowed bg-gray-400 text-gray-100"
                  : "bg-blue-600 text-white hover:bg-blue-700 active:bg-blue-800"
              }`}
              disabled={
                !selectedService ||
                !newPrice ||
                isNaN(newPrice) ||
                newPrice <= 0
              }
            >
              Update Price
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default UpdateRequest;
