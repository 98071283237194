import React, { useState, useEffect } from "react";
import axios from "axios";

const RewardOptions = () => {
  const [sellerInfo, setSellerInfo] = useState(null);
  const [paymentRequests, setPaymentRequests] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // Fetch Seller Data
  const fetchSellerData = async () => {
    try {
      const response = await axios.get("https://genz-staging.feeltiptop.com/api/payment/info", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setSellerInfo(response.data.sellerInfo);
      setPaymentRequests(response.data.paymentRequests);
    } catch (error) {
      setMessage("Error fetching seller data. Please try again later.");
    }
  };

  useEffect(() => {
    fetchSellerData();
  }, []);

  // Handle Option Change
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setMessage(""); // Clear any existing message
  };

  const handleSubmit = async () => {
    if (!selectedOption) {
      setMessage("Please select a reward option.");
      return;
    }

    setLoading(true);

    try {
      const requestData = { action: selectedOption };

      // If the user selected "store_points", we need to get the points to transfer
      if (selectedOption === "store_points") {
        const pointsToTransfer = prompt("Enter the number of points you want to transfer to your 360 Card:");
        if (!pointsToTransfer || isNaN(pointsToTransfer) || pointsToTransfer <= 0) {
          setMessage("Invalid points amount.");
          return;
        }
        requestData.points = parseInt(pointsToTransfer);
      }

      const response = await axios.post(
        "https://genz-staging.feeltiptop.com/api/payment/reward-options",
        requestData,
        { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
      );

      setMessage(response.data.message);

      // Refresh seller data after reward action
      fetchSellerData();
    } catch (error) {
      setMessage(error.response?.data?.message || "An error occurred while processing your request.");
    } finally {
      setLoading(false);
    }
  };

  if (!sellerInfo) {
    return <div className="container mx-auto p-4 text-center text-black">Loading seller information...</div>;
  }

  return (
    <div className="container mx-auto p-6 bg-gradient-to-r from-blue-50 to-blue-100 rounded-lg shadow-md">
      {/* Main Layout: Two-column structure */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

        {/* Left Section: Seller Info and Reward Options */}
        <div className="space-y-6">

          {/* Seller Info Section */}
          <div className="p-6 bg-white rounded-lg shadow-md">
            <h3 className="text-xl font-semibold flex items-center text-blue-600 text-left">
              Seller Information
            </h3>
            <div className="mt-2 text-left">
              {/* Current Level */}
              <div className="flex items-center space-x-2">
                <p className="text-black m-0">
                  Current Level: <span className="font-bold">{sellerInfo.Level}</span>
                </p>
                <span className="text-sm text-green-500 cursor-pointer relative group flex items-center">
                  <span className="w-5 h-5 flex items-center justify-center bg-green-500 text-white rounded-full text-xs font-bold">
                    i
                  </span>
                  <div className="absolute mt-2 w-64 p-4 text-sm text-gray-800 bg-gray-100 border border-gray-300 rounded shadow-lg hidden group-hover:block z-10">
                    <p>
                      <strong>Level System:</strong> <br />
                      - <strong>Level 0:</strong> Base level. No payment requests allowed. You can earn 360 points instead. <br />
                      - <strong>Levels 1-5:</strong> Payment requests allowed with a commission. TipTop takes 50% at Level 1, reducing by 10% per level. At Level 5, TipTop takes only 10%.
                    </p>
                  </div>
                </span>
              </div>

              {/* Points Earned */}
              <div className="flex items-center space-x-2 mt-4">
                <p className="text-black m-0">
                  Points Earned: <span className="font-bold">{sellerInfo.PointsEarned}</span>
                </p>
                <span className="text-sm cursor-pointer relative group flex items-center">
                  <span className="w-5 h-5 flex items-center justify-center bg-green-500 text-white rounded-full text-xs font-bold">
                    i
                  </span>
                  <div className="absolute mt-2 w-64 p-4 text-sm text-gray-800 bg-gray-100 border border-gray-300 rounded shadow-lg hidden group-hover:block z-10">
                    <p>
                      <strong>Points Earned:</strong> <br />
                      - You can only request payment if you have earned 1,000 or more points. <br />
                      - Alternatively, you can transfer points to your 360 Card as 360 Points with no restrictions.
                    </p>
                  </div>
                </span>
              </div>
            </div>
          </div>

          {/* Reward Options */}
          <div className="p-6 bg-white rounded-lg shadow-md">
            <h4 className="text-lg font-semibold text-blue-600 mb-2">Select Reward Option:</h4>
            <div className="flex items-center space-x-6">
              <label className="flex items-center text-black cursor-pointer">
                <input
                  type="radio"
                  value="store_points"
                  checked={selectedOption === "store_points"}
                  onChange={handleOptionChange}
                  className="mr-2 accent-blue-500"
                />
                Store earnings as <span className="font-semibold text-green-500 ml-1">360 Points</span>
              </label>
              <label className="flex items-center text-black cursor-pointer">
                <input
                  type="radio"
                  value="request_payment"
                  checked={selectedOption === "request_payment"}
                  onChange={handleOptionChange}
                  className="mr-2 accent-green-500"
                />
                Request <span className="font-semibold text-green-500 ml-1">Payment</span>
              </label>
            </div>
          </div>

          {/* Submit Button */}
          <div className="text-center">
            <button
              onClick={handleSubmit}
              className={`px-6 py-2 text-lg font-bold text-white rounded-lg shadow-lg ${
                loading ? "bg-gray-400 cursor-not-allowed" : "bg-gradient-to-r from-green-600 to-green-600"
              }`}
              disabled={loading}
            >
              {loading ? "Processing..." : "Get Reward"}
            </button>
          </div>
        </div>

        {/* Right Section: Payment History */}
        <div className="p-6 bg-white rounded-lg shadow-md">
          <h4 className="text-lg font-semibold text-blue-600 mb-2">Past Payment Requests:</h4>
          <ul className="list-disc pl-6 text-black">
            {paymentRequests.length > 0 ? (
              paymentRequests.map((request) => (
                <li key={request.RequestID} className="mb-2">
                  <span className="font-semibold">{request.Amount} USD</span> - {request.Status}{" "}
                  <span className="text-sm text-gray-500">(Requested on {new Date(request.RequestDate).toLocaleDateString()})</span>
                </li>
              ))
            ) : (
              <p className="text-gray-500">No payment requests found.</p>
            )}
          </ul>
        </div>
      </div>

      {/* Message */}
      {message && <p className="mt-6 text-center text-black">{message}</p>}
    </div>
  );
};

export default RewardOptions;
