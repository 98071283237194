import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import TextComponent from '../components/TextComponent';
import MediaComponent from '../components/MediaComponent';
import PollComponent from '../components/PollComponent';
import SpeechAssessmentComponent from '../components/SpeechAssessmentComponent';
import axios from 'axios';
import { encrypttoHEX } from './encodedecode.js';

const protocol = window.location.protocol;
const hostname = window.location.hostname;
const port = window.location.port;

const baseURL = `${protocol}//${hostname}${port ? ":" + port : ""}/`;

const formatTimestamp = (timestamp) => {
  if (!timestamp) return 'Pending';
  try {
    const utcTimestamp = timestamp.endsWith('Z') ? timestamp : timestamp + 'Z';
    const date = new Date(utcTimestamp);
    
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date');
    }

    return date.toLocaleString(undefined, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    });
  } catch (error) {
    console.error('Error formatting timestamp:', error, timestamp);
    return 'Invalid Date';
  }
};

const FulfillmentTimeline = ({ fulfillment, reactionId, requestId, assetName }) => {
  const [error, setError] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);

  const handleCreateFulfillmentFeed = async () => {
    try {
      setIsCreating(true);
      setError(null);
      
      const response = await axios.post(
        'https://genz-staging.feeltiptop.com/api/feed/create_fulfillment',
        {
          assetName: assetName,
          reactionId: reactionId,
          requestId: requestId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          }
        }
      );

      if (response.data.success) {
        const feedData = {
          feedID: response.data.feedId
        };
        const encryptedFeedId = encrypttoHEX(JSON.stringify(feedData));
        axios.put(`https://genz-staging.feeltiptop.com/api/feed/${response.data.feedId}/update_session_link`, 
          {
              SessionLink: `${baseURL}SpecificFeedConstruction?p=${encryptedFeedId}`,
          },
          {
              headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  'Content-Type': 'application/json',
              },
          }
        )
        .then(response => {
            console.log('Session link updated successfully:', response.data);
        })
        .catch(error => {
            console.error('Error updating session link:', error);
        });
      
        window.open(`/SpecificFeedConstruction?p=${encryptedFeedId}`, '_blank');
        
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error('Error creating fulfillment feed:', error);
      if (error.response?.status === 403) {
        setShowNotificationModal(true);
      } else {
        setError(error.response?.data?.message || 'An error occurred while creating the fulfillment feed');
      }
    } finally {
      setIsCreating(false);
    }
  };

  if (!fulfillment && !fulfillment?.feedLink) {
    const availableStatus = {
      status: 'Available',
      feedLink: null,
      inProgressTimestamp: null,
      completedTimestamp: null,
      seller: null
    };
    fulfillment = availableStatus;
  }

  const timelineSteps = [
    { status: 'Available', label: 'Available', time: null },
    { status: 'InProgress', label: 'In Progress', time: fulfillment.inProgressTimestamp },
    { status: 'Completed', label: 'Completed', time: fulfillment.completedTimestamp }
  ];
  
  const effectiveStatus = !fulfillment.feedLink ? 'Available' : fulfillment.status;
  const currentIndex = timelineSteps.findIndex(step => step.status === effectiveStatus);

  return (
    <>
      <div className="p-4 bg-gray-50 rounded-lg mt-4">
        {fulfillment.seller && (effectiveStatus === 'InProgress' || effectiveStatus === 'Completed') && (
          <div className="flex items-center mb-3">
            <span className="text-sm text-gray-600">
              {fulfillment.seller.firstName} {fulfillment.seller.lastName} (@{fulfillment.seller.userName})
            </span>
          </div>
        )}

        <h4 className="text-lg font-semibold mb-3">Fulfillment Status</h4>
        <div className="flex justify-between items-center">
          {timelineSteps.map((step, index) => {
            const isActive = index <= currentIndex;
            const isCurrentStep = step.status === effectiveStatus;
            
            return (
              <div key={step.status} className="flex flex-col items-center flex-1">
                <div 
                  className={`w-6 h-6 rounded-full flex items-center justify-center
                    ${isCurrentStep ? 'bg-blue-600 ring-4 ring-blue-100' :
                      isActive ? 'bg-blue-500' : 'bg-gray-300'
                    }`}
                >
                  {isActive && (
                    <div className="text-white text-xs">✓</div>
                  )}
                </div>
                <div className={`mt-2 text-sm font-medium ${
                  isActive ? 'text-blue-600' : 'text-gray-500'
                }`}>
                  {step.label}
                </div>
                <div className="text-xs text-gray-500">
                  {step.time ? formatTimestamp(step.time) : 'Pending'}
                </div>
              </div>
            );
          })}
        </div>
        
        {error && (
          <div className="mt-4 p-3 bg-red-50 text-red-700 rounded-lg">
            {error}
          </div>
        )}

        {effectiveStatus === 'Available' ? (
          <button
            className={`mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors ${
              isCreating ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleCreateFulfillmentFeed}
            disabled={isCreating}
          >
            {isCreating ? 'Creating...' : 'Create Fulfillment Feed'}
          </button>
        ) : (
          <button
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            onClick={() => window.open(fulfillment.feedLink, '_blank')}
          >
            {effectiveStatus === 'InProgress' ? 'View Progress' : 'View Completed Feed'}
          </button>
        )}
      </div>

      {showNotificationModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
            <h3 className="text-xl font-semibold mb-4">Seller Registration Required</h3>
            <p className="text-gray-600 mb-6">
              To create fulfillment feeds, you need to register as a seller first. Please register as a seller to continue.
            </p>
            
            <div className="flex justify-end">
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                onClick={() => setShowNotificationModal(false)}
              >
                Got it
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const ReactionCard = ({ reaction }) => {
  const { asset, brand, feed, user } = reaction;
  const token = localStorage.getItem('token');

  const fulfillmentData = reaction.reaction.reactionDetails.fulfillmentFeedStatus ? {
    status: reaction.reaction.reactionDetails.fulfillmentFeedStatus,
    feedLink: reaction.reaction.reactionDetails.fulfillmentFeedLink,
    inProgressTimestamp: reaction.reaction.reactionDetails.fulfillment?.inProgressTimestamp,
    completedTimestamp: reaction.reaction.reactionDetails.fulfillment?.completedTimestamp,
    seller: reaction.reaction.reactionDetails.fulfillment?.seller
  } : null;
  
  return (
    <div className="bg-white rounded-lg shadow-md mb-6 overflow-hidden">
      <div className="p-6">
        {/* Header */}
        <div className="flex justify-between items-start mb-4">
          <div>
            <h3 className="text-lg font-bold">{asset.assetName}</h3>
            <p className="text-sm text-gray-600">{feed.name}</p>
          </div>
          <div className="flex flex-col items-end">
            <span className="text-sm text-gray-500 mb-1">
              <span className="font-medium">Reaction:</span> {formatTimestamp(reaction.reaction.reactionTime)}
            </span>
            <span className="text-sm text-gray-500">
              <span className="font-medium">Asset created:</span> {formatTimestamp(asset.assetCreationTime)}
            </span>
          </div>
        </div>
        
        <div className="space-y-4">
          {/* Brand Info */}
          <div className="flex items-center">
            <div className="w-10 h-10 rounded-full overflow-hidden mr-3">
              <img
                src={brand.brandProfilePicture ? 
                  `https://genz-staging.feeltiptop.com/${brand.brandProfilePicture}` :
                  "https://via.placeholder.com/40"
                }
                alt={brand.brandName}
                className="w-full h-full object-cover"
              />
            </div>
            <span className="font-medium">{brand.brandName}</span>
          </div>

          {/* Asset Content */}
          {asset.assetContent && (
            <div className="rounded-lg overflow-hidden">
              {asset.assetContent.assetType === 'poll' && (
                <div className="bg-blue-50 p-4 rounded-lg">
                  <p className="font-medium">Selected: {reaction.reaction.reactionDetails.pollChoice}</p>
                </div>
              )}
              
              {asset.assetContent.assetType === 'text' && (
                <div className="prose max-w-none">
                  <TextComponent assetContent={typeof asset.assetContent === 'string' ? 
                    JSON.parse(asset.assetContent) : 
                    asset.assetContent} 
                  />
                </div>
              )}
              
              {asset.assetContent.assetType === 'media' && (
                <div className="aspect-video rounded-lg overflow-hidden">
                  <MediaComponent 
                    mediaLink={asset.assetContent.mediaContent.startsWith('http') ? 
                      asset.assetContent.mediaContent : 
                      `https://genz-staging.feeltiptop.com/${asset.assetContent.mediaContent}`
                    }
                  />
                </div>
              )}
              
              {asset.assetContent.assetType === 'SpeechAsset' && (
                <div className="aspect-video rounded-lg overflow-hidden">
                  <SpeechAssessmentComponent
                    mediaLink={asset.assetContent.mediaContent}
                    activeAssetId={asset.assetID}
                    feedID={feed.feedID}
                    caption={asset.caption}
                    token={token}
                  />
                </div>
              )}
            </div>
          )}

          {/* Reaction Details */}
          <div className="space-y-3">
            {reaction.reaction.reactionDetails.ranking && (
              <div className="bg-gray-50 p-3 rounded-lg">
                <div className="flex items-center">
                  <span className="font-medium mr-2">Rating:</span>
                  <div className="flex">
                    {[...Array(5)].map((_, i) => (
                      <svg
                        key={i}
                        className={`w-5 h-5 ${
                          i < reaction.reaction.reactionDetails.ranking
                            ? 'text-yellow-400'
                            : 'text-gray-300'
                        }`}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    ))}
                  </div>
                </div>
              </div>
            )}
            
            {reaction.reaction.reactionDetails.comment && (
              <div className="bg-gray-50 p-3 rounded-lg">
                <p className="whitespace-pre-wrap">{reaction.reaction.reactionDetails.comment}</p>
              </div>
            )}
            
            {reaction.reaction.reactionDetails.media && (
              <div className="rounded-lg overflow-hidden">
                <MediaComponent 
                  mediaLink={`https://genz-staging.feeltiptop.com/${reaction.reaction.reactionDetails.media}`} 
                />
              </div>
            )}
          </div>

          {/* Feed Timeline */}
          <div className="bg-gray-50 p-4 rounded-lg mt-4">
            <h4 className="text-lg font-semibold mb-3">Feed Timeline</h4>
            <div className="grid gap-2 text-sm">
              <div className="flex justify-between">
                <span className="font-medium">Started:</span>
                <span>{formatTimestamp(feed.inProgressTimestamp)}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Completed:</span>
                <span>{formatTimestamp(feed.completedTimestamp)}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Status:</span>
                <span className={`${
                  feed.status === 'Completed' ? 'text-green-600' : 'text-blue-600'
                }`}>
                  {feed.status}
                </span>
              </div>
            </div>
          </div>

          {/* Fulfillment Timeline */}
          <FulfillmentTimeline
            fulfillment={fulfillmentData}
            reactionId={reaction.reaction.reactionID}
            assetName={asset.assetName}
          />
        </div>
      </div>
    </div>
  );
};

const UserReactions = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [reactions, setReactions] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchUsers = useCallback(async () => {
    try {
      const response = await axios.get('https://genz-staging.feeltiptop.com/api/user/get_user');
      const formattedUsers = response.data.map(user => ({
        value: user.UserID,
        label: `${user.firstName} ${user.lastName} (@${user.UserName})`
      }));
      setUsers(formattedUsers);
    } catch (err) {
      setError('Failed to fetch users. Please try again.');
      console.error('Error fetching users:', err);
    }
  }, []);

  const fetchReactions = useCallback(async (userId) => {
    setIsLoading(true);
    setError('');
    try {
      const response = await axios.get('https://genz-staging.feeltiptop.com/api/user_reaction', {
        params: { userID: userId }
      });

      if (response.data.success) {
        setReactions(response.data.reactions);
      } else {
        setError(response.data.message || 'Failed to fetch reactions');
      }
    } catch (err) {
      setError('Failed to fetch reactions. Please try again.');
      console.error('Error fetching reactions:', err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleUserSelect = (selectedOption) => {
    setSelectedUser(selectedOption);
    if (selectedOption) {
      fetchReactions(selectedOption.value);
    } else {
      setReactions([]);
      setError('');
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="mb-6">
        <Select
          options={users}
          value={selectedUser}
          onChange={handleUserSelect}
          placeholder="Search and select a user..."
          isClearable
          className="w-full"
          classNamePrefix="select"
        />
      </div>

      {error && (
        <div className="bg-red-50 text-red-700 p-4 rounded-lg mb-6">
          {error}
        </div>
      )}

      {isLoading && (
        <div className="flex justify-center items-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-2 border-blue-500 border-t-transparent"></div>
        </div>
      )}

      {selectedUser && reactions.length > 0 && (
        <div className="space-y-6">
          <h2 className="text-2xl font-bold mb-4">
            Reactions for {selectedUser.label}
          </h2>
          {reactions.map((reaction) => (
            <ReactionCard 
              key={reaction.reaction.reactionID} 
              reaction={reaction} 
            />
          ))}
        </div>
      )}

      {selectedUser && reactions.length === 0 && !isLoading && (
        <div className="text-center py-12 bg-gray-50 rounded-lg">
          <p className="text-xl text-gray-600">No reactions found for this user</p>
        </div>
      )}

      {!selectedUser && !isLoading && (
        <div className="text-center py-12 bg-gray-50 rounded-lg">
          <p className="text-xl text-gray-600">Select a user to view their reactions</p>
        </div>
      )}
    </div>
  );
};

export default UserReactions;