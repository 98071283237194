import React, { useState } from 'react';

// Tooltip component
const ToolTip = ({ tooltipText, buttonText }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div 
      style={styles.tooltipContainer}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <button style={styles.button}>{buttonText}</button>
      <div 
        style={{ 
          ...styles.tooltip, 
          visibility: visible ? 'visible' : 'hidden', 
          opacity: visible ? 1 : 0 
        }} 
        dangerouslySetInnerHTML={{ __html: tooltipText.replace(/\n/g, '<br/>') }} 
      />
    </div>
  );
};

const styles = {
  tooltipContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  button: {
    cursor: 'pointer',
    transform: 'translateX(50%)',

  },
  tooltip: {
    width: '500px',
    maxWidth: '500px',
    backgroundColor: 'black',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '5px',
    padding: '5px 10px',
    position: 'absolute',
    zIndex: 1,
    bottom: '125%',
    left: '100%',
    transform: 'translateX(-50%)',
    transition: 'opacity 0.3s',
    whiteSpace: 'normal',
  },
};

// Export the Tooltip component
export default ToolTip;