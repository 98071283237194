import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaTrash, FaUserEdit, FaSearch, FaPlus } from "react-icons/fa";
import UserCalendar from "./UserCalendar";

const UserDashboard = () => {
  const [users, setUsers] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    UserName: "",
    Email: "",
    PhoneNumber: "",
    Birthday: "",
    Ethnicity: "",
    Gender: "",
    State: "",
    ProfilePictureURL: "",
  });
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [editUser, setEditUser] = useState(null);
  const [error, setError] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [skillName, setSkillName] = useState("");
  const [proficiency, setProficiency] = useState("");
  const [skillsList, setSkillsList] = useState([]);
  const [searchAttribute, setSearchAttribute] = useState("UserName"); // To store and display the skills list
  const [ratingModalOpen, setRatingModalOpen] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [otherProficiency, setOtherProficiency] = useState(1);
  const [newProficiencyLevel, setNewProficiencyLevel] = useState(1);

  const handleSkillClick = (skill) => {
    if (skill && skill.id) {
      setSelectedSkill(skill);
      setNewProficiencyLevel("");
      setRatingModalOpen(true);
    } else {
      console.error("Invalid skill object:", skill);
      console.log("Selected skillid:", skill.SkillID);
    }
  };

  const handleProficiencySubmit = async (e) => {
    e.preventDefault();

    if (!selectedSkill || !selectedSkill.id) {
      console.error("Selected skill is invalid or missing SkillID.");
      return;
    }
    console.log(newProficiencyLevel);
    try {
      await axios.put(
        `https://genz-staging.feeltiptop.com/api/users/${editUser.UserID}/skills/${selectedSkill.id}`,
        { OtherProficiencyLevel: newProficiencyLevel },
      );

      // Update local skill proficiency

      setEditUser((prevUser) => ({
        ...prevUser,
        Skills: prevUser.Skills.map((skill) =>
          skill.id === selectedSkill.id
            ? { ...skill, OtherProficiencyLevel: newProficiencyLevel }
            : skill,
        ),
      }));

      setRatingModalOpen(null);
    } catch (error) {
      setError("Failed to update skill proficiency: " + error.message);
    }
  };
  const handleSkillSubmit = async (e, userId) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `https://genz-staging.feeltiptop.com/api/users/${userId}/skills`,
        {
          skillName,
          proficiency,
        },
      );

      if (response.status === 201) {
        // Update the skills list on successful addition
        setSkillsList([...skillsList, { name: skillName, proficiency }]);
        setSkillName("");
        setProficiency("");
        fetchUsers();
      }
    } catch (error) {
      console.error("Error adding skill:", error);
    }
  };

  // Fetch users from the backend on component mount
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get("https://genz-staging.feeltiptop.com/api/users");
      const usersWithTopics = await Promise.all(
        response.data.map(async (user) => {
          const topics = await fetchUserTopics(user.UserID);
          const skills = await fetchUserSkills(user.UserID);
          return { ...user, Interests: topics, Skills: skills }; // Attach interests to user object
        }),
      );
      setUsers(usersWithTopics);
      setAllUsers(usersWithTopics);
    } catch (error) {
      setError("Failed to fetch users: " + error.message);
    }
  };

  const fetchUserTopics = async (userId) => {
    try {
      const response = await axios.get(
        `https://genz-staging.feeltiptop.com/api/users/${userId}/topics`,
      );
      return response.data;
    } catch (error) {
      setError("Failed to fetch user topics: " + error.message);
      return [];
    }
  };
  const fetchUserSkills = async (userId) => {
    try {
      const response = await axios.get(
        `https://genz-staging.feeltiptop.com/api/users/${userId}/skills`,
      );

      // Rename the keys here
      const renamedSkills = response.data.map((skill) => ({
        id: skill[0], // SkillID is at index 0
        SkillName: skill[1], // SkillName is at index 1
        ProficiencyLevel: skill[2], // ProficiencyLevel is at index 2
      }));
      console.log(renamedSkills);
      return renamedSkills; // Return the renamed data
    } catch (error) {
      setError("Failed to fetch user skills: " + error.message);
      return [];
    }
  };

  const handleAddUser = async () => {
    if (!newUser.UserName || !newUser.Email) {
      alert("Please fill in the required fields: User Name and Email.");
      return;
    }

    try {
      const response = await axios.post(
        "https://genz-staging.feeltiptop.com/api/users",
        newUser,
      );
      setUsers([...users, response.data]);
      setIsAddModalOpen(false);
    } catch (error) {
      setError("Failed to add user: " + error.message);
    }
  };

  const handleSaveChanges = async () => {
    try {
      await axios.put(
        `https://genz-staging.feeltiptop.com/api/users/${editUser.UserID}`,
        editUser,
      );
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.UserID === editUser.UserID ? editUser : user,
        ),
      );
      setEditUser(null);
    } catch (error) {
      setError("Failed to update user: " + error.message);
    }
  };
  // Function to handle search
  const handleSearch = async (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);

    if (searchTerm === "") {
      // If search term is empty, reset to all users
      setUsers(allUsers);
      return;
    }

    if (searchAttribute === "Skill" || searchAttribute === "Interest") {
      // Frontend filtering
      const filteredUsers = allUsers.filter((user) => {
        if (searchAttribute === "Skill") {
          return user.Skills.some((skill) =>
            skill.SkillName.toLowerCase().includes(searchTerm.toLowerCase()),
          );
        } else if (searchAttribute === "Interest") {
          return user.Interests.some((interest) =>
            interest.toLowerCase().includes(searchTerm.toLowerCase()),
          );
        }
        return false;
      });
      setUsers(filteredUsers);
    } else {
      const query = e.target.value;
      try {
        // Perform the search query
        const response = await axios.get(
          `https://genz-staging.feeltiptop.com/api/users/search?q=${query}`,
        );

        // Fetch and append interests and skills to each user returned in the search results
        const usersWithDetails = await Promise.all(
          response.data.map(async (user) => {
            const topics = await fetchUserTopics(user.UserID);
            const skills = await fetchUserSkills(user.UserID);
            return { ...user, Interests: topics, Skills: skills }; // Attach the interests and skills to the user object
          }),
        );

        // Update the users state with the search results including interests and skills
        setUsers(usersWithDetails);
        console.log(usersWithDetails); // Ensure users now have interests appended
      } catch (error) {
        setError("Failed to search users: " + error.message);
      }
    }
  };

  const handleDeleteUsers = async () => {
    if (selectedUserIds.length === 0) return;

    if (
      !window.confirm("Are you sure you want to delete the selected user(s)?")
    ) {
      return;
    }

    try {
      await axios.delete("https://genz-staging.feeltiptop.com/api/users", {
        data: { userIds: selectedUserIds },
      });
      setUsers((prevUsers) =>
        prevUsers.filter((user) => !selectedUserIds.includes(user.UserID)),
      );
      setSelectedUserIds([]);
    } catch (error) {
      setError("Failed to delete users: " + error.message);
    }
  };

  const openAddModal = () => {
    setNewUser({
      UserName: "",
      Email: "",
      PhoneNumber: "",
      Birthday: "",
      Ethnicity: "",
      Gender: "",
      State: "",
      ProfilePictureURL: "",
    });
    setIsAddModalOpen(true);
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleInterestClick = (interest) => {
    setSearchTerm(interest); // Set search bar value
    handleIntSearch({ target: { value: interest } }); // Trigger the search function
  };

  const handleDeleteUser = async (userId) => {
    if (!window.confirm("Are you sure you want to delete this user?")) {
      return;
    }

    try {
      await axios.delete(`https://genz-staging.feeltiptop.com/api/users/${userId}`);
      setUsers(users.filter((user) => user.UserID !== userId));
    } catch (error) {
      setError("Failed to delete user: " + error.message);
    }
  };
  const handleIntSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    if (searchTerm === "") {
      // If search is cleared, fetch all users again
      fetchUsers();
    } else {
      const filteredUsers = users.filter((user) =>
        user.Interests.some((interest) =>
          interest.toLowerCase().includes(searchTerm),
        ),
      );
      setUsers(filteredUsers); // Update users state with filtered users
    }
  };
  const renderView = () => {
    switch (selectedOption) {
      case "":
        return (
          <div>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <input
                className="w-full rounded-md border border-gray-300 p-2"
                name="UserName"
                placeholder="User Name"
                value={editUser.UserName}
                onChange={handleEditInputChange}
              />
              <input
                className="w-full rounded-md border border-gray-300 p-2"
                name="Email"
                placeholder="Email"
                value={editUser.Email}
                onChange={handleEditInputChange}
              />
              <input
                className="w-full rounded-md border border-gray-300 p-2"
                name="PhoneNumber"
                placeholder="Phone Number"
                value={editUser.PhoneNumber}
                onChange={handleEditInputChange}
              />
              <input
                className="w-full rounded-md border border-gray-300 p-2"
                name="Birthday"
                type="date"
                value={editUser.Birthday}
                onChange={(e) =>
                  setEditUser({ ...editUser, Birthday: e.target.value })
                }
              />
              <input
                className="w-full rounded-md border border-gray-300 p-2"
                name="Ethnicity"
                placeholder="Ethnicity"
                value={editUser.Ethnicity}
                onChange={handleEditInputChange}
              />
              <input
                className="w-full rounded-md border border-gray-300 p-2"
                name="Gender"
                placeholder="Gender"
                value={editUser.Gender}
                onChange={handleEditInputChange}
              />
              <input
                className="w-full rounded-md border border-gray-300 p-2"
                name="State"
                placeholder="State"
                value={editUser.State}
                onChange={handleEditInputChange}
              />
            </div>
            <button
              className="mt-5 flex items-center gap-2 rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
              onClick={handleSaveChanges}
            >
              <FaUserEdit />
              <span>Save Changes</span>
            </button>
          </div>
        );
      case "Posts":
        return (
          <div>
            <h2 className="text-xl font-semibold">Posts coming soon...</h2>
            {/* Placeholder content */}
          </div>
        );
      case "Calendar":
        return (
          <div>
            <UserCalendar userId={editUser.UserID} />;
          </div>
        );

      case "Topics":
        return (
          <div>
            <h2 className="text-xl font-semibold">
              {editUser.UserName} Interests:
            </h2>
            <div className="mt-2.5 flex flex-wrap gap-2.5">
              {editUser.Interests && editUser.Interests.length > 0 ? (
                editUser.Interests.map((interest, index) => (
                  <span
                    key={index}
                    className={`${
                      hoveredIndex === index ? "bg-gray-400" : "bg-gray-300"
                    } cursor-pointer rounded-full px-4 py-2 text-sm font-medium text-gray-800 transition-colors duration-300 ease-in-out`}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    onClick={() => handleInterestClick(interest)}
                  >
                    {interest}
                  </span>
                ))
              ) : (
                <p>No interests available.</p>
              )}
            </div>
          </div>
        );
      case "skills":
        return (
          <div>
            <div>
              <h3 className="mb-6 text-center text-2xl font-bold">Add Skill</h3>
              <form onSubmit={(e) => handleSkillSubmit(e, editUser.UserID)}>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Skill Name
                  </label>
                  <br />
                  <input
                    type="text"
                    value={skillName}
                    onChange={(e) => setSkillName(e.target.value)}
                    className="input-field"
                    placeholder="Enter a skill"
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Proficiency (1-10)
                  </label>
                  <br />

                  <input
                    type="number"
                    value={proficiency}
                    onChange={(e) => setProficiency(e.target.value)}
                    min="1"
                    max="10"
                    className="input-field"
                    placeholder="Enter proficiency level"
                  />
                </div>

                <button type="submit" className="btn-primary">
                  Add Skill
                </button>
              </form>

              <h3 className="mb-4 mt-8 text-center text-xl font-bold">
                {editUser.UserName}'s Skills
              </h3>
              <div className="mt-2.5 flex flex-wrap gap-2.5">
                {editUser.Skills && editUser.Skills.length > 0 ? (
                  editUser.Skills.map((skill, index) => (
                    <span
                      key={index}
                      onClick={() => handleSkillClick(skill)}
                      className="cursor-pointer rounded-full bg-gray-300 px-4 py-2 text-sm font-medium text-gray-800 transition-colors duration-300 ease-in-out"
                    >
                      {skill.SkillName} (Proficiency: {skill.ProficiencyLevel})
                      (OtherProficiencyLevel: {skill.OtherProficiencyLevel})
                    </span>
                  ))
                ) : (
                  <p>No skills available.</p>
                )}
              </div>
            </div>
            {ratingModalOpen && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
                  <h4 className="mb-4 text-center text-lg font-semibold">
                    Rate {selectedSkill.SkillName}
                  </h4>
                  <form
                    onSubmit={handleProficiencySubmit}
                    className="flex flex-col items-center"
                  >
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">
                        Your Rating (1-10)
                      </label>
                      <br />
                      <input
                        type="number"
                        value={newProficiencyLevel}
                        onChange={(e) => setNewProficiencyLevel(e.target.value)}
                        min="1"
                        max="10"
                        className="input-field w-full rounded-md border border-gray-300 p-2"
                        placeholder="Enter rating"
                      />
                    </div>
                    <button type="submit" className="btn-primary mb-2">
                      Save Rating
                    </button>
                  </form>
                  <button
                    className="mt-2 rounded-md bg-red-500 px-4 py-2 text-white hover:bg-red-600"
                    onClick={() => setRatingModalOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="mx-auto flex max-w-screen-xl flex-col items-center p-5">
      <h1 className="mb-5 text-3xl font-bold">User Dashboard</h1>

      {/* Add User Modal */}
      {isAddModalOpen && (
        <>
          <div
            className="fixed inset-0 z-50 bg-black/80"
            onClick={closeAddModal}
          />
          <div className="fixed left-1/2 top-1/2 z-50 max-h-[90vh] w-full max-w-screen-lg -translate-x-1/2 -translate-y-1/2 transform overflow-y-auto rounded-lg bg-white p-5 shadow-lg">
            <h2 className="mb-5 text-2xl font-semibold">Add User</h2>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <input
                className="w-full rounded-md border border-gray-300 p-2"
                name="UserName"
                placeholder="User Name"
                value={newUser.UserName}
                onChange={handleInputChange}
              />
              <input
                className="w-full rounded-md border border-gray-300 p-2"
                name="Email"
                placeholder="Email"
                value={newUser.Email}
                onChange={handleInputChange}
              />
              <input
                className="w-full rounded-md border border-gray-300 p-2"
                name="PhoneNumber"
                placeholder="Phone Number"
                value={newUser.PhoneNumber}
                onChange={handleInputChange}
              />
              <input
                className="w-full rounded-md border border-gray-300 p-2"
                name="Birthday"
                type="date"
                placeholder="Birthday"
                value={newUser.Birthday}
                onChange={handleInputChange}
              />
              <input
                className="w-full rounded-md border border-gray-300 p-2"
                name="Ethnicity"
                placeholder="Ethnicity"
                value={newUser.Ethnicity}
                onChange={handleInputChange}
              />
              <input
                className="w-full rounded-md border border-gray-300 p-2"
                name="Gender"
                placeholder="Gender"
                value={newUser.Gender}
                onChange={handleInputChange}
              />
              <input
                className="w-full rounded-md border border-gray-300 p-2"
                name="State"
                placeholder="State"
                value={newUser.State}
                onChange={handleInputChange}
              />
            </div>
            <button
              className="mt-5 flex items-center gap-2 rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
              onClick={handleAddUser}
            >
              <FaPlus />
              <span>Add User</span>
            </button>
          </div>
        </>
      )}

      {/* Edit User Modal */}
      {editUser && (
        <>
          <div
            className="fixed inset-0 z-50 bg-black/30"
            onClick={() => setEditUser(null)}
          />
          <div className="fixed left-1/2 top-1/2 z-50 max-h-[90vh] w-full max-w-screen-lg -translate-x-1/2 -translate-y-1/2 transform overflow-y-auto rounded-lg bg-white p-5 shadow-lg">
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
              <h2 className="mb-5 text-2xl font-semibold">User Profile</h2>
              <select
                className="cursor-pointer rounded-md bg-blue-500 px-4 py-2 text-base text-white hover:bg-blue-600"
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
              >
                <option value="">Profile</option>
                <option value="Posts">Posts</option>
                <option value="Calendar">Calendar</option>
                <option value="Topics">Topics</option>
                <option value="skills">Skills</option>
              </select>
            </div>
            {renderView()}
          </div>
        </>
      )}

      {/* Error Handling */}
      {error && <p className="text-red-500">{error}</p>}

      {/* Toolbar */}
      <div className="mb-5 flex w-full flex-col items-center justify-between gap-2.5 sm:flex-row sm:items-center">
        <div className="flex w-full flex-col gap-2.5 sm:flex-1 sm:flex-row sm:items-center">
          <div className="relative w-full">
            <FaSearch className="pointer-events-none absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-400" />
            <input
              className="w-full rounded-md border border-gray-300 py-2 pl-10 pr-3 text-base"
              placeholder="Search users..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          <select
            className="rounded-md border border-gray-300 px-3 py-2"
            value={searchAttribute}
            onChange={(e) => {
              setSearchAttribute(e.target.value);
              setSearchTerm(""); // Reset search term when attribute changes
              setUsers(allUsers); // Reset users list
            }}
          >
            <option value="UserName">Name</option>
            <option value="Email">Email</option>
            <option value="PhoneNumber">Phone Number</option>
            <option value="Skill">Skill</option>
            <option value="Interest">Interest</option>
          </select>
        </div>
        <button
          className="flex items-center gap-2 rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
          onClick={openAddModal}
        >
          <FaPlus />
          <span>Add User</span>
        </button>
      </div>

      {/* User Cards */}
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
        {users.map((user) => (
          <div
            key={user.UserID}
            className="relative mx-auto w-[300px] transform cursor-pointer overflow-hidden rounded-lg border border-gray-200 bg-white p-6 text-center shadow-lg transition duration-300 hover:scale-105 hover:shadow-xl"
            onClick={() => setEditUser(user)}
          >
            <div className="flex flex-col items-center">
              <div className="relative mb-4 h-24 w-24">
                <img
                  src={
                    user.ProfilePictureURL || "https://via.placeholder.com/150"
                  }
                  alt="Profile"
                  className="h-full w-full rounded-full border-4 border-blue-500 object-cover shadow-sm"
                />
              </div>
              <h3 className="text-lg font-semibold text-gray-800">
                {user.UserName}
              </h3>
              <p className="text-sm text-gray-500">{user.Email}</p>

              <div className="mt-4 flex flex-col items-center space-y-1">
                <p className="text-sm font-medium text-gray-700">
                  {user.PhoneNumber}
                </p>
                <p className="text-sm text-gray-500">{user.State}</p>
                <p className="text-sm text-gray-500">{user.Ethnicity}</p>
                <p className="text-sm text-gray-500">{user.Gender}</p>
              </div>
            </div>

            <button
              className="mt-6 w-full rounded-lg bg-red-500 py-2 text-white transition-colors duration-200 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-offset-2"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteUser(user.UserID);
              }}
            >
              <FaTrash className="mr-2 inline-block" />
              Delete
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserDashboard;
