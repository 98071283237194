import React from "react";

const ProgressBar = ({ classes, coursePlan }) => {
  // Only count courses that have been placed in a semester
  const coursesInSemesters = coursePlan.filter(course => course.semester && course.year).length;
  const totalCourses = classes.length;
  const progress = totalCourses > 0 ? (coursesInSemesters / totalCourses) * 100 : 0;

  return (
    <div className="mx-auto mt-6 max-w-xl">
      <div className="mb-1 flex justify-between">
        <span className="text-sm font-medium">Course Planning Progress</span>
        <span className="text-sm font-medium">{Math.round(progress)}%</span>
      </div>
      <div className="h-2.5 w-full rounded-full bg-gray-200">
        <div
          className="h-2.5 rounded-full bg-blue-600 transition-all duration-500"
          style={{
            width: `${progress}%`,
            backgroundColor: progress === 100 ? "#22c55e" : "#2563eb",
          }}
        ></div>
      </div>
      <div className="mt-1 flex justify-between text-sm text-gray-600">
        <span>{coursesInSemesters} courses placed in semesters</span>
        <span>{totalCourses} total courses</span>
      </div>
    </div>
  );
};

export default ProgressBar;
