import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Select from 'react-select';
import TextComponent from '../components/TextComponent';
import MediaComponent from '../components/MediaComponent';

const Container = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const SelectWrapper = styled.div`
  margin-bottom: 20px;
`;

const PollContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
`;

const Question = styled.h2`
  color: #333;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
`;

const AnswerButton = styled.div`
  display: block;
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  background-color: ${props => props.selected ? "#ffcccb" : "#f5f5f5"};
  border: 2px solid ${props => props.selected ? "#ff0000" : "#ddd"};
  border-radius: 8px;
  font-size: 16px;
  text-align: left;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
`;

const AnswerBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: ${props => props.selected ? "rgba(255, 0, 0, 0.2)" : "rgba(0, 0, 255, 0.1)"};
  width: ${props => props.percentage}%;
  z-index: 1;
`;

const AnswerText = styled.span`
  position: relative;
  z-index: 2;
`;

const AnswerStats = styled.span`
  float: right;
  font-weight: bold;
`;

const ResultMessage = styled.p`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  color: ${props => props.isCorrect ? "green" : "red"};
  width: 100%;
`;

const AssetContainer = styled.div`
  border: 1px solid #b3e0ff;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #e6f7ff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const AssetInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
`;

const AssetNameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AssetName = styled.h3`
  font-size: 1.4em;
  margin: 0;
  padding: 0;
`;

const FeedName = styled.p`
  font-size: 0.9em;
  margin: 5px 0 0 0;
  color: #666;
`;

const AssetCreationTime = styled.p`
  font-size: 0.8em;
  color: #666;
  margin: 0;
  text-align: right;
`;

const BrandContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const BrandIcon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
`;

const BrandImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const BrandName = styled.p`
  font-size: 1em;
  margin: 0;
`;

const ReactionCard = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  background-color: #ffffff;
`;

const ReactionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const UserInfo = styled.div`
  font-weight: bold;
`;

const ReactionTime = styled.div`
  color: #666;
  font-size: 0.9em;
`;

const ReactionContent = styled.div`
  margin-bottom: 10px;
`;

const FeedDetailsContainer = styled.div`
  background-color: #f8f8f8;
  border-top: 1px solid #eaeaea;
  padding: 10px;
  margin-top: 15px;
  font-size: 14px;
`;

const FeedDetailsHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const FeedDetailsContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FeedDetailsTimes = styled.div`
  display: flex;
`;

const FeedDetailsTime = styled.span`
  margin-right: 15px;
`;

const PollChoiceButton = styled.div`
  display: block;
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  background-color: ${props => props.selected ? "#ffcccb" : "#f5f5f5"};
  border: 2px solid ${props => props.selected ? "#ff0000" : "#ddd"};
  border-radius: 8px;
  font-size: 16px;
  text-align: left;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
`;

const CommentBubble = styled.div`
  background-color: #e6f2ff;
  border-radius: 12px;
  padding: 10px 15px;
  margin-top: 10px;
  position: relative;
  
  &:before {
    content: '';
    position: absolute;
    top: -10px;
    left: 15px;
    border-width: 0 10px 10px;
    border-style: solid;
    border-color: transparent transparent #e6f2ff;
  }
`;

const MediaReactionContainer = styled.div`
  margin-top: 10px;
`;

const MediaReactionImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 8px;
`;

const PollResult = ({ assetContent, userChoices, allResponses }) => {
  const totalResponses = Object.values(allResponses).reduce((sum, count) => sum + count, 0);

  return (
    <PollContainer>
      <Question>{assetContent.question}</Question>
      {Object.entries(assetContent.selections).map(([key, value]) => {
        const responseCount = allResponses[value] || 0;
        const percentage = totalResponses > 0 ? (responseCount / totalResponses) * 100 : 0;
        
        return (
          <AnswerButton
            key={key}
            selected={userChoices.includes(value)}
          >
            <AnswerBar percentage={percentage} selected={userChoices.includes(value)} />
            <AnswerText>
              {value}
              <AnswerStats>
                {responseCount} ({percentage.toFixed(1)}%)
              </AnswerStats>
            </AnswerText>
          </AnswerButton>
        );
      })}
      {assetContent.pollType === "quiz" && (
        <ResultMessage isCorrect={userChoices.includes(assetContent.answer)}>
          {userChoices.includes(assetContent.answer) 
            ? "Correct!" 
            : `Incorrect. The correct answer was "${assetContent.answer}"`}
        </ResultMessage>
      )}
    </PollContainer>
  );
};

const FeedDetails = ({ sessionLink, sessionStartTime, sessionEndTime }) => {
  return (
    <FeedDetailsContainer>
      <FeedDetailsHeader>
        <span role="img" aria-label="info" style={{ marginRight: '5px' }}>ℹ️</span>
        <strong>Feed Details</strong>
      </FeedDetailsHeader>
      <FeedDetailsContent>
        <FeedDetailsTimes>
          <FeedDetailsTime>
            <span role="img" aria-label="clock" style={{ marginRight: '5px' }}>🕒</span>
            Start: {new Date(sessionStartTime).toLocaleString()}
          </FeedDetailsTime>
          <FeedDetailsTime>
            <span role="img" aria-label="clock" style={{ marginRight: '5px' }}>🕒</span>
            End: {new Date(sessionEndTime).toLocaleString()}
          </FeedDetailsTime>
        </FeedDetailsTimes>
        <div>
          <span role="img" aria-label="link" style={{ marginRight: '5px' }}>🔗</span>
          <a href={sessionLink} target="_blank" rel="noopener noreferrer">Session Link</a>
        </div>
      </FeedDetailsContent>
    </FeedDetailsContainer>
  );
};

const AssetReactions = () => {
  const [assets, setAssets] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [reactions, setReactions] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAssets();
  }, []);

  const fetchAssets = async () => {
    try {
      const response = await axios.get('https://genz-staging.feeltiptop.com/api/getAssets');
      const formattedAssets = response.data.map(asset => ({
        value: asset.AssetID,
        label: `${asset.AssetName}: ${asset.Caption}`
      }));
      setAssets(formattedAssets);
    } catch (err) {
      setError('Failed to fetch assets. Please try again.');
    }
  };

  const fetchReactions = async (assetId) => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get(`https://genz-staging.feeltiptop.com/api/asset_reaction`, { 
        params: { assetID: assetId } 
      });
      const data = response.data;
      if (data.success) {
        setReactions(data.reactions);
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to fetch reactions. Please try again.');
    }
    setLoading(false);
  };

  const handleAssetSelect = (selectedOption) => {
    setSelectedAsset(selectedOption);
    if (selectedOption) {
      fetchReactions(selectedOption.value);
    } else {
      setReactions([]);
      setError('');
    }
  };

  const renderAssetContent = (assetContent) => {
    if (assetContent.assetType === "poll") {
      const userChoices = reactions.flatMap(reaction => 
        reaction.reaction.reactionDetails.pollChoice 
          ? [reaction.reaction.reactionDetails.pollChoice] 
          : []
      );
      
      const allResponses = reactions.reduce((acc, reaction) => {
        const choice = reaction.reaction.reactionDetails.pollChoice;
        if (choice) {
          acc[choice] = (acc[choice] || 0) + 1;
        }
        return acc;
      }, {});

      return <PollResult 
        assetContent={assetContent} 
        userChoices={userChoices} 
        allResponses={allResponses}
      />;
    } else if (assetContent.assetType === "text") {
      return <TextComponent assetContent={assetContent} />;
    } else if (assetContent.assetType === "media") {
      return <MediaComponent mediaLink={assetContent.mediaContent} />;
    } else {
      return <p>Unsupported asset type: {assetContent.assetType}</p>;
    }
  };

  const renderReactionContent = (reaction) => {
    const details = [];
    if (reaction.reaction.reactionDetails.ranking) {
      details.push(
        <div key="ranking">
          <strong>Ranking:</strong> {reaction.reaction.reactionDetails.ranking}
        </div>
      );
    }
    if (reaction.reaction.reactionDetails.pollChoice) {
      details.push(
        <PollChoiceButton key="pollChoice" selected={true}>
          {reaction.reaction.reactionDetails.pollChoice}
        </PollChoiceButton>
      );
    }
    if (reaction.reaction.reactionDetails.comment) {
      details.push(
        <CommentBubble key="comment">
          {reaction.reaction.reactionDetails.comment}
        </CommentBubble>
      );
    }
    if (reaction.reaction.reactionDetails.media) {
      details.push(
        <MediaReactionContainer key="media">
          <MediaReactionImage src={`https://genz-staging.feeltiptop.com/${reaction.reaction.reactionDetails.media}`} alt="Media Reaction" />
        </MediaReactionContainer>
      );
    }
    return details;
  };

  return (
    <Container>
      <SelectWrapper>
        <Select
          options={assets}
          value={selectedAsset}
          onChange={handleAssetSelect}
          placeholder="Search and select an asset"
          isClearable
        />
      </SelectWrapper>

      {error && (
        <div style={{ color: 'red', marginBottom: '20px' }}>
          <strong>Error:</strong> {error}
        </div>
      )}

      {loading && <p>Loading reactions...</p>}

      {selectedAsset && reactions.length > 0 && (
        <div>
          <AssetContainer>
            <AssetInfoContainer>
              <AssetNameContainer>
                <AssetName>{reactions[0].asset.assetName}</AssetName>
                <FeedName>{reactions[0].feed.name}</FeedName>
              </AssetNameContainer>
              <AssetCreationTime>
                🕒 {new Date(reactions[0].asset.assetCreationTime).toLocaleString()}
              </AssetCreationTime>
            </AssetInfoContainer>
            
            <BrandContainer>
              <BrandIcon>
                {reactions[0].brand.brandProfilePicture ? (
                  <BrandImage 
                    src={`https://genz-staging.feeltiptop.com/${reactions[0].brand.brandProfilePicture}`} 
                    alt={reactions[0].brand.brandName}
                  />
                ) : (
                  <BrandImage 
                  src="https://upload.wikimedia.org/wikipedia/commons/a/a7/Blank_image.jpg" 
                  alt="Blank Company Icon"
                />
              )}
            </BrandIcon>
            <BrandName>{reactions[0].brand.brandName}</BrandName>
          </BrandContainer>
          
          {renderAssetContent(reactions[0].asset.assetContent)}
        </AssetContainer>
        
        <div style={{ marginTop: '20px' }}>
          <h4 style={{ fontWeight: 'bold' }}>Reactions:</h4>
          {reactions.map((reaction) => (
            <ReactionCard key={reaction.reaction.reactionID}>
              <ReactionHeader>
                <UserInfo>
                  {`${reaction.user.firstName} ${reaction.user.lastName} (${reaction.user.userName})`}
                </UserInfo>
                <ReactionTime>
                  🕒 {new Date(reaction.reaction.reactionTime).toLocaleString()}
                </ReactionTime>
              </ReactionHeader>
              <ReactionContent>
                {renderReactionContent(reaction)}
              </ReactionContent>
              <FeedDetails 
                sessionLink={reaction.feed.sessionLink}
                sessionStartTime={reaction.feed.sessionStartTime}
                sessionEndTime={reaction.feed.sessionEndTime}
              />
            </ReactionCard>
          ))}
        </div>
      </div>
    )}
  </Container>
);
};

export default AssetReactions;