import { useState, useEffect } from "react";

// Each survey poll will have the following attributes:
// 1. question
// 2. selections: object with key as the letter and value as the selection
function PollSurvey({ surveyPolls, setSurveyPolls }) {
  useEffect(() => {
    console.log(surveyPolls);
  }, [surveyPolls]);

  const addPoll = () => {
    if (surveyPolls.length >= 10) {
      return;
    }
    setSurveyPolls((prev) => [
      ...prev,
      {
        question: "",
        selections: {
          a: "",
          b: "",
        },
      },
    ]);
  };

  const handleQuestionChange = (index, newQuestion) => {
    const updatedPolls = [...surveyPolls];
    updatedPolls[index].question = newQuestion;
    setSurveyPolls(updatedPolls);
  };

  const handleOptionChange = (pollIndex, optionKey, newValue) => {
    const updatedPolls = [...surveyPolls];
    updatedPolls[pollIndex].selections[optionKey] = newValue;
    setSurveyPolls(updatedPolls);
  };

  const deletePoll = (pollIndex) => {
    const updatedPolls = [...surveyPolls];
    updatedPolls.splice(pollIndex, 1);
    setSurveyPolls(updatedPolls);
  };

  const addOption = (pollIndex) => {
    const updatedPolls = [...surveyPolls];
    const currentSelections = updatedPolls[pollIndex].selections;
    const keys = Object.keys(currentSelections);

    if (keys.length < 6) {
      // Limit to 6 options (a-f)
      const nextKey = String.fromCharCode(97 + keys.length); // a, b, c, etc.
      updatedPolls[pollIndex].selections[nextKey] = "";
      setSurveyPolls(updatedPolls);
    }
  };

  return (
    <div className="mx-auto w-full max-w-4xl p-4">
      <h1 className="mb-6 text-2xl font-bold">Poll Survey</h1>

      {/* Iterate through the survey Polls */}
      {surveyPolls.map((poll, index) => (
        <div
          key={index}
          className="relative mb-8 rounded-lg border bg-white p-4 shadow"
        >
          <button
            type="button"
            className="absolute right-2 top-2 flex h-8 w-8 items-center justify-center rounded-full bg-red-100 text-red-600 transition-colors hover:bg-red-200"
            onClick={() => deletePoll(index)}
          >
            <span className="text-xl font-medium">×</span>
          </button>
          <div className="mb-4">
            <label className="mb-2 block text-sm font-medium">
              Question {index + 1}
            </label>
            <input
              type="text"
              value={poll.question}
              onChange={(e) => handleQuestionChange(index, e.target.value)}
              className="w-full rounded-lg border border-gray-300 p-2 focus:ring focus:ring-blue-200"
              placeholder="Enter your question here"
              required
            />
          </div>

          <div className="mb-4">
            <label className="mb-2 block text-sm font-medium">Options</label>
            {/* Iterate through the selections of each poll */}
            {Object.keys(poll.selections).map((key) => (
              <div key={key} className="mb-2 flex items-center">
                <span className="mr-2 font-medium">{key.toUpperCase()}:</span>
                <input
                  type="text"
                  value={poll.selections[key]}
                  onChange={(e) =>
                    handleOptionChange(index, key, e.target.value)
                  }
                  className="flex-1 rounded-lg border border-gray-300 p-2 focus:ring focus:ring-blue-200"
                  placeholder={`Option ${key.toUpperCase()}`}
                  required
                />
              </div>
            ))}

            {Object.keys(poll.selections).length > 2 && (
              <button
                type="button"
                onClick={() => deletePoll(index)}
                className="mt-2 rounded bg-red-500 px-3 py-1 text-white hover:bg-red-600"
              >
                Delete Option
              </button>
            )}
            <button
              type="button"
              onClick={() => addOption(index)}
              className="mt-2 rounded bg-gray-200 px-3 py-1 text-gray-700 hover:bg-gray-300"
            >
              + Add Option
            </button>
          </div>
        </div>
      ))}

      {/* Add a new poll */}
      {surveyPolls.length < 10 && (
        <div>
          <button
            type="button"
            onClick={addPoll}
            className="rounded-lg bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
          >
            Add New Poll
          </button>
        </div>
      )}
    </div>
  );
}

export default PollSurvey;
