import React, { useState, useEffect, useRef } from "react";
import { Mic, FileUp, Video, PlayCircle, Send, Upload, AudioLines as UploadIcon, AudioLines } from "lucide-react";
import axios from "axios";
import ToolTip from "./ToolTip";

export default function FeedCommentBox(props) {
  const [currentComment, setCurrentComment] = useState("");
  const [media, setMedia] = useState(null);
  const [mediaPreview, setMediaPreview] = useState(null);
  const [speech, setSpeech] = useState(null);
  const [error, setError] = useState("");
  const [isFirefox, setIsFirefox] = useState(false);
  const [browserCompatibilityError, setBrowserCompatibilityError] = useState(false);
  const microphoneButtonRef = useRef(null);
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const audioRef = useRef(null);
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const recognitionRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [scores, setScores] = useState("None");
  const [isSTTLoading, setIsSTTLoading] = useState(false);
  const fileInputRef = useRef(null);
  const audioFileInputRef = useRef(null);

  useEffect(() => {
    setCurrentComment("");
    setMedia(null);
    setMediaPreview(null);
    setSpeech(null);
    setAudioBlob(null);
    setAudioUrl(null);
  }, [props.activeAssetId]);

  useEffect(() => {
    setIsFirefox(typeof InstallTrigger !== "undefined");
  }, []);

  useEffect(() => {
    setupMicrophone();
  }, [isFirefox]);

  useEffect(() => {
    return () => {
      if (mediaPreview) {
        URL.revokeObjectURL(mediaPreview);
      }
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
    };
  }, [mediaPreview, audioUrl]);

  useEffect(() => {
    if (audioBlob) {
      const url = URL.createObjectURL(audioBlob);
      setAudioUrl(url);
      return () => URL.revokeObjectURL(url);
    }
  }, [audioBlob]);

  const setupMicrophone = () => {
    if (isFirefox) {
      setBrowserCompatibilityError(true);
      setError(
        "Speech recognition is not supported in Firefox. Please use a different browser."
      );
      return;
    }

    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    if (!SpeechRecognition) {
      setBrowserCompatibilityError(true);
      setError(
        "Speech recognition is not supported in this browser. Please use a different browser."
      );
      return;
    }

    recognitionRef.current = new SpeechRecognition();
    recognitionRef.current.continuous = true;
    recognitionRef.current.interimResults = true;
    recognitionRef.current.maxAlternatives = 1;

    recognitionRef.current.onresult = (event) => {
      let finalTranscript = "";
      let interimTranscript = "";

      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcriptPart = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscript += transcriptPart + " ";
        } else {
          interimTranscript += transcriptPart;
        }
      }

      if (finalTranscript) {
        setCurrentComment((prev) => prev + finalTranscript);
      }
    };

    recognitionRef.current.onerror = (event) => {
      console.error("Speech recognition error: ", event.error);
    };
  };

  const handleFileInputClick = (inputRef) => {
    inputRef.current.click();
  };

  const getFileNameFromInput = (file) => {
    if (!file) return "";
    return file.name.length > 20 ? file.name.substring(0, 20) + "..." : file.name;
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };
      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/webm",
        });
        setAudioBlob(audioBlob);
        audioChunksRef.current = [];
      };
      mediaRecorderRef.current.start();
      setIsRecording(true);

      if (recognitionRef.current) {
        recognitionRef.current.lang = "en-US";
        recognitionRef.current.start();
        setCurrentComment("");
      }
    } catch (error) {
      console.error("Error accessing microphone:", error);
      setError("Could not access microphone. Please check permissions.");
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
    if (recognitionRef.current) {
      recognitionRef.current.stop();
    }
  };

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const handleMediaChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/tiff",
      "video/mp4",
      "video/ogg",
      "audio/mp3",
      "audio/mpeg",
      "audio/wav",
    ];
    
    if (file && allowedTypes.includes(file.type)) {
      setError("");
      setMedia(file);
      
      const previewUrl = URL.createObjectURL(file);
      if (file.type.startsWith('audio/')) {
        setAudioUrl(previewUrl);
        setMediaPreview(null);
      } else {
        setMediaPreview(previewUrl);
        setAudioUrl(null);
      }
    } else {
      setError("Invalid file type. Please select a valid media file.");
      setMedia(null);
      setMediaPreview(null);
      setAudioUrl(null);
    }
  };

  const handleTextChange = async (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["audio/mp3", "audio/wav", "video/mp4", "audio/mpeg"];
    
    if (file && allowedTypes.includes(file.type)) {
      setError("");
      setSpeech(file);
      setIsSTTLoading(true);
      
      try {
        const formData = new FormData();
        formData.append("file", file);
  
        const response = await axios.post(
          "https://stt.tiptopbest.com/upload",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
  
        if (response.data && response.data.transcript) {
          setCurrentComment(response.data.transcript);
          setError("Transcription successful!");
        } else {
          setError("Failed to transcribe the audio file.");
        }
      } catch (error) {
        console.error("Transcription error:", error);
        setError("Error transcribing the audio file. Please try again.");
      } finally {
        setIsSTTLoading(false);
      }
  
      const previewUrl = URL.createObjectURL(file);
      setAudioUrl(previewUrl);
      setMediaPreview(null);
    } else {
      setError("Invalid file type. Please select a valid audio or video file.");
      setSpeech(null);
      setAudioUrl(null);
    }
  };

  const submitComment = async () => {
    if (props.activeAssetId && (currentComment || media || audioBlob)) {
      try {
        if (media) {
          const formData = new FormData();
          formData.append("assetID", props.activeAssetId);
          formData.append("feedID", props.feedID);
          formData.append("media", media);
          if (currentComment) {
            formData.append("comment", currentComment);
          }

          const response = await axios.post(
            "https://genz-staging.feeltiptop.com/api/media_response",
            formData,
            {
              headers: {
                Authorization: "Bearer " + props.token,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (response.data.success) {
            props.onCommentSubmit();
            resetForm();
          } else {
            setError("Failed to submit media. Please try again.");
          }
        } else if (currentComment) {
          const commentData = {
            assetID: props.activeAssetId,
            feedID: props.feedID,
            comment: currentComment,
          };

          const response = await axios.post(
            "https://genz-staging.feeltiptop.com/api/comment",
            commentData,
            {
              headers: {
                Authorization: "Bearer " + props.token,
              },
            }
          );

          if (response.data.success) {
            props.onCommentSubmit();
            resetForm();
          } else {
            setError("Failed to submit comment. Please try again.");
          }
        }
      } catch (error) {
        console.error("Error submitting:", error);
        setError("An error occurred. Please try again.");
      }
    } else {
      setError("Please enter a comment or upload media before submitting.");
    }
  };

  const resetForm = () => {
    setCurrentComment("");
    setMedia(null);
    setMediaPreview(null);
    setSpeech(null);
    setAudioBlob(null);
    setAudioUrl(null);
    props.setIsCommentModalOpen(false);
    props.setActiveAssetId(null);
  };

  const submitTranscript = async () => {
    if (props.activeAssetId && speech) {
      try {
        const formData = new FormData();
        formData.append("file", speech);

        const response = await axios.post(
          "https://stt.tiptopbest.com/upload",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        const { data } = response;
        if (data && data.transcript) {
          setCurrentComment(data.transcript);
          setError("Transcript obtained successfully.");
        } else {
          setError("Failed to process the file.");
        }
      } catch (error) {
        setError("Error processing the file.");
      }
    }
  };

  const convertWebmBlobToWav = async (webmBlob) => {
    const audioContext = new AudioContext();
    const arrayBuffer = await webmBlob.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    return audioBufferToWav(audioBuffer);
  };

  const audioBufferToWav = (audioBuffer) => {
    const numOfChannels = audioBuffer.numberOfChannels;
    const sampleRate = audioBuffer.sampleRate;
    const format = 1;
    const bitDepth = 16;

    const resultBuffer = new ArrayBuffer(
      44 + audioBuffer.length * numOfChannels * 2
    );
    const view = new DataView(resultBuffer);

    const writeString = (view, offset, string) => {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    };

    writeString(view, 0, "RIFF");
    view.setUint32(4, 36 + audioBuffer.length * numOfChannels * 2, true);
    writeString(view, 8, "WAVE");
    writeString(view, 12, "fmt ");
    view.setUint32(16, 16, true);
    view.setUint16(20, format, true);
    view.setUint16(22, numOfChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * numOfChannels * 2, true);
    view.setUint16(32, numOfChannels * 2, true);
    view.setUint16(34, bitDepth, true);
    writeString(view, 36, "data");
    view.setUint32(40, audioBuffer.length * numOfChannels * 2, true);

    let offset = 44;
    for (let i = 0; i < audioBuffer.length; i++) {
      for (let channel = 0; channel < numOfChannels; channel++) {
        const sample = Math.max(
          -1,
          Math.min(1, audioBuffer.getChannelData(channel)[i])
        );
        view.setInt16(offset, sample * 0x7fff, true);
        offset += 2;
      }
    }

    return new Blob([view], { type: "audio/wav" });
  };

  const submitSTT = async () => {
    setIsSTTLoading(true);
    if (props.activeAssetId && audioBlob) {
      try {
        const wavBlob = await convertWebmBlobToWav(audioBlob);
        const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
        const filename = `recording_${timestamp}.wav`;
        const audioFile = new File([wavBlob], filename, {
          type: "audio/wav",
          lastModified: new Date().getTime(),
        });

        const formDataSpeechAssessment = new FormData();
        formDataSpeechAssessment.append("caption", props.caption);
        formDataSpeechAssessment.append("assetID", props.activeAssetId);
        formDataSpeechAssessment.append("feedID", props.feedID);
        formDataSpeechAssessment.append("file", audioFile);

        const responseSpeechAssessment = await axios.post(
          "https://genz-staging.feeltiptop.com/api/speech_assessment",
          formDataSpeechAssessment,
          {
            headers: {
              Authorization: "Bearer " + props.token,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        setScores(responseSpeechAssessment.data.scores);

        const formDataMediaResponse = new FormData();
        formDataMediaResponse.append("assetID", props.activeAssetId);
        formDataMediaResponse.append("feedID", props.feedID);
        formDataMediaResponse.append("media", audioFile);

        const responseMediaResponse = await axios.post(
          "https://genz-staging.feeltiptop.com/api/media_response",
          formDataMediaResponse,
          {
            headers: {
              Authorization: "Bearer " + props.token,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (responseMediaResponse.data.success) {
          props.onCommentSubmit();
          resetForm();
        } else {
          setError("Failed to submit media. Please try again.");
        }
      } catch (error) {
        console.error("Error submitting media:", error);
        setError(
          "An error occurred while submitting the media. Please try again."
        );
      } finally {
        setIsSTTLoading(false);
      }
    } else {
      setError("Please record audio before submitting.");
      setIsSTTLoading(false);
    }
  };

  return (
    <>
      {/* Background Overlay */}
      {props.isCommentModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
          onClick={() => props.setIsCommentModalOpen(false)}
        />
      )}

      {/* Comment Modal */}
      <div
        className={`fixed left-1/2 top-1/2 z-50 h-[80%] w-[92%] max-w-2xl -translate-x-1/2 -translate-y-1/2 transform rounded-md bg-white shadow-lg ${
          props.isCommentModalOpen ? "block" : "hidden"
        }`}
      >
        <div className="flex h-full flex-col">
          <div className="relative flex flex-col h-full rounded-lg border border-gray-200 bg-gray-50">
            <div className="flex-grow">
              <textarea
                className="w-full h-full resize-none rounded-t-lg bg-transparent p-4 outline-none"
                value={currentComment}
                onChange={(e) => setCurrentComment(e.target.value)}
                placeholder="Type your comment here..."
              />
            </div>

            {/* Preview Area */}
            {(media || audioBlob || speech || mediaPreview || audioUrl) && (
              <div className="border-t border-gray-200 bg-gray-100 p-4">
                {/* Image Preview */}
                {mediaPreview && media?.type.startsWith('image/') && (
                  <div className="mb-2">
                    <img 
                      src={mediaPreview} 
                      alt="Preview" 
                      className="max-h-48 rounded-lg object-contain"
                    />
                  </div>
                )}
                
                {/* Video Preview */}
                {mediaPreview && media?.type.startsWith('video/') && (
                  <div className="mb-2">
                    <video 
                      ref={videoRef}
                      src={mediaPreview} 
                      controls 
                      className="max-h-48 w-full rounded-lg object-contain"
                    />
                  </div>
                )}
                
                {/* Audio Preview */}
                {audioUrl && (
                  <div className="mb-2">
                    <audio 
                      ref={audioRef}
                      src={audioUrl} 
                      controls 
                      className="w-full"
                    />
                  </div>
                )}

                <p className="text-sm text-gray-600">
                  Selected: {getFileNameFromInput(media || speech)}
                  {audioBlob && "Recorded Audio"}
                </p>
              </div>
            )}

            {/* Audio Visualizer */}
            {props.isSpeechAsset && audioBlob && (
              <div className="border-t border-gray-200">
                <canvas id="audio-visualizer" className="h-16 w-full"></canvas>
              </div>
            )}

            {/* Error Message */}
            {error && (
              <div className="border-t border-gray-200 bg-red-50 p-2">
                <p className="text-sm text-red-600">{error}</p>
              </div>
            )}

            {/* Integrated Action Bar */}
            <div className="flex items-center justify-between bg-gray-50 p-3">
              <div className="flex items-center gap-4">
                {/* Hidden File Inputs */}
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleMediaChange}
                  accept="image/*, video/mp4, audio/*"
                  className="hidden"
                />
                <input
                  type="file"
                  ref={audioFileInputRef}
                  onChange={handleTextChange}
                  accept="audio/*, video/mp4"
                  className="hidden"
                />

                {/* Media Upload Button */}
                <button
                  onClick={() => handleFileInputClick(fileInputRef)}
                  className="flex items-center gap-2 rounded-md p-2 text-gray-700 hover:bg-gray-200"
                  title="Upload Media"
                >
                  <FileUp className="h-5 w-5" />
                </button>

                {/* Audio Upload Button */}
                <button
                  onClick={() => handleFileInputClick(audioFileInputRef)}
                  className="flex items-center gap-2 rounded-md p-2 text-gray-700 hover:bg-gray-200"
                  title="Upload Audio for Transcription"
                  disabled={isSTTLoading}
                >
                  {isSTTLoading ? (
                    <div className="h-5 w-5 animate-spin rounded-full border-2 border-gray-300 border-t-blue-600" />
                  ) : (
                    <AudioLines className="h-5 w-5" />
                  )}
                </button>

                {/* Microphone Button */}
                {!browserCompatibilityError && (
                  <button
                    onClick={isRecording ? stopRecording : startRecording}
                    className={`flex items-center gap-2 rounded-md p-2 ${
                      isRecording ? "bg-red-100 text-red-600" : "text-gray-700 hover:bg-gray-200"
                    }`}
                    ref={microphoneButtonRef}
                    title="Record Audio"
                  >
                    <Mic className="h-5 w-5" />
                  </button>
                )}

                {/* Upload Speech Assessment Button */}
                {props.isSpeechAsset && audioBlob && (
                  <>
                    <button
                      onClick={submitSTT}
                      disabled={(!audioBlob && !media) || isSTTLoading}
                      className={`flex items-center gap-2 rounded-md p-2 ${
                        isSTTLoading || (!audioBlob && !media)
                          ? "cursor-not-allowed text-gray-400"
                          : "text-gray-700 hover:bg-gray-200"
                      }`}
                      title="Submit Speech Assessment"
                    >
                      <UploadIcon className="h-5 w-5" />
                    </button>
                    <ToolTip
                      buttonText="Score"
                      tooltipText={`Your Score: ${scores}`}
                    />
                  </>
                )}
              </div>

              {/* Send Button */}
              <button
                className={`rounded-full p-2 transition-colors duration-300 ${
                  !currentComment && !media && !audioBlob
                    ? "cursor-not-allowed bg-gray-200 text-gray-400"
                    : "bg-blue-500 text-white hover:bg-blue-600"
                }`}
                disabled={!currentComment && !media && !audioBlob}
                onClick={submitComment}
                title="Send Comment"
              >
                <Send className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}