import React, { useEffect, useState } from "react";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckPoints = () => {
  const [points, setPoints] = useState(0);
  const [amountToAdd, setAmountToAdd] = useState("");
  const [loading, setLoading] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [message, setMessage] = useState("");
  const location = useLocation();

  useEffect(() => {
    fetchPoints();
    const params = new URLSearchParams(location.search);
    if (params.get("payment_success") === "true") {
      alert("Payment was successful! Fetching updated points...");
      fetchPoints();
    }
  }, [location]);

  const fetchPoints = async () => {
    try {
      const response = await axios.get(
        "https://genz-staging.feeltiptop.com/api/payment/get-points",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      const fetchedPoints = parseFloat(response.data.Points) || 0;
      setPoints(fetchedPoints);
    } catch (error) {
      console.error(
        "Error fetching points:",
        error.response ? error.response.data : error.message,
      );
    }
  };

  const handleAddtoPoints = async () => {
    const parsedAmount = parseFloat(amountToAdd);
    const user_id = localStorage.getItem("user_id");

    if (isNaN(parsedAmount) || parsedAmount <= 0) {
      setMessage("Please enter a valid amount greater than 0.");
      return;
    }

    // Check for minimum amount (50 points)
    if (parsedAmount < 50) {
      setMessage("The minimum amount to add is 50 points.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        "https://genz-staging.feeltiptop.com/api/payment/create-checkout-session",
        {
          user_id: user_id,
          payment_amount: parsedAmount,
          transaction_type: "add",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      if (process.env.REACT_APP_TEST_MODE === "true") {
        console.log("points: ", points);
        console.log("parsedAmount: ", parsedAmount);

        const updatedPoints = parseFloat(points) + parsedAmount;
        console.log("updatedPoints: ", updatedPoints);

        setPoints(updatedPoints);
        setAmountToAdd("");
        setLoading(false);
        return;
      }

      if (response.data && response.data.id) {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: response.data.id,
        });
        if (error) {
          console.error("Error redirecting to Stripe Checkout:", error);
          alert(
            "An error occurred while redirecting to Stripe Checkout. Please try again.",
          );
        }
      } else {
        console.error(
          "Checkout session ID not found in response:",
          response.data,
        );
      }
    } catch (error) {
      console.error(
        "Error creating checkout session:",
        error.response ? error.response.data : error.message,
      );
      alert(
        "An error occurred while creating the checkout session. Please try again.",
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mx-auto mt-10 max-w-2xl rounded-lg bg-white p-8">
      <p className="mb-6 text-center text-2xl text-gray-700">
        Available Points:{" "}
        <span className="font-semibold">{points}</span>
        <span
          className="ml-2 cursor-pointer text-blue-600"
          onClick={() => setShowInfo(!showInfo)}
          aria-label="Info"
          role="img"
        >
          ℹ️
        </span>
      </p>
      {showInfo && (
        <div className="mb-4 p-4 text-sm text-gray-700 bg-gray-100 rounded">
          <strong>360 Points Information:</strong>
          <p>
            360 points are the currency used on our platform. 
            Currently, one dollar is equivalent to 100 points. Please note that the price of 360 points may change over time.
          </p>
          <button
            className="mt-2 text-sm text-blue-500 underline"
            onClick={() => setShowInfo(false)}
          >
            Close
          </button>
        </div>
      )}
      <div className="mt-6">
        <h2 className="mb-4 text-xl font-semibold">Add to Your Card</h2>
        <input
          type="number"
          value={amountToAdd}
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*$/.test(value)) {
              setAmountToAdd(value);
            }
          }}
          className="w-full rounded px-6 py-4 text-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Enter amount in 360 points (e.g., 100, 200.....)"
        />
        <button
          onClick={handleAddtoPoints}
          className={`mt-6 w-full rounded bg-blue-600 py-3 text-lg font-semibold text-white transition duration-200 hover:bg-blue-700 ${loading ? "cursor-not-allowed opacity-50" : ""}`}
          disabled={loading}
        >
          {loading ? "Adding..." : "Add Points"}
        </button>
        {/* Message for minimum points */}
        {message && (
          <p
            className={`mt-4 text-sm font-medium ${message.includes("minimum") ? "text-red-600" : "text-green-600"}`}
          >
            {message}
          </p>
        )}
      </div>
    </div>
  );
};

export default CheckPoints;