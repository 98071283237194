import React, { useState, useEffect } from "react";
import axios from "axios";
import { encrypttoHEX } from "./encodedecode.js";

function FeedConstruction(props) {
  const getCurrentTimestamp = () => {
    return new Date().toISOString().replace(/[-:]/g, "").split(".")[0];
  };

  const getDefaultStartTime = () => {
    const currentYear = new Date().getFullYear();
    return new Date(currentYear, 0, 1).toISOString().slice(0, 16);
  };

  const getDefaultEndTime = () => {
    const nextYear = new Date().getFullYear() + 1;
    return new Date(nextYear, 0, 1).toISOString().slice(0, 16);
  };

  const [formData, setFormData] = useState({
    Name: `Feed${getCurrentTimestamp()}`,
    SessionLink: "",
    SessionStartTime: getDefaultStartTime(),
    SessionEndTime: getDefaultEndTime(),
    MaxStarted: "10000",
    MaxCompleted: "10000",
  });

  const [assets, setAssets] = useState([]);
  const [assetPositions, setAssetPositions] = useState({});
  const [feedCreationSuccess, setFeedCreationSuccess] = useState(false);
  const [feedBrands, setFeedBrands] = useState([]);
  const [brandReportURLS, setBrandReportURLS] = useState([]);
  const [feedURL, setFeedURL] = useState("");
  const [feedID, setFeedID] = useState("");
  const [user, setUser] = useState("");

  const protocol = window.location.protocol;
  const hostname = window.location.hostname;
  const port = window.location.port;

  // Construct the baseURL using template literals
  const baseURL = `${protocol}//${hostname}${port ? ":" + port : ""}/`;

  useEffect(() => {
    axios
      .get("https://genz-staging.feeltiptop.com/api/getAssets", {
        headers: {
          Authorization: "Bearer " + props.token,
        },
      })
      .then((response) => {
        setAssets(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleSelectChange = (e, assetID, brandID, brandName) => {
    const value = parseInt(e.target.value);
    const newAssetPositions = { ...assetPositions };
    const isBrandInArray = feedBrands.some(
      (brand) => brand.brandID === brandID,
    );
    if (value !== 0) {
      if (Object.values(newAssetPositions).includes(value)) {
        alert(
          `Duplicate position selected: ${value}. Each asset must have a unique position.`,
        );
        return;
      }
      newAssetPositions[assetID] = value;
      if (!isBrandInArray) {
        setFeedBrands([
          ...feedBrands,
          { brandID: brandID, brandName: brandName },
        ]);
      }
    } else {
      delete newAssetPositions[assetID];
      if (isBrandInArray) {
        setFeedBrands(feedBrands.filter((item) => item.brandID !== brandID));
      }
    }
    setAssetPositions(newAssetPositions);
  };

  const handleInputChange = (e, field) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const now = new Date();
    const oneHourLater = new Date(now.getTime() + 60 * 60 * 1000);
    const formatDateTime = (date) =>
      date.toISOString().slice(0, 19).replace("T", " ");
    const submissionData = {
      ...formData,
      SessionStartTime: formData.SessionStartTime
        ? formatDateTime(new Date(formData.SessionStartTime))
        : formatDateTime(now),
      SessionEndTime: formData.SessionEndTime
        ? formatDateTime(new Date(formData.SessionEndTime))
        : formatDateTime(oneHourLater),
      SessionLink: feedURL,
    };
    console.log(submissionData);
    console.log("Token being sent:", props.token);
    axios
      .post("https://genz-staging.feeltiptop.com/api/addFeed", submissionData, {
        headers: {
          Authorization: `Bearer  ${localStorage.getItem("authToken")}`,
        },
      })
      .then((response) => {
        const tfeedId = response.data.FeedID;
        console.log("tfeedID: ", tfeedId);
        setFeedID(tfeedId);
        createObfuscatedReportURL();
        addFeedAssets(tfeedId);
        setBrandReportURLS(
          feedBrands.map((br) => {
            let reporturl = createObfuscatedReportURL(br.brandID, tfeedId);
            return { id: br.brandID, name: br.brandName, reporturl: reporturl };
          }),
        );
        setFeedURL(baseURL + createObfuscatedFeedURL(tfeedId));
        console.log(feedURL);
        setFeedCreationSuccess(true);
        axios.post(
          "https://genz-staging.feeltiptop.com/api/addFeedURL",
          {
            FeedID: tfeedId,
            SessionLink: baseURL + createObfuscatedFeedURL(tfeedId),
          },
          {
            headers: {
              Authorization: "Bearer " + props.token,
            },
          },
        );
      })
      .catch((error) => {
        console.error("Error adding feed:", error);
        setFeedCreationSuccess(false);
      });
  };

  const addFeedUser = (feedId) => {
    axios
      .post(
        "https://genz-staging.feeltiptop.com/api/addFeedUser",
        {
          feedId,
        },
        {
          headers: {
            Authorization: "Bearer " + props.token,
          },
        },
      )
      .then((response) => {
        console.log("feed user added:", response.data);
      })
      .catch((error) => {
        console.error("Error adding feed user:", error);
      });
  };

  const addFeedAssets = (feedId) => {
    console.log(assetPositions);
    axios
      .post(
        "https://genz-staging.feeltiptop.com/api/addFeedAssets",
        {
          feedId,
          assets: assetPositions,
        },
        {
          headers: {
            Authorization: "Bearer " + props.token,
          },
        },
      )
      .then((response) => {
        console.log("Feed assets added:", response.data);
      })
      .catch((error) => {
        console.error("Error adding feed assets:", error);
      });
  };

  const createObfuscatedReportURL = (brandID, feedID) => {
    const encryptSTR = JSON.stringify({ feedID: feedID, brandID: brandID });
    const URL = "Report?p=" + encrypttoHEX(encryptSTR);
    console.log("Report URL: " + URL);
    return URL;
  };

  const createObfuscatedFeedURL = (feedID) => {
    const encryptSTR = JSON.stringify({ feedID: feedID });
    const URL = "Login?p=" + encrypttoHEX(encryptSTR);
    return URL;
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    console.log("Token being sent:", token);

    axios
      .get("https://genz-staging.feeltiptop.com/api/getCurrentUserInfo", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("User info response:", response.data);
        setUser(response.data.username);
      })
      .catch((error) => {
        console.error("Error fetching user info:", error);
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
          console.error("Response headers:", error.response.headers);
        }
      });
  }, []);

  return (
    <div className="flex h-screen w-full flex-col">
      <div className="mb-4 mt-4 text-xl font-bold">Hello {user} 👋</div>
      <div>
        <div>
          <div className="mx-auto max-w-7xl">
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              {/* Asset list */}
              <div className="border p-4">
                <h2 className="mb-4 text-lg font-semibold">Assets</h2>
                <div>
                  {assets.map((asset) => (
                    <div
                      key={asset.AssetID}
                      className="flex items-center justify-between border-b py-2"
                    >
                      <span className="text-sm">
                        {`${asset.AssetName}: "${asset.BrandName}" "${asset.Caption}"`}
                      </span>
                      <select
                        name={`assetPosition${asset.AssetID}`}
                        className="w-30 ml-2 rounded border p-1 text-sm"
                        onChange={(e) =>
                          handleSelectChange(
                            e,
                            asset.AssetID,
                            asset.BrandID,
                            asset.BrandName,
                          )
                        }
                      >
                        <option value="0">Not in feed</option>
                        {Array.from({ length: 100 }, (_, i) => (
                          <option key={i} value={`${i + 1}`}>
                            {i + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                  ))}
                </div>
              </div>

              {/* Feed construction form */}
              <div className="border p-4">
                <h2 className="font-semibo mb-4 text-lg">Feed Constructor</h2>
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-1 gap-4">
                    <div>
                      <label
                        htmlFor="Name"
                        className="block text-sm text-gray-700"
                      >
                        Name:
                      </label>
                      <input
                        type="text"
                        id="Name"
                        name="Name"
                        value={formData.Name}
                        onChange={(e) => handleInputChange(e, "Name")}
                        className="mt-1 block w-full rounded border p-2 text-sm"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="SessionStartTime"
                        className="block text-sm"
                      >
                        Session Start Time:
                      </label>
                      <input
                        type="datetime-local"
                        id="SessionStartTime"
                        name="SessionStartTime"
                        value={formData.SessionStartTime}
                        onChange={(e) =>
                          handleInputChange(e, "SessionStartTime")
                        }
                        className="mt-1 block w-full rounded border p-2 text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="SessionEndTime" className="block text-sm">
                        Session End Time:
                      </label>
                      <input
                        type="datetime-local"
                        id="SessionEndTime"
                        name="SessionEndTime"
                        value={formData.SessionEndTime}
                        onChange={(e) => handleInputChange(e, "SessionEndTime")}
                        className="mt-1 block w-full rounded border border-gray-300 p-2 text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="MaxStarted" className="block text-sm">
                        Max Started:
                      </label>
                      <input
                        type="number"
                        id="MaxStarted"
                        name="MaxStarted"
                        value={formData.MaxStarted}
                        onChange={(e) => handleInputChange(e, "MaxStarted")}
                        className="mt-1 block w-full rounded border p-2 text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="MaxCompleted" className="block text-sm">
                        Max Completed:
                      </label>
                      <input
                        type="number"
                        id="MaxCompleted"
                        name="MaxCompleted"
                        value={formData.MaxCompleted}
                        onChange={(e) => handleInputChange(e, "MaxCompleted")}
                        className="mt-1 block w-full rounded border p-2 text-sm"
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="mt-6 w-full bg-emerald-500 px-4 py-2 text-sm text-white transition-colors duration-300 hover:bg-emerald-600"
                  >
                    Construct Feed
                  </button>
                </form>
              </div>
            </div>

            {/* Constructed output */}
            {feedCreationSuccess && (
              <div className="mt-6">
                <div className="bg-green-100 p-4 text-green-700">
                  Feed Created Successfully!
                </div>

                <div className="mt-4">
                  <h3 className="text-lg">Feed URL:</h3>
                  <a
                    href={feedURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="break-all text-blue-600 hover:underline"
                  >
                    {feedURL}
                  </a>
                </div>

                <div className="mt-4">
                  <h3 className="text-lg">Brand Report URLs:</h3>
                  <ul className="list-inside list-disc">
                    {brandReportURLS.map((branddetails) => (
                      <li key={branddetails.id}>
                        {branddetails.name} -{" "}
                        <a
                          href={`${baseURL}${branddetails.reporturl}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="break-all text-blue-600 hover:underline"
                        >
                          {`${baseURL}${branddetails.reporturl}`}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="mt-4">
                  <h3 className="text-lg">Feed Data Dump:</h3>
                  <a
                    href={`${baseURL}FeedSessionExport?feedid=${feedID}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="break-all text-blue-600 hover:underline"
                  >
                    {`${baseURL}FeedSessionExport?feedid=${feedID}`}
                  </a>
                </div>

                <div className="mb-4 mt-4">
                  <h3 className="text-lg">Ambassador's Data Dump:</h3>
                  <a
                    href={`${baseURL}AmbassadorProfiles`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="break-all text-blue-600 hover:underline"
                  >
                    {`${baseURL}AmbassadorProfiles`}
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeedConstruction;
