import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import {
  TextField,
  Button,
  FormGroup,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import backArrow from "../assets/images/arrow_back.svg";
import GenZLandingPageWallpaper from "../assets/images/GenZLandingPageWallpaper.svg";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function ResetPassword() {
  const { email, token } = useParams(); // Get email and token from URL
  const [newPassword, setNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const navigate = useNavigate(); // Use useNavigate for navigation

  useEffect(() => {
    if (!email || !token) {
      setError("Invalid reset link.");
      return;
    }
    console.log("Raw URL Token:", token);
    console.log("Raw Email:", email);
    const decodedEmail = decodeURIComponent(email);
    const decodedToken = decodeURIComponent(token);
    console.log("Decoded Token:", decodedToken); 
    console.log("Decoded Email:", decodedEmail); 

  }, [email, token]);

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const checkPassword = (pw) => {
    const decimal =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    return pw !== "" && decimal.test(pw);
  };

  const handleSubmit = async () => {
    setButtonClicked(true);
    setMessage("");
    setError("");

    const hashedPassword = CryptoJS.SHA256(newPassword).toString();

    if (!checkPassword(newPassword)) {
      setError(
        "Password must be between 8-15 characters, include at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      setButtonClicked(false);
      return;
    }

    try {
      const response =await axios.post(
        `https://genz-staging.feeltiptop.com/api/user/resetPassword/${encodeURIComponent(email)}/${encodeURIComponent(token)}`,
        { newPassword: hashedPassword }
      );      
      console.log("Response:", response);
      if (response.data.message) {
        setMessage(response.data.message);
        setTimeout(() => {
          navigate("/login"); // Redirect to login page after successful reset
        }, 2000);
      } else {
        setError(response.data.error || "Error resetting password. Please try again.");
      }
    } catch (error) {
      setError("Error resetting password. Please try again.");
    } finally {
      setButtonClicked(false);
    }
  };


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      className="flex min-h-screen items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: `url(${GenZLandingPageWallpaper})` }}
    >
      <div className="relative mx-4 w-full max-w-lg rounded-lg border bg-white p-4 shadow-xl">
        <div className="mb-4">
          <Link to="/">
            <img src={backArrow} alt="Back" className="h-6 w-6" />
          </Link>
        </div>

        <h2 className="mb-6 text-center text-2xl font-semibold">Reset Password</h2>

        <FormGroup className="flex w-full flex-col space-y-4">
          <div className="w-full">
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={newPassword}
                onChange={handlePasswordChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={showPassword ? "Hide password" : "Show password"}
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              {error && <FormHelperText error>{error}</FormHelperText>}
            </FormControl>
          </div>
        </FormGroup>

        <button
          className={`mt-4 w-full rounded-lg px-4 py-2 font-semibold text-white ${
            newPassword.length === 0 || buttonClicked
              ? "cursor-not-allowed bg-gray-400"
              : "bg-blue-600 hover:bg-blue-700"
          }`}
          disabled={newPassword.length === 0 || buttonClicked}
          onClick={handleSubmit}
        >
          Reset Password
        </button>

        {message && (
          <p className="mt-4 text-center text-sm text-green-600">{message}</p>
        )}
      </div>
    </div>
  );
}

export default ResetPassword;
