// BrandCreate.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from '../assets/images/GenZ_Logo.svg';
import '../formStyle.css';

function BrandCreate(props) {
  const [image, setImage] = useState(null);
  const [error, setError] = useState('');
  const [brandname, setBrandName] = useState('');
  const [user, setUser] = useState(null);

  const isValidFileType = (filename) => {
    return /\.(jpg|jpeg|png|gif|tiff)$/i.test(filename);
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    console.log("Token being sent:", token);

    axios.get("https://genz-staging.feeltiptop.com/api/getCurrentUserInfo", {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => {
      console.log("User info response:", response.data);
      setUser(response.data.username);
    })
    .catch(error => {
      console.error("Error fetching user info:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
    });
  }, []);

  const handleMediaChangeBrand = (e) => {
    const file = e.target.files[0];
    if (file && isValidFileType(file.name)) {
      setError('');
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setError('Invalid file type. Please select an image file (jpg, jpeg, png, gif, tiff).');
      setImage(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const postData = {
      BrandName: brandname,
      brandImage: image,
    };

    axios
      .post('https://genz-staging.feeltiptop.com/api/addBrand', postData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("authToken")
        }
      })
      .then((response) => {
        console.log('Post response:', response.data);
        alert('Brand created successfully');
        handleReset(); // Reset form after successful submission
      })
      .catch((error) => {
        console.error('Error creating post:', error);
        alert('Error creating post: ' + error.message);
      });
  };

  const handleReset = () => {
    // Reset all state variables
    setImage(null);
    setError('');
    setBrandName('');
    
    // Reset file input
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.value = '';
    }

    // Reset text input
    const brandNameInput = document.getElementById('brandNameSelect');
    if (brandNameInput) {
      brandNameInput.value = '';
    }
  };

  return (
    <div className="AssetForm p-4 bg-gray-50 min-h-screen">
      <header className="AssetForm-header flex items-center mb-6">
        <img src={logo} className="Ambassco-logo w-32 h-32 mr-5" alt="logo" />
        <div className="formTitle text-l font-bold text-center">Brand Generation Form</div>
      </header>

      <div className="text-lg mb-4">
        Hello <span className="font-bold">{user}</span> 👋
      </div>

      <form
        onSubmit={handleSubmit}
        className="bg-white shadow-md rounded-lg p-6 border border-gray-200"
      >
        <div className="formElements">
          <label
            htmlFor="brandName"
            className="block mb-2 font-medium text-gray-700"
          >
            Brand Name:
          </label>
          <input
            type="text"
            id="brandNameSelect"
            placeholder="Enter Brand"
            value={brandname}
            onChange={(e) => setBrandName(e.target.value)}
            className="w-full p-2 mb-4 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
          />

          <label
            htmlFor="brandPP"
            className="block mb-2 font-medium text-gray-700"
          >
            {image ? (
              <img
                src={image}
                alt="Media File"
                className="mediaInput w-full h-auto mb-4 rounded-lg"
              />
            ) : (
              "Brand Profile Picture:"
            )}
          </label>
          <input
            type="file"
            id="fileInput"
            accept="image/jpg, image/jpeg, image/png, image/gif, image/tiff"
            onChange={handleMediaChangeBrand}
            className="w-full p-2 mb-4 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
          />

          {error && (
            <div className="error-container bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
              <p className="error">{error}</p>
            </div>
          )}

          <div className="flex space-x-4">
            <button
              type="button"
              onClick={handleReset}
              className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
            >
              Reset
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default BrandCreate;