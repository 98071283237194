import { useState, useEffect } from "react";
import axios from "axios";
import { ClipboardIcon } from "@heroicons/react/24/solid";

function ReferralLink() {
  const [referralLink, setReferralLink] = useState("");
  const [copied, setCopied] = useState(false); // Track if link is copied

  // Function to fetch the current referral link
  const fetchReferralLink = async () => {
    try {
      const response = await axios.get(
        "https://genz-staging.feeltiptop.com/api/user/getReferralLink",
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        },
      );
      setReferralLink(response.data.referralLink);
    } catch (error) {
      console.error("Error fetching referral link:", error);
    }
  };

  useEffect(() => {
    fetchReferralLink();
  }, []);

  const handleCopyClick = () => {
    if (referralLink) {
      navigator.clipboard.writeText(referralLink).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset "copied" state after 2 seconds
      });
    }
  };

  const generateQRCodeSrc = (text) => {
    return `https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${encodeURIComponent(text)}`;
  };

  return (
    <div className="mx-auto flex max-w-3xl flex-col items-center justify-center p-6">
      <h1 className="mb-6 text-2xl font-bold text-gray-900">
        Referral QR Code
      </h1>
      <div className="mb-6 flex flex-col items-center justify-center">
        <img
          className="h-40 w-40 rounded-lg"
          src={generateQRCodeSrc(referralLink)}
          alt="QR Code"
        />
      </div>

      <div className="mt-6 w-full px-4 text-center">
        {referralLink ? (
          <div className="mx-auto flex w-full max-w-lg items-center">
            {/* Referral link input field wrapped in <a> to make it clickable */}
            <a
              href={referralLink}
              target="_blank"
              rel="noopener noreferrer"
              className="w-full rounded-l-md border-gray-300 bg-gray-200 p-2 text-sm text-gray-800 transition duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              {referralLink}
            </a>
            {/* Clipboard Icon button */}
            <button
              onClick={handleCopyClick}
              className="rounded-r-md bg-blue-600 p-2 text-white transition duration-200 ease-in-out hover:bg-blue-700"
              aria-label="Copy referral link"
            >
              <ClipboardIcon className="h-5 w-5" />
            </button>
          </div>
        ) : (
          <p className="text-gray-500">No referral link</p>
        )}
        {copied && (
          <span className="ml-2 text-sm text-green-500">Link copied!</span>
        )}
      </div>
    </div>
  );
}

export default ReferralLink;
