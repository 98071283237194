import React, { useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { encrypttoHEX } from "./encodedecode.js";

function FinalizeFeed(props) {
  const location = useLocation();
  const assetsFromLocation = location.state ? location.state.assets : [];

  const getCurrentTimestamp = () => {
    return new Date().toISOString().replace(/[-:]/g, "").split(".")[0];
  };

  const getDefaultStartTime = () => {
    const currentYear = new Date().getFullYear();
    return new Date(currentYear, 0, 1).toISOString().slice(0, 16);
  };

  const getDefaultEndTime = () => {
    const nextYear = new Date().getFullYear() + 1;
    return new Date(nextYear, 0, 1).toISOString().slice(0, 16);
  };

  const [formData, setFormData] = useState({
    Name: `Feed${getCurrentTimestamp()}`,
    SessionLink: "",
    SessionStartTime: getDefaultStartTime(),
    SessionEndTime: getDefaultEndTime(),
    MaxStarted: "10000",
    MaxCompleted: "10000",
  });

  const [feedCreationSuccess, setFeedCreationSuccess] = useState(false);
  const [feedBrands, setFeedBrands] = useState([]);
  const [brandReportURLS, setBrandReportURLS] = useState([]);
  const [feedURL, setFeedURL] = useState("");
  const [feedID, setFeedID] = useState("");
  const [assets, setAssets] = useState(assetsFromLocation);

  const { state } = useLocation();

  const protocol = window.location.protocol;
  const hostname = window.location.hostname;
  const port = window.location.port;
  const baseURL = `${protocol}//${hostname}${port ? ":" + port : ""}/`;

  const handleInputChange = (e, field) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: e.target.value,
    }));
  };

  const createObfuscatedReportURL = (brandID, feedID) => {
    const encryptSTR = JSON.stringify({ feedID: feedID, brandID: brandID });
    const URL = "Report?p=" + encrypttoHEX(encryptSTR);
    console.log("Report URL: " + URL);
    return URL;
  };

  const createObfuscatedFeedURL = (feedID) => {
    const encryptSTR = JSON.stringify({ feedID: feedID });
    const URL = "Login?p=" + encrypttoHEX(encryptSTR);
    return URL;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const now = new Date();
    const oneHourLater = new Date(now.getTime() + 60 * 60 * 1000);
    const formatDateTime = (date) =>
      date.toISOString().slice(0, 19).replace("T", " ");
      
    const submissionData = {
      ...formData,
      SessionStartTime: formData.SessionStartTime
        ? formatDateTime(new Date(formData.SessionStartTime))
        : formatDateTime(now),
      SessionEndTime: formData.SessionEndTime
        ? formatDateTime(new Date(formData.SessionEndTime))
        : formatDateTime(oneHourLater),
      SessionLink: feedURL,
    };

    try {
      const createResponse = await axios.post(
        "https://genz-staging.feeltiptop.com/api/feed/create",
        submissionData,
        {
          headers: { Authorization: "Bearer " + props.token }
        }
      );

      if (createResponse.data.success) {
        const tfeedId = createResponse.data.feedId;
        setFeedID(tfeedId);
        
        await axios.post(
          `https://genz-staging.feeltiptop.com/api/feed/${tfeedId}/assets/add`,
          {
            assets: state.assets.map(asset => asset.AssetID)
          },
          {
            headers: { Authorization: "Bearer " + props.token }
          }
        );

        createObfuscatedReportURL();
        setBrandReportURLS(
          feedBrands.map((br) => {
            let reporturl = createObfuscatedReportURL(br.brandID, tfeedId);
            return { id: br.brandID, name: br.brandName, reporturl: reporturl };
          })
        );
        
        const newFeedURL = baseURL + createObfuscatedFeedURL(tfeedId);
        setFeedURL(newFeedURL);

        await axios.post(
          "https://genz-staging.feeltiptop.com/api/addFeedURL",
          {
            FeedID: tfeedId,
            SessionLink: newFeedURL,
          },
          {
            headers: { Authorization: "Bearer " + props.token }
          }
        );

        setFeedCreationSuccess(true);
      }
    } catch (error) {
      console.error("Error creating feed:", error);
      setFeedCreationSuccess(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="Name">Name:</label>
        <input
          type="text"
          id="Name"
          name="Name"
          value={formData.Name}
          onChange={(e) => handleInputChange(e, "Name")}
        />
        <br />
        <label htmlFor="SessionStartTime">Session Start Time:</label>
        <input
          type="datetime-local"
          id="SessionStartTime"
          name="SessionStartTime"
          value={formData.SessionStartTime}
          onChange={(e) => handleInputChange(e, "SessionStartTime")}
        />
        <br />
        <label htmlFor="SessionEndTime">Session End Time:</label>
        <input
          type="datetime-local"
          id="SessionEndTime"
          name="SessionEndTime"
          value={formData.SessionEndTime}
          onChange={(e) => handleInputChange(e, "SessionEndTime")}
        />
        <br />
        <label htmlFor="MaxStarted">Max Started:</label>
        <input
          type="number"
          id="MaxStarted"
          name="MaxStarted"
          value={formData.MaxStarted}
          onChange={(e) => handleInputChange(e, "MaxStarted")}
        />
        <br />
        <label htmlFor="MaxCompleted">Max Completed:</label>
        <input
          type="number"
          id="MaxCompleted"
          name="MaxCompleted"
          value={formData.MaxCompleted}
          onChange={(e) => handleInputChange(e, "MaxCompleted")}
        />
        <br />
        <button type="submit" className="construct-button">
          Construct Feed
        </button>
      </form>

      {feedCreationSuccess && (
        <div>
          <br />
          <div className="confirmation-message">Feed Created Successfully!</div>
          <br />
          <div className="feedurls">
            <h3>Feed Url:</h3>
            <a href={feedURL} target="_blank" rel="noopener noreferrer">
              {feedURL}
            </a>
            <br />
            <br />
            <h3>Brand Report Urls:</h3>
            {
              <ul>
                {brandReportURLS.map((branddetails) => (
                  <li key={branddetails.id}>
                    {branddetails.name} -{" "}
                    <a
                      href={`${baseURL}${branddetails.reporturl}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`${baseURL}${branddetails.reporturl}`}
                    </a>
                  </li>
                ))}
              </ul>
            }
            <h3>Feed Data Dump:</h3>
            <a
              href={`${baseURL}FeedSessionExport?feedid=${feedID}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`${baseURL}FeedSessionExport?feedid=${feedID}`}
            </a>
            <br />
            <br />
            <h3>Ambassador's Data Dump:</h3>
            <a
              href={`${baseURL}AmbassadorProfiles`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`${baseURL}AmbassadorProfiles`}
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default FinalizeFeed;