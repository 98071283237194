import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { FaTrash } from "react-icons/fa";

const Semester = ({ semesterId, classes, courses, onDragOver, onDrop, onRemoveCourse }) => {
  return (
    <div className="w-1/2">
      <h4 className="mb-2 font-medium">{semesterId.split("-")[0]} Semester</h4>
      <div
        onDragOver={onDragOver}
        onDrop={onDrop}
        className="min-h-[200px] rounded border border-gray-200 p-4"
      >
        {courses.map((course) => {
          const classDetails = classes.find((c) => c.id === course.classId);
          return (
            <div
              key={course.classId}
              className="mb-2 flex justify-between rounded bg-white p-2 shadow"
            >
              <div>
                <h4 className="font-medium text-gray-700 text-xs">{classDetails?.classNumber}</h4>
                <p className="text-xs text-gray-600">{classDetails?.className}</p>
              </div>
              <button
                onClick={() => onRemoveCourse(course.classId, semesterId)}
                className="text-red-500 hover:text-red-700"
              >
                <FaTrash />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Semester;
