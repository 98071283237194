import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const Sidebar = ({ children, isOpen, onToggle }) => {
  return (
    <div
      className={`relative transition-all duration-300 ${
        isOpen ? "w-80" : "w-12"
      } h-screen border-r border-gray-200 bg-gray-100`}
    >
      {/* Toggle Button */}
      <button
        onClick={onToggle}
        className="absolute -right-3 top-4 z-10 rounded-full border border-gray-200 bg-white p-1 shadow-md"
      >
        {isOpen ? <FaChevronLeft /> : <FaChevronRight />}
      </button>

      {/* Sidebar Content */}
      <div
        className={`${
          isOpen ? "block" : "hidden" // Changed from opacity to display
        } h-full overflow-y-auto p-4`}
      >
        {children}
      </div>
    </div>
  );
};

export default Sidebar;
