import React, { useState } from "react";

const VisibilityToggle = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="flex items-center justify-center p-4">
      <div className="relative flex items-center">
        <span
          className={`mr-4 text-2xl font-medium transition-colors duration-500 ${!isVisible ? "text-gray-700" : "text-gray-400"}`}
        >
          ASSET HIDDEN
        </span>

        <button
          type="button"
          onClick={toggleVisibility}
          className="relative h-16 w-48 rounded-full bg-gray-200 transition-colors duration-500"
          aria-label={isVisible ? "Hide asset" : "Show asset"}
          role="switch"
          aria-checked={isVisible}
        >
          <div
            className={`absolute top-0 h-16 w-24 rounded-full bg-gray-200 transition-all duration-500 ease-in-out ${isVisible ? "left-24" : "left-0"}`}
          />
          <div
            className={`absolute top-2 flex h-12 w-12 items-center justify-center rounded-full bg-white shadow-md transition-all duration-500 ease-in-out ${isVisible ? "left-32" : "left-4"}`}
          >
            <div className="flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-400">
              <div className="h-2 w-2 rounded-full bg-blue-400"></div>
            </div>
          </div>
        </button>

        <span
          className={`ml-4 text-2xl font-medium transition-colors duration-500 ${isVisible ? "text-gray-700" : "text-gray-400"}`}
        >
          ASSET VISIBLE
        </span>
      </div>
    </div>
  );
};

export default VisibilityToggle;
