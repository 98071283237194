import React, { useState } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';

const Survey = ({ onClose }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  
  const questions = [
    {
      id: 1,
      question: "What are your hobbies?",
      type: "multiSelect",
      options: ["Gaming", "Sports", "Reading", "Music", "Art", "Travel", "Technology", "Other"]
    },
    {
      id: 2,
      question: "What industry do you work in?",
      type: "select",
      options: ["Technology", "Healthcare", "Education", "Finance", "Retail", "Other"]
    },
    {
      id: 3,
      question: "What types of products are you interested in buying?",
      type: "multiSelect",
      options: ["Electronics", "Clothing", "Books", "Sports Equipment", "Art Supplies", "Other"]
    },
    {
      id: 4,
      question: "What types of items are you interested in selling?",
      type: "multiSelect",
      options: ["Electronics", "Clothing", "Books", "Sports Equipment", "Art Supplies", "Other"]
    }
  ];

  const [answers, setAnswers] = useState({});

  const handleAnswer = (questionId, answer) => {
    setAnswers(prev => ({
      ...prev,
      [questionId]: answer
    }));
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      
      Object.entries(answers).forEach(([questionId, answer]) => {
        const value = Array.isArray(answer) ? answer.join(',') : answer;
        formData.append(`question_${questionId}`, value);
      });

      formData.append('timestamp', new Date().toISOString());
      formData.append('userId', 'user123');
   
      const response = await axios.post('/api/submit-survey', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        console.log('Survey submitted successfully:', response.data);
        alert('Thank you for completing the survey!');
        onClose();
      }
    } catch (error) {
     
      console.error('Error submitting survey:', error);
      alert('There was an error submitting the survey. Please try again.');
    }
  };

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(prev => prev + 1);
    } else {
      handleSubmit();
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(prev => prev - 1);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-2xl p-8 w-11/12 max-w-2xl shadow-lg">
        <motion.div
          key={currentQuestion}
          initial={{ x: 300, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -300, opacity: 0 }}
          className="mb-8"
        >
          <h2 className="text-2xl font-bold mb-6 text-gray-800">
            {questions[currentQuestion].question}
          </h2>
          
          <div className="space-y-4">
            {questions[currentQuestion].options.map((option) => (
              <label 
                key={option} 
                className="flex items-center space-x-3 cursor-pointer hover:bg-gray-50 p-3 rounded-lg transition-colors"
              >
                <input
                  type={questions[currentQuestion].type === "multiSelect" ? "checkbox" : "radio"}
                  name={`question-${questions[currentQuestion].id}`}
                  value={option}
                  onChange={(e) => handleAnswer(
                    questions[currentQuestion].id,
                    questions[currentQuestion].type === "multiSelect"
                      ? [...(answers[questions[currentQuestion].id] || []), e.target.value]
                      : e.target.value
                  )}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="text-gray-700">{option}</span>
              </label>
            ))}
          </div>
        </motion.div>

        <div className="flex items-center justify-between mt-8">
          <button 
            onClick={handlePrevious}
            disabled={currentQuestion === 0}
            className={`p-2 rounded-full ${
              currentQuestion === 0 
                ? 'bg-gray-200 text-gray-400 cursor-not-allowed' 
                : 'bg-blue-500 text-white hover:bg-blue-600'
            }`}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>

          <div className="flex space-x-2">
            {questions.map((_, index) => (
              <div
                key={index}
                className={`h-2 w-2 rounded-full ${
                  index === currentQuestion ? 'bg-blue-500' : 'bg-gray-300'
                }`}
              />
            ))}
          </div>

          {currentQuestion === questions.length - 1 ? (
            <button 
              onClick={handleNext}
              className="px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 flex items-center gap-2"
            >
              Submit
            </button>
          ) : (
            <button 
              onClick={handleNext}
              className="p-2 rounded-full bg-blue-500 text-white hover:bg-blue-600"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Survey;
