import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Slider,
  TextField,
  Button,
  Snackbar,
  Alert,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Card,
  Stack,
  InputAdornment,
} from "@mui/material";

import { IconButton, Badge, Collapse, Divider } from "@mui/material";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Logout as LogoutIcon,
  Payment as PaymentIcon,
  Settings as SettingsIcon,
  Update as UpdateIcon,
  HelpOutline as HelpOutlineIcon,
  CalendarToday as CalendarTodayIcon,
} from "@mui/icons-material";

import CodeIcon from "@mui/icons-material/Code";

import CopyToClipboard from "react-copy-to-clipboard";
import Drawer from "@mui/material/Drawer";

// Menu items with icons and badges
const menuItems = [
  { text: "Payment Bonus Multiplier", icon: <PaymentIcon />, badge: null },
  { text: "Generate Code", icon: <CodeIcon />, badge: null },
];

const Settings = () => {
  const [activeSetting, setActiveSetting] = useState("bonus");
  const [open, setOpen] = useState(false);
  const [userTab, setUserTab] = useState("Dashboard");
  const [expanded, setExpanded] = useState({});

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleUserTab = (tab) => {
    setUserTab(tab);

    switch (tab) {
      case "Payment Bonus Multiplier":
        setActiveSetting("bonus");
        break;
      case "Generate Code":
        setActiveSetting("code");
        break;
      default:
        return <div>Page not found</div>;
    }
  };

  const DrawerList = (
    <Box
      className="mb-4 mt-8 flex flex-col items-center justify-center"
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          p: 2,
          pb: 1,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: "auto",
            mr: 1.5,
            color: "grey.500",
            "& .MuiSvgIcon-root": {
              fontSize: 35, // Increased from default size
            },
          }}
        >
          <SettingsIcon />
        </ListItemIcon>

        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 700, fontSize: "1.4rem", color: "grey.800" }}
        >
          Settings
        </Typography>
      </Box>

      <Divider sx={{ mx: 2, mb: 2, color: "black" }} />

      <List
        sx={{
          px: 1,
          flex: 1,
          width: "100%",
          justifyContent: "start",
          padding: 2,
        }}
      >
        {menuItems.map((item) => (
          <React.Fragment key={item.text}>
            <ListItem disablePadding className="text-sm" sx={{ mb: 0.5 }}>
              <ListItemButton
                onClick={() =>
                  item.children
                    ? setExpanded((prev) => ({
                        ...prev,
                        [item.text]: !prev[item.text],
                      }))
                    : handleUserTab(item.text)
                }
                sx={{
                  borderRadius: 1,
                  backgroundColor:
                    userTab === item.text
                      ? "rgba(25, 118, 210, 0.12)"
                      : "transparent",
                  "&:hover": {
                    backgroundColor:
                      userTab === item.text
                        ? "rgba(25, 118, 210, 0.18)"
                        : "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 40,
                    color: userTab === item.text ? "primary.main" : "grey.500",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{
                    fontSize: "0.9rem",
                    fontWeight: userTab === item.text ? 500 : 400,
                    color: userTab === item.text ? "primary.main" : "grey.800",
                  }}
                />
                {item.badge && (
                  <Badge
                    badgeContent={item.badge}
                    color="primary"
                    sx={{
                      "& .MuiBadge-badge": {
                        fontSize: "0.75rem",
                        height: "20px",
                        minWidth: "20px",
                      },
                    }}
                  />
                )}
                {item.children &&
                  (expanded[item.text] ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  ))}
              </ListItemButton>
            </ListItem>

            {item.children && expanded[item.text] && (
              <Collapse in={expanded[item.text]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.children.map((childItem) => (
                    <ListItem
                      key={childItem.text}
                      disablePadding
                      className="text-sm"
                      sx={{ mb: 0.5 }}
                    >
                      <ListItemButton
                        onClick={() => handleUserTab(childItem.text)}
                        sx={{
                          pl: 4,
                          borderRadius: 1,
                          backgroundColor:
                            userTab === childItem.text
                              ? "rgba(25, 118, 210, 0.12)"
                              : "transparent",
                          "&:hover": {
                            backgroundColor:
                              userTab === childItem.text
                                ? "rgba(25, 118, 210, 0.18)"
                                : "rgba(0, 0, 0, 0.04)",
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 40,
                            color:
                              userTab === childItem.text
                                ? "primary.main"
                                : "grey.500",
                          }}
                        >
                          {childItem.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={childItem.text}
                          primaryTypographyProps={{
                            fontSize: "0.9rem",
                            fontWeight: userTab === childItem.text ? 500 : 400,
                            color:
                              userTab === childItem.text
                                ? "primary.main"
                                : "grey.800",
                          }}
                        />
                        {childItem.badge && (
                          <Badge
                            badgeContent={childItem.badge}
                            color="primary"
                            sx={{
                              "& .MuiBadge-badge": {
                                fontSize: "0.75rem",
                                height: "20px",
                                minWidth: "20px",
                              },
                            }}
                          />
                        )}
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>

      {/* User profile at bottom */}
      <Box
        sx={{
          mt: "auto",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Divider />
      </Box>
    </Box>
  );

  return (
    <Box display="flex" height="100vh">
      <IconButton
        sx={{
          display: { xs: "block", sm: "none" },
          position: "absolute",
          left: 20,
          top: 20,
          zIndex: 1200,
        }}
        onClick={() => setOpen(true)}
      >
        <LogoutIcon />
      </IconButton>
      {/* Sidebar */}
      <Box width="25%" color="white" p={2}>
        <Drawer
          variant="temporary"
          open={open}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              width: 250,
              boxSizing: "border-box",
            },
          }}
        >
          {DrawerList}
        </Drawer>

        {/* Desktop drawer */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            width: 320,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 300,
              boxSizing: "border-box",
              borderRight: "1px solid rgba(0, 0, 0, 0.12)",
            },
          }}
        >
          {DrawerList}
        </Drawer>
      </Box>

      {/* Main Content */}
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          height: "100vh",
          overflow: "auto",
          width: { sm: `calc(100% - ${300}px)` },
        }}
      >
        {activeSetting === "bonus" && <PaymentBonusMultiplier />}
        {activeSetting === "code" && <CodeGeneration />}
      </Box>
    </Box>
  );
};

const PaymentBonusMultiplier = () => {
  const token = localStorage.getItem("token");
  const [settings, setSettings] = useState({
    multiplier: 1.0,
    endDate: "",
  });
  const [message, setMessage] = useState({ type: "", text: "" });

  useEffect(() => {
    fetch("https://genz-staging.feeltiptop.com/api/user/getMultiplier")
      .then((res) => res.json())
      .then((data) => {
        setSettings({
          multiplier: data.multiplier,
          endDate: data.end_date,
        });
      });
  }, []);

  const handleUpdate = async () => {
    if (!settings.endDate) {
      setMessage({ type: "error", text: "End date is required." });
      return;
    }
  
    // Check if the end date is in the future
    const selectedEndDate = new Date(settings.endDate);
    const currentDate = new Date();
  
    if (selectedEndDate <= currentDate) {
      setMessage({ type: "error", text: "Please choose a future date for the end date." });
      return;
    }
  
    try {
      const response = await fetch("https://genz-staging.feeltiptop.com/api/user/setMultiplier", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          multiplier: settings.multiplier,
          end_date: settings.endDate,
        }),
      });
  
      const result = await response.json();
      setMessage({
        type: result.success ? "success" : "error",
        text: result.message || "Failed to update settings.",
      });
    } catch (error) {
      setMessage({ type: "error", text: "Error communicating with backend." });
    }
  };
  
  return (
    <Card
      sx={{
        maxWidth: 800,
        mx: "auto",
        mt: 4,
        p: 4,
        borderRadius: 2,
        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Typography
        variant="h5"
        component="h2"
        sx={{
          fontWeight: "bold",
          fontSize: "1.5rem",
          mb: 1,
          textAlign: "left",
        }}
      >
        Payment Bonus Multiplier
      </Typography>

      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ mb: 4, mt: 1, textAlign: "left" }}
      >
        Configure the bonus multiplier for payments and set an expiration date.
      </Typography>

      <Box sx={{ mb: 4 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Typography variant="subtitle1" fontWeight="medium">
            Bonus Multiplier
          </Typography>
          <Typography variant="subtitle1" fontWeight="medium">
            {settings.multiplier.toFixed(1)}x
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="body2" color="text.secondary">
            0.5x
          </Typography>
          <Slider
            min={0.5}
            max={3.0}
            step={0.1}
            value={settings.multiplier}
            onChange={(e, value) =>
              setSettings({ ...settings, multiplier: value })
            }
            sx={{
              "& .MuiSlider-thumb": {
                width: 20,
                height: 20,
                backgroundColor: "#fff",
                border: "2px solid currentColor",
              },
              "& .MuiSlider-track": {
                height: 4,
              },
              "& .MuiSlider-rail": {
                height: 4,
                opacity: 0.2,
              },
            }}
          />
          <Typography variant="body2" color="text.secondary">
            3.0x
          </Typography>
        </Stack>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Typography variant="subtitle1" fontWeight="medium">
            End Date
          </Typography>
          <IconButton size="small">
            <HelpOutlineIcon fontSize="small" />
          </IconButton>
        </Stack>
        {/* <TextField
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: 1,
          },
          mb: 4,
        }}
        type="datetime-local"
        fullWidth
        onChange={(e) =>
          setSettings({ ...settings, expiryTime: e.target.value })
        }
      /> */}

        <TextField
          fullWidth
          placeholder="Select date"
          type="datetime-local"
          value={settings.endDate}
          onChange={(e) =>
            setSettings({ ...settings, endDate: e.target.value })
          }
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: 1,
            },
          }}
        />
      </Box>

      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Button
          variant="outlined"
          color="inherit"
          sx={{
            borderColor: "rgba(0, 0, 0, 0.23)",
            color: "text.primary",
            px: 3,
          }}
          onClick={() => setSettings({ multiplier: 1.0, endDate: "" })}
        >
          Reset
        </Button>

        <Button
          variant="contained"
          color="primary"
          startIcon={<UpdateIcon />}
          sx={{
            bgcolor: "#121212",
            "&:hover": {
              bgcolor: "#333",
            },
            px: 3,
          }}
          onClick={handleUpdate}
          disabled={!settings.endDate} // Disable button if endDate is empty
        >
          Update Settings
        </Button>
      </Stack>

      <Snackbar
        open={!!message.text}
        autoHideDuration={4000}
        onClose={() => setMessage({ type: "", text: "" })}
      >
        <Alert severity={message.type}>{message.text}</Alert>
      </Snackbar>
    </Card>
  );
};

const CodeGeneration = () => {
  const token = localStorage.getItem("token");
  const [settings, setSettings] = useState({
    multiplier: 1.0,
    expiryTime: "",
  });
  const [message, setMessage] = useState({ type: "", text: "" });
  const [generatedCode, setGeneratedCode] = useState(null);
  const [codes, setCodes] = useState([]); // To store the list of codes from the backend
  const [copiedCode, setCopiedCode] = useState(""); // To track copied code for feedback
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  // Fetch all codes when the component mounts
  useEffect(() => {
    const fetchCodes = async () => {
      try {
        const response = await fetch(
          "https://genz-staging.feeltiptop.com/api/user/getCodes",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        const result = await response.json();
        if (result.success) {
          setCodes(result.codes);
        } else {
          setMessage({
            type: "error",
            text: result.error || "Failed to fetch codes.",
          });
        }
      } catch (error) {
        setMessage({
          type: "error",
          text: "Error communicating with backend.",
        });
      }
    };

    fetchCodes();
  }, [token]);

  const handleGenerateCode = async () => {
    const { expiryTime, multiplier } = settings;

    if (!expiryTime) {
      setMessage({ type: "error", text: "Expiry time is required." });
      return;
    }

    const formattedExpiryTime = expiryTime.replace("T", " ") + ":00";

    try {
      const response = await fetch(
        "https://genz-staging.feeltiptop.com/api/user/generateCode",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            expiry_time: formattedExpiryTime,
            multiplier: multiplier,
          }),
        },
      );

      const result = await response.json();
      if (result.success) {
        const newCode = result.code; // Get the new code from the response
        setGeneratedCode(newCode);
        setMessage({ type: "success", text: `Code generated: ${newCode}` });

        // Update the codes list directly with the new code without fetching from backend
        setCodes((prevCodes) => [
          ...prevCodes,
          {
            Code: newCode,
            Multiplier: multiplier,
            ExpiryTime: formattedExpiryTime,
          },
        ]);
      } else {
        setMessage({
          type: "error",
          text: result.error || "Failed to generate code.",
        });
      }
    } catch (error) {
      setMessage({ type: "error", text: "Error communicating with backend." });
    }
  };

  return (
    <Card
      sx={{
        maxWidth: 800,
        mx: "auto",
        mt: 4,
        p: 4,
        borderRadius: 2,
        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Typography
        variant="h5"
        component="h2"
        sx={{
          fontWeight: "bold",
          fontSize: "1.5rem",
          mb: 1,
          textAlign: "left",
        }}
      >
        Generate Code
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ mb: 4, mt: 1, textAlign: "left" }}
      >
        Create and manage access codes for users
      </Typography>
      <TextField
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: 1,
          },
          mb: 4,
        }}
        type="datetime-local"
        fullWidth
        onChange={(e) =>
          setSettings({ ...settings, expiryTime: e.target.value })
        }
      />

      <Box sx={{ mb: 4 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 1 }}
        >
          <Typography variant="subtitle1" fontWeight="medium">
            Bonus Multiplier
          </Typography>
          <Typography variant="subtitle1" fontWeight="medium">
            {settings.multiplier.toFixed(1)}x
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="body2" color="text.secondary">
            0.5x
          </Typography>
          <Slider
            min={0.5}
            max={3.0}
            step={0.1}
            value={settings.multiplier}
            onChange={(e, value) =>
              setSettings({ ...settings, multiplier: value })
            }
            sx={{
              "& .MuiSlider-thumb": {
                width: 20,
                height: 20,
                backgroundColor: "#fff",
                border: "2px solid currentColor",
              },
              "& .MuiSlider-track": {
                height: 4,
              },
              "& .MuiSlider-rail": {
                height: 4,
                opacity: 0.2,
              },
            }}
          />
          <Typography variant="body2" color="text.secondary">
            3.0x
          </Typography>
        </Stack>
      </Box>

      <Button
        variant="contained"
        color="primary"
        startIcon={<UpdateIcon />}
        sx={{
          bgcolor: "#121212",
          "&:hover": {
            bgcolor: "#333",
          },
          px: 3,
          width: "100%",
        }}
        onClick={handleGenerateCode}
      >
        Generate Code
      </Button>

      {generatedCode && (
        <Typography variant="body2" color="success.main" sx={{ mt: 2 }}>
          Generated Code: {generatedCode}
        </Typography>
      )}

      {/* Display all generated codes */}
      <Typography variant="h6" sx={{ mt: 4 }}>
        Existing Codes
      </Typography>
      {codes.length > 0 ? (
        <Box sx={{ mt: 2 }}>
          {codes.map((code, index) => (
            <Box
              key={index}
              sx={{
                mb: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography>
                {code.Code} (Multiplier: {code.Multiplier}) - Expires on{" "}
                {new Date(code.ExpiryTime).toLocaleString()}
              </Typography>

              <CopyToClipboard
                text={code.Code}
                onCopy={() => setCopiedCode(code.Code)} // Set copied code feedback
              >
                <Button variant="outlined" size="small">
                  {copiedCode === code.Code ? "Copied!" : "Copy"}{" "}
                  {/* Show feedback */}
                </Button>
              </CopyToClipboard>
            </Box>
          ))}
        </Box>
      ) : (
        <Typography variant="body2">No codes available.</Typography>
      )}

      <Snackbar
        open={!!message.text}
        autoHideDuration={4000}
        onClose={() => setMessage({ type: "", text: "" })}
      >
        <Alert severity={message.type}>{message.text}</Alert>
      </Snackbar>
    </Card>
  );
};

export default Settings;
