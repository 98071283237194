import React, { useState } from "react";
import { Link } from "react-router-dom";
import backArrow from "../assets/images/arrow_back.svg";
import GenZLandingPageWallpaper from "../assets/images/GenZLandingPageWallpaper.svg";
import * as XLSX from 'xlsx';
import axios from "axios";
import CryptoJS from "crypto-js";
import { saveAs } from 'file-saver';

const UserImport = () => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [error, setError] = useState("");
  const [progress, setProgress] = useState(0);
  
  const generatePassword = () => {
    const length = 10;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";
    let password = "";
    
    password += "A";
    password += "a";
    password += "1";
    password += "!";
    
    for (let i = 4; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    
    return password.split('').sort(() => 0.5 - Math.random()).join('');
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setError("");
    setResults(null);
  };

  const processFile = async () => {
    if (!file) {
      setError("Please select a file first");
      return;
    }
    
    setIsLoading(true);
    setProgress(0);
    
    try {
      const data = await readExcelFile(file);
      
      if (!data || data.length === 0) {
        throw new Error("No data found in file or invalid format");
      }
      
      const requiredFields = ['firstName', 'lastName', 'email'];
      const missingFields = requiredFields.filter(field => 
        !data[0].hasOwnProperty(field.toLowerCase()) && 
        !data[0].hasOwnProperty(field)
      );
      
      if (missingFields.length > 0) {
        throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
      }
      
      const tokenResponse = await axios.post("https://genz-staging.feeltiptop.com/api/token", {
        passCode: "4zPoQ5KAkR",
      });
      
      const token = tokenResponse.data.access_token;
      
      const processedUsers = [];
      let successCount = 0;
      let failCount = 0;
      
      for (let i = 0; i < data.length; i++) {
        const user = data[i];
        const progress = Math.round(((i + 1) / data.length) * 100);
        setProgress(progress);
        
        try {
          const normalizedUser = {};
          Object.keys(user).forEach(key => {
            normalizedUser[key.toLowerCase()] = user[key];
          });
          
          const firstName = normalizedUser.firstname || "";
          const lastName = normalizedUser.lastname || "";
          const email = normalizedUser.email || "";
          
          const baseUsername = `${firstName.toLowerCase()}${lastName.toLowerCase()}`;
          const randomSuffix = Math.floor(Math.random() * 1000);
          const username = `${baseUsername}${randomSuffix}`;
          
          const password = generatePassword();
          const hashedPassword = CryptoJS.SHA256(password).toString();
          
          const userData = {
            userName: username,
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: hashedPassword,
            phoneNumber: null, 
            termsAndConditions: "Accepted",
            textMessaging: "Consented",
            selectedCard: "360 Buyer Card ($100)", 
            paymentAmount: "0",
            status: "Deactivated",
            activated: false  
          };
          
          // Add the user
          await axios.post(
            "https://genz-staging.feeltiptop.com/api/user/addUser",
            userData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          
          processedUsers.push({
            ...userData,
            password: password 
          });
          
          successCount++;
        } catch (userError) {
          console.error(`Error creating user ${user.email}:`, userError);
          processedUsers.push({
            ...user,
            error: userError.message || "Unknown error"
          });
          failCount++;
        }
      }
      
      setResults({
        total: data.length,
        success: successCount,
        fail: failCount,
        users: processedUsers
      });
      
    } catch (err) {
      console.error("Error processing file:", err);
      setError(err.message || "An error occurred while processing the file");
    } finally {
      setIsLoading(false);
    }
  };

  const readExcelFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      
      reader.onload = (e) => {
        try {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false });
          resolve(jsonData);
        } catch (error) {
          reject(error);
        }
      };
      
      reader.onerror = (error) => {
        reject(error);
      };
      
      reader.readAsBinaryString(file);
    });
  };

  const downloadResults = () => {
    if (!results) return;
    
    let csvContent = "Username,Password,FirstName,LastName,Email,Status\n";
    
    results.users.forEach(user => {
      const escapeCsv = (field) => {
        if (field === undefined || field === null) return '';
        const str = String(field);
        return str.includes(',') || str.includes('"') 
          ? `"${str.replace(/"/g, '""')}"` 
          : str;
      };
      
      const row = [
        escapeCsv(user.userName),
        escapeCsv(user.password),
        escapeCsv(user.firstName),
        escapeCsv(user.lastName),
        escapeCsv(user.email),
        user.error ? `Error: ${escapeCsv(user.error)}` : 'Deactivated'
      ];
      
      csvContent += row.join(',') + '\n';
    });
    
    // Create blob and download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, "imported_users_with_passwords.csv");
  };

  return (
    <div
      className="flex min-h-screen items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: `url(${GenZLandingPageWallpaper})` }}
    >
      <div className="z-10 w-full max-w-2xl rounded-lg bg-white p-6 shadow-lg">
        <h2 className="mb-4 text-center text-2xl font-bold">
          Import Users from Spreadsheet
        </h2>
        
        <div className="mb-6">
          <p className="mb-2 text-sm text-gray-600">
            Upload a CSV or Excel file with user data. Required columns: firstName, lastName, email.
          </p>
          <p className="mb-4 text-sm text-gray-600">
            All users will be created with a 360 Buyer Card ($100) and unique generated usernames and passwords.
          </p>
          
          <input
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            className="w-full cursor-pointer rounded-md border border-gray-300 p-2"
            onChange={handleFileChange}
            disabled={isLoading}
          />
        </div>
        
        {error && (
          <div className="mb-4 rounded-md bg-red-50 p-4 text-red-700">
            <p className="font-medium">Error:</p>
            <p>{error}</p>
          </div>
        )}
        
        {isLoading && (
          <div className="mb-4">
            <p className="mb-2 text-sm font-medium text-gray-700">Processing users... {progress}%</p>
            <div className="h-2 w-full overflow-hidden rounded-full bg-gray-200">
              <div 
                className="h-full bg-blue-500 transition-all duration-300"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div>
        )}
        
        {results && (
          <div className="mb-4 rounded-md bg-green-50 p-4 text-green-700">
            <p className="font-medium">Import Results:</p>
            <p>Total records: {results.total}</p>
            <p>Successfully imported: {results.success}</p>
            <p>Failed: {results.fail}</p>
            <button
              onClick={downloadResults}
              className="mt-3 rounded bg-green-600 px-4 py-2 text-white hover:bg-green-700"
            >
              Download User Credentials
            </button>
          </div>
        )}
        
        <div className="flex justify-center">
          <button
            onClick={processFile}
            disabled={!file || isLoading}
            className={`rounded bg-blue-500 px-6 py-2 text-white ${(!file || isLoading) ? 'cursor-not-allowed opacity-50' : 'hover:bg-blue-600'}`}
          >
            {isLoading ? 'Processing...' : 'Import Users'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserImport;