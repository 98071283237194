import React, { useEffect, useState } from "react";

const ReferralPage = ({ userId }) => {
  const [referralCount, setReferralCount] = useState(0);
  const [referrals, setReferrals] = useState([]);

  const [subscribersReferredCount, setSubscribersReferredCount] = useState(0);
  const [subscribersReferred, setSubscribersReferred] = useState([]);

  console.log(userId);
  useEffect(() => {
    // Fetch referral data from the backend
    const fetchReferrals = async () => {
      try {
        const response = await fetch(
          `https://genz-staging.feeltiptop.com/api/dashboard/get_referrals?userID=${userId}`,
        );
        const data = await response.json();

        setReferralCount(data.count);
        setReferrals(data.referrals);
      } catch (error) {
        console.error("Error fetching referrals:", error);
      }
    };

    fetchReferrals();
  }, [userId]);
  useEffect(() => {
    const fetchSubscribersReferred = async () => {
      try {
        const response = await fetch(
          `https://genz-staging.feeltiptop.com/api/user/subscribers?userID=${userId}`,
        );
        const data = await response.json();

        if (
          data.referral_count !== undefined &&
          data.referred_subscribers !== undefined
        ) {
          setSubscribersReferredCount(data.referral_count);
          setSubscribersReferred(data.referred_subscribers);
          console.log("Subscribers Referred Count:", subscribersReferredCount);
          console.log("Subscribers Referred:", subscribersReferred);
        } else {
          console.error("Unexpected data format:", data);
        }
      } catch (error) {
        console.error("Error fetching subscribers referred:", error);
      }
    };

    if (userId) {
      fetchSubscribersReferred();
    }
  }, [userId]);

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <div className="mx-auto max-w-3xl rounded-lg bg-white p-4 shadow-md">
        <h1 className="mb-4 text-2xl font-bold text-gray-800">Referrals</h1>
        <p className="text-lg text-gray-600">
          You have referred{" "}
          <span className="font-semibold">{referralCount}</span> people.
        </p>
        <div className="mt-6">
          <h2 className="mb-4 text-xl font-semibold text-gray-700">
            People Referred:
          </h2>
          <div className="space-y-4">
            {referrals.map((referral) => (
              <div
                key={referral.UserID}
                className="rounded-lg bg-gray-100 p-4 shadow-sm"
              >
                <p className="font-medium text-gray-800">
                  {referral.firstName} {referral.lastName}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-10">
        <h2 className="mb-4 text-2xl font-bold text-gray-800">
          Subscribers Referred
        </h2>
        <p className="text-lg text-gray-600">
          You have referred{" "}
          <span className="font-semibold">{subscribersReferredCount}</span>{" "}
          subscribers.
        </p>
        <div className="mt-6">
          <h3 className="mb-4 text-xl font-semibold text-gray-700">
            Subscribers:
          </h3>
          <div className="space-y-4">
            {subscribersReferred.map((subscriber) => (
              <div
                key={subscriber.SubId}
                className="rounded-lg bg-gray-100 p-4 shadow-sm"
              >
                <p className="font-medium text-gray-800">
                  {subscriber.FirstName} {subscriber.LastName}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralPage;
