import React from "react";
import { FaTrash } from "react-icons/fa";

const DraggableCourse = ({ course, onDelete }) => {
  const handleDragStart = (e) => {
    e.dataTransfer.setData("application/json", JSON.stringify(course));
  };

  return (
    <div
      draggable
      onDragStart={handleDragStart}
      className="cursor-move rounded-lg border border-gray-200 bg-white p-2 shadow-sm transition-shadow hover:shadow-md text-sm"
    >
      <div className="flex justify-between items-start">
        <div>
          <h4 className="font-medium text-gray-700 text-xs">{course.classNumber}</h4>
          <p className="text-xs text-gray-600">{course.className}</p>
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            onDelete(course.id);
          }}
          className="text-red-500 hover:text-red-700 text-xs p-1"
        >
          <FaTrash />
        </button>
      </div>
    </div>
  );
};

export default DraggableCourse;
