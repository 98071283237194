import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import { encrypttoHEX } from "./encodedecode.js";
import MediaComponent from "../components/MediaComponent.js";
import PollComponent from "../components/PollComponent.js";
import TextComponent from "../components/TextComponent.js";
const DragHandle = SortableHandle(() => (
  <span className="text-gray-400 cursor-move px-2">⋮⋮</span>
));

const SortableItem = SortableElement(({ asset, handleRemoveAsset }) => (
  <div className="flex items-center justify-between p-4 mb-2 bg-white rounded-lg border border-gray-200 shadow-sm">
    <div className="flex items-center flex-1">
      <DragHandle />
      <div className="flex-1 ml-2">
        <p className="font-medium">{asset.AssetName}</p>
        <p className="text-sm text-gray-500 truncate">{asset.Caption}</p>
        <p className="text-xs text-gray-400">{asset.BrandName}</p>
      </div>
    </div>
    <button
      onClick={() => handleRemoveAsset(asset.AssetID)}
      className="ml-4 px-3 py-1 text-sm text-red-600 hover:text-red-800 hover:bg-red-50 rounded-md transition-colors"
    >
      Remove
    </button>
  </div>
));

const SortableList = SortableContainer(({ items, handleRemoveAsset }) => (
  <div className="space-y-2">
    {items.map((asset, index) => (
      <SortableItem
        key={`item-${asset.AssetID}`}
        index={index}
        asset={asset}
        handleRemoveAsset={handleRemoveAsset}
      />
    ))}
  </div>
));

const NewFeedConstruction = ({ token }) => {
  const location = useLocation();
  const { existingFeed, preSelectedAssets } = location.state || {};

  const [formData, setFormData] = useState({
    Name: existingFeed?.Name || `Feed_${new Date().toISOString().split('T')[0]}`,
    SessionLink: existingFeed?.SessionLink || "",
    SessionStartTime: existingFeed?.SessionStartTime || new Date().toISOString().slice(0, 16),
    SessionEndTime: existingFeed?.SessionEndTime || new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().slice(0, 16),
    MaxStarted: existingFeed?.MaxStarted || "10000",
    MaxCompleted: existingFeed?.MaxCompleted || "10000",
  });

  const [assets, setAssets] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState(preSelectedAssets || []);
  const [filter, setFilter] = useState("all");
  const [brandNames, setBrandNames] = useState([]);
  const [filterBrandName, setFilterBrandName] = useState("all");
  const [filterAsset, setFilterAsset] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchCategory, setSearchCategory] = useState("caption");

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("assets");
  
  const [feedCreationSuccess, setFeedCreationSuccess] = useState(false);
  const [feedURL, setFeedURL] = useState("");
  const [brandReportURLs, setBrandReportURLs] = useState([]);
  const [createdFeedID, setCreatedFeedID] = useState("");

  const baseURL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ""}/`;

  useEffect(() => {
    setIsLoading(true);
    axios
      .get("https://genz-staging.feeltiptop.com/api/getAssets", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setAssets(response.data);
        const uniqueBrands = [...new Set(response.data.map(asset => asset.BrandName))].filter(Boolean);
        setBrandNames(uniqueBrands);
        setFilterAsset(response.data);
      })
      .catch((error) => {
        console.error("Error fetching assets:", error);
        setError("Failed to load assets. Please try again.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [token]);

  useEffect(() => {
    let filtered = assets;

    if (filterBrandName !== "all") {
      filtered = filtered.filter(asset => asset.BrandName === filterBrandName);
    }

    if (filter !== "all") {
      filtered = filtered.filter(asset => {
        try {
          const content = JSON.parse(asset.AssetContent);
          if (filter === "image") {
            return content.assetType === "media" && content.mediaContent.match(/\.(jpg|jpeg|png|gif)$/i);
          }
          if (filter === "video") {
            return content.assetType === "media" && content.mediaContent.match(/\.(mp4|webm|mpeg)$/i);
          }
          return content.assetType === filter;
        } catch (error) {
          console.error("Error parsing asset content:", error);
          return false;
        }
      });
    }

    if (searchInput) {
      const searchField = searchCategory === "caption" ? "Caption" : "AssetName";
      filtered = filtered.filter(asset => 
        asset[searchField]?.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    setFilterAsset(filtered);
  }, [assets, filter, filterBrandName, searchInput, searchCategory]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAddAsset = (asset) => {
    if (selectedAssets.some(selected => selected.AssetID === asset.AssetID)) {
      alert("This asset is already selected.");
      return;
    }
    setSelectedAssets(prev => [...prev, asset]);
  };

  const handleRemoveAsset = (assetID) => {
    setSelectedAssets(prev => prev.filter(asset => asset.AssetID !== assetID));
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSelectedAssets(prev => arrayMove(prev, oldIndex, newIndex));
  };

  const createObfuscatedReportURL = (brandID, feedID) => {
    const encryptSTR = JSON.stringify({ feedID: feedID, brandID: brandID });
    return "Report?p=" + encrypttoHEX(encryptSTR);
  };

  const createObfuscatedFeedURL = (feedID) => {
    const encryptSTR = JSON.stringify({ feedID: feedID });
    return "Login?p=" + encrypttoHEX(encryptSTR);
  };

  const handleFinalize = async () => {
    if (!selectedAssets.length) {
      setError("Please select at least one asset for the feed.");
      return;
    }

    setIsLoading(true);
    try {
      const now = new Date();
      const oneHourLater = new Date(now.getTime() + 60 * 60 * 1000);
      const formatDateTime = (date) =>
        date.toISOString().slice(0, 19).replace("T", " ");
      
      const submissionData = {
        ...formData,
        SessionStartTime: formData.SessionStartTime
          ? formatDateTime(new Date(formData.SessionStartTime))
          : formatDateTime(now),
        SessionEndTime: formData.SessionEndTime
          ? formatDateTime(new Date(formData.SessionEndTime))
          : formatDateTime(oneHourLater)
      };

      // Create feed first
      const createResponse = await axios.post(
        "https://genz-staging.feeltiptop.com/api/feed/create",
        submissionData,
        {
          headers: { Authorization: "Bearer " + token }
        }
      );

      if (createResponse.data.success) {
        const feedId = createResponse.data.feedId;
        setCreatedFeedID(feedId);
        
        // Add assets
        await axios.post(
          `https://genz-staging.feeltiptop.com/api/feed/${feedId}/assets/add`,
          {
            assets: selectedAssets.map(asset => asset.AssetID)
          },
          {
            headers: { Authorization: "Bearer " + token }
          }
        );

        // Generate feed URL
        const newFeedURL = baseURL + createObfuscatedFeedURL(feedId);
        setFeedURL(newFeedURL);

        // Add the feed URL
        await axios.post(
          "https://genz-staging.feeltiptop.com/api/addFeedURL",
          {
            FeedID: feedId,
            SessionLink: newFeedURL,
          },
          {
            headers: { Authorization: "Bearer " + token }
          }
        );

        // Generate brand report URLs
        const uniqueBrands = [...new Set(selectedAssets.map(asset => ({
          brandID: asset.BrandID,
          brandName: asset.BrandName
        })))];

        const reportURLs = uniqueBrands.map(brand => ({
          id: brand.brandID,
          name: brand.brandName,
          reporturl: createObfuscatedReportURL(brand.brandID, feedId)
        }));
        setBrandReportURLs(reportURLs);

        setFeedCreationSuccess(true);
      }
    } catch (error) {
      console.error("Error creating feed:", error);
      setError(error.response?.data?.message || "Failed to create feed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Asset Content Renderer
  const renderAssetContent = (asset) => {
    try {
      const content = JSON.parse(asset.AssetContent);
      switch (content.assetType) {
        case "poll":
          return (
            <div className="bg-gray-50 p-4 rounded-lg">
              <PollComponent 
                assetContent={content}
                token={token}
                activeAssetId={asset.AssetID}
                feedID={createdFeedID}
                setToken={() => {}} // Placeholder since we're in preview mode
              />
            </div>
          );
        case "text":
          return (
            <div className="prose prose-sm max-w-none">
              <TextComponent assetContent={content} />
            </div>
          );
        case "media":
          return (
            <div className="relative aspect-video">
              <MediaComponent mediaLink={content.mediaContent} />
            </div>
          );
        default:
          return <p>Unsupported asset type: {content.assetType}</p>;
      }
    } catch (error) {
      console.error("Error rendering asset content:", error);
      return <p>Error displaying content</p>;
    }
  };

  // Success Modal Component
  const SuccessModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-green-600">Feed Created Successfully!</h2>
          <button
            onClick={() => setFeedCreationSuccess(false)}
            className="text-gray-500 hover:text-gray-700"
          >
            ×
          </button>
        </div>

        <div className="space-y-6">
          <div>
            <h3 className="font-semibold mb-2">Feed URL:</h3>
            <a
              href={feedURL}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline break-all"
            >
              {feedURL}
            </a>
          </div>

          <div>
            <h3 className="font-semibold mb-2">Brand Report URLs:</h3>
            <ul className="space-y-2">
              {brandReportURLs.map((brand) => (
                <li key={brand.id}>
                  <span className="font-medium">{brand.name}</span>
                  <a
                    href={`${baseURL}${brand.reporturl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-2 text-blue-600 hover:underline break-all"
                  >
                    {`${baseURL}${brand.reporturl}`}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className="font-semibold mb-2">Feed Data Export:</h3>
            <a
              href={`${baseURL}FeedSessionExport?feedid=${createdFeedID}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline break-all"
            >
              {`${baseURL}FeedSessionExport?feedid=${createdFeedID}`}
            </a>
          </div>

          <div>
            <h3 className="font-semibold mb-2">Ambassador Profiles:</h3>
            <a
              href={`${baseURL}AmbassadorProfiles`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:underline break-all"
            >
              {`${baseURL}AmbassadorProfiles`}
            </a>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex h-screen bg-gray-50">
      <div className="w-1/3 border-r border-gray-200 overflow-hidden flex flex-col">
        <div className="p-6 bg-white shadow-sm">
          <h1 className="text-2xl font-bold mb-6">
            {existingFeed ? "Edit Feed" : "New Feed"}
          </h1>

          <div className="bg-white rounded-lg shadow-sm p-6">
            <h2 className="text-lg font-semibold mb-6">Feed Settings</h2>
            <div className="space-y-4">
              <div>
                <label htmlFor="Name" className="block text-sm font-medium text-gray-700 mb-1">
                  Feed Name
                </label>
                <input
                  type="text"
                  id="Name"
                  name="Name"
                  value={formData.Name}
                  onChange={handleInputChange}
                  className="block w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label htmlFor="SessionStartTime" className="block text-sm font-medium text-gray-700 mb-1">
                    Session Start Time
                  </label>
                  <input
                    type="datetime-local"
                    id="SessionStartTime"
                    name="SessionStartTime"
                    value={formData.SessionStartTime}
                    onChange={handleInputChange}
                    className="block w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div>
                  <label htmlFor="SessionEndTime" className="block text-sm font-medium text-gray-700 mb-1">
                    Session End Time
                  </label>
                  <input
                    type="datetime-local"
                    id="SessionEndTime"
                    name="SessionEndTime"
                    value={formData.SessionEndTime}
                    onChange={handleInputChange}
                    className="block w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label htmlFor="MaxStarted" className="block text-sm font-medium text-gray-700 mb-1">
                    Max Started
                  </label>
                  <input
                    type="number"
                    id="MaxStarted"
                    name="MaxStarted"
                    value={formData.MaxStarted}
                    onChange={handleInputChange}
                    min="0"
                    className="block w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div>
                  <label htmlFor="MaxCompleted" className="block text-sm font-medium text-gray-700 mb-1">
                    Max Completed
                  </label>
                  <input
                    type="number"
                    id="MaxCompleted"
                    name="MaxCompleted"
                    value={formData.MaxCompleted}
                    onChange={handleInputChange}
                    min="0"
                    className="block w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>

              {error && (
                <div className="text-red-600 text-sm mt-2">
                  {error}
                </div>
              )}


            </div>
          </div>
          <div className="space-y-4">
            <div className="flex gap-2">
              <select
                value={searchCategory}
                onChange={(e) => setSearchCategory(e.target.value)}
                className="rounded-md border border-gray-300 px-3 py-2"
              >
                <option value="caption">Caption</option>
                <option value="name">Asset Name</option>
              </select>
              <input
                type="text"
                placeholder={`Search by ${searchCategory}`}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                className="flex-1 rounded-md border border-gray-300 px-3 py-2"
              />
            </div>

            <div className="flex gap-2">
              <select
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="flex-1 rounded-md border border-gray-300 px-3 py-2"
              >
                <option value="all">All Types</option>
                <option value="image">Images</option>
                <option value="video">Videos</option>
                <option value="poll">Polls</option>
                <option value="text">Text</option>
              </select>

              <select
                value={filterBrandName}
                onChange={(e) => setFilterBrandName(e.target.value)}
                className="flex-1 rounded-md border border-gray-300 px-3 py-2"
              >
                <option value="all">All Brands</option>
                {brandNames.map(brand => (
                  <option key={brand} value={brand}>{brand}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-6">
          <h2 className="text-lg font-semibold mb-4">Available Assets</h2>
          {isLoading ? (
            <div className="text-center py-4">Loading assets...</div>
          ) : error ? (
            <div className="text-red-600 text-center py-4">{error}</div>
          ) : (
            <div className="space-y-2">
              {filterAsset.map(asset => (
                <div
                  key={asset.AssetID}
                  className="p-4 bg-white rounded-lg border border-gray-200 hover:shadow-md transition-shadow"
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <p className="font-medium">{asset.AssetName}</p>
                      <p className="text-sm text-gray-500">{asset.Caption}</p>
                      <p className="text-xs text-gray-400 mt-1">{asset.BrandName}</p>
                    </div>
                    <button
                      onClick={() => handleAddAsset(asset)}
                      className="px-3 py-1 text-sm bg-blue-50 text-blue-600 hover:bg-blue-100 rounded-md transition-colors"
                    >
                      Add
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="p-6 bg-white shadow-sm border-b border-gray-200">
          <div className="flex justify-between items-center">
            <div className="flex space-x-4">
              <button
                onClick={() => setActiveTab('list')}
                className={`px-4 py-2 rounded-md transition-colors ${
                  activeTab === 'list'
                    ? "bg-blue-50 text-blue-600"
                    : "text-gray-600 hover:text-gray-800"
                }`}
              >
                Selected Assets ({selectedAssets.length})
              </button>
              <button
                onClick={() => setActiveTab('preview')}
                className={`px-4 py-2 rounded-md transition-colors ${
                  activeTab === 'preview'
                    ? "bg-blue-50 text-blue-600"
                    : "text-gray-600 hover:text-gray-800"
                }`}
              >
                Feed Preview
              </button>
            </div>
            <button
              onClick={handleFinalize}
              disabled={isLoading || !selectedAssets.length}
              className={`px-4 py-2 rounded-md text-white transition-colors ${
                isLoading || !selectedAssets.length
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              {isLoading ? "Creating Feed..." : "Create Feed"}
            </button>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto">
          {activeTab === 'list' ? (
            <div className="p-6">
              <SortableList
                items={selectedAssets}
                onSortEnd={onSortEnd}
                handleRemoveAsset={handleRemoveAsset}
                useDragHandle
                lockAxis="y"
              />
            </div>
          ) : (
            <div className="p-6 space-y-8">
              {selectedAssets.map((asset, index) => (
                <div key={asset.AssetID} className="bg-white rounded-lg border border-gray-200 overflow-hidden">
                  <div className="p-4">
                    <div className="flex items-center mb-4">
                      <div className="flex-shrink-0">
                        {asset.BrandProfilePicture ? (
                          <img
                            src={`https://genz-staging.feeltiptop.com/${asset.BrandProfilePicture}`}
                            alt={asset.BrandName}
                            className="w-12 h-12 rounded-full"
                          />
                        ) : (
                          <div className="w-12 h-12 rounded-full bg-gray-100 flex items-center justify-center">
                            <span className="text-gray-500 font-medium text-lg">
                              {asset.BrandName?.[0] || 'B'}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="mb-4">
                      <h3 className="font-medium text-gray-900 mb-2">{asset.AssetName}</h3>
                      {asset.Caption && (
                        <p className="text-gray-600">{asset.Caption}</p>
                      )}
                    </div>

                    <div className="border rounded-lg overflow-hidden">
                      {renderAssetContent(asset)}
                    </div>

                    {asset.OpenLink && (
                      <div className="mt-4 flex items-center">
                        <a
                          href={asset.OpenLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-600 hover:text-blue-800 flex items-center"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(asset.OpenLink, '_blank');
                          }}
                        >
                          <span className="mr-2">Open Link</span>
                          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                          </svg>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {feedCreationSuccess && <SuccessModal />}

      {error && (
        <div className="fixed bottom-4 right-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg shadow-lg">
          <p className="flex items-center">
            <span className="mr-2">⚠️</span>
            {error}
          </p>
        </div>
      )}
    </div>
  );
};

export default NewFeedConstruction;