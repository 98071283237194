import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

function UploadCalendar() {
  const [icsFile, setIcsFile] = useState(null);
  const [message, setMessage] = useState("");
  const { state } = useLocation();

  useEffect(() => {
    if (state && state.message) {
      setMessage(state.message);
    }
  }, [state]);

  const isValidFileType = (filename) => /\.(ics)$/i.test(filename);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && isValidFileType(selectedFile.name)) {
      setIcsFile(selectedFile);
      setMessage(""); // Clear previous messages when a valid file is selected
    } else {
      setMessage("Invalid file type. Please select an ICS file.");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!icsFile) {
      setMessage("Please select an ICS file first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", icsFile);
    formData.append("type", icsFile.type);

    const token = localStorage.getItem("authToken");
    if (!token) {
      setMessage("No authentication token found. Please log in again.");
      return;
    }

    try {
      const response = await axios.post(
        "https://genz-staging.feeltiptop.com/api/upload_calendar",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setMessage("ICS file uploaded successfully!");
    } catch (error) {
      console.error("Error uploading file:", error);
      setMessage(error.response?.data?.error || "An unexpected error occurred.");
    }
  };

  // Conditionally apply success or error styling
  const messageClass = message.includes("successfully") ? "text-green-500" : "text-red-500";

  return (
    <div className="flex flex-col items-center justify-center">
      <h2 className="text-2xl font-bold">Upload Your Calendar</h2>
      <p className="text-s">Only ICS files are allowed.</p>
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-md rounded-md bg-white p-6 shadow-md"
      >
        <input
          type="file"
          accept=".ics"
          onChange={handleFileChange}
          className="mb-4 rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm"
        />
        <button
          type="submit"
          className="w-full bg-emerald-500 py-2 text-white transition-colors duration-300 hover:bg-emerald-600"
        >
          Upload
        </button>
      </form>
      {message && (
        <p className={`mt-4 text-center text-sm ${messageClass}`}>{message}</p>
      )}
    </div>
  );
}

export default UploadCalendar;