import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { encrypttoHEX, decryptfromHEX } from "./encodedecode.js";

import PollComponent from "../components/PollComponent";
import TextComponent from "../components/TextComponent";
import MediaComponent from "../components/MediaComponent";

const protocol = window.location.protocol;
const hostname = window.location.hostname;
const port = window.location.port;
const baseURL = `${protocol}//${hostname}${port ? ":" + port : ""}/`;

const DNDKitSortableList = ({
  id,
  children,
  // handleRemoveAsset,
  setSelectedAssets,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const style = transform
    ? { transform: `translate(${transform.x}px, ${transform.y}px)` }
    : undefined;

  const handleRemoveAsset = (assetID) => {
    setSelectedAssets((prev) => prev.filter((a) => a.AssetID !== assetID));
  };

  return (
    <div className="space-y-2" ref={setNodeRef} style={style}>
      <div className="mb-2 flex items-center justify-between rounded-lg border border-gray-200 bg-white p-4 shadow-sm">
        <div
          className="flex flex-1 items-center"
          {...attributes}
          {...listeners}
        >
          <div className="ml-2 flex-1">
            <p className="font-medium">{children.AssetName}</p>
            <p className="truncate text-sm text-gray-500">{children.Caption}</p>
            <p className="text-xs text-gray-400">{children.BrandName}</p>
          </div>
        </div>
        <button
          onClick={(event) => {
            event.stopPropagation();
            console.log("Clicked Remove Asset:", children.AssetID);
            handleRemoveAsset(children.AssetID);
          }}
          className="ml-4 rounded-md px-3 py-1 text-sm text-red-600 transition-colors hover:bg-red-50 hover:text-red-800"
        >
          Remove Asset
        </button>
      </div>
    </div>
  );
};
const createObfuscatedFeedURL = (feedID) => {
  const encryptSTR = JSON.stringify({ feedID: feedID });
  const URL = baseURL + "Login?p=" + encrypttoHEX(encryptSTR);
  return URL;
};

export default function SpecificFeedConstruction() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [feed, setFeed] = useState(null);
  const [assets, setAssets] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [lastSavedAssets, setLastSavedAssets] = useState([]);
  const [brandNames, setBrandNames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchCategory, setSearchCategory] = useState("caption");
  const [searchInput, setSearchInput] = useState("");
  const [filter, setFilter] = useState("all");
  const [filterBrandName, setFilterBrandName] = useState("all");

  const [existingFeed, setExistingFeed] = useState(null);
  const [activeTab, setActiveTab] = useState("assets");

  const [formData, setFormData] = useState({
    Name:
      existingFeed?.Name || `Feed_${new Date().toISOString().split("T")[0]}`,
    SessionLink: existingFeed?.SessionLink || "",
    SessionStartTime:
      existingFeed?.SessionStartTime || new Date().toISOString().slice(0, 16),
    SessionEndTime:
      existingFeed?.SessionEndTime ||
      new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().slice(0, 16),
    MaxStarted: existingFeed?.MaxStarted || "10000",
    MaxCompleted: existingFeed?.MaxCompleted || "10000",
  });

  const handleRemoveAsset = (assetId) => {
    console.log("removing asset with ID:", assetId);
    setSelectedAssets((prev) => prev.filter((a) => a.AssetID !== assetId));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const renderAssetContent = (asset) => {
    try {
      const content = JSON.parse(asset.AssetContent);
      switch (content.assetType) {
        case "poll":
          return (
            <div className="rounded-lg bg-gray-50 p-4">
              <PollComponent
                assetContent={content}
                token={token}
                activeAssetId={asset.AssetID}
                feedID={createdFeedID}
                setToken={() => {}} // Placeholder since we're in preview mode
              />
            </div>
          );
        case "text":
          return (
            <div className="prose prose-sm max-w-none">
              <TextComponent assetContent={content} />
            </div>
          );
        case "media":
          return (
            <div className="relative aspect-video">
              <MediaComponent mediaLink={content.mediaContent} />
            </div>
          );
        default:
          return <p>Unsupported asset type: {content.assetType}</p>;
      }
    } catch (error) {
      console.error("Error rendering asset content:", error);
      return <p>Error displaying content</p>;
    }
  };

  const decodeFeedId = useCallback(() => {
    const encryptedFeedId = searchParams.get("p");
    if (!encryptedFeedId) {
      setError(
        "No feed ID provided. An encoded URL parameter (e.g., ?p=encrypted_string) is required.",
      );
      setLoading(false);
      return null;
    }

    try {
      const decryptedData = decryptfromHEX(encryptedFeedId);
      const feedData = JSON.parse(decryptedData);
      return feedData.feedID;
    } catch (e) {
      console.error("Decryption error:", e);
      setError("Invalid feed URL. The provided encryption code is not valid.");
      setLoading(false);
      return null;
    }
  }, [searchParams]);

  const fetchFeedData = useCallback(async () => {
    try {
      const feedId = decodeFeedId();
      if (!feedId) return;

      const token = localStorage.getItem("authToken");
      const userResponse = await axios.get(
        "https://genz-staging.feeltiptop.com/api/user/getCurrentUserInfo",
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );

      const currentUserId = userResponse.data.user_id;

      const response = await axios.get(
        `https://genz-staging.feeltiptop.com/api/feed/${feedId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );

      if (response.data.success) {
        const feedData = response.data.data;

        if (feedData.CreatorUserID !== currentUserId) {
          setIsAuthorized(false);
          setError("You are not the creator of this feed");
          setLoading(false);
          return;
        }

        setIsAuthorized(true);

        if (feedData.Status === "Completed") {
          setError("This feed has already been completed.");
          setLoading(false);
          return;
        }

        setFeed(feedData);
        const initialAssets = feedData.assets || [];
        console.log("Initial assets:", initialAssets);
        setSelectedAssets(initialAssets);
        setLastSavedAssets(initialAssets);
      } else {
        setError("Feed not found");
      }
    } catch (err) {
      if (err.response?.status === 403) {
        setIsAuthorized(false);
        setError("You are not the creator of this feed");
      } else {
        setError(err.response?.data?.message || "Error loading feed");
      }
    } finally {
      setLoading(false);
    }
  }, [decodeFeedId]);

  const fetchAvailableAssets = useCallback(async () => {
    try {
      const response = await axios.get(
        "https://genz-staging.feeltiptop.com/api/asset/getAssets",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        },
      );
      setAssets(response.data);
      setIsLoading(false);
    } catch (err) {
      console.error("Error fetching assets:", err);
      setError("Error loading available assets");
    }
  }, []);

  useEffect(() => {
    fetchFeedData();
    fetchAvailableAssets();
  }, [fetchFeedData, fetchAvailableAssets]);

  // Check for unsaved changes whenever selectedAssets changes
  useEffect(() => {
    const haveDifferentLength =
      selectedAssets.length !== lastSavedAssets.length;
    const haveDifferentContent = selectedAssets.some((asset, index) => {
      const savedAsset = lastSavedAssets[index];
      return !savedAsset || asset.AssetID !== savedAsset.AssetID;
    });

    setHasUnsavedChanges(haveDifferentLength || haveDifferentContent);
  }, [selectedAssets, lastSavedAssets]);

  const handleAddAsset = (asset) => {
    if (selectedAssets.some((a) => a.AssetID === asset.AssetID)) {
      console.warn("Asset is already added:", asset);
      return;
    }
    setSelectedAssets((prev) => [
      ...prev,
      { ...asset, Position: prev.length + 1 },
    ]);
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      const feedId = decodeFeedId();
      if (!feedId) return;

      const payload = selectedAssets.map((asset, index) => ({
        AssetID: asset.AssetID,
        Position: index + 1,
      }));

      const response = await axios.post(
        `https://genz-staging.feeltiptop.com/api/feed/${feedId}/save`,
        { assets: payload },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        },
      );

      if (response.data.success) {
        setLastSavedAssets(selectedAssets);
        setHasUnsavedChanges(false);
        alert("Feed saved successfully!");
      } else {
        console.error("Save failed:", response.data.message);
        alert("Failed to save feed: " + response.data.message);
      }
    } catch (err) {
      console.error("Error saving feed:", err.message);
      alert("Error saving feed: " + err.message);
    } finally {
      setSaving(false);
    }
  };

  const handleComplete = async () => {
    if (hasUnsavedChanges) {
      alert("Please save your changes before completing the feed.");
      return;
    }

    if (
      window.confirm(
        "Are you sure you want to complete this feed? This action cannot be undone.",
      )
    ) {
      try {
        const feedId = decodeFeedId();
        if (!feedId) return;

        const feedURL = createObfuscatedFeedURL(feedId);

        const response = await axios.post(
          `https://genz-staging.feeltiptop.com/api/feed/${feedId}/complete`,
          { SessionLink: feedURL },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          },
        );

        if (response.data.success) {
          setCompleted(true);
          alert("Feed marked as completed!");

          // Call the reward API for the seller after completing the feed
          const rewardResponse = await axios.post(
            `https://genz-staging.feeltiptop.com/api/feed/${feedId}/reward_seller`,
            { SessionLink: feedURL },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            },
          );

          if (rewardResponse.data.success) {
            alert("Points Earned!");
          } else {
            alert(
              "Error giving points to seller: " + rewardResponse.data.message,
            );
          }

          navigate("/AssetReactions");
        }
      } catch (err) {
        console.error("Error completing feed:", err.message);
        alert("Error completing feed: " + err.message);
      }
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id === over.id) {
      return;
    }
    const oldIndex = selectedAssets.findIndex(
      (asset) => asset.AssetID === active.id,
    );
    const newIndex = selectedAssets.findIndex(
      (asset) => asset.AssetID === over.id,
    );

    if (oldIndex !== newIndex) {
      const updatedAssets = arrayMove(selectedAssets, oldIndex, newIndex);
      console.log("Updated assets:", updatedAssets);

      setSelectedAssets(updatedAssets);
    }
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className="flex h-screen items-center justify-center">
          <div className="text-xl text-gray-600">Loading...</div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="container mx-auto p-4">
          <div className="mx-auto max-w-2xl rounded-lg border border-red-200 bg-red-50 p-4 text-red-800">
            <div className="mb-2 text-lg font-semibold">Error</div>
            <div>{error}</div>
          </div>
        </div>
      );
    }

    if (!isAuthorized) {
      return (
        <div className="container mx-auto p-4">
          <div className="mx-auto max-w-2xl rounded-lg border border-red-200 bg-red-50 p-4 text-red-800">
            <div className="mb-2 text-lg font-semibold">Access Denied</div>
            <div>You are not authorized to view or modify this feed.</div>
          </div>
        </div>
      );
    }

    if (feed?.Status === "Completed") {
      return (
        <div className="container mx-auto p-4">
          <div className="mx-auto max-w-2xl rounded-lg border border-yellow-200 bg-yellow-50 p-4 text-yellow-800">
            <div className="mb-2 text-lg font-semibold">Feed Completed</div>
            <div>
              This feed has already been marked as completed. Adjustments are no
              longer allowed.
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="flex flex-col bg-gray-50 md:h-screen md:flex-row">
        <div className="flex w-full flex-col overflow-hidden border-r border-gray-200 md:w-1/3">
          <div className="bg-white p-6 shadow-sm">
            <h1 className="mb-6 mt-4 text-3xl font-bold">
              {feed?.Name || "Specific Feed Construction"}
            </h1>

            <div className="rounded-lg bg-white p-6 shadow-sm">
              <div className="space-y-4">
                <div className="flex-1 flex-col overflow-y-auto p-6">
                  <h2 className="mb-4 text-lg font-semibold">Feed Settings</h2>
                  <div className="space-y-4">
                    <div>
                      <label
                        htmlFor="Name"
                        className="mb-1 block text-sm font-semibold text-gray-700"
                      >
                        Feed Name
                      </label>
                      <input
                        type="text"
                        id="Name"
                        name="Name"
                        value={formData.Name}
                        onChange={handleInputChange}
                        className="block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                      />
                    </div>
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                      <div>
                        <label
                          htmlFor="SessionStartTime"
                          className="mb-1 block text-sm font-semibold text-gray-700"
                        >
                          Start Time
                        </label>
                        <input
                          type="datetime-local"
                          name="SessionStartTime"
                          value={formData.SessionStartTime}
                          onChange={handleInputChange}
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-5"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="SessionEndTime"
                          className="mb-1 block text-sm font-semibold text-gray-700"
                        >
                          End Time
                        </label>
                        <input
                          type="datetime-local"
                          name="SessionEndTime"
                          value={formData.SessionEndTime}
                          onChange={handleInputChange}
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-5"
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                      <div>
                        <label
                          htmlFor="MaxStarted"
                          className="mb-1 block text-sm font-medium text-gray-700"
                        >
                          Max Started
                        </label>
                        <input
                          type="number"
                          name="MaxStarted"
                          value={formData.MaxStarted}
                          onChange={handleInputChange}
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-5"
                        />
                      </div>

                      <div>
                        <label
                          htmlFor="MaxCompleted"
                          className="mb-1 block text-sm font-medium text-gray-700"
                        >
                          Max Completed
                        </label>
                        <input
                          type="number"
                          name="MaxCompleted"
                          value={formData.MaxCompleted}
                          onChange={handleInputChange}
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-5"
                        />
                      </div>
                    </div>

                    <div className="space-y-4">
                      <div className="flex gap-2">
                        <select
                          value={searchCategory}
                          onChange={(e) => setSearchCategory(e.target.value)}
                          className="rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-5"
                        >
                          <option value="caption">Caption</option>
                          <option value="brand">Asset Name</option>
                        </select>

                        <input
                          type="text"
                          value={searchInput}
                          onChange={(e) => setSearchInput(e.target.value)}
                          placeholder={`Search by ${searchCategory}`}
                          className="flex-1 rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-5"
                        ></input>
                      </div>

                      <div className="flex gap-2">
                        <select
                          value={filter}
                          onChange={(e) => setFilter(e.target.value)}
                          className="flex-1 rounded-md border border-gray-300 px-3 py-2"
                        >
                          <option value="all">All</option>
                          <option value="poll">Images</option>
                          <option value="Videos">Videos</option>
                          <option value="text">Text</option>
                          <option value="poll">Polls</option>
                        </select>

                        <select
                          value={filterBrandName}
                          onChange={(e) => setFilterBrandName(e.target.value)}
                          className="flex-1 rounded-md border border-gray-300 px-3 py-2"
                        >
                          <option value="all">All Brands</option>
                          {brandNames.map((brandName) => (
                            <option key={brandName} value={brandName}>
                              {brandName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                {error && (
                  <div className="mt-2 text-sm text-red-600">{error}</div>
                )}
              </div>
            </div>
            <div className="space-y-4"></div>
          </div>

          <div className="flex-1 overflow-y-auto p-6">
            <h2 className="mb-4 text-lg font-semibold">Available Assets</h2>
            {isLoading ? (
              <div className="py-4 text-center">Loading assets...</div>
            ) : error ? (
              <div className="py-4 text-center text-red-600">{error}</div>
            ) : (
              <div className="space-y-2">
                {assets.map((asset) => (
                  <div
                    key={asset.AssetID}
                    className="rounded-lg border border-gray-200 bg-white p-4 transition-shadow hover:shadow-md"
                  >
                    <div className="flex items-start justify-between">
                      <div>
                        <p className="font-medium">{asset.AssetName}</p>
                        <p className="text-sm text-gray-500">{asset.Caption}</p>
                        <p className="mt-1 text-xs text-gray-400">
                          {asset.BrandName}
                        </p>
                      </div>
                      <button
                        onClick={() => handleAddAsset(asset)}
                        className="rounded-md bg-blue-50 px-3 py-1 text-sm text-blue-600 transition-colors hover:bg-blue-100"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          {}
        </div>

        <div className="flex flex-1 flex-col overflow-hidden">
          <div className="border-b border-gray-200 bg-white p-6 shadow-sm">
            <div className="mb-6 flex items-center justify-between">
              <div className="flex space-x-4">
                <button
                  onClick={() => setActiveTab("list")}
                  className={`rounded-md px-4 py-2 transition-colors ${
                    activeTab === "list"
                      ? "bg-blue-50 text-blue-600"
                      : "text-gray-600 hover:text-gray-800"
                  }`}
                >
                  Selected Assets ({selectedAssets.length})
                </button>
                <button
                  onClick={() => setActiveTab("preview")}
                  className={`rounded-md px-4 py-2 transition-colors ${
                    activeTab === "preview"
                      ? "bg-blue-50 text-blue-600"
                      : "text-gray-600 hover:text-gray-800"
                  }`}
                >
                  Feed Preview
                </button>
              </div>

              <div className="flex space-x-4">
                <button
                  onClick={handleSave}
                  disabled={saving || !hasUnsavedChanges}
                  className={`rounded bg-blue-500 px-4 py-2 text-white transition-colors hover:bg-blue-600 ${
                    saving || !hasUnsavedChanges
                      ? "cursor-not-allowed opacity-50"
                      : ""
                  }`}
                >
                  {saving ? "Saving..." : "Save"}
                </button>
                <button
                  onClick={handleComplete}
                  disabled={completed || hasUnsavedChanges}
                  className={`rounded bg-green-500 px-4 py-2 text-white transition-colors hover:bg-green-600 ${
                    completed || hasUnsavedChanges
                      ? "cursor-not-allowed opacity-50"
                      : ""
                  }`}
                >
                  Complete Feed
                </button>
              </div>
            </div>
          </div>

          {}

          <div className="flex-1 overflow-y-auto p-6">
            <div className="flex-1 items-center justify-center">
              {activeTab === "list" && (
                <DndContext
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext
                    items={selectedAssets.map((asset) => asset.AssetID)}
                    strategy={verticalListSortingStrategy}
                  >
                    <div className="p-6">
                      {selectedAssets.map((asset, index) => (
                        <DNDKitSortableList
                          id={asset.AssetID} // Use AssetID for the draggable id
                          key={asset.AssetID}
                          index={index}
                          // handleRemoveAsset={handleRemoveAsset}
                          setSelectedAssets={setSelectedAssets}
                        >
                          {asset}
                        </DNDKitSortableList>
                      ))}
                    </div>
                  </SortableContext>
                </DndContext>
              )}

              {activeTab === "preview" && (
                <div className="flex flex-col items-center justify-center rounded-lg bg-gray-100">
                  {selectedAssets.map((asset, index) => (
                    <div
                      key={asset.AssetID}
                      className="h-4/6 w-4/6 overflow-hidden rounded-lg border border-gray-200 bg-white"
                    >
                      <div className="p-4">
                        <div className="mb-4 flex items-center">
                          <div className="flex-shrink-0">
                            {asset.BrandProfilePicture ? (
                              <img
                                src={`https://genz-staging.feeltiptop.com/${asset.BrandProfilePicture}`}
                                alt={asset.BrandName}
                                className="h-12 w-12 rounded-full"
                              />
                            ) : (
                              <div className="flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                                <span className="text-lg font-medium text-gray-500">
                                  {asset.BrandName?.[0] || "B"}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="mb-4">
                          <h3 className="mb-2 font-medium text-gray-900">
                            {asset.AssetName}
                          </h3>
                          {asset.Caption && (
                            <p className="text-gray-600">{asset.Caption}</p>
                          )}
                        </div>

                        <div className="overflow-hidden rounded-lg border">
                          {renderAssetContent(asset)}
                        </div>

                        {asset.OpenLink && (
                          <div className="mt-4 flex items-center">
                            <a
                              href={asset.OpenLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="flex items-center text-blue-600 hover:text-blue-800"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(asset.OpenLink, "_blank");
                              }}
                            >
                              <span className="mr-2">Open Link</span>
                              <svg
                                className="h-4 w-4"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                />
                              </svg>
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return renderContent();
}
