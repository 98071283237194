import { useState, useEffect } from 'react';
import axios from 'axios';
import { ClipboardIcon } from "@heroicons/react/24/solid"; 

function ReferralLink() {
  const [referralLink, setReferralLink] = useState('');
  const [copied, setCopied] = useState(false); // Track if link is copied

  // Function to fetch the current referral link
  const fetchReferralLink = async () => {
    try {
      const response = await axios.get('https://genz-staging.feeltiptop.com/api/getRefferalLink', {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });
      setReferralLink(response.data.referralLink);
    } catch (error) {
      console.error('Error fetching referral link:', error);
    }
  };

  useEffect(() => {
    fetchReferralLink();
  }, []);

  // Function to regenerate the referral link
  const handleRegenerateLink = async () => {
    try {
      const response = await axios.post('https://genz-staging.feeltiptop.com/api/regenerateReferralLink', {}, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });
      setReferralLink(response.data.referralLink); // Update the link with the new one
    } catch (error) {
      console.error('Error regenerating referral link:', error);
    }
  };

  const handleCopyClick = () => {
    if (referralLink) {
      navigator.clipboard.writeText(referralLink).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset "copied" state after 2 seconds
      });
    }
  };

  const generateQRCodeSrc = (text) => {
    return `https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${encodeURIComponent(text)}`;
  };

  return (
    <div className="flex flex-col items-center justify-center p-6 max-w-3xl mx-auto">
      <h1 className="text-2xl font-bold text-gray-900 mb-6">Referral QR Code</h1>
      <div className="flex flex-col items-center justify-center mb-6">
        <img
          className="h-40 w-40 rounded-lg"  
          src={generateQRCodeSrc(referralLink)} 
          alt="QR Code"
        />
      </div>

      <div className="mt-6 text-center w-full px-4">
        {referralLink ? (
          <div className="flex items-center w-full max-w-lg mx-auto">
            {/* Referral link input field wrapped in <a> to make it clickable */}
            <a
              href={referralLink}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-gray-200 w-full text-sm text-gray-800 p-2 rounded-l-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 ease-in-out"
            >
              {referralLink}
            </a>
            {/* Clipboard Icon button */}
            <button
              onClick={handleCopyClick}
              className="bg-blue-600 text-white p-2 rounded-r-md hover:bg-blue-700 transition duration-200 ease-in-out"
              aria-label="Copy referral link"
            >
              <ClipboardIcon className="h-5 w-5" />
            </button>
          </div>
        ) : (
          <p className="text-gray-500">No referral link</p>
        )}
        {copied && (
          <span className="text-sm text-green-500 ml-2">Link copied!</span>
        )}

        {/* Button to regenerate the referral link */}
        <button
          onClick={handleRegenerateLink}
          className="mt-4 bg-green-600 text-white p-2 rounded-md hover:bg-green-700 transition duration-200 ease-in-out"
        >
          Refresh Referral Link
        </button>
      </div>
    </div>
  );
}

export default ReferralLink;