import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Report from "./routes/Report";
import Login from "./routes/Login";
import Feed from "./routes/Feed";
import Profile from "./routes/Profile";
import SignUp from "./routes/SignUp";
import VerifyPhoneNumber from "./routes/VerifyPhoneNumber";
import VerifyPhoneNumberLogin from "./routes/VerifyPhoneNumberLogin";
import EnterName from "./routes/EnterName";
import Gender from "./routes/gender";
import DateOfBirth from "./routes/DateOfBirth";
import Ethnicity from "./routes/Ethnicity";
import SelectState from "./routes/SelectState";
import SelectInterests from "./routes/SelectInterests";
import EmailAddress from "./routes/EmailAddress";
import UploadProfilePicture from "./routes/UploadProfilePicture";
import TermsAndConditions from "./routes/TermsAndConditions";
import BrandCreate from "./routes/BrandCreate";
import AssetCreate from "./routes/AssetCreate";
import useToken from "./components/useToken";
import PrivateRoute from "./components/PrivateRoute";
import FeedConstruction from "./routes/FeedConstruction";
import NewFeedConstruction from "./routes/NewFeedConstruction";
import FinalizeFeed from "./routes/FinalizeFeed";
import EncodeDecode from "./routes/encodedecode";
import AmbassadorProfiles from "./routes/AmbassadorProfiles";
import FeedSessionExport from "./routes/FeedSessionExport";
import logo from "./assets/images/GenZ_360Logo.png";
import ThankYouPage from "./routes/thankYouPage";
import EnterPassword from "./routes/EnterPassword";
import EnterUserName from "./routes/EnterUserName";
import LandingPage from "./routes/LandingPage";
import FeedStart from "./routes/FeedStart";
import FeedSelect from "./routes/FeedSelect";
import OldSignUp from "./routes/OldSignUp";
import KeyFrameExtractor from "./routes/KeyFrameExtractor";
import UserReactions from "./routes/UserReactions";
import AssetReactions from "./routes/AssetReactions";
import UploadResume from "./routes/UploadResume";
import MainLayout from "./routes/MainLayout";
import UploadCalendar from "./routes/UploadCalendar";
import UserDashboard from "./routes/UserDashboard";
import ClassSchedule from "./routes/ClassSchedule";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import VerifySeller from './routes/VerifySeller';
import SubscriptionStatus from "./routes/Subscription";
import SubscriptionGuard from './components/SubscriptionGuard';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function App() {
  const { token, removeToken, setToken } = useToken();

  return (
    <Router>
      <div className="App">
        {/* <img src={logo} alt="GenZ Logo" className="logo" loading="lazy"/> */}
        <Routes>
          <Route element={<MainLayout />}>
            <Route path="/" element={<LandingPage />} />
            <Route
              path="/login"
              element={
                <Login
                  setToken={setToken}
                  token={token}
                  removeToken={removeToken}
                />
              }
            />
            <Route
              path="/signup"
              element={
                <Elements stripe={stripePromise}>
                  <SignUp
                    setToken={setToken}
                    token={token}
                    removeToken={removeToken}
                  />
                </Elements>
              }
            />
          </Route>
          <Route path="/uploadresume" element={<UploadResume />} />
          <Route path="/VerifySeller" element={<VerifySeller />} />
          <Route path="/subscription" element={<SubscriptionStatus />} />

          <Route
            path="/verifyphonenumber"
            element={
              <VerifyPhoneNumber
                setToken={setToken}
                token={token}
                removeToken={removeToken}
              />
            }
          />
          <Route path="/UploadCalendar" element={<UploadCalendar />} />
          <Route path="/ClassSchedule" element={<ClassSchedule />} />
          <Route
            path="/verifyphonenumberLogin"
            element={
              <VerifyPhoneNumberLogin
                setToken={setToken}
                token={token}
                removeToken={removeToken}
              />
            }
          />
          <Route
            path="/enterusername"
            element={
              <PrivateRoute token={token}>
                <EnterUserName />
              </PrivateRoute>
            }
          />
          <Route
            path="/enterpassword"
            element={
              <PrivateRoute token={token}>
                <EnterPassword />
              </PrivateRoute>
            }
          />
          <Route
            path="/entername"
            element={
              <PrivateRoute token={token}>
                <EnterName />
              </PrivateRoute>
            }
          />
          <Route
            path="/gender"
            element={
              <PrivateRoute token={token}>
                <Gender />
              </PrivateRoute>
            }
          />
          <Route
            path="/DateOfBirth"
            element={
              <PrivateRoute token={token}>
                <DateOfBirth />
              </PrivateRoute>
            }
          />
          <Route
            path="/Ethnicity"
            element={
              <PrivateRoute token={token}>
                <Ethnicity />
              </PrivateRoute>
            }
          />
          <Route
            path="/SelectState"
            element={
              <PrivateRoute token={token}>
                <SelectState />
              </PrivateRoute>
            }
          />
          <Route
            path="/SelectInterests"
            element={
              <PrivateRoute token={token}>
                <SelectInterests />
              </PrivateRoute>
            }
          />
          <Route
            path="/EmailAddress"
            element={
              <PrivateRoute token={token}>
                <EmailAddress />
              </PrivateRoute>
            }
          />
          <Route
            path="/UploadProfilePicture"
            element={
              <PrivateRoute token={token}>
                <UploadProfilePicture />
              </PrivateRoute>
            }
          />
          <Route
            path="/TermsAndConditions"
            element={
              <PrivateRoute token={token}>
                <TermsAndConditions
                  setToken={setToken}
                  token={token}
                  removeToken={removeToken}
                />
              </PrivateRoute>
            }
          />
          <Route
            path="/feed"
            element={
              <PrivateRoute token={token}>
                <Feed
                  setToken={setToken}
                  token={token}
                  removeToken={removeToken}
                />
              </PrivateRoute>
            }
          />
          <Route
            path="/OldSignUp"
            element={
              <OldSignUp
                setToken={setToken}
                token={token}
                removeToken={removeToken}
              />
            }
          />
          <Route path="/EncDec" element={<EncodeDecode />} />
          <Route path="/AmbassadorProfiles" element={<AmbassadorProfiles />} />
          <Route path="/FeedSessionExport" element={<FeedSessionExport />} />
          <Route path="/thankyou" element={<ThankYouPage />} />
          <Route path="/KeyFrameExtractor" element={<KeyFrameExtractor />} />

          
          <Route element={<SubscriptionGuard />}>
            <Route path="/profile" element={<Profile />} />
            <Route path="/report" element={<Report />} />
            <Route
              path="/UserDashboard"
              element={
                <UserDashboard
                  setToken={setToken}
                  token={token}
                  removeToken={removeToken}
                />
              }
            />
            <Route
            path="/FeedSelect"
            element={
              <FeedSelect
                setToken={setToken}
                token={token}
                removeToken={removeToken}
              />
              }
            />
            <Route
              path="/brandcreate"
              element={
                <PrivateRoute token={token}>
                  <BrandCreate
                    setToken={setToken}
                    token={token}
                    removeToken={removeToken}
                  />
                </PrivateRoute>
              }
            />
            <Route path="/AssetReactions" element={<AssetReactions />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/report" element={<Report />} />
            <Route
              path="/brandcreate"
              element={
                <PrivateRoute token={token}>
                  <BrandCreate
                    setToken={setToken}
                    token={token}
                    removeToken={removeToken}
                  />
                </PrivateRoute>
              }
            />
            <Route
              path="/assetcreate"
              element={
                <PrivateRoute token={token}>
                  <AssetCreate
                    setToken={setToken}
                    token={token}
                    removeToken={removeToken}
                  />
                </PrivateRoute>
              }
            />
            <Route
              path="/FeedConstruction"
              element={
                <PrivateRoute token={token}>
                  <FeedConstruction
                    setToken={setToken}
                    token={token}
                    removeToken={removeToken}
                  />
                </PrivateRoute>
              }
            />
            <Route
              path="/NewFeedConstruction"
              element={
                <PrivateRoute token={token}>
                  <NewFeedConstruction
                    setToken={setToken}
                    token={token}
                    removeToken={removeToken}
                  />
                </PrivateRoute>
              }
            />
            <Route
              path="/FinalizeFeed"
              element={
                <PrivateRoute token={token}>
                  <FinalizeFeed
                    setToken={setToken}
                    token={token}
                    removeToken={removeToken}
                  />
                </PrivateRoute>
              }
            />
            <Route
              path="/FeedStart"
              element={
                <PrivateRoute token={token}>
                  <FeedStart />
                </PrivateRoute>
              }
            />
            <Route
              path="/FeedSelect"
              element={
                <FeedSelect
                  setToken={setToken}
                  token={token}
                  removeToken={removeToken}
                />
              }
            />
            <Route
              path="/UserReactions"
              element={
                <UserReactions
                  setToken={setToken}
                  token={token}
                  removeToken={removeToken}
                />
              }
            />
          </Route>

          <Route path="*" element={() => <div>404 Not Found</div>} />{" "}

        </Routes>
      </div>
    </Router>
  );
}

export default App;
