import React, { useState, useEffect } from "react";

const BASE_URL = "https://genz-staging.feeltiptop.com/api/question_board";

const getAuthToken = () => {
  const token = localStorage.getItem("accessToken");
  return token; // or null if not found
};

const QuestionBoard = ({ setToken, token, removeToken }) => {
  // =============== State ===============
  const [questions, setQuestions] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);

  // For "Add a New Question"
  const [newTitle, setNewTitle] = useState("");
  const [newText, setNewText] = useState("");
  const [selectedTagNames, setSelectedTagNames] = useState([]);

  // For Searching & Ordering
  const [searchKeywords, setSearchKeywords] = useState("");
  const [searchTags, setSearchTags] = useState(""); // a comma-separated string for new tag-based search
  const [orderBy, setOrderBy] = useState("DESC");

  // For error or status messages
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  // Pagination
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  // For image upload
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  // For sliding sidebar
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // =============== Effects ===============
  useEffect(() => {
    fetchQuestions();
    fetchTags();
  }, [limit, offset, orderBy]);

  // =============== API Calls ===============

  
  const fetchQuestions = async () => {
    try {
      setError("");
      const userToken = getAuthToken();
      const endpoint = userToken ? "get-questions-auth" : "get-questions";

      // Build query params
      const queryParams = new URLSearchParams({
        limit,
        offset,
        order_by: orderBy
      });
      if (searchKeywords.trim()) queryParams.append("keywords", searchKeywords.trim());

     
      const trimmedTagSearch = searchTags.trim();
      if (trimmedTagSearch) {
        
        queryParams.append("tags", trimmedTagSearch);
      }

      const url = `${BASE_URL}/${endpoint}?${queryParams.toString()}`;
      const headers = {};
      if (userToken) {
        headers["Authorization"] = `Bearer ${userToken}`;
      }

      const response = await fetch(url, { headers });
      if (!response.ok) {
        throw new Error(`Server error: ${response.statusText}`);
      }
      const data = await response.json();
      setQuestions(data);
    } catch (err) {
      setError(err.message);
    }
  };

  /**
   * Fetch available tags
   */
  const fetchTags = async () => {
    try {
      const response = await fetch(`${BASE_URL}/get-tags`);
      if (!response.ok) {
        throw new Error(`Tag fetch error: ${response.statusText}`);
      }
      const data = await response.json();
      setAvailableTags(data);
    } catch (err) {
      setError(err.message);
    }
  };

  /**
   * Create a new question
   */
  const handleAddQuestion = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const formData = new FormData();
      formData.append("title", newTitle);
      formData.append("text", newText);
      selectedTagNames.forEach(tag => formData.append("tags[]", tag));
      if (selectedImage) {
        formData.append("image", selectedImage);
      }

      const response = await fetch(`${BASE_URL}/post-question`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData
      });

      const data = await response.json();
      
      if (response.ok) {
        setMessage("Question posted successfully!");
        setNewTitle("");
        setNewText("");
        setSelectedTagNames([]);
        setSelectedImage(null);
        setImagePreview(null);
        fetchQuestions();
      } else {
        setError(data.message || "Failed to post question");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  /**
   * Create a new tag
   */
  const handleCreateTag = async (tagName) => {
    setError("");
    try {
      const response = await fetch(`${BASE_URL}/create-tag`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ tag: tagName }),
      });
      const data = await response.json();
      if (response.ok) {
        setMessage(`Tag '${tagName}' created successfully!`);
        fetchTags();
      } else {
        setError(data.message || "Failed to create tag");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  /**
   * Searching or advanced filtering
   */
  const handleSearch = async () => {
    setOffset(0); // reset offset to 0 when searching
    fetchQuestions();
  };

  const handleImageSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      // Create preview URL
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
    }
  };

  // =============== Rendering ===============
  return (
    <div className="min-h-screen bg-gray-100 p-4">
      {/* Main Content */}
      <div className="relative">
        {/* Header with Create Button */}
        <div className="mb-4 flex items-center justify-between">
          <h1 className="text-3xl font-bold text-gray-800">Question Board</h1>
          <button
            onClick={() => setIsSidebarOpen(true)}
            className="rounded-lg bg-blue-600 px-4 py-2 text-white hover:bg-blue-700"
          >
            Create New Question
          </button>
        </div>

        {message && <p className="mb-4 text-green-600">{message}</p>}
        {error && <p className="mb-4 text-red-600">Error: {error}</p>}

        {/* Search Controls */}
        <div className="mb-4 flex flex-wrap items-center gap-2">
          {/* Keywords Search */}
          <input
            type="text"
            placeholder="Search Keywords"
            value={searchKeywords}
            onChange={(e) => setSearchKeywords(e.target.value)}
            className="rounded border p-2 focus:outline-none focus:ring"
          />

          {/* Tag Search */}
          <input
            type="text"
            placeholder="Tags (comma-separated)"
            value={searchTags}
            onChange={(e) => setSearchTags(e.target.value)}
            className="rounded border p-2 focus:outline-none focus:ring"
          />

          <select
            value={orderBy}
            onChange={(e) => setOrderBy(e.target.value)}
            className="rounded border p-2"
          >
            <option value="ASC">Oldest First</option>
            <option value="DESC">Newest First</option>
          </select>

          <button
            onClick={handleSearch}
            className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
          >
            Search
          </button>

          {/* Pagination */}
          <div className="ml-auto flex items-center space-x-2">
            <button
              onClick={() => setOffset(Math.max(offset - limit, 0))}
              disabled={offset === 0}
              className="rounded bg-gray-300 px-3 py-1 hover:bg-gray-400 disabled:bg-gray-200"
            >
              Prev
            </button>
            <button
              onClick={() => setOffset(offset + limit)}
              className="rounded bg-gray-300 px-3 py-1 hover:bg-gray-400"
            >
              Next
            </button>
          </div>
        </div>

        {/* Questions Grid */}
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
          {questions.map((question) => (
            <QuestionCard
              key={question.questionId}
              question={question}
              token={token}
              refreshQuestions={fetchQuestions}
            />
          ))}
        </div>
      </div>

      {/* Sliding Sidebar */}
      <div
        className={`fixed inset-y-0 right-0 z-50 w-1/2 transform overflow-y-auto bg-white p-8 shadow-lg transition-transform duration-300 ease-in-out ${
          isSidebarOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        {/* Sidebar Header */}
        <div className="mb-8 flex items-center justify-between">
          <h2 className="text-3xl font-bold text-gray-800">Create Content</h2>
          <button
            onClick={() => setIsSidebarOpen(false)}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg className="h-8 w-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Add Question Form */}
        <div className="mb-12">
          <h3 className="mb-6 text-2xl font-semibold text-gray-700">
            Add a New Question
          </h3>
          <form onSubmit={handleAddQuestion} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Question Title
              </label>
              <input
                type="text"
                placeholder="Enter your question title"
                value={newTitle}
                onChange={(e) => setNewTitle(e.target.value)}
                className="block w-full rounded-lg border p-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Question Details
              </label>
              <textarea
                value={newText}
                onChange={(e) => setNewText(e.target.value)}
                rows="6"
                className="block w-full rounded-lg border p-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Describe your question in detail..."
              ></textarea>
            </div>

            {/* Image Upload */}
            <div className="space-y-3">
              <label className="block text-sm font-medium text-gray-700">
                Add an Image (optional)
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageSelect}
                className="block w-full text-sm text-gray-500
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-blue-50 file:text-blue-700
                  hover:file:bg-blue-100"
              />
              {imagePreview && (
                <div className="mt-4">
                  <img
                    src={imagePreview}
                    alt="Preview"
                    className="max-h-64 rounded-lg object-contain"
                  />
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedImage(null);
                      setImagePreview(null);
                    }}
                    className="mt-2 text-sm text-red-600 hover:text-red-800"
                  >
                    Remove Image
                  </button>
                </div>
              )}
            </div>

            {/* Tag Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Select Tags
              </label>
              <div className="max-h-48 overflow-y-auto rounded-lg border p-4">
                <div className="flex flex-wrap gap-3">
                  {availableTags.map((tagName) => (
                    <label key={tagName} className="flex items-center">
                      <input
                        type="checkbox"
                        className="mr-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        checked={selectedTagNames.includes(tagName)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedTagNames((prev) => [...prev, tagName]);
                          } else {
                            setSelectedTagNames((prev) =>
                              prev.filter((t) => t !== tagName)
                            );
                          }
                        }}
                      />
                      <span className="text-sm text-gray-700">{tagName}</span>
                    </label>
                  ))}
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="w-full rounded-lg bg-blue-600 px-4 py-3 text-white hover:bg-blue-700 font-medium"
            >
              Post Question
            </button>
          </form>
        </div>

        {/* Create Tag Form */}
        <div className="border-t pt-8">
          <h3 className="mb-6 text-2xl font-semibold text-gray-700">
            Create a New Tag
          </h3>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const tagInput = e.target.elements.tagInput.value.trim();
              if (tagInput) {
                handleCreateTag(tagInput);
                e.target.reset();
              }
            }}
            className="space-y-6"
          >
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Tag Name
              </label>
              <input
                type="text"
                name="tagInput"
                placeholder="Enter new tag name"
                className="block w-full rounded-lg border p-3 focus:outline-none focus:ring-2 focus:ring-green-500"
              />
            </div>
            <button
              type="submit"
              className="w-full rounded-lg bg-green-600 px-4 py-3 text-white hover:bg-green-700 font-medium"
            >
              Create Tag
            </button>
          </form>

          {/* Available Tags List */}
          <div className="mt-8">
            <h4 className="mb-4 text-lg font-medium text-gray-700">
              Available Tags
            </h4>
            <div className="max-h-48 overflow-y-auto rounded-lg border p-4">
              <div className="flex flex-wrap gap-2">
                {availableTags.map((tag) => (
                  <span
                    key={tag}
                    className="rounded-full bg-gray-100 px-4 py-2 text-sm text-gray-600"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Overlay */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}
    </div>
  );
};
/**
 * The QuestionCard component is responsible for:
 * - Displaying question info (title, text, tags, etc.)
 * - Loading / displaying replies
 * - Adding new replies
 * - Upvoting replies
 * - Possibly editing/deleting the question if the user is the owner (check if question.userId matches current user)
 */
const QuestionCard = ({ question, token, refreshQuestions }) => {
  const [replies, setReplies] = useState([]);
  const [replyText, setReplyText] = useState("");
  const [showReplies, setShowReplies] = useState(false);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);

  // Toggle to load/unload replies
  const handleLoadReplies = async () => {
    setError("");
    setShowReplies(!showReplies);

    if (!showReplies) {
      try {
        // If user is logged in, we might call get-replies-auth
        // But here's the public version
        const response = await fetch(
          `${BASE_URL}/get-replies?questionNum=${question.questionId}`
        );
        if (!response.ok) {
          throw new Error("Failed to load replies");
        }
        const data = await response.json();
        setReplies(data);
      } catch (err) {
        setError(err.message);
      }
    }
  };

  // Add reply
  const handleAddReply = async (e) => {
    e.preventDefault();
    setError("");

    if (!replyText.trim()) return;

    try {
      const headers = { "Content-Type": "application/json" };
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await fetch(`${BASE_URL}/post-reply`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          questionId: question.questionId,
          text: replyText,
        }),
      });
      const result = await response.json();
      if (response.ok) {
        // Insert the new reply into local state
        const newReply = {
          replyId: result.replyId,
          userId: 123, // or from your token
          text: replyText,
          updatedAt: new Date().toISOString(),
          parentReplyId: null,
          username: "You",
          upvotes: 0,
        };
        setReplies((prev) => [...prev, newReply]);
        setReplyText("");
      } else {
        setError(result.message || "Failed to create reply.");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  // Upvote reply
  const handleUpvoteReply = async (replyId) => {
    setError("");
    try {
      const headers = { "Content-Type": "application/json" };
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await fetch(`${BASE_URL}/upvote-reply`, {
        method: "POST",
        headers,
        body: JSON.stringify({ replyId }),
      });
      const data = await response.json();
      if (response.ok) {
        setReplies((prevReplies) =>
          prevReplies.map((reply) => {
            if (reply.replyId === replyId) {
              return { ...reply, upvotes: data.newUpvoteCount };
            }
            return reply;
          })
        );
      } else {
        if (response.status === 403) {
          setError("You have already upvoted this reply");
        } else {
          setError(data.message || "Failed to upvote.");
        }
      }
    } catch (err) {
      setError(err.message);
    }
  };

  // Edit question (if user is owner)
  const handleEditQuestion = async () => {
    const newTitle = prompt("Enter new title", question.title);
    const newText = prompt("Enter new text", question.text);
    if (!newTitle && !newText) {
      return;
    }
    try {
      const headers = { "Content-Type": "application/json" };
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await fetch(`${BASE_URL}/edit-question`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          questionId: question.questionId,
          title: newTitle || undefined,
          text: newText || undefined,
        }),
      });
      const result = await response.json();
      if (response.ok) {
        // Refresh question list
        refreshQuestions();
      } else {
        setError(result.message || "Failed to edit question.");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  // Delete question (if user is owner)
  const handleDeleteQuestion = async () => {
    if (!window.confirm("Are you sure you want to delete this question?")) {
      return;
    }
    try {
      const headers = { "Content-Type": "application/json" };
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await fetch(`${BASE_URL}/delete-question`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          questionId: question.questionId,
        }),
      });
      const result = await response.json();
      if (response.ok) {
        // Refresh question list
        refreshQuestions();
      } else {
        setError(result.message || "Failed to delete question.");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const IMAGE_BASE_URL = "https://genz-staging.feeltiptop.com/media";

  const getFullImageUrl = (imageUrl) => {
    if (!imageUrl) return null;
    if (imageUrl.startsWith("http")) return imageUrl;
    // If the image URL starts with "media/", remove it so it doesn't get added twice.
    if (imageUrl.startsWith("media/")) {
      imageUrl = imageUrl.substring("media/".length);
    }
    return `${IMAGE_BASE_URL}/${imageUrl.replace(/^\/+/, "")}`;
  };

  // Add this helper function to decode JWT and check ownership
  const isQuestionOwner = () => {
    if (!token) return false;
    try {
      // Decode the JWT token (get the payload part)
      const payload = JSON.parse(atob(token.split('.')[1]));
      console.log(payload);
      return payload.sub.user_id === question.userId;
    } catch (e) {
      return false;
    }
  };

  return (
    <>
      <div className="flex flex-col rounded-md bg-white p-4 shadow">
        {/* Title section with delete icon */}
        <div 
          onClick={() => setShowModal(true)} 
          className="cursor-pointer"
        >
          <div className="flex items-center justify-between">
            <h2 className="mb-1 text-lg font-bold text-gray-800">
              {question.title || "Untitled Question"}
            </h2>
            {isQuestionOwner() && (
              <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevent modal from opening
                  if (window.confirm('Are you sure you want to delete this question?')) {
                    handleDeleteQuestion();
                  }
                }}
                className="ml-2 text-red-600 hover:text-red-800"
                title="Delete question"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                </svg>
              </button>
            )}
          </div>

          {/* Question Image */}
          {question.imageUrl && (
            <div className="mb-4">
              <img
                src={getFullImageUrl(question.imageUrl)}
                alt="Question"
                className="rounded-lg object-cover w-full max-h-96"
              />
            </div>
          )}

          {/* Question Content */}
          <div className="mb-2 text-gray-700">
            {question.text}
          </div>
        </div>

        {/* View/Status Info */}
        <p className="mb-2 text-xs text-gray-400">
          Views: {question.views} | Status: {question.status} | User: {question.username} | Upvotes: {question.upvotes ?? 0}
        </p>

        {/* Replies Section */}
        <div className="mt-2">
          <button
            onClick={handleLoadReplies}
            className="text-sm text-blue-600 hover:text-blue-800"
          >
            {showReplies ? "Hide Replies" : "Show Replies"}
          </button>
        </div>

        {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-black bg-opacity-50 p-4">
          <div className="relative max-h-[90vh] w-full max-w-4xl overflow-y-auto rounded-lg bg-white p-6">
            {/* Close button */}
            <button
              onClick={() => setShowModal(false)}
              className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
            >
              <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>

            {/* Modal content */}
            <div className="mt-4">
              <h2 className="mb-4 text-2xl font-bold text-gray-800">
                {question.title}
              </h2>

              {question.imageUrl && (
                <div className="mb-6">
                  <img
                    src={getFullImageUrl(question.imageUrl)}
                    alt="Question"
                    className="rounded-lg object-contain w-full max-h-[60vh]"
                  />
                </div>
              )}

              <div className="mb-4 text-gray-700 whitespace-pre-wrap">
                {question.text}
              </div>

              {question.tags && (
                <div className="mb-4 flex flex-wrap gap-2">
                  {question.tags.split(', ').map((tag) => (
                    <span
                      key={tag}
                      className="rounded-full bg-gray-100 px-3 py-1 text-sm text-gray-600"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              )}

              <div className="text-sm text-gray-500">
                Posted by: {question.username} | 
                Views: {question.views} | 
                Status: {question.status} | 
                Upvotes: {question.upvotes ?? 0}
              </div>
            </div>

            {/* Replies section in modal */}
            <div className="mt-6">
              <button
                onClick={handleLoadReplies}
                className="text-blue-600 hover:text-blue-800"
              >
                {showReplies ? "Hide Replies" : "Show Replies"}
              </button>

              {showReplies && (
                <div className="mt-4 space-y-4">
                  {replies.map((reply) => (
                    <div
                      key={reply.replyId}
                      className="rounded-lg bg-gray-50 p-4"
                    >
                      <div className="flex items-center justify-between">
                        <p className="text-gray-700">{reply.text}</p>
                        <button
                          onClick={() => handleUpvoteReply(reply.replyId)}
                          className="text-blue-600 hover:text-blue-800"
                        >
                          👍 {reply.upvotes}
                        </button>
                      </div>
                      <p className="mt-2 text-sm text-gray-500">
                        By: {reply.username} | {new Date(reply.updatedAt).toLocaleDateString()}
                      </p>
                    </div>
                  ))}

                  {token && (
                    <form onSubmit={handleAddReply} className="mt-4">
                      <textarea
                        value={replyText}
                        onChange={(e) => setReplyText(e.target.value)}
                        className="w-full rounded border p-2"
                        placeholder="Write a reply..."
                        rows="3"
                      ></textarea>
                      <button
                        type="submit"
                        className="mt-2 rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
                      >
                        Add Reply
                      </button>
                    </form>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default QuestionBoard;
