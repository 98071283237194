import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc';
import { encrypttoHEX, decryptfromHEX } from './encodedecode.js';

const protocol = window.location.protocol;
const hostname = window.location.hostname;
const port = window.location.port;
const baseURL = `${protocol}//${hostname}${port ? ":" + port : ""}/`;

const DragHandle = SortableHandle(() => (
  <span className="cursor-move mr-2 text-gray-400 hover:text-gray-600">::</span>
));

const SortableItem = SortableElement(({ asset }) => (
  <div className="flex items-center justify-between p-3 mb-2 bg-white border rounded shadow-sm hover:shadow">
    <div className="flex items-center">
      <DragHandle />
      <span className="text-sm truncate">
        {asset.AssetName}: "{asset.Caption}"
      </span>
    </div>
  </div>
));

const SortableList = SortableContainer(({ items }) => (
  <div className="space-y-2">
    {items.map((asset, index) => (
      <SortableItem key={`item-${asset.AssetID}`} index={index} asset={asset} />
    ))}
  </div>
));

const createObfuscatedFeedURL = (feedID) => {
  const encryptSTR = JSON.stringify({ feedID: feedID });
  const URL = baseURL + "Login?p=" + encrypttoHEX(encryptSTR);
  return URL;
};

export default function SpecificFeedConstruction() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [feed, setFeed] = useState(null);
  const [assets, setAssets] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [saving, setSaving] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [lastSavedAssets, setLastSavedAssets] = useState([]);

  const decodeFeedId = useCallback(() => {
    const encryptedFeedId = searchParams.get('p');
    if (!encryptedFeedId) {
      setError('No feed ID provided. An encoded URL parameter (e.g., ?p=encrypted_string) is required.');
      setLoading(false);
      return null;
    }

    try {
      const decryptedData = decryptfromHEX(encryptedFeedId);
      const feedData = JSON.parse(decryptedData);
      return feedData.feedID;
    } catch (e) {
      console.error('Decryption error:', e);
      setError('Invalid feed URL. The provided encryption code is not valid.');
      setLoading(false);
      return null;
    }
  }, [searchParams]);

  const fetchFeedData = useCallback(async () => {
    try {
      const feedId = decodeFeedId();
      if (!feedId) return;

      const token = localStorage.getItem('authToken');
      const userResponse = await axios.get("https://genz-staging.feeltiptop.com/api/getCurrentUserInfo", {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
  
      const currentUserId = userResponse.data.user_id;
  
      const response = await axios.get(`https://genz-staging.feeltiptop.com/api/feed/${feedId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (response.data.success) {
        const feedData = response.data.data;
        
        if (feedData.CreatorUserID !== currentUserId) {
          setIsAuthorized(false);
          setError('You are not the creator of this feed');
          setLoading(false);
          return;
        }
  
        setIsAuthorized(true);
  
        if (feedData.Status === 'Completed') {
          setError('This feed has already been completed.');
          setLoading(false);
          return;
        }
  
        setFeed(feedData);
        const initialAssets = feedData.assets || [];
        setSelectedAssets(initialAssets);
        setLastSavedAssets(initialAssets);
      } else {
        setError('Feed not found');
      }
    } catch (err) {
      if (err.response?.status === 403) {
        setIsAuthorized(false);
        setError('You are not the creator of this feed');
      } else {
        setError(err.response?.data?.message || 'Error loading feed');
      }
    } finally {
      setLoading(false);
    }
  }, [decodeFeedId]);

  const fetchAvailableAssets = useCallback(async () => {
    try {
      const response = await axios.get('https://genz-staging.feeltiptop.com/api/getAssets', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      setAssets(response.data);
    } catch (err) {
      console.error('Error fetching assets:', err);
      setError('Error loading available assets');
    }
  }, []);

  useEffect(() => {
    fetchFeedData();
    fetchAvailableAssets();
  }, [fetchFeedData, fetchAvailableAssets]);

  // Check for unsaved changes whenever selectedAssets changes
  useEffect(() => {
    const haveDifferentLength = selectedAssets.length !== lastSavedAssets.length;
    const haveDifferentContent = selectedAssets.some((asset, index) => {
      const savedAsset = lastSavedAssets[index];
      return !savedAsset || asset.AssetID !== savedAsset.AssetID;
    });
    
    setHasUnsavedChanges(haveDifferentLength || haveDifferentContent);
  }, [selectedAssets, lastSavedAssets]);

  const handleAddAsset = (asset) => {
    if (selectedAssets.some((a) => a.AssetID === asset.AssetID)) {
      console.warn('Asset is already added:', asset);
      return;
    }
    setSelectedAssets((prev) => [...prev, { ...asset, Position: prev.length + 1 }]);
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      const feedId = decodeFeedId();
      if (!feedId) return;

      const payload = selectedAssets.map((asset, index) => ({
        AssetID: asset.AssetID,
        Position: index + 1,
      }));

      const response = await axios.post(
        `https://genz-staging.feeltiptop.com/api/feed/${feedId}/save`,
        { assets: payload },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.success) {
        setLastSavedAssets(selectedAssets);
        setHasUnsavedChanges(false);
        alert('Feed saved successfully!');
      } else {
        console.error('Save failed:', response.data.message);
        alert('Failed to save feed: ' + response.data.message);
      }
    } catch (err) {
      console.error('Error saving feed:', err.message);
      alert('Error saving feed: ' + err.message);
    } finally {
      setSaving(false);
    }
  };

  const handleComplete = async () => {
    if (hasUnsavedChanges) {
      alert('Please save your changes before completing the feed.');
      return;
    }

    if (window.confirm('Are you sure you want to complete this feed? This action cannot be undone.')) {
      try {
        const feedId = decodeFeedId();
        if (!feedId) return;

        const feedURL = createObfuscatedFeedURL(feedId);

        const response = await axios.post(
          `https://genz-staging.feeltiptop.com/api/feed/${feedId}/complete`,
          { SessionLink: feedURL },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.data.success) {
          setCompleted(true);
          alert('Feed marked as completed!');

          // Call the reward API for the seller after completing the feed
          const rewardResponse = await axios.post(
            `https://genz-staging.feeltiptop.com/api/feed/${feedId}/reward_seller`,
            {SessionLink: feedURL},
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
              },
            }
          );

          if (rewardResponse.data.success) {
            alert('Points Earned!');
          } else {
            alert('Error giving points to seller: ' + rewardResponse.data.message);
          }

          navigate('/AssetReactions');
        }
      } catch (err) {
        console.error('Error completing feed:', err.message);
        alert('Error completing feed: ' + err.message);
      }
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSelectedAssets((prevAssets) => arrayMove(prevAssets, oldIndex, newIndex));
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className="flex items-center justify-center h-screen">
          <div className="text-xl text-gray-600">Loading...</div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="container mx-auto p-4">
          <div className="bg-red-50 border border-red-200 text-red-800 rounded-lg p-4 max-w-2xl mx-auto">
            <div className="text-lg font-semibold mb-2">Error</div>
            <div>{error}</div>
          </div>
        </div>
      );
    }

    if (!isAuthorized) {
      return (
        <div className="container mx-auto p-4">
          <div className="bg-red-50 border border-red-200 text-red-800 rounded-lg p-4 max-w-2xl mx-auto">
            <div className="text-lg font-semibold mb-2">Access Denied</div>
            <div>You are not authorized to view or modify this feed.</div>
          </div>
        </div>
      );
    }

    if (feed?.Status === 'Completed') {
      return (
        <div className="container mx-auto p-4">
          <div className="bg-yellow-50 border border-yellow-200 text-yellow-800 rounded-lg p-4 max-w-2xl mx-auto">
            <div className="text-lg font-semibold mb-2">Feed Completed</div>
            <div>
              This feed has already been marked as completed. Adjustments are no longer allowed.
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="container mx-auto p-4">
        <div className="flex justify-between items-center mb-6">
          <div>
            <h1 className="text-2xl font-bold">{feed?.Name || 'Feed Construction'}</h1>
            {hasUnsavedChanges && (
              <p className="text-orange-500 text-sm mt-1">You have unsaved changes</p>
            )}
          </div>
          <div className="space-x-4">
            <button
              onClick={handleSave}
              disabled={saving || !hasUnsavedChanges}
              className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors ${
                (saving || !hasUnsavedChanges) ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {saving ? 'Saving...' : 'Save'}
            </button>
            <button
              onClick={handleComplete}
              disabled={completed || hasUnsavedChanges}
              className={`px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors ${
                (completed || hasUnsavedChanges) ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              Complete Feed
            </button>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-6">
          <div className="space-y-4">
            <h2 className="text-lg font-semibold">Available Assets</h2>
            <div className="max-h-[400px] overflow-y-auto">
              {assets.map((asset) => (
                <div key={asset.AssetID} className="flex justify-between p-2 border rounded mb-2">
                  <span className="text-sm truncate">{asset.AssetName}: "{asset.Caption}"</span>
                  <button
                    onClick={() => handleAddAsset(asset)}
                    className="px-2 py-1 bg-blue-500 text-white text-sm rounded"
                  >
                    Add
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="space-y-4">
            <h2 className="text-lg font-semibold">Selected Assets</h2>
            <SortableList items={selectedAssets} onSortEnd={onSortEnd} useDragHandle />
          </div>
        </div>
      </div>
    );
  };

  return renderContent();
}