import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  AppBar,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import PaymentHistory from "../components/SellerDashboard/PaymentTab/PaymentHistory";
import RewardOptionsTab from "../components/SellerDashboard/PaymentTab/RewardOptions";

const tabs = ["Reward Options", "Payment History"];

const RewardOptions = () => {
  const [sellerInfo, setSellerInfo] = useState(null);
  const [paymentRequests, setPaymentRequests] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const [currentTab, setCurrentTab] = useState("Reward Options");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // Fetch Seller Data
  const fetchSellerData = async () => {
    try {
      const response = await axios.get(
        "https://genz-staging.feeltiptop.com/api/payment/info",
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        },
      );
      setSellerInfo(response.data.sellerInfo);
      setPaymentRequests(response.data.paymentRequests);
    } catch (error) {
      setMessage("Error fetching seller data. Please try again later.");
    }
  };

  useEffect(() => {
    fetchSellerData();
  }, []);

  // Handle Option Change
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setMessage(""); // Clear any existing message
  };

  const handleChange = (event, newValue) => {
    event.preventDefault();
    console.log(newValue);
    switch (newValue) {
      case 0:
        setCurrentTab("Reward Options");
        break;
      case 1:
        setCurrentTab("Payment History");
        break;
    }
  };
  const handleSubmit = async () => {
    if (!selectedOption) {
      setMessage("Please select a reward option.");
      return;
    }

    setLoading(true);

    try {
      const requestData = { action: selectedOption };

      // If the user selected "store_points", we need to get the points to transfer
      if (selectedOption === "store_points") {
        const pointsToTransfer = prompt(
          "Enter the number of points you want to transfer to your 360 Card:",
        );
        if (
          !pointsToTransfer ||
          isNaN(pointsToTransfer) ||
          pointsToTransfer <= 0
        ) {
          setMessage("Invalid points amount.");
          return;
        }
        requestData.points = parseInt(pointsToTransfer);
      }

      const response = await axios.post(
        "https://genz-staging.feeltiptop.com/api/payment/reward-options",
        requestData,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        },
      );

      setMessage(response.data.message);

      // Refresh seller data after reward action
      fetchSellerData();
    } catch (error) {
      setMessage(
        error.response?.data?.message ||
          "An error occurred while processing your request.",
      );
    } finally {
      setLoading(false);
    }
  };

  if (!sellerInfo) {
    return (
      <div className="container mx-auto p-4 text-center text-black">
        Loading seller information...
      </div>
    );
  }

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "start", mb: 4 }}>
        <h1
          variant="h4"
          className="w-full text-2xl font-semibold lg:text-start"
        >
          Payment Tab
        </h1>
      </Box>
      <AppBar position="static" color="default">
        {isMobile ? (
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
            >
              {tabs.map((index, page) => (
                <MenuItem key={index}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        ) : (
          <Tabs
            value={currentTab === "Reward Options" ? 0 : 1}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="payment tabs"
          >
            {tabs.map((tab) => (
              <Tab key={tab} label={tab} />
            ))}
          </Tabs>
        )}
      </AppBar>

      <div className="w-full rounded-lg bg-gradient-to-r from-gray-50 to-gray-100">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          {currentTab === "Reward Options" && (
            <RewardOptionsTab
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              handleSubmit={handleSubmit}
              loading={loading}
              handleOptionChange={handleOptionChange}
            />
          )}

          {currentTab === "Payment History" && (
            <PaymentHistory paymentRequests={paymentRequests} />
          )}
        </div>

        {message && <p className="mt-6 text-center text-black">{message}</p>}
      </div>
    </>
  );
};

export default RewardOptions;
