import React, { useEffect, useState } from 'react';
import useToken from './useToken';
import axios from 'axios';
import { Navigate, Outlet } from 'react-router-dom';

const SubscriptionGuard = () => {
  const { token } = useToken();
  const [subscriptionExpired, setSubscriptionExpired] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      if (!token) return; // Exit if token is null or undefined
      try {
        const response = await axios.get('https://genz-staging.feeltiptop.com/api/payment/subscriptionExpired', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSubscriptionExpired(response.data.subscription_expired);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    checkSubscriptionStatus();
  }, [token]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  if (subscriptionExpired) {
    return <Navigate to="/subscription" replace />;
  }

  return <Outlet />; // Proceed to child routes if subscription is valid
};

export default SubscriptionGuard;