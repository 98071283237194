import React, { useEffect, useState } from 'react';
import useToken from './useToken';
import axios from 'axios';
import { Navigate, Outlet } from 'react-router-dom';

function SellerGuard() {
  const { token } = useToken();
  const [isSeller, setIsSeller] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!token) {
      setIsSeller(false);
      setLoading(false);
      return;
    }

    const checkSellerStatus = async () => {
      try {
        const response = await axios.get('https://genz-staging.feeltiptop.com/api/payment/SellerGuard', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success && response.data.card_type.toLowerCase() === 'seller') {
          setIsSeller(true);
        } else {
          setIsSeller(false);
          setError(response.data.message || 'Access Denied: You are not a seller.');
        }
      } catch (err) {
        setError('Error fetching seller status');
        setIsSeller(false);
      } finally {
        setLoading(false);
      }
    };

    checkSellerStatus();
  }, [token]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!isSeller) return <Navigate to="/subscription" replace />; // Redirect if not a seller

  return <Outlet />; // Proceed to child routes if the user is a seller
}

export default SellerGuard;