import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const SubscriptionStatus = () => {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const isTestMode = process.env.REACT_APP_TEST_MODE === "true";

  useEffect(() => {
    fetchSubscription();
    const params = new URLSearchParams(location.search);
    if (params.get("subscription_updated") === "true") {
      alert("Subscription updated successfully! Fetching updated status...");
      fetchSubscription();
    }
  }, [location]);

  const fetchSubscription = async () => {
    try {
      const response = await axios.get(
        "https://genz-staging.feeltiptop.com/api/payment/subscription",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setSubscription(response.data.data);
    } catch (err) {
      setError(
        err.response?.data?.message || "Error fetching subscription data",
      );
    } finally {
      setLoading(false);
    }
  };

  const handleRenewSubscription = async () => {
    setLoading(true); // Set loading state at the beginning of the function

    if (isTestMode) {
      // Bypass checkout session in test mode
      alert(
        "In test mode, the checkout session is bypassed. Subscription renewed without payment.",
      );
      await updateSubscription();
      window.location.reload(); // Reload the page after the update
      return;
    }

    const token = localStorage.getItem("token");

    try {
      // Fetch user data to get the user ID
      const userDataResponse = await axios.get(
        "https://genz-staging.feeltiptop.com/api/getUserData",
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      const { userID } = userDataResponse.data; // Extract user ID

      const response = await axios.post(
        "https://genz-staging.feeltiptop.com/api/payment/create-checkout-session",
        {
          user_id: userID, 
          payment_amount: 30,
          transaction_type: "sub",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.data && response.data.id) {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: response.data.id,
        });
        if (error) {
          console.error("Error redirecting to Stripe Checkout:", error);
          alert(
            "An error occurred while redirecting to Stripe Checkout. Please try again.",
          );
        }
      } else {
        console.error(
          "Checkout session ID not found in response:",
          response.data,
        );
      }
    } catch (error) {
      console.error(
        "Error creating checkout session:",
        error.response ? error.response.data : error.message,
      );
      alert(
        "An error occurred while creating the checkout session. Please try again.",
      );
    } finally {
      setLoading(false); // Set loading state to false at the end of the function
    }
  };

  const updateSubscription = async () => {
    const token = localStorage.getItem("token");
    try {
      // Fetch user data to get the user ID
      const userDataResponse = await axios.get(
        "https://genz-staging.feeltiptop.com/api/getUserData",
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      const { userID } = userDataResponse.data; // Extract user ID

      const response = await axios.post(
        "https://genz-staging.feeltiptop.com/api/payment/test-subscription",
        { userID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (err) {
      console.error("Error during subscription update:", err.response?.data); // Debug log
      setError(
        err.response?.data?.message || "Error updating subscription data",
      );
    } finally {
      setLoading(false);
    }
  };

  if (loading)
    return (
      <p className="mt-8 text-center text-lg font-semibold text-gray-600">
        Loading...
      </p>
    );

  if (error)
    return (
      <p className="mt-8 text-center text-lg font-semibold text-red-600">
        {error}
      </p>
    );

  return (
    <div className="mx-auto max-w-2xl rounded-lg bg-white p-8">
      <h2 className="mb-6 text-center text-3xl font-bold text-gray-800">
        Subscription Status
      </h2>
      <div className="space-y-4 text-gray-700">
        <p className="text-lg">
          <strong>Last Subscribed:</strong>{" "}
          <span className="text-gray-600">
            {new Date(subscription.CreationTime).toLocaleString("en-US", {
              timeZone: "UTC",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: false,
            })}{" "}
            UTC
          </span>
        </p>
        <p className="text-lg">
          <strong>Expiration Date:</strong>{" "}
          <span className="text-gray-600">
            {new Date(subscription.ExpirationTime).toLocaleString("en-US", {
              timeZone: "UTC",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: false,
            })}{" "}
            UTC
          </span>
        </p>
        <p className="text-lg font-semibold">
          <strong>Status:</strong>{" "}
          <span
            className={
              subscription.Status === "expired"
                ? "text-red-600"
                : "text-green-600"
            }
          >
            {subscription.Status.charAt(0).toUpperCase() +
              subscription.Status.slice(1)}
          </span>
        </p>
        <p className="text-lg">
          <strong>Days Remaining:</strong>{" "}
          <span className="text-gray-600">
            {subscription.DaysRemaining} days
          </span>
        </p>
      </div>
      <div className="mt-6">
        <button
          onClick={handleRenewSubscription}
          className={`w-full rounded-lg bg-blue-600 px-6 py-3 text-lg font-semibold text-white shadow-md transition duration-200 hover:bg-blue-700 ${loading ? "cursor-not-allowed opacity-50" : ""}`}
          disabled={loading}
        >
          {loading ? "Renewing..." : "Renew Subscription for $30"}
        </button>
      </div>
      <p className="mt-4 text-center text-sm text-gray-500">
        Renewal extends your subscription by one month from the current
        expiration date.
      </p>
    </div>
  );
};

export default SubscriptionStatus;
