import React, { useState, useEffect } from "react";
import Semester from "./Semester";
import { DragDropContext } from "react-beautiful-dnd";
import { FaTrash } from "react-icons/fa";

const YearTabs = ({ classes, coursePlan, setCoursePlan, userId, initialYears = [1] }) => {
  const [years, setYears] = useState(initialYears);
  const [activeYear, setActiveYear] = useState(initialYears[0]);
  const [plans, setPlans] = useState([]);
  const [activePlanId, setActivePlanId] = useState(null);

  // Fetch all plans on component mount
  useEffect(() => {
    fetchPlans();
  }, [userId]);

  const fetchPlans = async () => {
    try {
      const response = await fetch(`https://genz-staging.feeltiptop.com/api/user-plans/${userId}`);
      const data = await response.json();
      if (data.plans && data.plans.length > 0) {
        setPlans(data.plans);
        setActivePlanId(data.plans[0].id);
        setCoursePlan(data.plans[0].courses);
      } else {
        // Create default plan if no plans exist
        handleAddPlan("Default Plan", true);
      }
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };

  const handleAddPlan = async (suggestedName = null, isDefault = false) => {
    const planName = suggestedName || prompt("Enter a name for the new plan:");
    if (planName) {
      try {
        const response = await fetch(`https://genz-staging.feeltiptop.com/api/course-plans`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            user_id: userId,
            plan_name: planName,
            courses: [],
            is_default: isDefault
          }),
        });
        const newPlan = await response.json();
        
        // Create a properly structured plan object
        const planObject = {
          id: newPlan.id,
          name: planName,
          courses: []
        };
        
        // Update local state
        setPlans([...plans, planObject]);
        setActivePlanId(newPlan.id);
        setCoursePlan([]);
        
        // Reset years to initial state for new plan
        setYears(initialYears);
        setActiveYear(initialYears[0]);
      } catch (error) {
        console.error("Error creating new plan:", error);
      }
    }
  };

  const handleDeletePlan = async (planId) => {
    if (plans.length <= 1) {
      alert("You must have at least one plan!");
      return;
    }

    if (confirm("Are you sure you want to delete this plan?")) {
      try {
        await fetch(`https://genz-staging.feeltiptop.com/api/course-plans/${planId}`, {
          method: "DELETE",
        });
        
        const updatedPlans = plans.filter(p => p.id !== planId);
        setPlans(updatedPlans);
        
        if (planId === activePlanId) {
          setActivePlanId(updatedPlans[0].id);
          setCoursePlan(updatedPlans[0].courses);
        }
      } catch (error) {
        console.error("Error deleting plan:", error);
      }
    }
  };

  const handlePlanChange = (planId) => {
    const selectedPlan = plans.find(p => p.id === Number(planId));
    setActivePlanId(selectedPlan.id);
    setCoursePlan(selectedPlan.courses);
  };

  const updateLocalPlan = (updatedCourses) => {
    // Update the local plans state
    const updatedPlans = plans.map(plan => 
      plan.id === activePlanId 
        ? { ...plan, courses: updatedCourses }
        : plan
    );
    setPlans(updatedPlans);
  };

  const savePlanToBackend = async (updatedCourses) => {
    try {
      await fetch(`https://genz-staging.feeltiptop.com/api/course-plans/${activePlanId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user_id: userId,
          courses: updatedCourses,
        }),
      });
      // Update local state after successful save
      updateLocalPlan(updatedCourses);
    } catch (error) {
      console.error("Error saving plan:", error);
    }
  };

  const handleDeleteYear = (yearToDelete) => {
    if (years.length <= 1) return;
    setYears(years.filter(year => year !== yearToDelete));
    const updatedPlan = coursePlan.filter(course => course.year !== yearToDelete);
    setCoursePlan(updatedPlan);
    savePlanToBackend(updatedPlan);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const updatedPlan = [...coursePlan];
    updatedPlan.push({
      classId: parseInt(result.draggableId, 10),
      year: parseInt(result.destination.droppableId.split("-")[1], 10),
      semester: result.destination.droppableId.split("-")[0],
    });
    setCoursePlan(updatedPlan);
    savePlanToBackend(updatedPlan);
  };

  const handleDrop = (e, year, semester) => {
    e.preventDefault();
    const courseData = JSON.parse(e.dataTransfer.getData("application/json"));
    
    const courseExists = coursePlan.some(
      plan => plan.classId === courseData.id && 
              plan.year === year && 
              plan.semester === semester
    );

    if (courseExists) return;

    const newPlanEntry = {
      classId: courseData.id,
      year: year,
      semester: semester,
      className: courseData.className,
      classNumber: courseData.classNumber
    };

    const updatedPlan = [...coursePlan, newPlanEntry];
    setCoursePlan(updatedPlan);
    savePlanToBackend(updatedPlan);
  };

  const handleRemoveCourse = (classId, semesterId) => {
    const [semester, year] = semesterId.split("-");
    const updatedPlan = coursePlan.filter(
      course => !(
        course.classId === classId &&
        course.year === parseInt(year) &&
        course.semester === semester
      )
    );
    setCoursePlan(updatedPlan);
    savePlanToBackend(updatedPlan);
  };

  const handleAddYear = () => {
    const nextYear = Math.max(...years) + 1;
    setYears([...years, nextYear]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // Update the return statement to include plan selection UI
  return (
    <div>
      <div className="mb-4">
        <div className="flex gap-4 mb-4 items-center">
          <select 
            value={activePlanId || ''}
            onChange={(e) => handlePlanChange(Number(e.target.value))}
            className="rounded border p-2"
          >
            {plans.map(plan => (
              <option key={plan.id} value={plan.id}>
                {plan.name}
              </option>
            ))}
          </select>
          <button
            onClick={() => handleAddPlan()}
            className="rounded bg-purple-500 px-4 py-2 text-white hover:bg-purple-600"
          >
            + New Plan
          </button>
          {plans.length > 1 && (
            <button
              onClick={() => handleDeletePlan(activePlanId)}
              className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-600"
            >
              Delete Plan
            </button>
          )}
        </div>
        <div className="mb-4 flex gap-4">
          {years.map((year) => (
            <div key={year} className="flex flex-col items-center gap-2">
              <button 
                className={`rounded px-4 py-2 text-white ${
                  activeYear === year ? 'bg-blue-700' : 'bg-blue-500'
                }`}
                onClick={() => setActiveYear(year)}
              >
                Year {year}
              </button>
              {years.length > 1 && (
                <button
                  onClick={() => handleDeleteYear(year)}
                  className="text-red-500 hover:text-red-700"
                  title="Delete Year"
                >
                  <FaTrash />
                </button>
              )}
            </div>
          ))}
          <button
            onClick={handleAddYear}
            className="rounded bg-green-500 px-4 py-2 text-white"
          >
            + Add Year
          </button>
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="mb-6">
          <h3 className="mb-2 text-lg font-semibold">Year {activeYear}</h3>
          <div className="flex gap-8">
            <Semester
              semesterId={`Fall-${activeYear}`}
              classes={classes}
              courses={coursePlan.filter(
                (course) =>
                  course.year === activeYear && course.semester === "Fall"
              )}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, activeYear, "Fall")}
              onRemoveCourse={handleRemoveCourse}
            />
            <Semester
              semesterId={`Spring-${activeYear}`}
              classes={classes}
              courses={coursePlan.filter(
                (course) =>
                  course.year === activeYear && course.semester === "Spring"
              )}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, activeYear, "Spring")}
              onRemoveCourse={handleRemoveCourse}
            />
          </div>
        </div>
      </DragDropContext>
    </div>
  );
};

export default YearTabs;
