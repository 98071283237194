import React, { useState } from "react";
// These imports would work after installing:
// npm install @mui/material @emotion/react @emotion/styled

// Material UI components
import {
  Box,
  Typography,
  Button,
  TextField,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tabs,
  Tab,
  Grid,
  Paper,
  Container,
} from "@mui/material";

// This would normally import Material UI icons
// import { AccountCircle, Lock, Notifications, Language, DarkMode, CreditCard, Help } from '@mui/icons-material';
// Instead we'll use a placeholder for the icons
const Icon = ({ type }) => {
  const getIcon = () => {
    switch (type) {
      case "account":
        return "👤";
      case "privacy":
        return "🔒";
      case "notifications":
        return "🔔";
      case "language":
        return "🌐";
      case "appearance":
        return "🌙";
      case "billing":
        return "💳";
      case "help":
        return "❓";
      default:
        return "⚙️";
    }
  };

  return (
    <span style={{ fontSize: "1.25rem", marginRight: "8px" }}>{getIcon()}</span>
  );
};

// Tab Panel component for the tabbed interface
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

// Main Settings Page Component
const SettingsPage = () => {
  const [tabValue, setTabValue] = useState(0);
  const [selectedMenuItem, setSelectedMenuItem] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const menuItems = [
    { icon: "account", label: "Account" },
    { icon: "privacy", label: "Privacy & Security" },
    { icon: "notifications", label: "Notifications" },
    { icon: "language", label: "Language & Region" },
    { icon: "appearance", label: "Appearance" },
    { icon: "billing", label: "Billing" },
    { icon: "help", label: "Help & Support" },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Box sx={{ display: "flex", flexGrow: 1, overflow: "hidden" }}>
        {/* Sidebar */}
        <Paper
          elevation={4}
          sx={{
            width: "auto",
            flexShrink: 0,
            height: "100%",
            overflow: "auto",
            borderRadius: 0,
          }}
        >
          <List component="nav" aria-label="settings navigation">
            {menuItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem
                  disablePadding
                  sx={{ borderRadius: 0, width: "250px" }}
                >
                  <ListItemButton
                    selected={selectedMenuItem === index}
                    onClick={() => setSelectedMenuItem(index)}
                    sx={{
                      py: 1.5,
                      "&.Mui-selected": {
                        backgroundColor: "primary.light",
                        color: "primary.main",
                        "&:hover": {
                          backgroundColor: "primary.light",
                        },
                      },
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      <Icon type={item.icon} />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ fontSize: "20px" }}
                      primary={item.label}
                    />
                  </ListItemButton>
                </ListItem>
                {index < menuItems.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Paper>

        {/* Main Content */}
        <Box
          sx={{
            flexGrow: 1,
            p: 3,
            overflow: "auto",
            height: "100%",
            backgroundColor: "background.default",
          }}
        >
          <Card
            elevation={1}
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CardContent sx={{ flexGrow: 1, overflow: "auto" }}>
              <Typography variant="h5" component="h2" gutterBottom>
                Account Settings
              </Typography>

              <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="settings tabs"
                >
                  <Tab label="General" id="settings-tab-0" />
                  <Tab label="Profile" id="settings-tab-1" />
                  <Tab label="Security" id="settings-tab-2" />
                </Tabs>
              </Box>

              {/* General Tab */}
              <TabPanel value={tabValue} index={0}>
                <Box component="form" noValidate sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="displayName"
                    label="Name"
                    name="displayName"
                    autoComplete="name"
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    id="phone"
                    label="Phone Number"
                    name="phone"
                    autoComplete="tel"
                  />
                  <Box sx={{ mt: 3 }}>
                    <Button variant="contained" color="primary">
                      Save Changes
                    </Button>
                  </Box>
                </Box>
              </TabPanel>

              {/* Profile Tab */}
              <TabPanel value={tabValue} index={1}>
                <Box component="form" noValidate sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="bio"
                    label="Bio"
                    name="bio"
                    multiline
                    rows={4}
                    placeholder="Tell us about yourself..."
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    id="location"
                    label="Location"
                    name="location"
                    placeholder="City, Country"
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    id="website"
                    label="Website"
                    name="website"
                    placeholder="https://example.com"
                  />
                  <Box sx={{ mt: 3 }}>
                    <Button variant="contained" color="primary">
                      Update Profile
                    </Button>
                  </Box>
                </Box>
              </TabPanel>

              {/* Security Tab */}
              <TabPanel value={tabValue} index={2}>
                <Box component="form" noValidate sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="currentPassword"
                    label="Current Password"
                    type="password"
                    id="currentPassword"
                    autoComplete="current-password"
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="newPassword"
                    label="New Password"
                    type="password"
                    id="newPassword"
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm New Password"
                    type="password"
                    id="confirmPassword"
                  />
                  <Box sx={{ mt: 3 }}>
                    <Button variant="contained" color="primary">
                      Change Password
                    </Button>
                  </Box>

                  <Divider sx={{ my: 4 }} />

                  <Box sx={{ mt: 4 }}>
                    <Typography
                      variant="h6"
                      component="h3"
                      color="error"
                      gutterBottom
                    >
                      Danger Zone
                    </Typography>
                    <Paper
                      variant="outlined"
                      sx={{
                        p: 2,
                        borderColor: "error.light",
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        alignItems: { sm: "center" },
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ mb: { xs: 2, sm: 0 } }}>
                        <Typography variant="subtitle1" component="p">
                          Delete Account
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Once deleted, your account cannot be recovered.
                        </Typography>
                      </Box>
                      <Button variant="contained" color="error">
                        Delete Account
                      </Button>
                    </Paper>
                  </Box>
                </Box>
              </TabPanel>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default SettingsPage;
