import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SnackBar from "../components/UX/SnackBar";

const SubmitFeedComponent = ({ feedID, userID, token, setToken }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    console.log("Feed ID: ", feedID);
  }, []);

  const allAssetsAreCompleted = async () => {
    try {
      const payload = {
        userID: userID,
        feedID: feedID,
      };

      const res = await fetch(
        "https://genz-staging.feeltiptop.com/api/check_all_assets_for_survey",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        },
      );

      const data = await res.json();
      if (data.sucess) {
        return data.isAllAssetsAnswered;
      }
    } catch (error) {
      console.error("Error submitting poll response:", error);
      return false;
    }
  };

  const submitFeed = async (choice) => {
    setIsSubmitting(true);
    setError(null);
    if (!(await allAssetsAreCompleted())) {
      setError("Please complete all assets before submitting.");
      setIsSubmitting(false);
      return;
    }

    try {
      const payload = {
        userID: userID,
        feedID: feedID,
      };

      const response = await fetch(
        "https://genz-staging.feeltiptop.com/api/user/completeFeedUserStatus",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        },
      );

      const data = await response.json();

      if (data.access_token) {
        setToken(data.access_token);
      }

      if (data.message === "already-completed") {
        navigate("/thankyou", {
          replace: true,
          state: { text: "You have already completed the survey!" },
        });
      }

      if (data.message === "success") {
        setMessage(data.message);
        navigate("/thankyou", {
          replace: true,
          state: {
            text: "You have successfully completed the survey!",
          },
        });

        return true;
      } else {
        console.error("Server responded with failure:", data.message);
        return false;
      }
    } catch (error) {
      console.error("Error submitting poll response:", error);

      return false;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="mx-auto w-full max-w-2xl p-6 font-sans">
      <button
        onClick={submitFeed}
        className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
      >
        Complete Survey
      </button>
      {message && <SnackBar message={message} type="success" />}
      {error && <SnackBar message={error} type="error" />}
    </div>
  );
};

export default SubmitFeedComponent;
