import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import logo from "../assets/images/GenZ_Logo.svg";
import "../assetFormStyle.css";
import Quiz from "../components/Quiz";
import Poll from "../components/assets/Poll";
import Text from "../components/assets/Text/Text";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

function AssetCreate(props) {
  const [assetName, setAssetName] = useState("");
  const [assetType, setAssetType] = useState("");
  const [caption, setCaption] = useState("");
  const [media, setMedia] = useState("");
  const [mediaUrl, setMediaUrl] = useState("");
  const [mediaType, setMediaType] = useState(""); // "file" or "link"
  const [brandId, setBrandId] = useState("");
  const [allBrands, setAllBrands] = useState([]);
  const [generateAssestOption, setGenerateAssestOption] = useState("");
  const [quizOptions, setQuizOptions] = useState(null);
  const [quizsSelected, setQuizesSelected] = useState([]);
  const [poll, setPoll] = useState(null);
  const [textContent, setTextContent] = useState("");
  const [quizUrl, setQuizUrl] = useState("");
  const [audioText, setAudioText] = useState("");
  const [TTSLoading, setTTSLoading] = useState(false)

  const state = useLocation();

  const [topic, setTopic] = useState("");
  const [t, setT] = useState("");

  const [user, setUser] = useState("");

  const [quizData, setQuizData] = useState({
    questionNumber: 0,
    language: "English",
    newsType: "",
    type: 1,
    mainTopic: "",
    subTopic: "",
    timeFrame: "",
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // console.log(quizData);
  }, [quizData]);

  useEffect(() => {
    const token = localStorage.getItem("authToken");

    axios
      .get("https://genz-staging.feeltiptop.com/api/getCurrentUserInfo", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUser(response.data.username);
      })
      .catch((error) => {
        console.error("Error fetching user info:", error);
        // Dont want to be showing external users extra un-needed info
        //if (error.response) {
          //console.error("Response data:", error.response.data);
          //console.error("Response status:", error.response.status);
          //console.error("Response headers:", error.response.headers);
        //}
      });
    axios
      .get("https://genz-staging.feeltiptop.com/api/getBrands", {
        headers: {
          Authorization: "Bearer " + props.token,
        },
      })
      .then((response) => {
        let tmpbrands = response.data.map((opt) => ({
          label: opt[1],
          value: opt[0],
        }));
        setAllBrands(tmpbrands);
      })
      .catch((error) => {
        console.error(error);
      });

    if (state && state.message) {
      setError(state.message);
    }
  }, []);

  const handleGenerateAssestOptionChange = (generateAssestOptionEvent) => {
    setGenerateAssestOption(generateAssestOptionEvent.target.value);
  };

  const handleAssetTypeChange = (event) => {
    setAssetType(event.target.value);
  };

  const handleCaptionChange = (event) => {
    setCaption(event.target.value);
  };

  const handleMediaTypeChange = (event) => {
    setMediaType(event.target.value);
    setMedia("");
    setMediaUrl("");
  };

  const handleMediaChange = (event) => {
    const file = event.target.files[0];
    if (!assetType) {
      setError("Please choose an Asset Type before selecting media.");
      return;
    }

    if (assetType === "media") {
      setError("");
      const reader = new FileReader();
      reader.onload = (e) => {
        setMedia(e.target.result);
      };
      reader.readAsDataURL(file);

      // console.log("File type:", file.type);
      // console.log("File name:", file.name);
    } else {
      setError("Invalid file type. Please select a valid media file.");
      setMedia(null);
    }
  };

  const handleMediaUrlChange = (event) => {
    setMediaUrl(event.target.value);
  };

  const handleAudioTextChange = (event) => {
    setAudioText(event.target.value);
  };

  const handleAssetNameChange = (event) => {
    setAssetName(event.target.value);
  };

  const handleBrandChange = (selectedOption) => {
    setBrandId(selectedOption.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!assetType || assetType === "") {
      setError("Please select an Asset Type.");
      return;
    }

    if (!brandId) {
      setError("Please select a Brand.");
      return;
    }

    let preContent;
    if (assetType === "text") {
      preContent = {
        assetType: assetType,
        html: textContent,
      };
    } else if (assetType === "poll") {
      preContent = {
        assetType: assetType,
        question: poll.question,
        selections: poll.selections,
        answer: poll.answer,
        pollType: poll.pollType,
      };
    } else if (assetType === "auto-asset" && generateAssestOption === "quiz") {
      preContent = {
        assetType: "quiz",
        quizzes: quizsSelected,
        apiCall: quizUrl,
      };
      //console.log(preContent);

      for (let i = 0; i < quizsSelected.length; i++) {
        const assetData = {
          AssetName: assetName,
          AssetType: assetType,
          Caption: caption,
          AssetContent: JSON.stringify({
            assetType: generateAssestOption,
            question: quizsSelected[i].question,
            ...(quizsSelected[i].type.includes("True or False") && {
              selections: { A: "True", B: "False" },
            }),
            ...(quizsSelected[i].type.includes("Multiple Choice") && {
              selections: quizsSelected[i].selections,
            }),
            ...(quizsSelected[i].type.includes("Fill in the blank") && {
              selections: { A: "Fill in the blank" },
            }),
            answer: quizsSelected[i].answer,
            apiCall: quizUrl,
          }),
          BrandID: brandId,
        };
        axios
          .post("https://genz-staging.feeltiptop.com/api/addAsset", assetData, {
            headers: {
              Authorization: "Bearer " + props.token,
            },
          })
          .then((response) => {
            //console.log("Asset created successfully!");
            //console.log(response.asset_id);
            // alert(response.asset_id + "created successfully");
          })
          .catch((error) => {
            console.error("Error creating asset:", error);
            console.error("Full response:", error.response);
            if (error.response && error.response.data.message) {
              setError(error.response.data.message);
            } else {
              setError("Error creating asset: " + error.message);
            }
          });
      }
      alert("Assets created successfully!");

      navigate(0);
      return;
    } else if (assetType === "media") {
      //console.log("MEdia Asset")
      preContent = {
        assetType: "media",
        mediaType: mediaType,
        mediaContent: mediaType.includes("file") ? media : mediaUrl,
      };
      //console.log(preContent)
      //console.log(media)
      //console.log(mediaUrl)
    }

    const assetData = {
      AssetName: assetName,
      AssetType: assetType,
      Caption: caption,
      AssetContent: JSON.stringify(preContent),
      BrandID: brandId,
      MediaType: assetType === "media" ? mediaType : "",
      MediaLink: assetType === "media" && mediaType === "link" ? mediaUrl : "",
      MediaUpload: assetType === "media" && mediaType.includes("file") ? media : "",
    };

    //("Form Data:", assetData);
    axios
      .post("https://genz-staging.feeltiptop.com/api/addAsset", assetData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      })
      .then((response) => {
        alert("Asset # " + response.data.asset_id + " created successfully!");
        navigate(0);
      })
      .catch((error) => {
        console.error("Error creating asset:", error);
        console.error("Full response:", error.response);
        if (error.response && error.response.data.message) {
          setError(error.response.data.message);
        } else {
          setError("Error creating asset: " + error.message);
        }
      });
  };

  const generateAssests = (event, setIsloading) => {
    event.preventDefault();

    if (generateAssestOption === "quiz") {
      if (quizOptions && quizsSelected) {
        setQuizOptions(null);
        setQuizesSelected([]);
      }
      setIsloading(true);
      //console.log("Quiz Data", quizData);

      axios
        .get("https://genz-staging.feeltiptop.com/api/quizApi", {
          timeout: 600000,
          params: {
            questionNumber: quizData.questionNumber,
            language: quizData.language,
            newsType: quizData.newsType,
            quizType: quizData.type,
            mainTopic: quizData.mainTopic,
            subTopic: quizData.subTopic,
            timeFrame: quizData.timeFrame,
          },
        })
        .then((response) => {
          const { url, quizData } = response.data;
          //console.log("Quiz Data:", quizData);
          setQuizUrl(url);
          //console.log("Quiz URL:", url);
          setQuizOptions(quizData);
          setIsloading(false);
          setQuizesSelected(quizData);
        })
        .catch((error) => {
          setError(
            "An error occured when fetching your asset. Please try again later.",
          );
          setIsloading(false);
        });
    }
  };

  const handleReset = () => {
    setAssetName("");
    setAssetType("");
    setCaption("");
    setMedia("");
    setMediaUrl("");
    setMediaType("");
    setBrandId("");
    setError("");
  };

  const generateVideo = async (type, seach) => {
    try {
      let response = await axios.get(
        "https://genz-staging.feeltiptop.com/api/generate_teaser_video",
        {
          params: { vidType: type, search: seach },
        },
      );
      //console.log("Custom video URL:", response.data);
      setMediaUrl(response.data);
    } catch (error) {
      console.error("Error fetching video:", error);
    }
  };

  const generateTTS = async (audioText) => {
    setTTSLoading(true)
    try {
      let response = await axios.get(
        "https://feeltiptop.com/demos/videogen/MSSpeech.php?usetiptoptts=1&txt=" + encodeURI(audioText), {
        responseType: 'blob' }
      );
      //console.log("Audio Response: ", response);
      const audioBlob = new Blob([response.data], { type: 'audio/wav' });
      const audioFile = new File([audioBlob], 'audio.wav', { type: 'audio/wav' });
      handleMediaChange({ target: { files: [audioFile] } });

      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
      audio.play();
      setTTSLoading(false)
    } catch (error) {
      console.error("Error fetching TTS Audio:", error);
      setTTSLoading(false)
    }
  };

  return (
    <div className="AssetForm">
      <header className="AssetForm-header">
        <img src={logo} className="Ambassco-logo" alt="logo" />
        <div className="formTitle">Asset Generation Form</div>
      </header>

      <div>Hello {user}</div>

      <form onSubmit={handleSubmit}>
        <label htmlFor="assetName">Asset Name:</label>
        <input
          type="text"
          id="assetName"
          name="assetName"
          onChange={handleAssetNameChange}
          required
        />
        <br />

        <label htmlFor="assetType">Select Asset Type:</label>
        <select
          id="assetType"
          name="assetType"
          onChange={handleAssetTypeChange}
        >
          <option value="">Select Type...</option>
          <option value="media">Media</option>
          <option value="poll">Poll</option>
          <option value="text">Text</option>
          <option value="auto-asset">Auto Asset</option>
        </select>
        <br />

        <label htmlFor="caption">Caption:</label>
        <textarea id="caption" name="caption" onChange={handleCaptionChange} />
        <br />
        {assetType === "auto-asset" ? (
          <>
            <select
              id="autoAsset"
              name="autoAsset"
              onChange={handleGenerateAssestOptionChange}
            >
              <option value="Default">Select Option..</option>
              <option value="quiz">Quiz</option>
            </select>

            {generateAssestOption === "quiz" && (
              <>
                <Quiz
                  setQuizData={setQuizData}
                  generateAssests={generateAssests}
                  quizOptions={quizOptions}
                  setQuizesSelected={setQuizesSelected}
                  quizsSelected={quizsSelected}
                />

                {/* {error.length !== 0 && <h1>{error}</h1>} */}
              </>
            )}
          </>
        ) : assetType === "poll" ? (
          <Poll setPoll={setPoll}></Poll>
        ) : assetType === "text" ? (
          <Text
            textContent={textContent}
            setTextContent={setTextContent}
          ></Text>
        ) : assetType === "media" ? (
          <div>
            <label>
              <input
                type="radio"
                value="file"
                checked={mediaType === "file"}
                onChange={handleMediaTypeChange}
              />{" "}
              Upload File
            </label>
            <label>
              <input
                type="radio"
                value="link"
                checked={mediaType === "link"}
                onChange={handleMediaTypeChange}
              />{" "}
              Provide Link
            </label>
            <label>
              <input
                type="radio"
                value="file-audio"
                checked={mediaType === "file-audio"}
                onChange={handleMediaTypeChange}
              />{" "}
              Text-to-Speech Audio
            </label>

            {mediaType === "file" && (
              <div>
                <label htmlFor="mediaFile">
                  {media ? (
                    <img src={media} alt="Media File" className="mediaInput" />
                  ) : (
                    "Select Media File:"
                  )}
                </label>
                <input
                  type="file"
                  id="mediaFile"
                  name="mediaFile"
                  accept="image/*, video/*, audio/*"
                  onChange={handleMediaChange}
                />
              </div>
            )}

            {mediaType === "file-audio" && (
              <div>
                <button
                  onClick={() => generateTTS(caption)}
                  type="button"
                  disabled={!caption || TTSLoading}
                >
                  {TTSLoading ? "Generating..." : "Generate Audio TTS"}
                </button>
              </div>
            )}

            {mediaType === "link" && (
              <div>
                <label htmlFor="mediaUrl">Enter media URL:</label>
                <input
                  type="text"
                  id="mediaUrl"
                  name="mediaUrl"
                  onChange={handleMediaUrlChange}
                  placeholder="Enter media URL"
                  value={mediaUrl}
                />
                <div>
                  <label htmlFor="topic">Enter a topic:</label>
                  <input
                    type="text"
                    id="topic"
                    name="topic"
                    onChange={(e) => setTopic(e.target.value)}
                    placeholder="Topic"
                  />
                  <button
                    onClick={() => generateVideo("topic", topic)}
                    type="button"
                  >
                    Generate Video
                  </button>
                  <label htmlFor="topic">Enter a T:</label>
                  <input
                    type="text"
                    id="T"
                    name="T"
                    onChange={(e) => setT(e.target.value)}
                    placeholder="T"
                  />
                  <button onClick={() => generateVideo("T", t)} type="button">
                    Generate Video
                  </button>
                </div>

                <div>
                  <a href={mediaUrl} target="_blank" rel="noreferrer">
                    {mediaUrl}
                  </a>
                </div>
              </div>
            )}
          </div>
        ) : null}

        <label htmlFor="brand">Select Brand:</label>
        <Select options={allBrands} onChange={handleBrandChange} />
        <br />

        {error && (
          <div className="error-container">
            <p className="error">{error}</p>
          </div>
        )}

        <input type="reset" onClick={handleReset} value="Reset" />
        <input type="submit" value="Submit" />
      </form>
    </div>
  );
}

export default AssetCreate;