import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Drawer,
  Divider,
  IconButton,
  Typography,
  Avatar,
  InputBase,
  Badge,
} from "@mui/material";
import {
  Dashboard as DashboardIcon,
  CreditCard as PaymentIcon,
  Search as SearchIcon,
  FilterList as FilterIcon,
  ViewList as ViewListIcon,
  Logout as LogoutIcon,
} from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ArticleIcon from "@mui/icons-material/Article";
import GroupsIcon from "@mui/icons-material/Groups";
import { styled } from "@mui/material/styles";
import SellerHomeTab from "../components/SellerDashboard/SellerHomeTab";
import RewardOptions from "../routes/RewardOptions";
import ServiceRequestFeed from "../components/SellerDashboard/ServiceRequestFeed";
import axios from "axios";
import SnackBar from "../components/UX/SnackBar";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Collapse from "@mui/material/Collapse";
import AssetCreate from "../routes/AssetCreate";
import BrandCreate from "../routes/BrandCreate";
import NewFeedConstruction from "../routes/FeedConstruction";
import AssetReactions from "../routes/AssetReactions";
import SettingsPage from "../components/SellerDashboard/Settings";

// Styled components
const SearchBar = styled("div")(({ theme }) => ({
  padding: "6px 10px",
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.palette.background.default,
  borderRadius: "20px",
  margin: theme.spacing(1, 2),
  border: `1px solid ${theme.palette.divider}`,
}));

function SellerDashboard() {
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [expanded, setExpanded] = useState({});

  const [loading, setLoading] = useState(true);
  const [completedServicePerMonth, setCompletedServicePerMonth] =
    useState(null);
  const [sellerDetails, setSellerDetails] = useState(null);
  const [userTab, setUserTab] = useState(
    localStorage.getItem("userTab") || "Dashboard",
  );

  const navigate = useNavigate();

  const baseUrl =
    process.env.REACT_APP_HOST_BASE_URL || "http://localhost:8000";

  console.log("base url is : ", baseUrl);

  const getCompletedServices = async () => {
    try {
      await axios
        .get(`${baseUrl}/tiers/getSellerDetails`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          setSellerDetails(response.data[0].data.SellerInfo);
          setCompletedServicePerMonth(response.data[0].data.MonthlyServices);
        });
    } catch (error) {
      console.error("Error fetching completed services:", error);
      setError(error);
    }
  };

  const fetchUserInfo = async () => {
    console.log("fetching user info with token", localStorage.getItem("token"));
    try {
      const response = await axios.get(
        "https://genz-staging.feeltiptop.com/api/user/getCurrentUserInfo",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        },
      );
      if (response.data) {
        setUserInfo(response.data);
      }
    } catch (error) {
      console.error("Error fetching current user:", error);
      setError(error);
    }
  };

  const renderComponentService = () => {
    console.log("User tab is: ", userTab);
    switch (userTab) {
      case "Dashboard":
        return (
          <SellerHomeTab
            userInfo={userInfo}
            sellerDetails={sellerDetails}
            completedServicePerMonth={completedServicePerMonth}
          />
        );
      case "Payment":
        return <RewardOptions />;
      case "Rate Service Requests":
        return <ServiceRequestFeed />;

      case "Asset Creation":
        return <AssetCreate token={localStorage.getItem("token")} />;
      case "Brand Creation":
        return <BrandCreate token={localStorage.getItem("token")} />;
      case "Feed Construction":
        return <NewFeedConstruction token={localStorage.getItem("token")} />;
      case "Asset Reactions":
        return <AssetReactions token={localStorage.getItem("token")} />;
      case "Settings":
        return <SettingsPage />;

      default:
        return <div> Page not found </div>;
    }
  };

  const handleUserTab = (tab) => {
    setUserTab(tab);
    localStorage.setItem("userTab", tab);
  };

  const handleLogOut = () => {
    axios
      .post(
        "https://genz-staging.feeltiptop.com/api/user/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        },
      )
      .then((response) => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("feedState");
        navigate("/login");
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  };
  useEffect(() => {
    const wait = async () => {
      setLoading(true);
      await fetchUserInfo();

      await getCompletedServices();
      setLoading(false);
    };

    wait();
  }, []);

  const handleDrawerClose = () => {
    setIsClosed(true);
    setOpen(false);
  };

  if (error) {
    setUserInfo({});
    setSellerDetails({});
    setCompletedServicePerMonth({});
    setErrorMessage("Error fetching your data. Please try again.");
  }

  // Menu items with icons and badges
  const menuItems = [
    { text: "Dashboard", icon: <DashboardIcon />, badge: null },
    { text: "Payment", icon: <PaymentIcon />, badge: null },
    { text: "Documents", icon: <ArticleIcon />, badge: null },
    { text: "Projects", icon: <AccountTreeIcon />, badge: null },
    { text: "Teams", icon: <GroupsIcon />, badge: null },
    {
      text: "Content Creation",
      icon: <GroupsIcon />,
      children: [
        { text: "Brand Creation", icon: <GroupsIcon /> },
        { text: "Asset Creation", icon: <GroupsIcon /> },

        { text: "Feed Construction", icon: <GroupsIcon /> },
      ],
    },
    {
      text: "Asset Reactions",
      icon: <GroupsIcon />,
      badge: null,
    },
    { text: "Settings", icon: <SettingsIcon />, badge: null },
  ];

  const DrawerList = (
    <Box
      className="mb-4 mt-8 flex flex-col items-center justify-center"
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        className="flex w-full items-start justify-start"
        sx={{ p: 2, gap: 1.5 }}
      >
        <Avatar sx={{ bgcolor: "grey.100", color: "grey.500" }}>
          {userInfo?.username ? userInfo.username.charAt(0).toUpperCase() : "U"}
        </Avatar>
        <Box>
          <Typography
            variant="subtitle1"
            className="text-left"
            sx={{ fontWeight: 500 }}
          >
            {userInfo?.username || "User Name"}
          </Typography>
        </Box>
      </Box>

      <SearchBar>
        <SearchIcon sx={{ fontSize: 20, color: "text.secondary", mr: 1 }} />
        <InputBase
          placeholder="Search..."
          sx={{ ml: 1, flex: 1, fontSize: "0.875rem" }}
        />
        <IconButton size="small" sx={{ p: 0.5 }}>
          <FilterIcon fontSize="small" />
        </IconButton>
        <IconButton size="small" sx={{ p: 0.5 }}>
          <ViewListIcon fontSize="small" />
        </IconButton>
      </SearchBar>

      {/* Navigation menu */}
      <List
        sx={{
          px: 1,
          flex: 1,
          width: "100%",
          justifyContent: "start",
          padding: 2,
        }}
      >
        {menuItems.map((item) => (
          <React.Fragment key={item.text}>
            <ListItem disablePadding className="text-sm" sx={{ mb: 0.5 }}>
              <ListItemButton
                onClick={() =>
                  item.children
                    ? setExpanded((prev) => ({
                        ...prev,
                        [item.text]: !prev[item.text],
                      }))
                    : handleUserTab(item.text)
                }
                sx={{
                  borderRadius: 1,
                  backgroundColor:
                    userTab === item.text
                      ? "rgba(25, 118, 210, 0.12)"
                      : "transparent",
                  "&:hover": {
                    backgroundColor:
                      userTab === item.text
                        ? "rgba(25, 118, 210, 0.18)"
                        : "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 40,
                    color: userTab === item.text ? "primary.main" : "grey.500",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{
                    fontSize: "0.9rem",
                    fontWeight: userTab === item.text ? 500 : 400,
                    color: userTab === item.text ? "primary.main" : "grey.800",
                  }}
                />
                {item.badge && (
                  <Badge
                    badgeContent={item.badge}
                    color="primary"
                    sx={{
                      "& .MuiBadge-badge": {
                        fontSize: "0.75rem",
                        height: "20px",
                        minWidth: "20px",
                      },
                    }}
                  />
                )}
                {item.children &&
                  (expanded[item.text] ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  ))}
              </ListItemButton>
            </ListItem>

            {item.children && expanded[item.text] && (
              <Collapse in={expanded[item.text]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.children.map((childItem) => (
                    <ListItem
                      key={childItem.text}
                      disablePadding
                      className="text-sm"
                      sx={{ mb: 0.5 }}
                    >
                      <ListItemButton
                        onClick={() => handleUserTab(childItem.text)}
                        sx={{
                          pl: 4,
                          borderRadius: 1,
                          backgroundColor:
                            userTab === childItem.text
                              ? "rgba(25, 118, 210, 0.12)"
                              : "transparent",
                          "&:hover": {
                            backgroundColor:
                              userTab === childItem.text
                                ? "rgba(25, 118, 210, 0.18)"
                                : "rgba(0, 0, 0, 0.04)",
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 40,
                            color:
                              userTab === childItem.text
                                ? "primary.main"
                                : "grey.500",
                          }}
                        >
                          {childItem.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={childItem.text}
                          primaryTypographyProps={{
                            fontSize: "0.9rem",
                            fontWeight: userTab === childItem.text ? 500 : 400,
                            color:
                              userTab === childItem.text
                                ? "primary.main"
                                : "grey.800",
                          }}
                        />
                        {childItem.badge && (
                          <Badge
                            badgeContent={childItem.badge}
                            color="primary"
                            sx={{
                              "& .MuiBadge-badge": {
                                fontSize: "0.75rem",
                                height: "20px",
                                minWidth: "20px",
                              },
                            }}
                          />
                        )}
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>

      {/* User profile at bottom */}
      <Box
        sx={{
          mt: "auto",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        {/* sign out */}
        <ListItem
          disablePadding
          className="text-sm"
          sx={{ mb: 0.5 }}
          onClick={handleLogOut}
        >
          <ListItemButton
            sx={{
              borderRadius: 1,
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 40,
                color: "grey.500",
              }}
            >
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText
              primary="Sign Out"
              primaryTypographyProps={{
                fontSize: "0.9rem",
                fontWeight: 400,
                color: "grey.800",
              }}
            />
          </ListItemButton>
        </ListItem>
        <Divider />
        <Box
          className="flex w-full items-start justify-start space-x-4"
          sx={{
            p: 2,
          }}
        >
          <Avatar alt={userInfo?.username || "User"}>
            {userInfo?.username
              ? userInfo.username.charAt(0).toUpperCase()
              : "U"}
          </Avatar>
          <Box>
            <Typography className="text-left" variant="subtitle2">
              {userInfo?.username || "User Name"}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {userInfo?.email || "user@example.com"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <IconButton
        sx={{
          display: { xs: "block", sm: "none" },
          position: "absolute",
          left: 20,
          top: 20,
          zIndex: 1200,
        }}
        onClick={() => setOpen(true)}
      >
        <LogoutIcon />
      </IconButton>

      {/* Mobile drawer */}
      <Drawer
        variant="temporary"
        open={open}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            width: 250,
            boxSizing: "border-box",
          },
        }}
      >
        {DrawerList}
      </Drawer>

      {/* Desktop drawer */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          width: 320,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 300,
            boxSizing: "border-box",
            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
          },
        }}
      >
        {DrawerList}
      </Drawer>

      {loading ? (
        <Box sx={{ flexGrow: 1, overflow: "auto", height: "100vh" }}>
          <Stack spacing={2} sx={{ p: 3, width: "100%", height: "100%" }}>
            <Stack direction="row" spacing={2} sx={{ height: "15vh" }}>
              <Skeleton
                variant="rectangular"
                sx={{ width: "33%", height: "80%" }}
              />
              <Skeleton
                variant="rectangular"
                sx={{ width: "33%", height: "80%" }}
              />
              <Skeleton
                variant="rectangular"
                sx={{ width: "33%", height: "80%" }}
              />
            </Stack>

            <Stack direction="row" spacing={2} sx={{ height: "15vh" }}>
              <Skeleton
                variant="rectangular"
                sx={{ width: "33%", height: "80%" }}
              />
              <Skeleton
                variant="rectangular"
                sx={{ width: "33%", height: "80%" }}
              />
              <Skeleton
                variant="rectangular"
                sx={{ width: "33%", height: "80%" }}
              />
            </Stack>

            <Skeleton
              variant="rectangular"
              sx={{ height: "calc(70vh - 40px)", width: "100%" }}
            />
          </Stack>
        </Box>
      ) : (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            height: "100vh",
            overflow: "auto",
            width: { sm: `calc(100% - ${300}px)` },
          }}
        >
          {renderComponentService()}
        </Box>
      )}
      {error && <SnackBar message={errorMessage} type="error" />}
    </Box>
  );
}

export default SellerDashboard;
