import React, { useState } from "react";
import { Snackbar, IconButton, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

export default function SnackBar({ message, type = "info" }) {
  const [open, setOpen] = useState(true);
  const [exited, setExited] = useState(false);

  console.log("Message is being rendered: ", message);

  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  // Determine icon and color based on type
  const getIcon = () => {
    if (type === "success") return <CheckCircleIcon />;
    if (type === "error") return <ErrorIcon />;
    return null;
  };

  const getColor = () => {
    if (type === "success") return "#4caf50";
    if (type === "error") return "#f44336";
    return "#2196f3";
  };

  return (
    <React.Fragment>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        TransitionProps={{
          onExited: () => setExited(true),
        }}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={type === "success" || type === "error" ? type : "info"}
          icon={getIcon()}
          sx={{
            width: "100%",
            backgroundColor: getColor(),
            color: "white",
            "& .MuiAlert-icon": {
              color: "white",
            },
          }}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
