import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import axios from "axios";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import logo from "../assets/images/GenZ_Logo.svg";
import { ReactComponent as SharelinkIcon } from "../assets/svg/sharelink-icon.svg";
import logoutIcon from "../assets/svg/logout-icon.svg";
import TextComponent from "../components/TextComponent";
import FeedCommentBox from "../components/FeedCommentBox";
import commentImage from "../assets/reaction_images/Comment.svg";
import commentImageClicked from "../assets/reaction_images/CommentClicked.svg";
import PollComponent from "../components/PollComponent";
import MediaComponent from "../components/MediaComponent";
import SpeechAssessmentComponent from "../components/SpeechAssessmentComponent";

import OptionsDisplay from "../components/payment/OptionsDisplay";
import { CreditCardIcon } from "@heroicons/react/24/solid";

import "./FeedStyling.css";

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

const Feed = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();

  const [error, setError] = useState("");
  const [currentComment, setCurrentComment] = useState("");
  const [timer, setTimer] = useState("0:00");
  const [sessionEndTime, setSessionEndTime] = useState("");
  const [feedStartTime, setFeedStartTime] = useState("");

  const [currentFeedID, setCurrentFeedID] = useState(1);
  const [currentUserID, setCurrentUserID] = useState(1);

  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isFeedComplete, setIsFeedComplete] = useState(false);
  const [feedIdInUrl, setFeedIdInUrl] = useState(false);
  const [openPaymentBox, setOpenPaymentBox] = useState(false); // State to control the visibility of the payment box

  const [posts, setPosts] = useState([]);
  const [feedIdQueue, setFeedIdQueue] = useState([]);

  const [commentSubmitted, setCommentSubmitted] = useState({});

  const [activeAssetId, setActiveAssetId] = useState(null);
  const [caption, setCaption] = useState("");
  const [seen, setSeen] = useState(new Set());

  const [offSet, setOffSet] = useState(0);
  const [endReached, setEndReached] = useState(false);

  const [isSpeechAsset, setIsSpeechAsset] = useState(false);

  const [speechAssets, setSpeechAssets] = useState({});

  document.title = "GenZ Feed";

  //Logic to keep track of the asset in view
  const assetRefs = useRef([]); // Array of refs for each post
  const [visiblePosts, setVisiblePosts] = useState([]);

  useEffect(() => {
    assetRefs.current = posts.map(
      (_, i) => assetRefs.current[i] || React.createRef(),
    );
    
    // When posts change, check if each post is a speech asset
    const updatedSpeechAssets = posts.reduce((acc, post) => {
      if (!post.AssetContent) return acc; // Skip if AssetContent is None
      const assetContent = JSON.parse(post.AssetContent);
      if (assetContent.mediaType === "file-audio") {
        acc[post.AssetID] = true;
      }
      return acc;
    }, {});

    setSpeechAssets(updatedSpeechAssets);

    const observers = assetRefs.current.map(
      (ref, index) =>
        new IntersectionObserver(
          ([entry]) => {
            setVisiblePosts((prevVisiblePosts) => {
              const isVisible = entry.isIntersecting;
              if (isVisible && !prevVisiblePosts.includes(index)) {
                return [...prevVisiblePosts, index]; // Add visible post index
              } else if (!isVisible && prevVisiblePosts.includes(index)) {
                return prevVisiblePosts.filter((i) => i !== index); // Remove post index
              }
              return prevVisiblePosts;
            });
          },
          { threshold: 0.1 },
        ),
    );

    // Start observing each post
    assetRefs.current.forEach(
      (ref, i) => ref.current && observers[i].observe(ref.current),
    );

    // Clean up observers on unmount
    return () => {
      observers.forEach((observer, i) => observer.disconnect());
    };
  }, [posts]);

  const handleCommentSubmit = (assetId) => {
    // Update the state to indicate that a comment has been submitted for this assetId
    setCommentSubmitted((prev) => ({ ...prev, [assetId]: true }));
  };

  const isReactComponent = (item) => {
    // Check if the item is a valid React element
    return React.isValidElement(item);
  };

  const Timer = ({ feedStartTime }) => {
    useEffect(() => {
      const updateTimer = () => {
        const fstartTime = new Date(feedStartTime);
        const currentTime = new Date();

        const fstartTimeUtc = Date.UTC(
          fstartTime.getFullYear(),
          fstartTime.getMonth(),
          fstartTime.getDate(),
          fstartTime.getHours(),
          fstartTime.getMinutes(),
          fstartTime.getSeconds(),
        );

        const currentTimeUtc = Date.UTC(
          currentTime.getFullYear(),
          currentTime.getMonth(),
          currentTime.getDate(),
          currentTime.getHours(),
          currentTime.getMinutes(),
          currentTime.getSeconds(),
        );

        const difference = currentTimeUtc - fstartTimeUtc;
        if (difference >= 0) {
          const minutes = Math.floor(difference / 60000);
          const seconds = Math.floor((difference % 60000) / 1000);
          setTimer(
            (minutes < 10 ? "0" : "") +
              minutes +
              ":" +
              (seconds < 10 ? "0" : "") +
              seconds,
          );
        }
      };

      const timerInterval = setInterval(updateTimer, 1000);

      return () => clearInterval(timerInterval);
    }, [feedStartTime]);
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  // Function to fetch posts from the backend
  useEffect(() => {
    const logo = document.querySelector(".logo");
    if (logo) {
      logo.style.visibility = "hidden";
    }

    return () => {
      if (logo) {
        logo.style.visibility = "hidden";
      }
    };
  }, []);

  useEffect(() => {
    // If currentFeedID is not 0 (i.e., it is a valid feed ID), make a GET request to the backend to fetch posts for that feed
    //Additional logic: Find if currentFeedId is equal to states feedID, if not, set it to state feedID
    if (state != null && currentFeedID !== 0 && state.feedID == currentFeedID && props.token) {
      axios
        .get("https://genz-staging.feeltiptop.com/api/getPosts", {
          // Include the feed ID as a query parameter in the request
          params: { feedID: currentFeedID },
          // Include an Authorization header with a bearer token for authentication
          headers: { Authorization: "Bearer " + props.token },
        })
        .then((response) => {
          // If the server response contains an access token, update the token state
          response.data.access_token &&
            props.setToken(response.data.access_token);
          // Append the new posts to the existing posts
          const filterAssetContent = response.data.filter(
            (post) => !seen.has(post.AssetID),
          );

          setPosts((prevPosts) => [...prevPosts, ...filterAssetContent]);

          // setPosts(...response.data);
        })
        .catch((error) => {
          // If there's an error fetching the posts, set the error state
          setError(error);
        });
    }
  }, [currentFeedID]);

  useEffect(() => {
    if (state) {
      console.table(state);
      setCurrentFeedID(state.feedID);
      setCurrentUserID(state.userID);
      setSessionEndTime(state.sessionEndTime);
      setFeedStartTime(state.feedStartTime);
      setFeedIdInUrl(true);
    } else {
      if (
        searchParams.has("feedid") &&
        searchParams.has("userid") &&
        searchParams.has("sessionendtime")
      ) {
        setCurrentFeedID(searchParams.get("feedid"));
        setCurrentUserID(searchParams.get("userid"));
        setSessionEndTime(searchParams.get("sessionendtime"));
        setFeedStartTime(searchParams.get("feedstarttime"));
        setFeedIdInUrl(true);
      } else {
        fetchUniqueFeedSession();
      }
    }
  }, [state, searchParams, currentFeedID]);

  const handleLogOut = () => {
    axios
      .post(
        "https://genz-staging.feeltiptop.com/api/logout",
        {},
        {
          headers: { Authorization: `Bearer ${props.token}` },
        },
      )
      .then((response) => {
        props.removeToken();
        localStorage.removeItem("authToken");
        navigate("/login");
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  };

  const clickLink = async (userId, feedId, assetId) => {
    try {
      const response = await axios.post(
        "https://genz-staging.feeltiptop.com/api/linkclick",
        {
          assetID: assetId,
          userID: userId,
          feedID: feedId,
        },
        {
          headers: { Authorization: "Bearer " + props.token },
        },
      );
      response.data.access_token && props.setToken(response.data.access_token);
      if (response.data.success) {
        console.log("Link click submitted successfully");
      } else {
        console.error("Server responded with failure");
      }
    } catch (error) {
      console.error("Error submitting link click:", error);
    }
  };
  useEffect(() => {
    if (activeAssetId) {
      const activePost = posts.find((post) => post.AssetID === activeAssetId);
      if (activePost) {
        const assetContent = JSON.parse(activePost.AssetContent);
        setIsSpeechAsset(assetContent.mediaType === "file-audio");
      }
    } else {
      setIsSpeechAsset(false);
    }
  }, [activeAssetId, posts]);

  const renderAssetContent = (post) => {
    if (isReactComponent(post)) {
      return post;
    }
    const assetContent = JSON.parse(post.AssetContent);
    switch (assetContent.assetType) {
      case "poll":
        return (
          <PollComponent
            assetContent={assetContent}
            userID={currentUserID}
            feedID={currentFeedID}
            activeAssetId={post.AssetID}
            token={props.token}
            setToken={props.setToken}
          />
        );
      case "text":
        return <TextComponent assetContent={assetContent} />;
      case "media":
        return <MediaComponent mediaLink={assetContent.mediaContent} />;
      case "SpeechAsset":
        return <SpeechAssessmentComponent mediaLink={assetContent.mediaContent} activeAssetId={post.AssetID} feedID={currentFeedID} caption={post.Caption} token={props.token} />;
        // mediaLink, activeAssetId, caption, feedID, token
      default:
        return <p>Unsupported asset type: {assetContent.assetType}</p>;
    }
  };

  //note to sel: ranme fetchFeedIds -> fetchAssetIDS

  const fetchFeedIDS = async () => {
    if (endReached) {
      return;
    }

    try {
      // Fetch asset list with scores
      const response = await axios.get("https://genz-staging.feeltiptop.com/api/fillPQ", {
        params: {
          offSet: offSet + 5,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      });

      let assetList = response.data.data[0];
      setEndReached(response.data.data[1]);

      if (assetList.length == 0) {
        return;
      }

      setOffSet(offSet + 5);

      if (assetList.length === 0) {
        navigate("/uploadresume", {
          state: {
            message:
              "No posts available for you at the moment. Please upload your resume to get started.",
          },
        }); // Redirect to the resume upload page if there are no posts
      }

      // Create a mapping of asset IDs to their scores

      // Extract just asset IDs
      const justAssets = assetList.map((asset) => asset.asset_id);

      // Fetch new posts for the asset IDs
      const newPostsResponse = await axios.get(
        "https://genz-staging.feeltiptop.com/api/getAssetPosts",
        {
          params: {
            assetIDList: justAssets,
          },
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        },
      );
      if (newPostsResponse.data.length === 0) {
        navigate("/uploadresume");
      }

      const sub_array = posts.slice(
        posts.length - newPostsResponse.data.length,
        posts.length,
      );

      setPosts((prevPosts) => {
        const cur = new Set(prevPosts.map((post) => post.AssetID));
        const newPost = newPostsResponse.data.filter(
          (post) => !cur.has(post.AssetID),
        );
        return [...prevPosts, ...newPost];
      });

      // Sort newPosts based on assetScoreMap
      // const sortedPosts = newPostsResponse.data.sort((a, b) => {
      //   const scoreA = assetScoreMap[a.AssetID] || 0;
      //   const scoreB = assetScoreMap[b.AssetID] || 0;
      //   return scoreB - scoreA; // Descending order
      // });

      // console.log("sortedPosts: ", sortedPosts);
    } catch (error) {
      setError(error.message);
    }
  };

  const fetchUniqueFeedSession = async () => {
    if (feedIdQueue.length > 0) {
      setSeen((prevState) => {
        const newAssetIDs = posts.map((post) => post.AssetID);
        // Make a new Set with the previous state and extra values
        const updatedSet = new Set([...prevState, ...newAssetIDs]);
        return updatedSet;
      });

      setCurrentFeedID(feedIdQueue[0]);

      setFeedIdQueue((prevState) => {
        const [, ...rest] = prevState;
        return rest;
      });
    } else {
      fetchFeedIDS();
    }
  };
  const checkSpeechAssetType = (post) => {
    return speechAssets[post.AssetID] || false; // Return the cached value for the post
  };
  const handleScroll = () => {
    const scrollBuffer = 10; // Adjust the buffer as needed

    // Check if the user has scrolled to the bottom of the page
    if (
      window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - scrollBuffer &&
      feedIdInUrl !== true
    ) {
      // Call the `fetchUniqueFeedSession` function to fetch a new feed session
      fetchUniqueFeedSession();
    }
  };

  const debouncedHandleScroll = debounce(handleScroll, 200);

  useEffect(() => {
    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [debouncedHandleScroll]);

  const renderedPosts = posts.map((post, index) => (
    <div>
      {checkSpeechAssetType(post) && index == 0 && (
        <div className="overflow-auto p-4">
        <h2 className="text-xl font-bold mb-4">Speech Assessment Directions</h2>
        <p className="font-medium text-gray-700 mb-2">
          Welcome to the speech assessment. Please follow the steps below to complete the process:
        </p>
        <ol className="list-decimal list-inside space-y-2">
          <li>Click the <strong>Play Audio</strong> button to begin the assessment.</li>
          <li>
            Listen carefully to the audio sentence. After the beep, repeat the sentence as closely as possible.
          </li>
          <li>
            Once you finish speaking, click the same button, which will now say <strong>Submit Response</strong>, to submit your recording.
          </li>
          <li>
            If no speech is detected within the first 30 seconds, the recording will automatically stop and process.
          </li>
        </ol>
        <p className="font-medium text-gray-600 mt-4">
          Make sure you are in a quiet environment to ensure the best results.
        </p>
      </div>
      
      )}
    <div
      key={index}
      ref={assetRefs.current[index]}
      className="w-screen max-w-lg border rounded-lg"
    >
      <div className="h-12 w-full"></div>

      {/** Content */}
      {!checkSpeechAssetType(post) && (
        <div className="overflow-auto p-4">
          <p className="font-semibold">{post.Caption}</p>
        </div>
      )}
      {isReactComponent(post) ? (
        post
      ) : (
        <div className="overflow-auto p-4">
          <div>{renderAssetContent(post)}</div>

          {post.OpenLink && (
            <a
              href={post.OpenLink}
              target="_blank"
              rel="noopener noreferrer"
              className=""
              onClick={(e) => {
                e.preventDefault();
                clickLink(currentUserID, currentFeedID, post.AssetID);
                window.open(post.OpenLink, "_blank");
              }}
            >
              <span>Open Link</span>
              <div className="">
                <SharelinkIcon />
              </div>
            </a>
          )}
          {/** Comment box */}
          {!checkSpeechAssetType(post) && (
            <div>
              <button
                className="flex w-full items-center justify-center py-1 transition-transform hover:scale-105"
                onClick={() => {
                  setActiveAssetId(post.AssetID);
                  setCaption(post.Caption);
                  setIsCommentModalOpen(true);
                }}
              >
                <img
                  src={
                    commentSubmitted[post.AssetID]
                      ? commentImageClicked
                      : commentImage
                  }
                  alt="Comment"
                />
              </button>
            </div>
          )}
        </div>
      )}
    </div>
    </div>
  ));

  return error ? (
    <div>{error}</div>
  ) : (
    <>
      <div className="flex flex-col items-center justify-center">
        {/** Header */}
        <div className="min-w-full border-b p-2">
          <div className="mx-auto flex max-w-lg items-center justify-between">
            <img src={logo} alt="Logo" className="h-8" />
            <Timer feedStartTime={feedStartTime} />
            <div
              className="cursor-pointer hover:scale-105"
              onClick={handleLogOut}
            >
              <img src={logoutIcon} alt="Logout" className="h-8 w-8" />
            </div>
          </div>
        </div>

        <div className="flex w-screen max-w-lg flex-col items-center justify-center gap-4">
          {renderedPosts}
        </div>
      </div>

      <FeedCommentBox
        userID={currentUserID}
        feedID={currentFeedID}
        activeAssetId={activeAssetId}
        setActiveAssetId={setActiveAssetId}
        caption={caption}
        token={props.token}
        setToken={props.setToken}
        isCommentModalOpen={isCommentModalOpen}
        setIsCommentModalOpen={setIsCommentModalOpen}
        currentComment={currentComment}
        setCurrentComment={setCurrentComment}
        onCommentSubmit={() => handleCommentSubmit(activeAssetId)}
        isSpeechAsset={isSpeechAsset}
      />
      <div className="fixed bottom-0 left-0 flex w-full">
        {openPaymentBox ? (
          <OptionsDisplay
            setOpenPaymentBox={setOpenPaymentBox}
            setPosts={setPosts}
            posts={posts}
            visiblePosts={visiblePosts}
          />
        ) : (
          <button
            className="payment-options-button"
            onClick={() => setOpenPaymentBox(true)}
          >
            <CreditCardIcon className="h-6 w-6" />
          </button>
        )}
      </div>
      {endReached && (
        <div className="flex flex-col items-center justify-center">
          <h1 className="border border-r-4 text-center text-gray-800 drop-shadow-lg">
          </h1>
        </div>
      )}
    </>
  );
};

export default Feed;