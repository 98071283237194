import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Paper,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Chip,
} from "@mui/material";

import {
  Timer as TimerIcon,
  Check as CheckIcon,
  TrendingUp as TrendingUpIcon,
  Warning as WarningIcon,
} from "@mui/icons-material";

function SellerHomeTab({ userInfo, sellerDetails, completedServicePerMonth }) {
  const level = (numericLevel) => {
    switch (numericLevel) {
      case 0:
        return "Unranked";
      case 1:
        return "I";
      case 2:
        return "II";
      case 3:
        return "III";
      case 4:
        return "IV";
      case 5:
        return "V";
      default:
        return "Unranked";
    }
  };

  const extractMonth = (date) => {
    console.log(date);
    const month = date.slice(-2);
    switch (month) {
      case "01":
        return "January";
      case "02":
        return "February";
      case "03":
        return "March";
      case "04":
        return "April";
      case "05":
        return "May";
      case "06":
        return "June";
      case "07":
        return "July";
      case "08":
        return "August";
      case "09":
        return "September";
      case "10":
        return "October";
      case "11":
        return "November";
      case "12":
        return "December";
    }
    return "Invalid Month";
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
        <Box>
          <Typography variant="h4">Hello, {userInfo.username}</Typography>
          <Typography color="text.secondary">
            Track your progress as a seller here
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box sx={{ textAlign: "right" }}>
            <Typography variant="subtitle1">{userInfo.username}</Typography>
            <Typography color="text.secondary">{userInfo.email}</Typography>
          </Box>
          <Avatar>{userInfo.username[0]}</Avatar>
        </Box>
      </Box>

      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography color="text.secondary">
                    Total Completed Services
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "baseline", gap: 1 }}>
                    <Typography variant="h4">
                      {sellerDetails && sellerDetails.ServicesProvidedInTotal}
                    </Typography>
                  </Box>
                </Box>
                <CheckIcon color="action" />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography color="text.secondary">
                    Completed Services this month
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "baseline", gap: 1 }}>
                    <Typography variant="h4">
                      {" "}
                      {sellerDetails && sellerDetails.CurrentMonthServices}
                    </Typography>
                  </Box>
                </Box>
                <TimerIcon color="action" />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography color="text.secondary">
                    Total point earned
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "baseline", gap: 1 }}>
                    <Typography variant="h4">
                      {sellerDetails && sellerDetails.PointsEarned}
                    </Typography>
                  </Box>
                </Box>
                <TimerIcon color="action" />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography color="text.secondary">Seller Tier</Typography>
                  <Box sx={{ display: "flex", alignItems: "baseline", gap: 1 }}>
                    <Typography variant="h4">
                      {level(userInfo.Level)}
                    </Typography>
                  </Box>
                </Box>
                <TrendingUpIcon color="action" />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography color="text.secondary">Seller Rating</Typography>
                  <Box sx={{ display: "flex", alignItems: "baseline", gap: 1 }}>
                    <Typography
                      variant="h4"
                      sx={{
                        color:
                          sellerDetails && sellerDetails.Rating < 5.0
                            ? "error.main"
                            : "text.primary",
                      }}
                    >
                      {sellerDetails && sellerDetails.Rating}
                    </Typography>
                    {sellerDetails && sellerDetails.Rating < 5.0 && (
                      <Typography
                        color="error.main"
                        variant="caption"
                        sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                      >
                        <WarningIcon fontSize="small" />
                        Risk of tier downgrade
                      </Typography>
                    )}
                  </Box>
                </Box>
                <TrendingUpIcon color="action" />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Paper
        elevation={2}
        sx={{
          maxWidth: screen,
          mx: "auto",
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{ p: 3, bgcolor: "primary.main", color: "primary.contrastText" }}
        >
          <Typography variant="h6" component="h2">
            Services Completed per Month
          </Typography>
        </Box>

        <List sx={{ p: 0 }}>
          {completedServicePerMonth &&
            completedServicePerMonth.map((activity, index) => (
              <ListItem
                key={index}
                sx={{
                  borderBottom: "1px solid",
                  borderColor: "divider",
                  py: 2,
                  "&:last-child": {
                    borderBottom: "none",
                  },
                  "&:hover": {
                    bgcolor: "action.hover",
                  },
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" component="div">
                      {extractMonth(activity.ActivityMonth)}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="text.secondary">
                      {activity.TotalReactionFulfillment} fulfillments
                    </Typography>
                  }
                />
                <Chip
                  label={`${activity.TotalServicesProvided} services`}
                  color="primary"
                  size="small"
                  sx={{
                    minWidth: 100,
                    "& .MuiChip-label": {
                      px: 2,
                    },
                  }}
                />
              </ListItem>
            ))}
        </List>
      </Paper>
    </>
  );
}

export default SellerHomeTab;
