import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "../App.css";
import ToolTip from "./ToolTip";

export default function FeedCommentBox(props) {
  const [currentComment, setCurrentComment] = useState("");
  const [media, setMedia] = useState(null);
  const [speech, setSpeech] = useState(null);
  const [error, setError] = useState("");
  const [isFirefox, setIsFirefox] = useState(false);
  const microphoneButtonRef = useRef(null);
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches,
  );
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const recognitionRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [scores, setScores] = useState("None");
  const [isToolboxVisible, setIsToolboxVisible] = useState(false);
  const [isSTTLoading, setIsSTTLoading] = useState(false);

  useEffect(() => {
    setCurrentComment("");
    setMedia(null);
    setSpeech(null);
    setAudioBlob(null);
  }, [props.activeAssetId]);

  useEffect(() => {
    setIsFirefox(typeof InstallTrigger !== "undefined");
  }, []);

  useEffect(() => {
    setupMicrophone();
  }, [isFirefox]);

  const setupMicrophone = () => {
    if (isFirefox) {
      setError(
        "Speech recognition is not supported in Firefox. Please use a different browser.",
      );
      return;
    }

    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    if (!SpeechRecognition) {
      setError(
        "Speech recognition is not supported in this browser. Please use a different browser.",
      );
      return;
    }

    recognitionRef.current = new SpeechRecognition();
    recognitionRef.current.continuous = true;
    recognitionRef.current.interimResults = true;
    recognitionRef.current.maxAlternatives = 1;

    recognitionRef.current.onresult = (event) => {
      let finalTranscript = "";
      let interimTranscript = "";

      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcriptPart = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscript += transcriptPart + " ";
        } else {
          interimTranscript += transcriptPart;
        }
      }

      // Update the comment with finalized transcription
      if (finalTranscript) {
        setCurrentComment((prev) => prev + finalTranscript);
      }
    };

    recognitionRef.current.onerror = (event) => {
      console.error("Speech recognition error: ", event.error);
      if (
        event.error === "no-speech" ||
        event.error === "audio-capture" ||
        event.error === "not-allowed"
      ) {
        // Handle specific errors if needed
      }
    };
  };

  const microphoneButton = microphoneButtonRef.current;

  const toggleBlack = () => {
    if (microphoneButton) {
      microphoneButton.style.background = "";
    }
  };

  const toggleRed = () => {
    if (microphoneButton) {
      microphoneButton.style.background = "red";
    }
  };

  // Function to start recording audio and speech recognition
  const startRecording = async () => {
    try {
      // Start Recording Audio
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };
      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/webm",
        });
        setAudioBlob(audioBlob);
        audioChunksRef.current = []; // Clear the audio chunks for the next recording
      };
      mediaRecorderRef.current.start();
      setIsRecording(true);

      // Start speech recognition
      if (recognitionRef.current) {
        recognitionRef.current.lang = "en-US";
        recognitionRef.current.start();
        toggleRed();
        setCurrentComment("");
      }
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = () => {
    // Stop Recording Audio
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
    toggleBlack();
    // Stop speech recognition
    if (recognitionRef.current) {
      recognitionRef.current.stop();
    }
  };

  const playAudio = () => {
    if (audioBlob) {
      createAudioVisualizer(audioBlob);
    }
  };

  // Function to create the audio visualizer on a canvas
  function createAudioVisualizer(audioBlob) {
    const canvas = document.getElementById("audio-visualizer");
    const canvasContext = canvas.getContext("2d");

    // Create an AudioContext to work with the audio
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 2048; // The size of the FFT (frequency analysis)

    // Create a buffer source node from the audioBlob
    const reader = new FileReader();
    reader.onload = function (event) {
      audioContext.decodeAudioData(event.target.result, (buffer) => {
        const source = audioContext.createBufferSource();
        source.buffer = buffer;

        // Connect the source to the analyser and then to the destination (speakers)
        source.connect(analyser);
        analyser.connect(audioContext.destination);

        // Start the audio playback
        source.start();

        // Call the function to draw the visualizer
        drawVisualizer(canvasContext, analyser);
      });
    };
    reader.readAsArrayBuffer(audioBlob);
  }

  // Function to draw the visualizer on the canvas
  function drawVisualizer(canvasContext, analyser) {
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    function draw() {
      requestAnimationFrame(draw);

      // Get the frequency data from the analyser
      analyser.getByteFrequencyData(dataArray);

      // Clear the canvas
      canvasContext.fillStyle = "white";
      canvasContext.fillRect(
        0,
        0,
        canvasContext.canvas.width,
        canvasContext.canvas.height,
      );

      // Calculate the center line of the canvas
      const centerY = canvasContext.canvas.height / 2;
      const barWidth = (canvasContext.canvas.width / bufferLength) * 10; // Adjust multiplier for bar spacing
      let barHeight;
      let x = 0;

      // Loop through the frequency data and draw each bar
      for (let i = 0; i < bufferLength; i++) {
        barHeight = dataArray[i] / 2;
        // Set the color of the bars (can be customized)
        canvasContext.fillStyle = "red";
        // Draw the bar extending above and below the center line
        canvasContext.fillRect(x, centerY - barHeight / 2, barWidth, barHeight);
        x += barWidth + 1; // Move to the next bar position with spacing
      }
    }

    draw(); // Start the visualization
  }

  // Handler for media file selection
  const handleMediaChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/tiff",
      "video/mp4",
      "video/ogg",
      "audio/mp3",
      "audio/mpeg",
      "audio/wav",
    ];
    if (file && allowedTypes.includes(file.type)) {
      setError("");
      setMedia(file);
    } else {
      setError("Invalid file type. Please select a valid media file.");
      setMedia(null);
    }
  };

  const handleTextChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["audio/mp3", "audio/wav", "video/mp4", "audio/mpeg"];
    if (file && allowedTypes.includes(file.type)) {
      setError("");
      setSpeech(file);
    } else {
      setError("Invalid file type. Please select a valid audio or video file.");
      setSpeech(null);
    }
  };

  const submitComment = async () => {
    if (props.activeAssetId && currentComment) {
      try {
        const commentData = {
          assetID: props.activeAssetId,
          feedID: props.feedID,
          comment: currentComment,
        };
        console.log(commentData);

        const response = await axios.post(
          "https://genz-staging.feeltiptop.com/api/comment",
          commentData,
          {
            headers: {
              Authorization: "Bearer " + props.token,
            },
          },
        );

        if (response.data.success) {
          console.log("Comment submitted successfully");
          props.onCommentSubmit();

          // Reset state and close modal
          setCurrentComment("");
          setMedia(null);
          setSpeech(null);
          props.setIsCommentModalOpen(false);
          props.setActiveAssetId(null);
        } else {
          console.error("Server responded with failure");
          setError("Failed to submit comment. Please try again.");
        }
      } catch (error) {
        console.error("Error submitting comment:", error);
        setError(
          "An error occurred while submitting the comment. Please try again.",
        );
      }
    } else {
      setError("Please enter a comment before submitting.");
    }
  };

  const submitMedia = async () => {
    if (props.activeAssetId && media) {
      try {
        // Create a FormData object to handle file upload
        const formData = new FormData();
        formData.append("assetID", props.activeAssetId);
        formData.append("feedID", props.feedID);
        formData.append("media", media);

        for (let [key, value] of formData.entries()) {
          console.log(`${key}:`, value);
        }

        const response = await axios.post(
          "https://genz-staging.feeltiptop.com/api/media_response",
          formData,
          {
            headers: {
              Authorization: "Bearer " + props.token,
              "Content-Type": "multipart/form-data",
            },
          },
        );

        if (response.data.success) {
          console.log("Media submitted successfully");
          console.log(response);
          props.onCommentSubmit();

          // Reset state and close modal
          setCurrentComment("");
          setMedia(null);
          setSpeech(null);
          props.setIsCommentModalOpen(false);
          props.setActiveAssetId(null);
        } else {
          console.error("Server responded with failure");
          setError("Failed to submit media. Please try again.");
        }
      } catch (error) {
        console.error("Error submitting media:", error);
        setError(
          "An error occurred while submitting the media. Please try again.",
        );
      }
    } else {
      setError("Please enter a media before submitting.");
    }
  };

  const submitTranscript = async () => {
    // Need to add disable to the button to prevent multi api calls
    if (props.activeAssetId && speech) {
      try {
        const formData = new FormData();
        formData.append("file", speech);

        const response = await axios.post(
          "https://stt.tiptopbest.com/upload",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          },
        );
        const { data } = response;
        if (data && data.transcript) {
          setCurrentComment(data.transcript);
          setError("Transcript obtained successfully.");
        } else {
          setError("Failed to process the file.");
        }
      } catch (error) {
        setError("Error processing the file.");
      }
    }
  };

  const convertWebmBlobToWav = async (webmBlob) => {
    const audioContext = new AudioContext();
    const arrayBuffer = await webmBlob.arrayBuffer();

    // Decode audio data from the arrayBuffer
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    // Convert audioBuffer to WAV format
    const wavBlob = audioBufferToWav(audioBuffer);

    // Log first few bytes of WAV file to confirm RIFF header
    const wavArrayBuffer = await wavBlob.arrayBuffer();
    console.log(
      "First 4 bytes (should be 'RIFF'):",
      new Uint8Array(wavArrayBuffer).slice(0, 4),
    );

    return wavBlob;
  };

  const audioBufferToWav = (audioBuffer) => {
    const numOfChannels = audioBuffer.numberOfChannels;
    const sampleRate = audioBuffer.sampleRate;
    const format = 1; // PCM
    const bitDepth = 16;

    // Calculate buffer size
    const resultBuffer = new ArrayBuffer(
      44 + audioBuffer.length * numOfChannels * 2,
    );
    const view = new DataView(resultBuffer);

    // Write WAV headers
    writeString(view, 0, "RIFF");
    view.setUint32(4, 36 + audioBuffer.length * numOfChannels * 2, true);
    writeString(view, 8, "WAVE");
    writeString(view, 12, "fmt ");
    view.setUint32(16, 16, true); // PCM format
    view.setUint16(20, format, true);
    view.setUint16(22, numOfChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * numOfChannels * 2, true); // Byte rate
    view.setUint16(32, numOfChannels * 2, true); // Block align
    view.setUint16(34, bitDepth, true);
    writeString(view, 36, "data");
    view.setUint32(40, audioBuffer.length * numOfChannels * 2, true);

    // Write interleaved PCM samples
    let offset = 44;
    for (let i = 0; i < audioBuffer.length; i++) {
      for (let channel = 0; channel < numOfChannels; channel++) {
        const sample = Math.max(
          -1,
          Math.min(1, audioBuffer.getChannelData(channel)[i]),
        );
        view.setInt16(offset, sample * 0x7fff, true);
        offset += 2;
      }
    }

    return new Blob([view], { type: "audio/wav" });
  };

  // Helper function to write a string to DataView
  const writeString = (view, offset, string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };

  const submitSTT = async () => {
    setIsSTTLoading(true);
    if (props.activeAssetId && audioBlob) {
      try {
        // Convert audio blob to WAV format
        const wavBlob = await convertWebmBlobToWav(audioBlob);
        const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
        const filename = `recording_${timestamp}.wav`;
        const audioFile = new File([wavBlob], filename, {
          type: "audio/wav",
          lastModified: new Date().getTime(),
        });

        // Call the Speech Assessment API
        const formDataSpeechAssessment = new FormData();
        formDataSpeechAssessment.append("caption", props.caption);
        formDataSpeechAssessment.append("assetID", props.activeAssetId);
        formDataSpeechAssessment.append("feedID", props.feedID);
        formDataSpeechAssessment.append("file", audioFile);

        const responseSpeechAssessment = await axios.post(
          "https://genz-staging.feeltiptop.com/api/speech_assessment",
          formDataSpeechAssessment,
          {
            headers: {
              Authorization: "Bearer " + props.token,
              "Content-Type": "multipart/form-data",
            },
          },
        );

        // If successful, update scores
        setScores(responseSpeechAssessment.data.scores);
        console.log(responseSpeechAssessment.data.scores)
        // Then, call the Media Response API
        const formDataMediaResponse = new FormData();
        formDataMediaResponse.append("assetID", props.activeAssetId);
        formDataMediaResponse.append("feedID", props.feedID);
        formDataMediaResponse.append("media", audioFile);

        const responseMediaResponse = await axios.post(
          "https://genz-staging.feeltiptop.com/api/media_response",
          formDataMediaResponse,
          {
            headers: {
              Authorization: "Bearer " + props.token,
              "Content-Type": "multipart/form-data",
            },
          },
        );

        if (
          responseMediaResponse.data.success
        ) {
          props.onCommentSubmit(); // Call comment submit handler
        } else {
          console.error("Failed to submit media.");
          setError("Failed to submit media. Please try again.");
        }
      } catch (error) {
        console.error("Error submitting media:", error);
        setError(
          "An error occurred while submitting the media. Please try again.",
        );
      } finally {
        // Reset state and stop loading
        setCurrentComment("");
        setMedia(null);
        setSpeech(null);
        setAudioBlob(null);
        setIsSTTLoading(false);
      }
    } else {
      setError("Please enter a media before submitting.");
      setIsSTTLoading(false);
    }
  };

  return (
    <>
      {/* Background Overlay */}
      {props.isCommentModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
          onClick={() => props.setIsCommentModalOpen(false)}
        />
      )}

      {/* Comment Container */}
      <div
        className={`fixed left-1/2 top-1/2 z-50 h-auto w-[92%] max-w-2xl -translate-x-1/2 -translate-y-1/2 transform rounded-md bg-white p-4 shadow-lg ${
          props.isCommentModalOpen ? "block" : "hidden"
        }`}
      >
        <div className="mb-4 flex items-center justify-between">
          <div className="text-lg font-semibold text-gray-700">
            Add a Comment
          </div>

          <button
            onClick={() => setIsToolboxVisible((prev) => !prev)}
            className="flex items-center justify-center rounded-md bg-gray-200 p-2 transition-colors duration-300 hover:bg-gray-300"
          >
            Tools
          </button>
        </div>

        {/* Toolbox Section */}
        {isToolboxVisible && (
          <div className="mb-4 rounded-md border bg-gray-50 p-2">
            {/* You can add more tools here as needed */}
            <div className="flex flex-wrap gap-2">
              {/* Media Upload Tool */}
              <div className="flex items-center gap-2">
                <label
                  htmlFor="mediaFile"
                  className={`mr-2 ${isMobile ? "text-xs" : "text-sm"}`}
                >
                  Select Media:
                </label>
                <input
                  type="file"
                  id="mediaFile"
                  name="mediaFile"
                  accept="image/*, video/mp4, audio/*"
                  onChange={handleMediaChange}
                  className="rounded-lg border border-gray-300 p-2"
                />
                <button
                  onClick={submitMedia}
                  disabled={!media}
                  className={`h-10 rounded-lg px-4 py-2 font-medium transition-colors duration-300 ${
                    !media
                      ? "cursor-not-allowed bg-gray-300 text-gray-500"
                      : "bg-emerald-600 text-white hover:bg-emerald-700"
                  }`}
                >
                  Upload
                </button>
              </div>
              {/* Speech to Text Tool */}
              <div className="flex items-center gap-2">
                <label
                  htmlFor="textFile"
                  className={`mr-2 font-medium text-gray-600 ${isMobile ? "text-xs" : "text-sm"}`}
                >
                  Upload Audio/Video for Speech-to-Text:
                </label>
                <input
                  type="file"
                  id="textFile"
                  name="textFile"
                  accept="audio/*, video/mp4"
                  onChange={handleTextChange}
                  className="rounded-lg border border-gray-300 p-2"
                />
                <button
                  onClick={submitTranscript}
                  disabled={!speech}
                  className={`h-10 rounded-lg px-4 py-2 font-medium transition-colors duration-300 ${
                    !speech
                      ? "cursor-not-allowed bg-gray-300 text-gray-500"
                      : "bg-emerald-600 text-white hover:bg-emerald-700"
                  }`}
                >
                  Convert
                </button>
              </div>
              {props.isSpeechAsset && (
                <div className="flex flex-col gap-4">
                  {/* Live Speech to Text Tool */}
                  <div className="flex items-center gap-2">
                    <label
                      htmlFor="microphone"
                      className={`mr-2 font-medium text-gray-600 ${isMobile ? "text-xs" : "text-sm"}`}
                    >
                      Live Speech-to-Text:
                    </label>
                    <button
                      onClick={isRecording ? stopRecording : startRecording}
                      className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-100 text-2xl shadow-md transition-all duration-300 hover:bg-gray-300"
                      ref={microphoneButtonRef}
                      id="microphone"
                    >
                      🎤
                    </button>
                    <button
                      onClick={playAudio}
                      className={`h-10 rounded-lg px-4 py-2 font-medium transition-colors duration-300 ${
                        !audioBlob
                          ? "cursor-not-allowed bg-gray-300 text-gray-500"
                          : "bg-emerald-600 text-white hover:bg-emerald-700"
                      }`}
                    >
                      ▶
                    </button>
                    <div className="relative overflow-hidden rounded-lg border-gray-300 px-4 py-2">
                      <canvas
                        id="audio-visualizer"
                        className="block h-10 w-20 -translate-y-1/2" // Center the canvas vertically
                      ></canvas>
                    </div>
                    <button
                      onClick={submitSTT}
                      disabled={(!audioBlob && !media) || isSTTLoading}
                      className={`ml-20 h-10 rounded-lg px-4 py-2 font-medium transition-colors duration-300 ${
                        !audioBlob
                          ? "cursor-not-allowed bg-gray-300 text-gray-500"
                          : "bg-emerald-600 text-white hover:bg-emerald-700"
                      }`}
                    >
                      {isSTTLoading ? "Loading..." : "Upload"}
                    </button>
                    <ToolTip
                      buttonText="Score"
                      tooltipText={`Your Score: ${scores}`}
                    />
                  </div>
                </div>
              )}
              {!props.isSpeechAsset && (
                <div className="flex flex-col gap-4">
                  {/* Live Speech to Text Tool */}
                  <div className="flex items-center gap-2">
                    <label
                      htmlFor="microphone"
                      className={`mr-2 font-medium text-gray-600 ${isMobile ? "text-xs" : "text-sm"}`}
                    >
                      Live Speech-to-Text:
                    </label>
                    <button
                      onClick={isRecording ? stopRecording : startRecording}
                      className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-100 text-2xl shadow-md transition-all duration-300 hover:bg-gray-300"
                      ref={microphoneButtonRef}
                      id="microphone"
                    >
                      🎤
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {/* Comment Box */}
        <div className="mb-5 h-[200px] w-full rounded-md bg-gray-100 p-4 shadow-inner">
          <textarea
            className="h-full w-full resize-none bg-transparent outline-none"
            value={currentComment}
            onChange={(e) => setCurrentComment(e.target.value)}
            placeholder="Type your comment here..."
          />
        </div>

        {/* Error Message */}
        {error && <div className="mt-2 text-sm text-red-600">{error}</div>}

        {/* Submit Comment Button */}
        <button
          className={`h-12 w-full rounded-lg font-medium text-white transition-colors duration-300 ${
            !currentComment
              ? "cursor-not-allowed bg-gray-400"
              : "bg-emerald-600 hover:bg-emerald-700"
          }`}
          disabled={!currentComment}
          onClick={submitComment}
        >
          Post Comment
        </button>
      </div>
    </>
  );
}
