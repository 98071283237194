import React, { useEffect, useState } from 'react';

const ReferralPage = ({ userId }) => {
    const [referralCount, setReferralCount] = useState(0);
    const [referrals, setReferrals] = useState([]);

    const [subscribersReferredCount, setSubscribersReferredCount] = useState(0);
    const [subscribersReferred, setSubscribersReferred] = useState([]);


    console.log(userId);
    useEffect(() => {
        // Fetch referral data from the backend
        const fetchReferrals = async () => {
            try {
                const response = await fetch(`https://genz-staging.feeltiptop.com/api/get_referrals?userID=${userId}`);
                const data = await response.json();

                setReferralCount(data.count);
                setReferrals(data.referrals);
            } catch (error) {
                console.error('Error fetching referrals:', error);
            }
        };

        fetchReferrals();
    }, [userId]);
    useEffect(() => {
        const fetchSubscribersReferred = async () => {
            try {
                const response = await fetch(`https://genz-staging.feeltiptop.com/api/subscribers?userID=${userId}`);
                const data = await response.json();

                if (data.referral_count !== undefined && data.referred_subscribers !== undefined) {
                    setSubscribersReferredCount(data.referral_count);
                    setSubscribersReferred(data.referred_subscribers);
                    console.log('Subscribers Referred Count:', subscribersReferredCount);
                    console.log('Subscribers Referred:', subscribersReferred);
                } else {
                    console.error('Unexpected data format:', data);
                }
            } catch (error) {
                console.error('Error fetching subscribers referred:', error);
            }
        };

        if (userId) {
            fetchSubscribersReferred();
        }
    }, [userId])

    return (
        <div className="p-6 bg-gray-50 min-h-screen">
            <div className="max-w-3xl mx-auto bg-white shadow-md rounded-lg p-4">
                <h1 className="text-2xl font-bold text-gray-800 mb-4">
                    Referrals
                </h1>
                <p className="text-lg text-gray-600">
                    You have referred <span className="font-semibold">{referralCount}</span> people.
                </p>
                <div className="mt-6">
                    <h2 className="text-xl font-semibold text-gray-700 mb-4">
                        People Referred:
                    </h2>
                    <div className="space-y-4">
                        {referrals.map((referral) => (
                            <div
                                key={referral.UserID}
                                className="p-4 bg-gray-100 rounded-lg shadow-sm"
                            >
                                <p className="text-gray-800 font-medium">
                                    {referral.firstName} {referral.lastName}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="mt-10">
                    <h2 className="text-2xl font-bold text-gray-800 mb-4">
                        Subscribers Referred
                    </h2>
                    <p className="text-lg text-gray-600">
                        You have referred <span className="font-semibold">{subscribersReferredCount}</span> subscribers.
                    </p>
                    <div className="mt-6">
                        <h3 className="text-xl font-semibold text-gray-700 mb-4">
                            Subscribers:
                        </h3>
                        <div className="space-y-4">
                            {subscribersReferred.map((subscriber) => (
                                <div
                                    key={subscriber.SubId}
                                    className="p-4 bg-gray-100 rounded-lg shadow-sm"
                                >
                                    <p className="text-gray-800 font-medium">
                                        {subscriber.FirstName} {subscriber.LastName}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
        </div>
        
    );
};

export default ReferralPage;
