import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import logo from "../assets/images/GenZ_Logo.svg";
import "../assetFormStyle.css";
import Quiz from "../components/Quiz";
import Text from "../components/assets/Text/Text";
import { useNavigate, useLocation } from "react-router-dom";
import VisibilityToggle from "../components/assetcreate/VisibilityToggle";
import PollSurvey from "../components/assets/poll/PollSurvey";

function AssetCreate(props) {
  const [assetName, setAssetName] = useState("");
  const [assetType, setAssetType] = useState("");
  const [caption, setCaption] = useState("");
  const [media, setMedia] = useState("");
  const [mediaUrl, setMediaUrl] = useState("");
  const [mediaType, setMediaType] = useState("");
  const [brandId, setBrandId] = useState("");
  const [allBrands, setAllBrands] = useState([]);
  const [newBrand, setNewBrand] = useState(""); // For adding new brands
  const [addingNewBrand, setAddingNewBrand] = useState(false); // For adding new brands
  const [generateAssestOption, setGenerateAssestOption] = useState("");
  const [quizOptions, setQuizOptions] = useState(null);
  const [quizsSelected, setQuizesSelected] = useState([]);
  const [textContent, setTextContent] = useState("");
  const [quizUrl, setQuizUrl] = useState("");
  const [audioText, setAudioText] = useState("");
  const [TTSLoading, setTTSLoading] = useState(false);
  const [user, setUser] = useState("");
  const [error, setError] = useState("");

  const [pollQuestion, setPollQuestion] = useState("");
  const [pollType, setPollType] = useState("quiz");
  const [pollOptions, setPollOptions] = useState(["", ""]);
  const [pollAnswer, setPollAnswer] = useState("");
  const [publicAsset, setPublicAsset] = useState("default");
  const [pollMaxSelections, setPollMaxSelections] = useState(2);

  const [surveyPolls, setSurveyPolls] = useState([
    {
      question: "",
      selections: {
        a: "",
        b: "",
      },
    },
    {
      question: "",
      selections: {
        a: "",
        b: "",
      },
    },
  ]);

  const [quizData, setQuizData] = useState({
    questionNumber: 0,
    language: "English",
    newsType: "",
    type: 1,
    mainTopic: "",
    subTopic: "",
    timeFrame: "",
  });

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("authToken");

    axios
      .get("https://genz-staging.feeltiptop.com/api/user/getCurrentUserInfo", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUser(response.data.username);
      })
      .catch((error) => {
        console.error("Error fetching user info:", error);
      });

    axios
      .get("https://genz-staging.feeltiptop.com/api/brand/getBrands", {
        headers: {
          Authorization: "Bearer " + props.token,
        },
      })
      .then((response) => {
        // Add the "Select Brand..." option
        const selectBrandOption = { label: "Select Brand...", value: null };
        const tmpbrands = [
          selectBrandOption,
          ...response.data.map((opt) => ({
            label: opt[1],
            value: opt[0],
          })),
        ];

        setAllBrands(tmpbrands);
      })
      .catch((error) => {
        console.error(error);
      });

    if (location.state && location.state.message) {
      setError(location.state.message);
    }
  }, [props.token]);

  const handleReset = () => {
    setAssetName("");
    setAssetType("");
    setCaption("");
    setMedia("");
    setMediaUrl("");
    setMediaType("");
    setBrandId(null);
    setError("");
    setGenerateAssestOption("");
    setQuizOptions(null);
    setQuizesSelected([]);
    setTextContent("");
    setQuizUrl("");
    setAudioText("");
    setTTSLoading(false);

    setPollQuestion("");
    setPollType("quiz");
    setPollOptions(["", ""]);
    setPollAnswer("");
    setPollMaxSelections(2);

    const allInputs = document.querySelectorAll("input, textarea, select");
    allInputs.forEach((input) => {
      input.value = "";
    });
  };

  const handleAddOption = () => {
    setPollOptions([...pollOptions, ""]);
  };

  const handleRemoveOption = (index) => {
    const newOptions = pollOptions.filter((_, i) => i !== index);
    setPollOptions(newOptions);
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...pollOptions];
    newOptions[index] = value;
    setPollOptions(newOptions);
  };

  const handleGenerateAssestOptionChange = (event) => {
    setGenerateAssestOption(event.target.value);
  };

  const handleMediaTypeChange = (event) => {
    setMediaType(event.target.value);
    setMedia("");
    setMediaUrl("");
  };

  const handleMediaChange = (event) => {
    const file = event.target.files[0];
    if (!assetType) {
      setError("Please choose an Asset Type before selecting media.");
      return;
    }

    if (assetType === "media" || assetType === "SpeechAsset") {
      setError("");
      const reader = new FileReader();
      reader.onload = (e) => {
        setMedia(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setError("Invalid file type. Please select a valid media file.");
      setMedia(null);
    }
  };

  const handleMediaUrlChange = (event) => {
    setMediaUrl(event.target.value);
  };

  const handleAssetNameChange = (event) => {
    setAssetName(event.target.value);
  };

  const handleBrandChange = (selectedOption) => {
    setBrandId(selectedOption ? selectedOption.value : null);
  };

  const generateTTS = async (audioText) => {
    setTTSLoading(true);
    try {
      let response = await axios.get(
        "https://feeltiptop.com/demos/videogen/MSSpeech.php?usetiptoptts=1&txt=" +
          encodeURI(audioText),
        {
          responseType: "blob",
        },
      );
      const audioBlob = new Blob([response.data], { type: "audio/wav" });
      const audioFile = new File([audioBlob], "audio.wav", {
        type: "audio/wav",
      });
      handleMediaChange({ target: { files: [audioFile] } });

      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
      audio.play();
      setTTSLoading(false);
    } catch (error) {
      console.error("Error fetching TTS Audio:", error);
      setTTSLoading(false);
    }
  };

  const generateAssests = (event, setIsloading) => {
    event.preventDefault();

    if (generateAssestOption === "quiz") {
      if (quizOptions && quizsSelected) {
        setQuizOptions(null);
        setQuizesSelected([]);
      }
      setIsloading(true);

      axios
        .get("https://genz-staging.feeltiptop.com/api/asset/quizApi", {
          timeout: 600000,
          params: {
            questionNumber: quizData.questionNumber,
            language: quizData.language,
            newsType: quizData.newsType,
            quizType: quizData.typess,
            mainTopic: quizData.mainTopic,
            subTopic: quizData.subTopic,
            timeFrame: quizData.timeFrame,
          },
        })
        .then((response) => {
          const { url, quizData: responseQuizData } = response.data;
          setQuizUrl(url);
          setQuizOptions(responseQuizData);
          setIsloading(false);
          setQuizesSelected(responseQuizData);
        })
        .catch((error) => {
          setError(
            "An error occurred when fetching your asset. Please try again later.",
          );
          setIsloading(false);
        });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!assetType || assetType === "") {
      setError("Please select an Asset Type.");
      return;
    }

    if (!brandId) {
      setError("Please select a Brand.");
      return;
    }

    if (!publicAsset || publicAsset === "default") {
      setError("Please select an asset Visability.");
      return;
    }

    let preContent;
    if (assetType === "text") {
      preContent = {
        assetType: assetType,
        html: textContent,
      };
    } else if (assetType === "poll") {
      if (pollType !== "survey" && pollType !== "submit_button" && !pollQuestion) {
        setError("Please fill in the poll question");
        return;
      }

      if (pollType === "free_response") {
        preContent = {
          assetType: assetType,
          question: pollQuestion,
          pollType: pollType,
        };
      } else {
        if (pollType === "survey") {
          Object.entries(surveyPolls).forEach(([index, poll]) => {
            if (!poll.question || poll.question.trim() === "") {
              setError(`Please fill in survey question ${parseInt(index) + 1}`);
              return;
            }
            if (Object.values(poll.selections).some((value) => !value)) {
              setError(
                `Please fill in all options for survey question ${index + 1}`,
              );
              return;
            }
          });
        }

        if (
          pollType !== "survey" &&
          pollType !== "submit_button" &&
          pollType !== "binary" &&
          pollOptions.some((opt) => !opt)
        ) {
          setError("Please fill in all poll fields");
          return;
        }

        if ((pollType === "quiz" || pollType === "binary") && !pollAnswer) {
          setError("Please select the correct answer for the quiz");
          return;
        }

        let pollSelections = {};
        if (pollType === "binary") {
          pollSelections.A = "True";
          pollSelections.B = "False";
        } else if (pollType === "survey") {
          pollSelections = surveyPolls;
        } else {
          pollOptions.forEach((option, index) => {
            const key = String.fromCharCode(65 + index);
            pollSelections[key] = option;
          });
        }

        if (pollType === "survey") {
          preContent = {
            assetType: assetType,
            selections: pollSelections,
            pollType: pollType,
          };
        } else if (pollType === "submit_button") {
          preContent = {
            assetType: assetType,
            pollType: pollType,
          };
        } else if (pollType === "limited-multi") {
          if (!pollMaxSelections || pollMaxSelections < 1) {
            setError("Please set a valid maximum number of selections");
            return;
          }
          preContent = {
            assetType: assetType,
            question: pollQuestion,
            selections: pollSelections,
            pollType: pollType,
            maxSelections: pollMaxSelections,
          };
        } else {
          preContent = {
            assetType: assetType,
            question: pollQuestion,
            selections: pollSelections,
            answer:
              pollType === "regular" || pollType === "multi"
                ? null
                : pollAnswer,
            pollType: pollType,
            allowMultiSelect: pollType === "multi",
          };
        }

        console.log("Poll content being saved:", preContent);
      }
    } else if (assetType === "SpeechAsset") {
      preContent = {
        assetType: "SpeechAsset",
        mediaType: "file-audio",
        mediaContent: media,
      };
    } else if (assetType === "auto-asset" && generateAssestOption === "quiz") {
      preContent = {
        assetType: "quiz",
        quizzes: quizsSelected,
        apiCall: quizUrl,
      };

      for (let i = 0; i < quizsSelected.length; i++) {
        const assetData = {
          AssetName: assetName,
          AssetType: assetType,
          Caption: caption,
          AssetContent: JSON.stringify({
            assetType: generateAssestOption,
            question: quizsSelected[i].question,
            ...(quizsSelected[i].type.includes("True or False") && {
              selections: { A: "True", B: "False" },
            }),
            ...(quizsSelected[i].type.includes("Multiple Choice") && {
              selections: quizsSelected[i].selections,
            }),
            ...(quizsSelected[i].type.includes("Fill in the blank") && {
              selections: { A: "Fill in the blank" },
            }),
            answer: quizsSelected[i].answer,
            apiCall: quizUrl,
          }),
          BrandID: brandId,
        };

        axios
          .post("https://genz-staging.feeltiptop.com/api/asset/addAsset", assetData, {
            headers: {
              Authorization: "Bearer " + props.token,
            },
          })
          .then((response) => {
            console.log("Asset created successfully!");
          })
          .catch((error) => {
            console.error("Error creating asset:", error);
            if (error.response && error.response.data.message) {
              setError(error.response.data.message);
            } else {
              setError("Error creating asset: " + error.message);
            }
          });
      }
      alert("Assets created successfully!");
      navigate(0);
      return;
    } else if (assetType === "media") {
      preContent = {
        assetType: "media",
        mediaType: mediaType,
        mediaContent: mediaType.includes("file") ? media : mediaUrl,
      };
    }

    const assetData = {
      AssetName: assetName,
      AssetType: assetType,
      Caption: caption,
      AssetContent: JSON.stringify(preContent),
      BrandID: brandId,
      PublicAsset: publicAsset,
    };

    axios
      .post("https://genz-staging.feeltiptop.com/api/asset/addAsset", assetData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      })
      .then((response) => {
        alert("Asset # " + response.data.asset_id + " created successfully!");
        handleReset();
      })
      .catch((error) => {
        console.error("Error creating asset:", error);
                if (error.response && error.response.data.message) {
          setError(error.response.data.message);
        } else {
          setError("Error creating asset: " + error.message);
        }
      });
  };

  return (
    <div className="AssetForm min-h-screen bg-gray-50 p-4">
      <header className="AssetForm-header mb-6">
        <img
          src={logo}
          className="Ambassco-logo mb-4 mr-5 h-32 w-32"
          alt="logo"
        />
        <div className="formTitle text-l text-center font-bold">
          Asset Generation Form
        </div>
      </header>

      <div className="mb-4 text-lg">
        Hello <span className="font-bold">{user}</span> 👋
      </div>

      <form
        onSubmit={handleSubmit}
        className="rounded-lg border border-gray-200 bg-white p-6 shadow-md"
      >
        <label
          htmlFor="assetName"
          className="mb-2 block font-medium text-gray-700"
        >
          Asset Name:
        </label>
        <input
          type="text"
          id="assetName"
          value={assetName}
          onChange={(e) => setAssetName(e.target.value)}
          required
          className="mb-4 w-full rounded-lg border border-gray-300 p-2 focus:ring focus:ring-blue-200"
        />

        <label
          htmlFor="assetType"
          className="mb-2 block font-medium text-gray-700"
        >
          Select Asset Type:
        </label>
        <select
          id="assetType"
          value={assetType}
          onChange={(e) => setAssetType(e.target.value)}
          className="mb-4 w-full rounded-lg border border-gray-300 p-2 focus:ring focus:ring-blue-200"
        >
          <option value="">Select Type...</option>
          <option value="media">Media</option>
          <option value="poll">Poll</option>
          <option value="text">Text</option>
          <option value="auto-asset">Auto Asset</option>
          <option value="SpeechAsset">Speech Asset</option>
        </select>

        <label
          htmlFor="caption"
          className="mb-2 block font-medium text-gray-700"
        >
          Caption:
        </label>
        <textarea
          id="caption"
          value={caption}
          onChange={(e) => setCaption(e.target.value)}
          className="mb-4 w-full rounded-lg border border-gray-300 p-2 focus:ring focus:ring-blue-200"
        />

        {assetType === "poll" && pollType !== "submit_button" && (
          <div className="poll-section mb-4">
            {pollType !== "survey" && (
              <>
                <label className="mb-2 block font-medium text-gray-700">
                  Poll Question:
                </label>
                <input
                  type="text"
                  value={pollQuestion}
                  onChange={(e) => setPollQuestion(e.target.value)}
                  className="mb-4 w-full rounded-lg border border-gray-300 p-2 focus:ring focus:ring-blue-200"
                  placeholder="Enter your poll question"
                  required
                />
              </>
            )}

            <label className="mb-2 block font-medium text-gray-700">
              Poll Type:
            </label>
            <select
              value={pollType}
              onChange={(e) => setPollType(e.target.value)}
              className="mb-4 w-full rounded-lg border border-gray-300 p-2 focus:ring focus:ring-blue-200"
            >
              <option value="regular">One Answer Poll</option>
              <option value="multi">Multi Answer Poll</option>
              <option value="limited-multi">Limited Multi Answer Poll</option>
              <option value="quiz">Multiple Choice Quiz</option>
              <option value="binary">True/False Quiz</option>
              <option value="free_response">Free Response</option>
              <option value="survey">Survey</option>
              <option value="submit_button">Submit</option>
            </select>

            {pollType === "limited-multi" && (
              <div className="mb-4">
                <label className="mb-2 block font-medium text-gray-700">
                  Maximum number of selections:
                </label>
                <input
                  type="number"
                  value={pollMaxSelections}
                  onChange={(e) => setPollMaxSelections(parseInt(e.target.value))}
                  min="1"
                  className="w-full rounded-lg border border-gray-300 p-2 focus:ring focus:ring-blue-200"
                />
              </div>
            )}

            {pollType === "submit_button" && (
              <div className="mb-4">
                <p className="text-sm text-gray-600">
                  This will add a submit button to the asset
                </p>
              </div>
            )}

            {pollType !== "free_response" && pollType !== "submit" && (
              <div className="poll-options">
                {pollType !== "survey" && pollType !== "submit_button" && (
                  <label className="mb-2 block font-medium text-gray-700">
                    Options:
                  </label>
                )}

                {pollType === "binary" ? (
                  <div className="mb-4">
                    <div className="mb-2 flex items-center space-x-2">
                      <span className="w-20">True</span>
                      <input
                        type="radio"
                        name="correctAnswer"
                        value="A"
                        checked={pollAnswer === "A"}
                        onChange={(e) => setPollAnswer(e.target.value)}
                        className="ml-2"
                      />
                    </div>
                    <div className="flex items-center space-x-2">
                      <span className="w-20">False</span>
                      <input
                        type="radio"
                        name="correctAnswer"
                        value="B"
                        checked={pollAnswer === "B"}
                        onChange={(e) => setPollAnswer(e.target.value)}
                        className="ml-2"
                      />
                    </div>
                  </div>
                ) : pollType === "survey" ? (
                  <PollSurvey
                    surveyPolls={surveyPolls}
                    setSurveyPolls={setSurveyPolls}
                  />
                ) : pollType === "submit_button" ? (
                  <div className="mb-4">
                  </div>
                ) : (
                  <>
                    {pollOptions.map((option, index) => (
                      <div
                        key={index}
                        className="mb-2 flex items-center space-x-2"
                      >
                        <input
                          type="text"
                          value={option}
                          onChange={(e) =>
                            handleOptionChange(index, e.target.value)
                          }
                          className="flex-1 rounded-lg border border-gray-300 p-2 focus:ring focus:ring-blue-200"
                          placeholder={`Option ${String.fromCharCode(65 + index)}`}
                          required
                        />
                        {pollType === "quiz" && (
                          <input
                            type="radio"
                            name="correctAnswer"
                            value={String.fromCharCode(65 + index)}
                            checked={
                              pollAnswer === String.fromCharCode(65 + index)
                            }
                            onChange={(e) => setPollAnswer(e.target.value)}
                            className="ml-2"
                          />
                        )}
                        {pollOptions.length > 2 && (
                          <button
                            type="button"
                            onClick={() => handleRemoveOption(index)}
                            className="rounded-lg bg-red-500 px-3 py-2 text-white hover:bg-red-600"
                          >
                            Remove
                          </button>
                        )}
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={handleAddOption}
                      className="mt-2 rounded-lg bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
                    >
                      Add Option
                    </button>
                  </>
                )}
              </div>
            )}

            {pollType === "free_response" && (
              <div className="mb-4 mt-2">
                <p className="text-sm text-gray-600">
                  Users will be able to type in their own response to this
                  question
                </p>
              </div>
            )}

            {pollType === "quiz" && (
              <div className="mt-2 text-sm text-gray-600">
                Select the radio button next to the correct answer
              </div>
            )}

            {pollType === "multi" && (
              <div className="mb-4 mt-2">
                <p className="text-sm text-gray-600">
                  Users will be able to select multiple options in this poll
                </p>
              </div>
            )}

            {pollType === "limited-multi" && (
              <div className="mb-4 mt-2">
                <p className="text-sm text-gray-600">
                  Users will be able to select up to {pollMaxSelections} options in this poll
                </p>
              </div>
            )}

            {pollType === "regular" && (
              <div className="mb-4 mt-2">
                <p className="text-sm text-gray-600">
                  Users will be able to select one option in this poll
                </p>
              </div>
            )}
          </div>
        )}

        {assetType === "auto-asset" && (
          <div className="mb-4">
            <select
              value={generateAssestOption}
              onChange={(e) => setGenerateAssestOption(e.target.value)}
              className="w-full rounded-lg border border-gray-300 p-2 focus:ring focus:ring-blue-200"
            >
              <option value="Default">Select Option..</option>
              <option value="quiz">Quiz</option>
            </select>

            {generateAssestOption === "quiz" && (
              <Quiz
                setQuizData={setQuizData}
                generateAssests={generateAssests}
                quizOptions={quizOptions}
                setQuizesSelected={setQuizesSelected}
                quizsSelected={quizsSelected}
              />
            )}
          </div>
        )}

        {assetType === "text" && (
          <Text textContent={textContent} setTextContent={setTextContent} />
        )}

        {assetType === "SpeechAsset" && (
          <div className="mb-4">
            <button
              onClick={() => generateTTS(caption)}
              type="button"
              disabled={!caption || TTSLoading}
              className="rounded-lg bg-blue-500 px-4 py-2 font-medium text-white hover:bg-blue-600 disabled:bg-gray-300"
            >
              {TTSLoading ? "Generating..." : "Generate Audio TTS"}
            </button>
          </div>
        )}

        {assetType === "media" && (
          <div className="mb-4">
            <div className="mb-4">
              <label className="mr-4 inline-flex items-center">
                <input
                  type="radio"
                  value="file"
                  checked={mediaType === "file"}
                  onChange={handleMediaTypeChange}
                  className="mr-2"
                />
                Upload File
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  value="link"
                  checked={mediaType === "link"}
                  onChange={handleMediaTypeChange}
                  className="mr-2"
                />
                Provide Link
              </label>
            </div>

            {mediaType === "file" && (
              <div>
                <label
                  htmlFor="mediaFile"
                  className="mb-2 block font-medium text-gray-700"
                >
                  {media ? (
                    <img
                      src={media}
                      alt="Media File"
                      className="mediaInput mb-4 h-auto w-full rounded-lg"
                    />
                  ) : (
                    "Select Media File:"
                  )}
                </label>
                <input
                  type="file"
                  id="mediaFile"
                  accept="image/*, video/*, audio/*"
                  onChange={handleMediaChange}
                  className="mb-4 w-full rounded-lg border border-gray-300 p-2 focus:ring focus:ring-blue-200"
                />
              </div>
            )}

            {mediaType === "link" && (
              <div>
                <label
                  htmlFor="mediaUrl"
                  className="mb-2 block font-medium text-gray-700"
                >
                  Enter media URL:
                </label>
                <input
                  type="text"
                  id="mediaUrl"
                  value={mediaUrl}
                  onChange={handleMediaUrlChange}
                  placeholder="Enter media URL"
                  className="mb-4 w-full rounded-lg border border-gray-300 p-2 focus:ring focus:ring-blue-200"
                />
              </div>
            )}
          </div>
        )}

        <label htmlFor="brand" className="mb-2 block font-medium text-gray-700">
          Select Brand:
        </label>
        <Select
          options={allBrands}
          value={allBrands.find((brand) => brand.value === brandId)}
          onChange={handleBrandChange}
          isClearable={true}
          className="mb-4"
        />

        <div className="mb-4 text-left">
          <label
            htmlFor="assetName"
            className="mb-2 block font-medium text-gray-700"
          >
            Asset Visability:
          </label>

          <select
            onChange={(e) => setPublicAsset(e.target.value)}
            value={publicAsset}
            className="mb-4 block w-full rounded-lg border border-gray-300 p-2 focus:ring focus:ring-blue-200"
          >
            <option value="default">Select an asset Visability...</option>
            <option value="public"> Public</option>
            <option value="private"> Private</option>
          </select>
        </div>

        {error && (
          <div className="error-container relative mb-4 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700">
            <p className="error">{error}</p>
          </div>
        )}

        <div className="flex space-x-4">
          <button
            type="button"
            onClick={handleReset}
            className="rounded-lg bg-gray-500 px-4 py-2 text-white hover:bg-gray-600"
          >
            Reset
          </button>
          <button
            type="submit"
            className="rounded-lg bg-green-500 px-4 py-2 text-white hover:bg-green-600"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default AssetCreate;


