import React, { useState } from "react";
import axios from "axios";

function UploadResume() {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(""); // State to hold error messages

  const isValidFileType = (filename) => /\.(pdf|docx)$/i.test(filename);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && isValidFileType(selectedFile.name)) {
      setFile(selectedFile);
      setError(""); // Clear any previous errors
    } else {
      setError("Invalid file type. Please select a PDF or DOCX file.");
      setFile(null); // Clear the invalid file
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      setError("Please select a file first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", file.type);

    const token = localStorage.getItem("authToken");
    if (!token) {
      setError("No authentication token found. Please log in again.");
      return;
    }

    try {
      const response = await axios.post(
        "https://genz-staging.feeltiptop.com/api/upload_file",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      setMessage("File uploaded successfully!");
      setError(""); // Clear any errors
    } catch (error) {
      console.error("Error uploading file:", error);
      setError(
        error.response?.data?.error || 
        "Error uploading file. The resume format could not be extracted or processed. Please ensure it is in the correct format and try again."
      );
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <h2 className="text-2xl font-bold">Upload Your Resume</h2>
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-md rounded-md bg-white p-6 shadow-md"
      >
        <input
          type="file"
          accept=".pdf,.docx"
          onChange={handleFileChange}
          className="mb-4 rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm"
        />
        <button
          type="submit"
          className="w-full bg-emerald-500 py-2 text-white transition-colors duration-300 hover:bg-emerald-600"
        >
          Upload
        </button>
      </form>
      {/* Display the success message */}
      {message && <p className="mt-4 text-center text-sm text-green-500">{message}</p>}
      {/* Display the error message */}
      {error && <p className="mt-4 text-center text-sm text-red-500">{error}</p>}
    </div>
  );
}

export default UploadResume;