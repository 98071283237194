import React, { useState, useEffect } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const AssessmentView = ({ userId }) => {
  const [assessments, setAssessments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAssessments = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `https://genz-staging.feeltiptop.com/api/speech-assessment/${userId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response.data.success) {
          setAssessments(response.data.assessments);
        }
      } catch (err) {
        setError("Failed to fetch assessments");
        console.error("Error fetching assessments:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchAssessments();
  }, [userId]);

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="text-xl font-semibold text-gray-600">
          Loading assessments...
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="text-xl font-semibold text-red-600">{error}</div>
      </div>
    );
  }

  // Ensure all assessment scores are parsed into valid JSON objects
  const parsedAssessments = assessments.map((assessment) => {
    try {
      return {
        ...assessment,
        scores: JSON.parse(assessment.scores),
      };
    } catch (error) {
      return {
        ...assessment,
        scores: {},
      };
    }
  });

  const scoreKeys = parsedAssessments.length > 0 ? Object.keys(parsedAssessments[0].scores) : [];

  const sortedAssessments = [...parsedAssessments].sort(
    (a, b) => new Date(a.assessmentDate) - new Date(b.assessmentDate)
  );

  const baseColors = [
    "hsl(0, 100%, 50%)", // Red
    "hsl(120, 100%, 50%)", // Green
    "hsl(240, 100%, 50%)", // Blue
    "hsl(60, 100%, 50%)",  // Yellow
    "hsl(180, 100%, 50%)", // Cyan
  ];

  const getLightenedColor = (index) => {
    const baseColor = baseColors[index % baseColors.length];
    const lightnessAdjustment = 10 * Math.floor(index / baseColors.length);

    const hsl = baseColor.match(/hsl\((\d+), (\d+)%, (\d+)%\)/);
    if (hsl) {
      const hue = hsl[1];
      const saturation = hsl[2];
      let lightness = parseInt(hsl[3], 10) + lightnessAdjustment;
      lightness = Math.min(lightness, 100);
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    }
    return baseColor;
  };

  const chartData = {
    labels: sortedAssessments.map((a) =>
      new Date(a.assessmentDate).toLocaleDateString()
    ),
    datasets: scoreKeys.map((key, index) => ({
      label: key.replace(/([A-Z])/g, ' $1').trim(),
      data: sortedAssessments.map((a) => a.scores[key] || 0),
      borderColor: getLightenedColor(index),
      tension: 0.1,
    })),
  };

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          usePointStyle: true,
          boxWidth: 6,
          padding: 20,
          color: "#4B5563",
          font: {
            size: 12,
          },
        },
        backgroundColor: "transparent",
      },
    },
    scales: {
      y: {
        min: 0,
        max: 10,
        grid: {
          drawBorder: false,
        },
      },
      x: {
        grid: {
          drawBorder: false,
        },
      },
    },
  };

  return (
    <div className="container mx-auto mt-40 px-4 py-8">
      <h1 className="mb-12 text-3xl font-bold text-gray-800">
        Speech Assessments History
      </h1>

      <div className="mt-8 grid gap-6">
        {parsedAssessments.map((assessment) => (
          <div
            key={assessment.assessmentId}
            className="rounded-lg border border-gray-200 bg-white p-6 shadow-md transition-transform duration-200 hover:scale-[1.02]"
          >
            <div className="mb-4 flex items-center justify-between">
              <h2 className="text-xl font-semibold text-gray-800">
                Assessment #{assessment.assessmentId}
                <br />
                Prompt: {assessment.prompt}
              </h2>
              <span className="text-sm text-gray-500">
                {new Date(assessment.assessmentDate).toLocaleDateString()}
              </span>
            </div>

            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              {Object.keys(assessment.scores).map((key) => (
                <div key={key} className="space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">
                      {key.replace(/([A-Z])/g, ' $1').trim()}:
                    </span>
                    <span className="font-medium">
                      {typeof assessment.scores[key] === "number"
                        ? assessment.scores[key].toFixed(1)
                        : "!.0"}/10
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}

        {parsedAssessments.length === 0 && (
          <div className="rounded-lg border border-gray-200 bg-white p-6 text-center text-gray-500">
            No assessments found.
          </div>
        )}
      </div>

      {parsedAssessments.length > 0 && (
        <div
          className="mt-12 rounded-lg bg-white p-6"
          style={{ height: "800px", width: "1000px" }}
        >
          <Line data={chartData} options={chartOptions} />
        </div>
      )}
    </div>
  );
};

export default AssessmentView;
