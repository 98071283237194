import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import backArrow from "../assets/images/arrow_back.svg";
import "../App.css";
import { decryptfromHEX } from "./encodedecode.js";
import CryptoJS from "crypto-js";
import GenZLandingPageWallpaper from "../assets/images/GenZLandingPageWallpaper.svg";
import SnackBar from "../components/UX/SnackBar.js";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { TextField } from "@mui/material";
import { FormGroup } from "@mui/material";

function Login(props) {
  const [usernameOrEmail, setUsernameOrEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [errorBoolean, setErrorBoolean] = useState(false);
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  let feedID = null;

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  document.title = "GenZ Sign In";

  useEffect(() => {
    const checkExistingToken = async () => {
      const token = localStorage.getItem("authToken");
      if (token) {
        try {
          const response = await axios.get(
            "https://genz-staging.feeltiptop.com/api/verify-token",
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );

          if (response.data.valid) {
            props.setToken(token);
            const userDataResponse = await axios.get(
              "https://genz-staging.feeltiptop.com/api/user/getUserData",
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            );
            const { userID } = userDataResponse.data;

            if (searchParams.has("p")) {
              let encjson = searchParams.get("p");
              let jsondatastr = decryptfromHEX(encjson);
              let jsondata;
              try {
                jsondata = JSON.parse(jsondatastr);
                feedID = jsondata.feedID;
              } catch (error) {
                setError(
                  "It seems there's an issue with the feed link. Please check the formatting and try again.",
                );
                setErrorBoolean(true);
              }
            }
            await fetchData(token, userID);
          }
        } catch (error) {
          localStorage.removeItem("authToken");
          checkAutoLogin();
        }
      } else {
        checkAutoLogin();
      }
    };

    const checkAutoLogin = async () => {
      if (searchParams.has("p")) {
        let encjson = searchParams.get("p");
        let jsondatastr = decryptfromHEX(encjson);
        let jsondata;

        try {
          jsondata = JSON.parse(jsondatastr);
          feedID = jsondata.feedID;

          // Check if username and password are included in the encrypted data
          if (jsondata.username && jsondata.password) {
            setUsernameOrEmail(jsondata.username);
            // Don't set the password in state for security reasons
            // Instead, use it directly in the auto-login process
            await autoLogin(jsondata.username, jsondata.password, feedID);
          }
        } catch (error) {
          setError(
            "It seems there's an issue with the feed link. Please check the formatting and try again.",
          );
          setErrorBoolean(true);
        }
      }
    };

    checkExistingToken();
  }, [navigate, props, searchParams]);

  const autoLogin = async (username, password, feedID) => {
    setButtonClicked(true);

    try {
      const hashedPassword = CryptoJS.SHA256(password).toString();

      const response = await axios.post(
        "https://genz-staging.feeltiptop.com/api/user/login",
        {
          usernameOrEmail: username,
          password: hashedPassword,
        },
      );

      const { access_token, userID } = response.data;
      if (access_token) {
        localStorage.setItem("authToken", access_token);
        props.setToken(access_token);
        await fetchData(access_token, userID);
      } else {
        setError("Invalid login credentials in the link");
        setErrorBoolean(true);
        setButtonClicked(false);
      }
    } catch (error) {
      setError("Auto-login failed. Please log in manually.");
      setErrorBoolean(true);
      setButtonClicked(false);
    }
  };

  const fetchData = async (token, userID) => {
    try {
      console.log(userID, feedID);
      if (feedID == null) {
        navigate("/feed");
      }
      const feedSessionResponse = await axios.post(
        "https://genz-staging.feeltiptop.com/api/user/getFeedSession",
        {
          userID,
          feedID,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      const { feedID: sessionFeedID, feedStartTime } = feedSessionResponse.data;

      const countResponse = await axios.get(
        `https://genz-staging.feeltiptop.com/api/user/countUserStatus?feedId=${sessionFeedID}`,
      );
      const userStatusResponse = await axios.get(
        `https://genz-staging.feeltiptop.com/api/user/getUserStatus`,
        {
          params: {
            feedID: sessionFeedID,
            userID: userID,
          },
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      const { MaxStarted, MaxCompleted } = countResponse.data.statusCheck;

      if (userStatusResponse.data.UserStatus === "Completed") {
        //Here
        setStatusMessage("You have already completed the feed");
        if (searchParams.has("p")) {
          //Remove the params from the url and keep the base
          searchParams.delete("p");
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname,
          );
          console.log("navigaing to feed");
          navigate("/feed", {
            state: {
              feedCompleted: true,
            },
          });
        }

        return;
      }
      if (
        (MaxStarted || MaxCompleted) &&
        userStatusResponse.data.UserStatus !== "Started"
      ) {
        alert("Feed is already full");
        return;
      }

      const changeFeedUserStatusResponse = await axios.post(
        "https://genz-staging.feeltiptop.com/api/user/changeFeedUserStatus",
        {
          feedID: sessionFeedID,
          userID,
          feedStartTime,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      const { message, feedStartTime: updatedFeedStartTime } =
        changeFeedUserStatusResponse.data;
      navigate("/feed", {
        state: {
          feedID: sessionFeedID,
          feedStartTime: updatedFeedStartTime,
          userID,
        },
      });
    } catch (error) {
      console.error(error);
      if (error.response) {
        const statusCode = error.response.status;
        switch (statusCode) {
          case 400:
            // alert("Bad Request - The server could not understand the request due to invalid syntax.");
            break;

          case 401:
            alert(
              "Unauthorized - Authentication is required and has failed or has not yet been provided.",
            );
            break;
          case 403:
            alert(
              "Forbidden - You do not have the necessary permissions to access this resource.",
            );
            break;
          case 404:
            alert(
              "An error has occurred. Make sure that the link you have is correct.",
            );
            break;
          case 500:
            alert(
              "Internal Server Error - An unexpected condition was encountered on the server.",
            );
            break;
          default:
            console.log(
              `Error ${statusCode}: Something went wrong, please try again later.`,
            );
        }
      } else if (error.request) {
        alert(
          "No response was received from the server. Please check your network connection.",
        );
      } else {
        alert("An error occurred while setting up the request.");
      }
    }
  };

  const handleLogin = async () => {
    if (!buttonClicked) {
      setButtonClicked(true);

      if (searchParams.has("p")) {
        let encjson = searchParams.get("p");
        console.log("encjson: line 184", encjson);
        let jsondatastr = decryptfromHEX(encjson);
        let jsondata;
        try {
          jsondata = JSON.parse(jsondatastr);
          feedID = jsondata.feedID;
          console.log(feedID);
        } catch (error) {
          setError(
            "It seems there's an issue with the feed link. Please check the formatting and try again.",
          );
          setErrorBoolean(true);
        }
      }

      try {
        console.log(CryptoJS.SHA256(password).toString());
        const response = await axios.post(
          "https://genz-staging.feeltiptop.com/api/user/login",
          {
            usernameOrEmail,
            password: CryptoJS.SHA256(password).toString(),
          },
        );
        console.log(response.data);
        const { access_token, userID } = response.data;
        if (access_token) {
          localStorage.setItem("authToken", access_token);
          props.setToken(access_token);
          await fetchData(access_token, userID);
        } else {
          setError("Invalid Username/Email or password");
          setButtonClicked(false);
          setErrorBoolean(true);
        }
      } catch (error) {
        setError("Invalid Username/Email or password");
        setButtonClicked(false);
        setErrorBoolean(true);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (
      e.key === "Enter" &&
      usernameOrEmail.length > 1 &&
      password.length > 1
    ) {
      handleLogin();
    }
  };

  const handleInputUserEmailChange = (e) => {
    setUsernameOrEmail(e.target.value.trim());
    setButtonClicked(false);
  };

  const handleInputPasswordChange = (e) => {
    setPassword(e.target.value);
    setButtonClicked(false);
  };

  return (
    <div
      className="flex min-h-screen items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: `url(${GenZLandingPageWallpaper})` }}
    >
      <div className="relative mx-4 w-full max-w-lg rounded-lg border bg-white p-4 shadow-xl">
        <div className="mb-4">
          <Link to="/">
            <img src={backArrow} alt="Back" className="h-6 w-6" />
          </Link>
        </div>
        <h2 className="mb-6 text-center text-2xl font-semibold">Login</h2>

        <FormGroup
          onSubmit={handleLogin}
          onKeyDown={handleKeyPress}
          className="flex w-full flex-col space-y-4"
        >
          <div className="w-full">
            <TextField
              value={usernameOrEmail}
              onChange={handleInputUserEmailChange}
              fullWidth
              id="outlined-basic"
              label="Username or Email"
              variant="outlined"
              size="medium"
              error={errorBoolean}
            />
          </div>

          <div className="w-full">
            <FormControl fullWidth variant="outlined" error={errorBoolean}>
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handleInputPasswordChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={
                        showPassword
                          ? "hide the password"
                          : "display the password"
                      }
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseUpPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              {error && <FormHelperText error={true}>{error}</FormHelperText>}
            </FormControl>
          </div>
        </FormGroup>

        <p className="mt-2 mb-0 text-center text-sm">
          <Link to="/forgotpassword" className="text-[#FF2A2F] hover:underline">
            Forgot Password?
          </Link>
        </p>

        <button
          className={`mt-4 w-full rounded-lg px-4 py-2 font-semibold text-white ${
            usernameOrEmail.length === 0 ||
            password.length === 0 ||
            buttonClicked
              ? "cursor-not-allowed bg-gray-400"
              : "bg-blue-600 hover:bg-blue-700"
          }`}
          disabled={
            usernameOrEmail.length === 0 ||
            password.length === 0 ||
            buttonClicked
          }
          onClick={handleLogin}
        >
          Login
        </button>
      </div>

      {statusMessage !== "" && <SnackBar message={statusMessage} />}
    </div>
  );
}

export default Login;
