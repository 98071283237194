import React, { useState, useEffect } from 'react';
import { FaCalendarAlt, FaFileUpload, FaDownload, FaInfoCircle } from 'react-icons/fa';
import axios from 'axios';

function SyllabusComponent() {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(null);
  const [events, setEvents] = useState([]);

  // Fetch user's events on component mount
  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const token = localStorage.getItem('token');
      console.log(token);
      if (!token) {
        setError("You must be logged in to view events");
        return;
      }

      const response = await axios.get('https://genz-staging.feeltiptop.com/api/syllabus/events', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setEvents(response.data.events || []);
    } catch (err) {
      console.error("Error fetching events:", err);
      setError("Failed to load your events");
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setError("Please select a file to upload.");
      return;
    }
    setError(null);
    setUploading(true);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const token = localStorage.getItem('token');
      console.log(token);
      if (!token) {
        setError("You must be logged in to upload a syllabus");
        setUploading(false);
        return;
      }

      const res = await axios.post("https://genz-staging.feeltiptop.com/api/syllabus/upload", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });

      setResponseData(res.data);
      // Refresh events after successful upload
      fetchEvents();
    } catch (err) {
      console.error("Upload error:", err);
      setError(err.response?.data?.error || "An error occurred while uploading the file.");
    } finally {
      setUploading(false);
    }
  };

  // Format date for better display
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Format time for display
  const formatTime = (timeString) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return new Date(`2000-01-01T${timeString}`).toLocaleTimeString(undefined, options);
  };

  const handleDownload = (e) => {
    e.preventDefault();
    // Add token to download request
    const token = localStorage.getItem('token');
    const url = e.target.href;
    fetch(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = responseData.ics_file.split('/').pop();
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <div className="p-4">
      <div className="max-w-4xl mx-auto">
        {/* Upload Section */}
        <div className="bg-white rounded-lg shadow-md p-6 mb-4">
          <h2 className="text-xl font-semibold mb-4">Upload Syllabus</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <input
                type="file"
                onChange={handleFileChange}
                className="block w-full text-sm text-gray-500
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-md file:border-0
                  file:text-sm file:font-semibold
                  file:bg-indigo-50 file:text-indigo-700
                  hover:file:bg-indigo-100"
              />
            </div>
            <button
              type="submit"
              disabled={uploading}
              className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 disabled:opacity-50"
            >
              {uploading ? 'Processing...' : 'Upload and Process'}
            </button>
          </form>
        </div>

        {/* Events Display Section */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold mb-4">Course Schedule</h2>
          <div className="max-h-[60vh] overflow-y-auto">
            {events.length > 0 ? (
              <div className="space-y-2">
                {events.map((event, index) => (
                  <div 
                    key={event.id || index}
                    className="p-3 bg-gray-50 rounded-md hover:bg-gray-100 transition-colors"
                  >
                    <div className="flex items-start justify-between">
                      <div className="flex items-center space-x-3">
                        <div className="flex-shrink-0">
                          <FaCalendarAlt className="text-indigo-600" />
                        </div>
                        <div>
                          <p className="font-medium text-gray-900">
                            {new Date(event.date).toLocaleDateString()}
                          </p>
                          <p className="text-sm text-gray-600">{event.name}</p>
                        </div>
                      </div>
                      <div className="text-sm text-gray-500">
                        {event.time !== '00:00:00' && event.time.slice(0, 5)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-500 text-center">No events found</p>
            )}
          </div>
        </div>

        {/* Response Data Section */}
        {responseData && (
          <div className="mt-4 bg-white rounded-lg shadow-md p-6">
            <h3 className="text-lg font-semibold mb-4">Newly Added Events</h3>
            <div className="max-h-[40vh] overflow-y-auto">
              <div className="grid gap-3">
                {responseData.json_output.sorted.map((item, index) => (
                  <div 
                    key={index}
                    className="p-3 bg-gray-50 rounded-md"
                  >
                    <div className="flex items-center justify-between">
                      <div>
                        <p className="font-medium">{new Date(item.date).toLocaleDateString()}</p>
                        <p className="text-sm text-gray-600">{item.information}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {responseData.ics_file && (
              <div className="mt-4 text-center">
                <a
                  href={`https://genz-staging.feeltiptop.com/api/syllabus/download/ics/${responseData.ics_file.split('/').pop()}`}
                  className="inline-flex items-center px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                  download
                  onClick={handleDownload}
                >
                  <FaDownload className="mr-2" />
                  Download Calendar File (.ics)
                </a>
              </div>
            )}
          </div>
        )}

        {error && (
          <div className="mt-4 p-4 bg-red-50 text-red-600 rounded-md">
            {error}
          </div>
        )}
      </div>
    </div>
  );
}

export default SyllabusComponent; 