import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import moment from "moment-timezone";

const ClassSchedule = () => {
  const location = useLocation();
  const { userId, selectedDate } = location.state || {};
  const [classes, setClasses] = useState([]);
  const [existingClasses, setExistingClasses] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const [newClass, setNewClass] = useState({
    className: "",
    schedule: [{ dayOfWeek: "", startTime: "", endTime: "" }],
  });

  // Fetch user's current classes and pre-existing classes
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const userClassesResponse = await axios.get(
          `https://genz-staging.feeltiptop.com/api/users/${userId}/classes`,
        );
        setClasses(userClassesResponse.data);

        const existingClassesResponse = await axios.get(
          `https://genz-staging.feeltiptop.com/api/classes`,
        );
        setExistingClasses(existingClassesResponse.data);
      } catch (error) {
        console.error("Error fetching classes:", error);
      }
    };

    fetchClasses();
  }, [userId]);

  const handleScheduleChange = (index, field, value) => {
    const updatedSchedule = [...newClass.schedule];
    updatedSchedule[index][field] = value;
    setNewClass((prevClass) => ({
      ...prevClass,
      schedule: updatedSchedule,
    }));
  };

  const addScheduleRow = () => {
    setNewClass((prevClass) => ({
      ...prevClass,
      schedule: [
        ...prevClass.schedule,
        { dayOfWeek: "", startTime: "", endTime: "" },
      ],
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewClass((prevClass) => ({
      ...prevClass,
      [name]: value,
    }));
  };

  const handleAddClass = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `https://genz-staging.feeltiptop.com/api/users/${userId}/classes`,
        newClass,
      );
      setClasses((prevClasses) => [...prevClasses, response.data]);
      setNewClass({
        className: "",
        schedule: [{ dayOfWeek: "", startTime: "", endTime: "" }],
      });
    } catch (error) {
      console.error("Error adding class:", error);
    }
  };

  const handleSelectExistingClass = (selectedClass) => {
    if (!selectedClass || !selectedClass.Schedule) {
      console.error("Invalid class data:", selectedClass);
      return;
    }

    setNewClass({
      className: selectedClass.ClassName || "", // Default to an empty string if undefined
      schedule: (selectedClass.Schedule || []).map((item) => ({
        dayOfWeek: item.DayOfWeek,
        startTime: item.StartTime,
        endTime: item.EndTime,
      })),
    });
    setShowModal(false);
  };

  return (
    <div className="flex min-h-screen flex-col bg-gray-100 p-4 md:flex-row md:p-10">
      {/* Modal for selecting or creating a new class */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-full max-w-lg rounded bg-white p-6 shadow-md">
            <h3 className="mb-4 text-xl font-semibold">
              Select or Create a Class
            </h3>
            <button
              onClick={() => setShowModal(false)}
              className="mb-4 w-full rounded bg-blue-600 py-2 text-white"
            >
              Start a New Class
            </button>
            <h4 className="mb-2 font-semibold">Choose an Existing Class:</h4>
            {existingClasses.map((existingClass) => (
              <div
                key={existingClass.ClassID}
                className="cursor-pointer border-b p-2 hover:bg-gray-100"
                onClick={() => handleSelectExistingClass(existingClass)}
              >
                {existingClass.ClassName}
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Left side - Current Classes */}
      <div className="md:w-1/2 md:pr-5">
        <h2 className="mb-6 border-b pb-2 text-2xl font-semibold">
          Current Classes
        </h2>
        {classes.length > 0 ? (
          classes.map((classItem) => (
            <div
              key={classItem.ClassID}
              className="mb-4 rounded bg-white p-5 shadow"
            >
              <h3 className="mb-2 text-xl font-semibold">
                {classItem.ClassName}
              </h3>
              <div>
                {classItem.Schedule.map((scheduleItem, index) => {
                  const startTime = moment
                    .tz(scheduleItem.StartTime, "HH:mm:ss", "UTC")
                    .format("hh:mm A");
                  const endTime = moment
                    .tz(scheduleItem.EndTime, "HH:mm:ss", "UTC")
                    .format("hh:mm A");

                  return (
                    <p key={index} className="text-lg">
                      {scheduleItem.DayOfWeek} - {startTime} to {endTime}
                    </p>
                  );
                })}
              </div>
            </div>
          ))
        ) : (
          <p className="text-lg">No classes added yet.</p>
        )}
      </div>

      {/* Right side - Add New Class */}
      <div className="mt-8 md:mt-0 md:w-1/2 md:pl-5">
        <h2 className="mb-6 border-b pb-2 text-2xl font-semibold">
          Add A Class
        </h2>
        <form onSubmit={handleAddClass} className="rounded bg-white p-8 shadow">
          <div className="mb-5">
            <label className="mb-2 block">Name of class</label>
            <input
              type="text"
              name="className"
              value={newClass.className}
              onChange={(e) =>
                setNewClass({ ...newClass, className: e.target.value })
              }
              required
              className="w-full rounded border p-3 focus:border-blue-300 focus:outline-none focus:ring"
            />
          </div>

          {newClass.schedule.map((schedule, index) => (
            <div key={index} className="mb-5">
              <label className="mb-2 block">Day of the Week</label>
              <select
                name="dayOfWeek"
                value={schedule.dayOfWeek}
                onChange={(e) =>
                  handleScheduleChange(index, "dayOfWeek", e.target.value)
                }
                required
                className="w-full rounded border p-3 focus:border-blue-300 focus:outline-none focus:ring"
              >
                <option value="">Select a day</option>
                <option value="Monday">Monday</option>
                <option value="Tuesday">Tuesday</option>
                <option value="Wednesday">Wednesday</option>
                <option value="Thursday">Thursday</option>
                <option value="Friday">Friday</option>
                <option value="Saturday">Saturday</option>
                <option value="Sunday">Sunday</option>
              </select>

              <label className="mb-2 block">Start Time</label>
              <input
                type="time"
                name="startTime"
                value={schedule.startTime}
                onChange={(e) =>
                  handleScheduleChange(index, "startTime", e.target.value)
                }
                required
                className="w-full rounded border p-3 focus:border-blue-300 focus:outline-none focus:ring"
              />

              <label className="mb-2 block">End Time</label>
              <input
                type="time"
                name="endTime"
                value={schedule.endTime}
                onChange={(e) =>
                  handleScheduleChange(index, "endTime", e.target.value)
                }
                required
                className="w-full rounded border p-3 focus:border-blue-300 focus:outline-none focus:ring"
              />
            </div>
          ))}

          <button
            type="button"
            onClick={addScheduleRow}
            className="mt-4 w-full rounded bg-gray-300 py-2 transition-all duration-300 hover:scale-105"
          >
            Add Another Day
          </button>

          <button
            type="submit"
            className="mt-6 w-full rounded bg-blue-600 py-3 text-white transition-all duration-300 hover:scale-105 hover:bg-blue-700"
          >
            Add Class
          </button>
        </form>
      </div>
    </div>
  );
};

export default ClassSchedule;
