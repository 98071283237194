import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const RouteSwitcher = ({ routes }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const activeIndex = routes.findIndex((route) =>
    location.pathname.startsWith(route.path)
  );

  return (
    <div className="relative w-full">
      {/* Button container */}
      <div className="flex space-x-1 bg-gray-200 rounded-lg p-2">
        {routes.map((route, index) => (
          <button
            key={route.path}
            className={`flex-1 text-center p-2 font-medium rounded-lg ${
              activeIndex === index ? "bg-blue-500 text-white" : "bg-gray-300 text-gray-700"
            }`}
            onClick={() => navigate(route.path)}
          >
            {route.name}
          </button>
        ))}
      </div>

      {/* Page Content */}
      <div className="mt-6">
        <Outlet />
      </div>
    </div>
  );
};

export default RouteSwitcher;
