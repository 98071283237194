// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font-family: "Acumin-RPro";
    font-size: 1rem;
}

.AssetForm-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
 }

.formName {
    margin-top: 2rem;
    font-size: 2rem;
}

.formElements {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.formElements label {
    margin-top: 10px;
}

.formElements input {
    margin-bottom: 10px;
}

.reset,
.submit {
    font-family: "Acumin-RPro";
}

/* Custom transition for the slider background */
.slider {
    transition: transform 0.3s ease-in-out;
  }
  
  button.active {
    background-color: #3b82f6; /* Tailwind's bg-blue-500 color */
  }
  
  button.inactive {
    background-color: #9ca3af; /* Tailwind's bg-gray-400 color */
  }
  

`, "",{"version":3,"sources":["webpack://./src/formStyle.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;CAC1B;;AAED;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;;IAEI,0BAA0B;AAC9B;;AAEA,gDAAgD;AAChD;IACI,sCAAsC;EACxC;;EAEA;IACE,yBAAyB,EAAE,iCAAiC;EAC9D;;EAEA;IACE,yBAAyB,EAAE,iCAAiC;EAC9D","sourcesContent":["body {\n    font-family: \"Acumin-RPro\";\n    font-size: 1rem;\n}\n\n.AssetForm-header {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n }\n\n.formName {\n    margin-top: 2rem;\n    font-size: 2rem;\n}\n\n.formElements {\n    display: flex;\n    flex-direction: column;\n    align-items: left;\n}\n\n.formElements label {\n    margin-top: 10px;\n}\n\n.formElements input {\n    margin-bottom: 10px;\n}\n\n.reset,\n.submit {\n    font-family: \"Acumin-RPro\";\n}\n\n/* Custom transition for the slider background */\n.slider {\n    transition: transform 0.3s ease-in-out;\n  }\n  \n  button.active {\n    background-color: #3b82f6; /* Tailwind's bg-blue-500 color */\n  }\n  \n  button.inactive {\n    background-color: #9ca3af; /* Tailwind's bg-gray-400 color */\n  }\n  \n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
