import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  TextField,
  Button,
  FormGroup,
  Container,
  Typography,
  Paper,
} from "@mui/material";
import backArrow from "../assets/images/arrow_back.svg";
import GenZLandingPageWallpaper from "../assets/images/GenZLandingPageWallpaper.svg";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleSubmit = async () => {
    setButtonClicked(true);
    setMessage("");
    setError("");
  
    try {
      const response = await axios.post(
        "https://genz-staging.feeltiptop.com/api/user/forgotPassword",
        { email }
      );
  
      if (response.status === 200) {
        setMessage("A password reset link has been sent to your email.");
      } else {
        setError(response.data.message || "Something went wrong. Please try again.");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setError("Please enter a valid email address.");
        } else if (error.response.status === 404) {
          setError("No account found with this email.");
        } else if (error.response.status === 429) {
          setError("Too many requests. Please try again in an hour.");
          setButtonClicked(true); // Disable button if limit is reached
        } else {
          setError("An unexpected error occurred. Please try again later.");
        }
      } else {
        setError("Network error. Please check your connection and try again.");
      }
    } finally {
      if (error?.response?.status !== 429) {
        setButtonClicked(false); // Only re-enable the button if not rate limited
      }
    }
  };  
  

  return (
    <div
      className="flex min-h-screen items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: `url(${GenZLandingPageWallpaper})` }}
    >
      <div className="relative mx-4 w-full max-w-lg rounded-lg border bg-white p-4 shadow-xl">
        <div className="mb-4">
          <Link to="/">
            <img src={backArrow} alt="Back" className="h-6 w-6" />
          </Link>
        </div>

        <h2 className="mb-6 text-center text-2xl font-semibold">
          Forgot Password
        </h2>

        <FormGroup className="flex w-full flex-col space-y-4">
          <div className="w-full">
            <TextField
              type="email"
              label="Enter your email"
              variant="outlined"
              fullWidth
              size="medium"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </FormGroup>

        <button
          className={`mt-4 w-full rounded-lg px-4 py-2 font-semibold text-white ${
            email.length === 0 || buttonClicked
              ? "cursor-not-allowed bg-gray-400"
              : "bg-blue-600 hover:bg-blue-700"
          }`}
          disabled={email.length === 0 || buttonClicked}
          onClick={handleSubmit}
        >
          Send Reset Link
        </button>

        {message && (
          <p className="mt-4 text-center text-sm text-green-600">{message}</p>
        )}
        {error && (
          <p className="mt-4 text-center text-sm text-red-600">{error}</p>
        )}
      </div>
    </div>
  );
}

export default ForgotPassword;
