import React, { useState } from "react";

const CourseInputForm = ({ classes, setClasses, userId }) => {
  const [className, setClassName] = useState("");
  const [classNumber, setClassNumber] = useState("");

  const formatClassNumber = (input) => {
    // Remove any spaces and non-digit/colon characters
    const cleaned = input.replace(/[^\d:]/g, '');
    
    // Add colons automatically if they're typing numbers
    const numbers = cleaned.split(':');
    return numbers.join(':');
  };

  const handleClassNumberChange = (e) => {
    const formatted = formatClassNumber(e.target.value);
    setClassNumber(formatted);
  };

  const handleAddClass = async () => {
    if (!className) return alert("Class name is required!");
    if (!classNumber) return alert("Class number is required!");

    const newClass = { id: Date.now(), className, classNumber };
    setClasses([...classes, newClass]);

    // Save to backend
    await fetch("https://genz-staging.feeltiptop.com/api/classes-planner", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        user_id: userId,
        class_name: className,
        class_number: classNumber,
      }),
    });

    setClassName("");
    setClassNumber("");
  };

  return (
    <div className="mb-6">
      <h2 className="mb-4 text-xl font-semibold">Add Classes</h2>
      <div className="flex flex-col gap-4">
        <input
          type="text"
          placeholder="Class Name"
          value={className}
          onChange={(e) => setClassName(e.target.value)}
          className="rounded border p-2"
        />
        <input
          type="text"
          placeholder="Class Number (e.g., 130:140:210)"
          value={classNumber}
          onChange={handleClassNumberChange}
          className="rounded border p-2"
          pattern="[\d:]*"  // Only allow digits and colons
        />
        <button
          onClick={handleAddClass}
          className="rounded bg-blue-500 px-4 py-2 text-white"
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default CourseInputForm;
