import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import backArrow from '../assets/images/arrow_back.svg';
import '../App.css';

function UploadProfilePicture() {
  const [image, setImage] = useState(null);
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const { state } = useLocation();
  const {phoneNumber, userName, password, firstName, lastName, gender, dob, ethnicity, selectedState, interests, email} = state;

  const handleBackArrow = () => {
    navigate('/emailaddress', { state: {phoneNumber, userName, password, firstName, lastName, gender, dob, ethnicity, selectedState, interests, email} });
  };

  const isValidFileType = (filename) => {
    return /\.(jpg|jpeg|png|gif|tiff)$/i.test(filename);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && isValidFileType(file.name)) {
      setError('');
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setError('Invalid file type. Please select an image file (jpg, jpeg, png, gif, tiff).');
      setImage(null);
    }
  };

  const handleSubmit = () => {
    console.log(interests); 
    navigate('/TermsAndConditions', { state: {phoneNumber, userName, password, firstName, lastName, gender, dob, ethnicity, selectedState, interests, email, image} });
  };

  return (
    <div className="signup-container">
      <div className="arrow-button">
          <img src={backArrow} alt="Logo" className="arrow" onClick={handleBackArrow}/>
      </div>
      <h2 className='title-verification2'>Upload a Profile Picture</h2>
      <h3 className='ProfilePicture_Sub-Text'>Adding a profile picture increases your chance of being selected for projects with brands :<span>&#41;</span></h3>
      <div className='profile-upload-container'>
      <label htmlFor="fileInput" className={image ? 'image-box2' : 'upload-box2'}>
        {image ? <img src={image} alt="Profile" className='profile-image' /> : 'Click Here to Upload'}
      </label>
        <input
          type="file"
          id="fileInput"
          className='file-input'
          accept="image/jpg, image/jpeg, image/png, image/gif, image/tiff"
          onChange={handleImageChange}
        />
      <h3 className='Optional'><span>&#40;</span>Optional<span>&#41;</span></h3>  
      </div>
      {error && (
        <div className="error-container">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 22C17.5228 22 22 12 22 6.47715 17.5228 2 12 2 6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 8V12" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 16H12.01" stroke="#DB4335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <p className='error'>{error}</p>
        </div>
      )}
      <button
        style={{marginTop:'200px'}}
        className={`button ${image ? 'red-background' : 'red-background'}`}
        onClick={handleSubmit}
      >
        {image ? 'Next' : 'Skip'}
      </button>
    </div>
  );
}

export default UploadProfilePicture;
