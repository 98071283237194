import React, { useState, useRef } from "react";
import axios from "axios";

// Configuration for different API modes
const apiModesConfig = [
  {
    value: "YTLinkToPDF",
    label: "YouTube Link w/ OCR and STT (Optional) - PDF",
    inputs: [
      { type: "youtubeUrl", label: "YouTube URL", placeholder: "Enter YouTube URL" },
      { type: "checkbox", label: "Use OCR", name: "useOcr" },
      { type: "checkbox", label: "Use STT", name: "useSTT" }
    ],
    apiHandler: async (formData) => {
      let useOCR = false
      let useSTT = false
      let YTLink = ""
      if (formData.useOcr) {
        useOCR = formData.useOcr;
      }
      if (formData.useSTT) {
        useSTT = formData.useSTT;
      }
      if (formData.youtubeUrl) {
        YTLink = formData.youtubeUrl
      }

      console.log(YTLink, useOCR, useSTT)
      return uploadYTLinkToPDF(YTLink, useOCR, useSTT)
    },
    responseType: "pdf",
  },
  {
    value: "VideoToPDF",
    label: "Upload File w/ OCR and STT (Optional) - PDF",
    inputs: [
      { type: "file", label: "Upload Video (MP4) (MP4)" },
      { type: "checkbox", label: "Use OCR", name: "useOcr" },
      { type: "checkbox", label: "Use STT", name: "useSTT" }
    ],
    apiHandler: async (formData) => {
      let useOCR = false
      let useSTT = false
      if (formData.useOcr) {
        useOCR = formData.useOcr;
      }
      if (formData.useSTT) {
        useSTT = formData.useSTT;
      }

      console.log(formData.file, useOCR, useSTT)
      return uploadChunkedFileToPDF(formData.file, useOCR, useSTT)
    },
    responseType: "pdf",
  },
  {
    value: "VideoToFrames",
    label: "Upload File - Frames + Timestamps",
    inputs: [{ type: "file", label: "Upload Video (MP4)" }],
    apiHandler: async (formData) => {
      return uploadChunkedFileToFrames(formData.file)
    },
    responseType: "json",
  },
  {
    value: "VideoTimestampToFrames",
    label: "Upload Video (MP4) and Timestamps - Frames + Timestamps",
    inputs: [
      { type: "file", label: "Upload Video (MP4)" },
      { type: "text", label: "Timestamps (comma-separated)", placeholder: "e.g., 00:00:05, 00:00:10", name: "pickle" },
    ],
    apiHandler: async (formData) => {
      console.log("Data: ", formData.text)
      return uploadChunkedFileAndTimestampsToFrames(formData.file, formData.text)
    },
    responseType: "json",
  },
];

function calculateFileHash(file) {
  return new Promise((resolve, reject) => {
    if (!(file instanceof Blob)) {
      reject('The provided object is not a valid Blob or File.');
      return;
    }
    const reader = new FileReader();

    // Use FileReader to read the file as an ArrayBuffer
    reader.readAsArrayBuffer(file);

    reader.onload = async function(event) {
      const arrayBuffer = event.target.result;
      
      try {
        // Compute the hash using the SubtleCrypto API
        const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);

        // Convert the hash buffer to a hexadecimal string
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

        resolve(hashHex);
      } catch (error) {
        reject('Error calculating file hash: ' + error);
      }
    };

    reader.onerror = function(error) {
      reject('Error reading file: ' + error);
    };
  });
}

const uploadChunkedFileToFrames = async (file) => {
  try {
    
    const initResponse = await axios.post("https://genz-staging.feeltiptop.com/api/init_upload/extract_key_frames", {
      fileName: file.name,
      fileSize: file.size,
      fileHash: await calculateFileHash(file),
    }, {
      headers: { "Content-Type": "application/json" }
    });

    console.log(initResponse.data)
    const { sessionId, chunkSize } = initResponse.data;

    const totalChunks = Math.ceil(file.size / chunkSize);
    for (let i = 0; i < totalChunks; i++) {
      const chunk = file.slice(i * chunkSize, (i + 1) * chunkSize);
      const formData = new FormData();
      formData.append("sessionId", sessionId);
      formData.append("chunkNumber", i);
      formData.append("file", chunk);

      const uploadResponse = await axios.post("https://genz-staging.feeltiptop.com/api/upload_chunk/extract_key_frames", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log(`Chunk ${i + 1} of ${totalChunks} uploaded:`, uploadResponse.data.message);
    }

    // Step 3: Poll the server for upload status or wait for a final result
    const statusResponse = await axios.get("https://genz-staging.feeltiptop.com/api/upload_results/extract_key_frames", {
      params: { sessionId },
    });

    if (statusResponse.data.completed) {
      console.log("Upload and processing completed:", statusResponse.data);
      return statusResponse.data
    }

  } catch (error) {
    console.error("Error during file upload:", error);
  }
};

const uploadChunkedFileToPDF = async (file, useOCR, useSTT) => {
  try {

    const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, '');
    
    const initResponse = await axios.post("https://genz-staging.feeltiptop.com/api/init_upload/file_to_pdf", {
      fileName: fileNameWithoutExtension,
      fileSize: file.size,
      fileHash: await calculateFileHash(file),
      use_ocr: useOCR,
      use_stt: useSTT,
    }, {
      headers: { "Content-Type": "application/json" }
    });

    console.log(initResponse.data)
    const { sessionId, chunkSize } = initResponse.data;

    const totalChunks = Math.ceil(file.size / chunkSize);
    for (let i = 0; i < totalChunks; i++) {
      const chunk = file.slice(i * chunkSize, (i + 1) * chunkSize);
      const formData = new FormData();
      formData.append("sessionId", sessionId);
      formData.append("chunkNumber", i);
      formData.append("file", chunk);

      const uploadResponse = await axios.post("https://genz-staging.feeltiptop.com/api/upload_chunk/file_to_pdf", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log(`Chunk ${i + 1} of ${totalChunks} uploaded:`, uploadResponse.data.message);
    }

    // Step 3: Poll the server for upload status or wait for a final result
    const statusResponse = await axios.get("https://genz-staging.feeltiptop.com/api/upload_results/file_to_pdf", {
      params: { sessionId },
    });

    if (statusResponse.data.completed) {
      console.log("Upload and processing completed:", statusResponse.data);
      return statusResponse.data
    }

  } catch (error) {
    console.error("Error during file upload:", error);
  }
};

const uploadChunkedFileAndTimestampsToFrames = async (file, timestamps) => {
  try {
    console.log(timestamps)
    const initResponse = await axios.post("https://genz-staging.feeltiptop.com/api/init_upload/extract_by_timestamps", {
      fileName: file.name,
      fileSize: file.size,
      fileHash: await calculateFileHash(file),
      timestamps: JSON.stringify(timestamps.split(',').map(t => t.trim()))
    }, {
      headers: { "Content-Type": "application/json" }
    });

    console.log(initResponse.data)
    const { sessionId, chunkSize } = initResponse.data;

    const totalChunks = Math.ceil(file.size / chunkSize);
    for (let i = 0; i < totalChunks; i++) {
      const chunk = file.slice(i * chunkSize, (i + 1) * chunkSize);
      const formData = new FormData();
      formData.append("sessionId", sessionId);
      formData.append("chunkNumber", i);
      formData.append("file", chunk);

      const uploadResponse = await axios.post("https://genz-staging.feeltiptop.com/api/upload_chunk/extract_by_timestamps", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log(`Chunk ${i + 1} of ${totalChunks} uploaded:`, uploadResponse.data.message);
    }

    // Step 3: Poll the server for upload status or wait for a final result
    const statusResponse = await axios.get("https://genz-staging.feeltiptop.com/api/upload_results/extract_by_timestamps", {
      params: { sessionId },
    });

    if (statusResponse.data.completed) {
      console.log("Upload and processing completed:", statusResponse.data);
      return statusResponse.data
    }

  } catch (error) {
    console.error("Error during file upload:", error);
  }
};

const uploadYTLinkToPDF = async (url, useOCR, useSTT) => {
  try {
    
    const response = await axios.post("https://genz-staging.feeltiptop.com/api/upload/process_yt_link", {
      url: url,
      use_ocr: useOCR,
      use_stt: useSTT,
    }, {
      headers: { "Content-Type": "application/json" }
    });

    if (response.data.completed) {
      console.log("Upload and processing completed:", response.data);
      return response.data
    }

  } catch (error) {
    console.error("Error during file upload:", error);
  }
};

const KeyFrameExtractor = () => {
  const [apiMode, setApiMode] = useState("");
  const [formData, setFormData] = useState({});
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfName, setPdfName] = useState("");
  const [formattedInfo, setFormattedInfo] = useState('');
  const fileInputRef = useRef(null);

  // Handle input change
  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  // Submit the form based on API mode
  const handleSubmit = async () => {
    const selectedMode = apiModesConfig.find(mode => mode.value === apiMode);
    if (!selectedMode) return;

    setLoading(true);
    setErrorMessage("");
    setPdfUrl("");
    setResult(null);

    try {
      const response = await selectedMode.apiHandler(formData);
      
      if (selectedMode.responseType === "pdf") {
        console.log("DunDa:", response);
      
        const byteCharacters = atob(response.pdf);  // atob decodes base64
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers); 
        const pdfBlob = new Blob([byteArray], { type: 'application/pdf' });
      
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
        setPdfName(response.pdf_name);

        

        console.log("results: ", response)
        if (response.info) {
          const formattedInfo = JSON.stringify(response.info, null, 2);
          setFormattedInfo(formattedInfo);
        }
        
      } else {
        setResult(response);
        console.log("response: ", response)
      }
    } catch (error) {
      setPdfUrl("");
      setResult(null);
      setErrorMessage('Failed to fetch the data. Please try again.');
      console.error('API call failed:', error);
    } finally {
      setLoading(false);
    }
  };

  // Render inputs based on selected API mode
  const renderInputs = () => {
    const selectedMode = apiModesConfig.find(mode => mode.value === apiMode);
    if (!selectedMode) return null;

    return selectedMode.inputs.map((input) => (
      <div key={input.name || input.type} style={styles.inputContainer}>
        <label style={styles.label}>
          {input.type === 'checkbox' ? (
            <>
              <input
                type="checkbox"
                name={input.name}
                checked={formData[input.name] || false}
                onChange={(e) => handleInputChange(input.name, e.target.checked)}
                style={styles.checkbox}
              />
              {input.label}
            </>
          ) : (
            <>
              {input.label}:
              <input
                type={input.type === "file" ? "file" : "text"}
                placeholder={input.placeholder || ""}
                onChange={(e) => handleInputChange(input.type, input.type === "file" ? e.target.files[0] : e.target.value)}
                style={styles.input}
                ref={input.type === "file" ? fileInputRef : null}
              />
            </>
          )}
        </label>
      </div>
    ));
  };

  // Render result based on response type
  const renderResult = () => {
    if (pdfUrl) {
      return (
        <div>
          <div style={{ marginTop: '20px' }}>
            <embed src={pdfUrl} type="application/pdf" width="100%" height="600px" />
            <button 
              style={{ marginBottom: '20px', marginTop: '20px' }} 
              onClick={() => {
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.download = `${pdfName}.pdf`;
                link.click();
              }}
            >
              Download PDF
            </button>
          </div>
          <div
            id="videoInfo"
            style={{ marginBottom: '20px', marginTop: '20px', maxWidth: '100vw', width: '100%' }}
          >
            <h2>Video Information</h2>
            <pre
              id="infoContent"
              style={{ marginBottom: '20px', marginTop: '20px', whiteSpace: 'pre-wrap', wordWrap: 'break-word', textAlign: 'left' }}
            >
              {formattedInfo}
            </pre>
            <button
              style={{ marginBottom: '20px', marginTop: '20px' }}
              onClick={() => {
                // Convert the JSON string into a properly formatted version with newlines
                const fileData = JSON.stringify(JSON.parse(formattedInfo), null, 2); // Parses and formats the JSON string
                
                const blob = new Blob([fileData], { type: 'application/json' }); // Create a Blob from the formatted string
                const url = URL.createObjectURL(blob); // Create a URL for the Blob
                const link = document.createElement('a'); // Create a temporary download link
                link.href = url;
                link.download = `${pdfName}.json`; // Set the default file name for download
                link.click(); // Trigger the download
                URL.revokeObjectURL(url); // Clean up the URL after the download
              }}
            >
              Download JSON
            </button>
          </div>

        </div>
      );
    } else if (result && result.frames) {
      return (
        <div id="frames" style={{ marginTop: '20px' }}>
          {result.frames.map((frame, index) => (
            <div key={index}>
              <img src={`data:image/jpeg;base64,${frame.image}`} alt={`Frame at ${frame.timestamp}`} style={{ maxWidth: '90vh', margin: '50px' }} />
              <p>Timestamp: {frame.timestamp}</p>
            </div>
          ))}
        </div>
      );
    } else if (result) {
      return <pre>{JSON.stringify(result, null, 2)}</pre>;
    }
    return null;
  };

  return (
    <div style={styles.container}>
      <form onSubmit={(e) => e.preventDefault()} style={styles.form}>
        <label style={styles.label}>Select Mode:</label>
        <select
          value={apiMode}
          onChange={(e) => setApiMode(e.target.value)}
          style={styles.select}
        >
          <option value="" disabled hidden>
            Select API Mode
          </option>
          {apiModesConfig.map((mode) => (
            <option key={mode.value} value={mode.value}>
              {mode.label}
            </option>
          ))}
        </select>

        {renderInputs()}

        <button
          type="button"
          onClick={handleSubmit}
          disabled={loading}
          style={loading ? styles.buttonDisabled : styles.button}
        >
          {loading ? "Processing..." : "Submit"}
        </button>
      </form>

      {errorMessage && <div style={styles.error}>{errorMessage}</div>}
      <div id="resultDisplay" style={styles.result}>
        {renderResult()}
      </div>
    </div>
  );
};

export default KeyFrameExtractor;

// Inline styles
const styles = {
  container: {
    maxWidth: "85vw",
    margin: "auto",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "10px",
    backgroundColor: "#f9f9f9",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  label: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#333",
  },
  input: {
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    fontSize: "14px",
    width: "100%",
  },
  select: {
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    fontSize: "14px",
    width: "100%",
  },
  inputContainer: {
    marginBottom: "10px",
  },
  button: {
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#28a745",
    color: "#fff",
    fontSize: "16px",
    cursor: "pointer",
  },
  buttonDisabled: {
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#6c757d",
    color: "#fff",
    fontSize: "16px",
  },
  result: {
    marginTop: "20px",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    backgroundColor: "#fff",
    minHeight: "50px",
  },
  error: {
    color: "red",
  },
};
