import React, { useState, useEffect } from "react";
import CourseInputForm from "../components/planner/CourseInputForm";
import YearTabs from "../components/planner/YearTabs";
import ProgressBar from "../components/planner/ProgressBar";
import Sidebar from "../components/planner/Sidebar";
import DraggableCourse from "../components/planner/DraggableCourse";
import { useLocation } from "react-router-dom";

const CoursePlanner = () => {
  const location = useLocation();
  const userId = location.state?.userId;
  const [classes, setClasses] = useState([]);
  const [coursePlan, setCoursePlan] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [years, setYears] = useState([1]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const [classesResponse, coursePlanResponse] = await Promise.all([
          fetch(`https://genz-staging.feeltiptop.com/api/classes-pl?user_id=${userId}`),
          fetch(`https://genz-staging.feeltiptop.com/api/course-plans?user_id=${userId}`),
        ]);

        if (!classesResponse.ok) throw new Error("Failed to fetch classes");
        if (!coursePlanResponse.ok) throw new Error("Failed to fetch course plan");

        const classesData = await classesResponse.json();
        const coursePlanData = await coursePlanResponse.json();

        console.log("Loaded Classes:", classesData);
        console.log("Loaded Course Plan:", coursePlanData);

        setClasses(classesData);
        setCoursePlan(coursePlanData);

        if (coursePlanData && coursePlanData.length > 0) {
          const maxYear = Math.max(...coursePlanData.map(course => course.year));
          setYears(Array.from({ length: maxYear }, (_, i) => i + 1));
        } else {
          setYears([1]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  const getAvailableCourses = () => {
    const placedCourseIds = new Set(coursePlan.map(course => course.classId));
    const unplacedCourses = classes.filter(course => !placedCourseIds.has(course.id));
    
    if (!searchQuery) return unplacedCourses;

    const query = searchQuery.toLowerCase();
    return unplacedCourses.filter(course => 
      course.className.toLowerCase().includes(query) || 
      course.classNumber.toLowerCase().includes(query)
    );
  };

  const handleDeleteCourse = async (courseId) => {
    try {
      // Delete from backend
      const response = await fetch(`https://genz-staging.feeltiptop.com/api/classes-planner`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user_id: userId,
          class_id: courseId,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to delete course');
      }

      // Remove from classes state
      setClasses(classes.filter(course => course.id !== courseId));
      
      // Remove from course plan if it was placed in a semester
      setCoursePlan(coursePlan.filter(plan => plan.classId !== courseId));
      
    } catch (error) {
      console.error('Error deleting course:', error);
      alert('Failed to delete course');
    }
  };

  // Add this helper function to format the data
  const formatCoursePlanForExport = (coursePlan, classes) => {
    // Group courses by year and semester
    const organizedPlan = coursePlan.reduce((acc, plan) => {
      const year = plan.year;
      const semester = plan.semester;
      const course = classes.find(c => c.id === plan.classId);
      
      if (!acc[year]) acc[year] = { Fall: [], Spring: [] };
      
      if (course) {
        acc[year][semester].push({
          number: course.classNumber,
          name: course.className
        });
      }
      
      return acc;
    }, {});

    // Create CSV content
    let csvContent = "Year,Semester,Course Number,Course Name\n";
    
    Object.keys(organizedPlan).sort().forEach(year => {
      ['Fall', 'Spring'].forEach(semester => {
        const courses = organizedPlan[year][semester];
        if (courses.length > 0) {
          courses.forEach(course => {
            csvContent += `${year},${semester},${course.number},"${course.name}"\n`;
          });
        }
      });
    });

    return csvContent;
  };

  // Add export function
  const handleExport = () => {
    const csvContent = formatCoursePlanForExport(coursePlan, classes);
    
    // Create blob and download link
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    
    // Create download link
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, 'course_plan.csv');
    } else {
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', 'course_plan.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // Add import function
  const handleImport = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Verify file type
    if (file.type !== 'text/csv') {
      alert('Please upload a CSV file');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('user_id', userId);

      const response = await fetch('https://genz-staging.feeltiptop.com/api/import-course-plan', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to import course plan');
      }

      // Refresh the data
      const [classesResponse, coursePlanResponse] = await Promise.all([
        fetch(`https://genz-staging.feeltiptop.com/api/classes-pl?user_id=${userId}`),
        fetch(`https://genz-staging.feeltiptop.com/api/course-plans?user_id=${userId}`),
      ]);

      const classesData = await classesResponse.json();
      const coursePlanData = await coursePlanResponse.json();

      setClasses(classesData);
      setCoursePlan(coursePlanData);

      // Update years based on imported data
      if (coursePlanData && coursePlanData.length > 0) {
        const maxYear = Math.max(...coursePlanData.map(course => course.year));
        setYears(Array.from({ length: maxYear }, (_, i) => i + 1));
      }

      alert('Course plan imported successfully!');
    } catch (error) {
      console.error('Import error:', error);
      alert('Failed to import course plan');
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        isOpen={isSidebarOpen}
        onToggle={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        <div className="space-y-6">
          {/* Course Input Form Section */}
          <div className="border-b border-gray-200 pb-4">
            <h3 className="mb-2 text-lg font-semibold">Add New Course</h3>
            <CourseInputForm
              classes={classes}
              setClasses={setClasses}
              userId={userId}
            />
          </div>

          {/* Available Courses Section */}
          <div>
            <h3 className="mb-2 text-lg font-semibold">Available Courses</h3>
            {/* Add Search Bar */}
            <div className="mb-2">
              <input
                type="text"
                placeholder="Search by name or number..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full rounded border border-gray-300 p-2 text-sm focus:border-blue-500 focus:outline-none"
              />
            </div>
            {/* Courses List */}
            <div className="space-y-1 max-h-[calc(100vh-300px)] overflow-y-auto">
              {getAvailableCourses().map((course) => (
                <DraggableCourse 
                  key={course.id} 
                  course={course} 
                  onDelete={handleDeleteCourse}
                />
              ))}
            </div>
          </div>
        </div>
      </Sidebar>

      {/* Main Content */}
      <div className="flex-1 overflow-auto p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold">4-Year Course Planner</h1>
          <div className="flex gap-4">
            {/* Import Button */}
            <label className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
              </svg>
              Import Plan
              <input
                type="file"
                accept=".csv"
                className="hidden"
                onChange={handleImport}
              />
            </label>
            
            {/* Export Button */}
            <button
              onClick={handleExport}
              className="flex items-center gap-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
              Export Plan
            </button>
          </div>
        </div>
        
        <YearTabs
          classes={classes}
          coursePlan={coursePlan}
          setCoursePlan={setCoursePlan}
          userId={userId}
          initialYears={years}
        />
        <ProgressBar classes={classes} coursePlan={coursePlan} />
      </div>
    </div>
  );
};

export default CoursePlanner;
