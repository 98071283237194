import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import axios from "axios";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import logo from "../assets/images/GenZ_Logo.svg";
import { ReactComponent as SharelinkIcon } from "../assets/svg/sharelink-icon.svg";
import logoutIcon from "../assets/svg/logout-icon.svg";
import TextComponent from "../components/TextComponent";
import FeedCommentBox from "../components/FeedCommentBox";
import commentImage from "../assets/reaction_images/Comment.svg";
import commentImageClicked from "../assets/reaction_images/CommentClicked.svg";
import PollComponent from "../components/PollComponent";
import MediaComponent from "../components/MediaComponent";
import SpeechAssessmentComponent from "../components/SpeechAssessmentComponent";
import ServiceRequestFeed from "../components/SellerDashboard/ServiceRequestFeed";
import SubmitFeedComponent from "../components/SubmitFeedComponent";
import SnackBar from "../components/UX/SnackBar";

import OptionsDisplay from "../components/payment/OptionsDisplay";
import { CreditCardIcon } from "@heroicons/react/24/solid";

import FeedPollSurvey from "../components/assets/poll/FeedPollSurvey";

import "./FeedStyling.css";

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

const Feed = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();

  const [error, setError] = useState("");
  const [currentComment, setCurrentComment] = useState("");
  const [timer, setTimer] = useState("0:00");
  const [sessionEndTime, setSessionEndTime] = useState("");
  const [feedStartTime, setFeedStartTime] = useState("");

  const [currentFeedID, setCurrentFeedID] = useState(1);
  const [currentUserID, setCurrentUserID] = useState(1);

  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isFeedComplete, setIsFeedComplete] = useState(false);
  const [feedIdInUrl, setFeedIdInUrl] = useState(false);
  const [openPaymentBox, setOpenPaymentBox] = useState(false); // State to control the visibility of the payment box

  const [posts, setPosts] = useState([]);
  const [feedIdQueue, setFeedIdQueue] = useState([]);

  const [commentSubmitted, setCommentSubmitted] = useState({});

  const [activeAssetId, setActiveAssetId] = useState(null);
  const [caption, setCaption] = useState("");
  const [seen, setSeen] = useState(new Set());

  const [offSet, setOffSet] = useState(0);
  const [endReached, setEndReached] = useState(false);

  const [isSpeechAsset, setIsSpeechAsset] = useState(false);

  const [speechAssets, setSpeechAssets] = useState({});

  // Flag to determine if any speech assets exist in the posts
  const [hasSpeechAssets, setHasSpeechAssets] = useState(false);

  document.title = "360 Feed";

  //Logic to keep track of the asset in view
  const assetRefs = useRef([]); // Array of refs for each post
  const [visiblePosts, setVisiblePosts] = useState([]);

  useEffect(() => {
    assetRefs.current = posts.map(
      (_, i) => assetRefs.current[i] || React.createRef(),
    );

    // When posts change, check if each post is a speech asset
    const updatedSpeechAssets = posts.reduce(
      (acc, post) => {
        if (!post.AssetContent) return acc; // Skip if AssetContent is None
        try {
          const assetContent = JSON.parse(post.AssetContent);
          if (
            assetContent.assetType === "SpeechAsset" ||
            assetContent.pollType === "submit_button" ||
            assetContent.pollType === "multi" ||
            assetContent.pollType === "limited-multi" ||
            assetContent.pollType === "regular" ||
            assetContent.pollType === "free_response"
          ) {
            acc[post.AssetID] = true;
          }
        } catch (e) {
          // Handle the case where AssetContent is not valid JSON
        }
        return acc;
      },
      { posts },
    );

    setSpeechAssets(updatedSpeechAssets);

    // Set hasSpeechAssets flag based on if any speech assets were found
    // setHasSpeechAssets(Object.keys(updatedSpeechAssets).length > 0);

    const observers = assetRefs.current.map(
      (ref, index) =>
        new IntersectionObserver(
          ([entry]) => {
            setVisiblePosts((prevVisiblePosts) => {
              const isVisible = entry.isIntersecting;
              if (isVisible && !prevVisiblePosts.includes(index)) {
                return [...prevVisiblePosts, index]; // Add visible post index
              } else if (!isVisible && prevVisiblePosts.includes(index)) {
                return prevVisiblePosts.filter((i) => i !== index); // Remove post index
              }
              return prevVisiblePosts;
            });
          },
          { threshold: 0.1 },
        ),
    );

    // Start observing each post
    assetRefs.current.forEach(
      (ref, i) => ref.current && observers[i].observe(ref.current),
    );

    // Clean up observers on unmount
    return () => {
      observers.forEach((observer, i) => observer.disconnect());
    };
  }, [posts]);

  const handleCommentSubmit = (assetId) => {
    // Update the state to indicate that a comment has been submitted for this assetId
    setCommentSubmitted((prev) => ({ ...prev, [assetId]: true }));
  };

  const isReactComponent = (item) => {
    // Check if the item is a valid React element
    return React.isValidElement(item);
  };

  const Timer = ({ feedStartTime }) => {
    useEffect(() => {
      const updateTimer = () => {
        const fstartTime = new Date(feedStartTime);
        const currentTime = new Date();

        const fstartTimeUtc = Date.UTC(
          fstartTime.getFullYear(),
          fstartTime.getMonth(),
          fstartTime.getDate(),
          fstartTime.getHours(),
          fstartTime.getMinutes(),
          fstartTime.getSeconds(),
        );

        const currentTimeUtc = Date.UTC(
          currentTime.getFullYear(),
          currentTime.getMonth(),
          currentTime.getDate(),
          currentTime.getHours(),
          currentTime.getMinutes(),
          currentTime.getSeconds(),
        );

        const difference = currentTimeUtc - fstartTimeUtc;
        if (difference >= 0) {
          const minutes = Math.floor(difference / 60000);
          const seconds = Math.floor((difference % 60000) / 1000);
          setTimer(
            (minutes < 10 ? "0" : "") +
              minutes +
              ":" +
              (seconds < 10 ? "0" : "") +
              seconds,
          );
        }
      };

      const timerInterval = setInterval(updateTimer, 1000);

      return () => clearInterval(timerInterval);
    }, [feedStartTime]);
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  // Function to fetch posts from the backend
  useEffect(() => {
    const logo = document.querySelector(".logo");
    if (logo) {
      logo.style.visibility = "hidden";
    }

    return () => {
      if (logo) {
        logo.style.visibility = "hidden";
      }
    };
  }, []);

  useEffect(() => {
    // If currentFeedID is not 0 (i.e., it is a valid feed ID), make a GET request to the backend to fetch posts for that feed
    //Additional logic: Find if currentFeedId is equal to states feedID, if not, set it to state feedID
    console.log("A change in the currentFeedID: ", currentFeedID);
    if (
      state != null &&
      state.feedCompleted === undefined &&
      currentFeedID !== 0 &&
      state.feedID == currentFeedID &&
      props.token
    ) {
      console.log("Fetching posts for feed ID:", currentFeedID);
      axios
        .get("https://genz-staging.feeltiptop.com/api/asset/getPosts", {
          // Include the feed ID as a query parameter in the request
          params: { feedID: currentFeedID },
          // Include an Authorization header with a bearer token for authentication
          headers: { Authorization: "Bearer " + props.token },
        })
        .then((response) => {
          // If the server response contains an access token, update the token state
          response.data.access_token &&
            props.setToken(response.data.access_token);
          // Append the new posts to the existing posts
          const filterAssetContent = response.data.filter(
            (post) => !seen.has(post.AssetID),
          );

          setPosts((prevPosts) => [...prevPosts, ...filterAssetContent]);

          // setPosts(...response.data);
        })
        .catch((error) => {
          // If there's an error fetching the posts, set the error state
          setError(error);
        });
    }
  }, [currentFeedID]);

  useEffect(() => {
    console.log("Checking for state after changes: ", state);
    if (state != null && state.feedCompleted === undefined) {
      console.table(state);
      const feedState = JSON.parse(localStorage.getItem("feedState"));
      if (feedState && feedState.feedID !== state.feedID) {
        console.log(
          "Feed ID in state is different from feed ID in local storage",
        );
        const feedState = {
          feedID: state.feedID,
        };
        localStorage.setItem("feedState", JSON.stringify(feedState));
      }

      setCurrentFeedID(state.feedID);
      setCurrentUserID(state.userID);
      setSessionEndTime(state.sessionEndTime);
      setFeedStartTime(state.feedStartTime);
      setFeedIdInUrl(true);
      console.log("single feed show here");
    } else {
      if (
        searchParams.has("feedid") &&
        searchParams.has("userid") &&
        searchParams.has("sessionendtime")
      ) {
        setCurrentFeedID(searchParams.get("feedid"));
        setCurrentUserID(searchParams.get("userid"));
        setSessionEndTime(searchParams.get("sessionendtime"));
        setFeedStartTime(searchParams.get("feedstarttime"));
        setFeedIdInUrl(true);
      } else {
        console.log("default fetch");
        fetchUniqueFeedSession();
      }
    }
  }, [state, searchParams, currentFeedID]);

  const handleLogOut = () => {
    axios
      .post(
        "https://genz-staging.feeltiptop.com/api/user/logout",
        {},
        {
          headers: { Authorization: `Bearer ${props.token}` },
        },
      )
      .then((response) => {
        props.removeToken();
        localStorage.removeItem("authToken");
        localStorage.removeItem("feedState");
        navigate("/login");
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  };

  const clickLink = async (userId, feedId, assetId) => {
    try {
      const response = await axios.post(
        "https://genz-staging.feeltiptop.com/api/linkclick",
        {
          assetID: assetId,
          userID: userId,
          feedID: feedId,
        },
        {
          headers: { Authorization: "Bearer " + props.token },
        },
      );
      response.data.access_token && props.setToken(response.data.access_token);
      if (response.data.success) {
        console.log("Link click submitted successfully");
      } else {
        console.error("Server responded with failure");
      }
    } catch (error) {
      console.error("Error submitting link click:", error);
    }
  };
  useEffect(() => {
    if (activeAssetId) {
      const activePost = posts.find((post) => post.AssetID === activeAssetId);
      if (activePost) {
        try {
          const assetContent = JSON.parse(activePost.AssetContent);
          setIsSpeechAsset(assetContent.assetType === "SpeechAsset");
        } catch (e) {
          setIsSpeechAsset(false);
        }
      }
    } else {
      setIsSpeechAsset(false);
    }
  }, [activeAssetId, posts]);

  const renderAssetContent = (post) => {
    if (isReactComponent(post)) {
      return post;
    }
    try {
      const assetContent = JSON.parse(post.AssetContent);

      if (assetContent.pollType === "survey") {
        return (
          <FeedPollSurvey
            surveyPolls={assetContent.selections}
            assetContent={assetContent}
            userID={currentUserID}
            feedID={currentFeedID}
            activeAssetId={post.AssetID}
            token={props.token}
            setToken={props.setToken}
          />
        );
      }

      switch (assetContent.assetType) {
        case "poll":
          if (assetContent.pollType === "survey") {
            return (
              <FeedPollSurvey
                surveyPolls={assetContent.selections}
                assetContent={assetContent}
                userID={currentUserID}
                feedID={currentFeedID}
                activeAssetId={post.AssetID}
                token={props.token}
                setToken={props.setToken}
              />
            );
          } else if (assetContent.pollType === "submit_button") {
            return (
              <SubmitFeedComponent
                feedID={currentFeedID}
                userID={currentUserID}
                token={props.token}
                setToken={props.setToken}
              />
            );
          }
          return (
            <PollComponent
              assetContent={assetContent}
              userID={currentUserID}
              feedID={currentFeedID}
              activeAssetId={post.AssetID}
              token={props.token}
              setToken={props.setToken}
            />
          );
        case "text":
          return <TextComponent assetContent={assetContent} />;
        case "media":
          return <MediaComponent mediaLink={assetContent.mediaContent} />;
        case "SpeechAsset":
          return (
            <SpeechAssessmentComponent
              mediaLink={assetContent.mediaContent}
              activeAssetId={post.AssetID}
              feedID={currentFeedID}
              caption={post.Caption}
              token={props.token}
            />
          );
        default:
          return <p>Unsupported asset type: {assetContent.assetType}</p>;
      }
    } catch (e) {
      return <p>Error parsing asset content</p>;
    }
  };

  useEffect(() => {
    if (offSet !== 0 && offSet % 50 === 0) {
      setPosts((prevPosts) => [...prevPosts, <ServiceRequestFeed />]);
    }
  }, [offSet]);

  //note to sel: ranme fetchFeedIds -> fetchAssetIDS

  const fetchFeedIDS = async () => {
    if (endReached) {
      return;
    }

    try {
      // Fetch asset list with scores
      const response = await axios.get("https://genz-staging.feeltiptop.com/api/fillPQ", {
        params: {
          offSet: offSet,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      });

      let assetList = response.data.data[0];
      const endReached = response.data.data[1];
      setEndReached(endReached);

      if (assetList.length == 0 || endReached) {
        return;
      }

      setOffSet(offSet + 5);

      if (assetList.length === 0) {
        navigate("/uploadresume", {
          state: {
            message:
              "No posts available for you at the moment. Please upload your resume to get started.",
          },
        }); // Redirect to the resume upload page if there are no posts
      }

      // Create a mapping of asset IDs to their scores

      // Extract just asset IDs
      const justAssets = assetList.map((asset) => asset.asset_id);

      // Fetch new posts for the asset IDs
      const newPostsResponse = await axios.get(
        "https://genz-staging.feeltiptop.com/api/asset/getAssetPosts",
        {
          params: {
            assetIDList: justAssets,
          },
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        },
      );
      if (newPostsResponse.data.length === 0) {
        navigate("/uploadresume");
      }

      setPosts((prevPosts) => {
        const cur = new Set(prevPosts.map((post) => post.AssetID));
        const newPost = newPostsResponse.data.filter(
          (post) => !cur.has(post.AssetID),
        );
        return [...prevPosts, ...newPost];
      });
    } catch (error) {
      console.error("Error fetching feed IDs:", error.response);
      if (!error.response) {
        navigate("/login");
        return;
      }
      switch (error.response.status) {
        case 500:
          setError("Token has expired. Please log in again.");
          navigate("/login");

          break;
        default:
          setError("An error occurred. Please try again.");
          break;
      }
      setError(error.message);
    }
  };

  const fetchUniqueFeedSession = async () => {
    if (feedIdQueue.length > 0) {
      setSeen((prevState) => {
        const newAssetIDs = posts.map((post) => post.AssetID);
        // Make a new Set with the previous state and extra values
        const updatedSet = new Set([...prevState, ...newAssetIDs]);
        return updatedSet;
      });

      setCurrentFeedID(feedIdQueue[0]);

      setFeedIdQueue((prevState) => {
        const [, ...rest] = prevState;
        return rest;
      });
    } else {
      fetchFeedIDS();
    }
  };

  const checkSpeechAssetType = (post) => {
    if (!post || !post.AssetID) return false;
    return speechAssets[post.AssetID] || false; // Return the cached value for the post
  };

  const handleScroll = () => {
    const scrollBuffer = 10; // Adjust the buffer as needed

    // Check if the user has scrolled to the bottom of the page
    if (
      window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - scrollBuffer &&
      feedIdInUrl !== true
    ) {
      // Call the `fetchUniqueFeedSession` function to fetch a new feed session
      fetchUniqueFeedSession();
    }
  };

  const debouncedHandleScroll = debounce(handleScroll, 200);

  useEffect(() => {
    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [debouncedHandleScroll]);

  // Create separate component for speech directions
  const SpeechAssessmentDirections = () => (
    <div className="mb-4 w-screen max-w-lg overflow-auto rounded-lg border p-4">
      <h2 className="mb-4 text-xl font-bold">Speech Assessment Directions</h2>
      <p className="mb-2 font-medium text-gray-700">
        Welcome to the speech assessment. Please follow the steps below to
        complete the process:
      </p>
      <ol className="list-inside list-decimal space-y-2">
        <li>
          Click the <strong>Play Audio</strong> button to begin the assessment.
        </li>
        <li>
          Listen carefully to the audio sentence. After the beep, repeat the
          sentence as closely as possible.
        </li>
        <li>
          Once you finish speaking, click the same button, which will now say{" "}
          <strong>Submit Response</strong>, to submit your recording.
        </li>
        <li>
          If no speech is detected within the first 30 seconds, the recording
          will automatically stop and process.
        </li>
      </ol>
      <p className="mt-4 font-medium text-gray-600">
        Make sure you are in a quiet environment to ensure the best results.
      </p>
    </div>
  );

  const renderedPosts = posts.map((post, index) => (
    <div key={index}>
      <div
        ref={assetRefs.current[index]}
        className="w-screen max-w-lg rounded-lg border"
      >
        {/** Content */}
        {!checkSpeechAssetType(post) && post.Caption && (
          <div className="overflow-auto p-4">
            <p className="font-semibold">{post.Caption}</p>
          </div>
        )}
        {isReactComponent(post) ? (
          post
        ) : (
          <div className="overflow-auto p-4">
            <div>{renderAssetContent(post)}</div>

            {post.OpenLink && (
              <a
                href={post.OpenLink}
                target="_blank"
                rel="noopener noreferrer"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  clickLink(currentUserID, currentFeedID, post.AssetID);
                  window.open(post.OpenLink, "_blank");
                }}
              >
                <span>Open Link</span>
                <div className="">
                  <SharelinkIcon />
                </div>
              </a>
            )}
            {/** Comment box */}
            {!checkSpeechAssetType(post) && (
              <div>
                <button
                  className="flex w-full items-center justify-center py-1 transition-transform hover:scale-105"
                  onClick={() => {
                    setActiveAssetId(post.AssetID);
                    setCaption(post.Caption);
                    setIsCommentModalOpen(true);
                  }}
                >
                  <img
                    src={
                      commentSubmitted[post.AssetID]
                        ? commentImageClicked
                        : commentImage
                    }
                    alt="Comment"
                    className="rounded-2xl"
                  />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  ));

  return error ? (
    <div>{error}</div>
  ) : (
    <>
      <div className="flex flex-col items-center justify-center">
        {/** Header */}
        <div className="min-w-full border-b p-2">
          <div className="mx-auto flex max-w-lg items-center justify-between">
            <img src={logo} alt="Logo" className="h-8" />
            <Timer feedStartTime={feedStartTime} />
            <div
              className="cursor-pointer hover:scale-105"
              onClick={handleLogOut}
            >
              <img src={logoutIcon} alt="Logout" className="h-8 w-8" />
            </div>
          </div>
        </div>

        <div className="flex w-screen max-w-lg flex-col items-center justify-center gap-4">
          {/* Speech Assessment Directions - only render if we have speech assets */}
          {/* {hasSpeechAssets && <SpeechAssessmentDirections />} */}

          {/* Rendered Posts */}
          {renderedPosts}
        </div>
      </div>

      <FeedCommentBox
        userID={currentUserID}
        feedID={currentFeedID}
        activeAssetId={activeAssetId}
        setActiveAssetId={setActiveAssetId}
        caption={caption}
        token={props.token}
        setToken={props.setToken}
        isCommentModalOpen={isCommentModalOpen}
        setIsCommentModalOpen={setIsCommentModalOpen}
        currentComment={currentComment}
        setCurrentComment={setCurrentComment}
        onCommentSubmit={() => handleCommentSubmit(activeAssetId)}
        isSpeechAsset={isSpeechAsset}
      />
      <div className="fixed bottom-0 left-0 flex w-full">
        {openPaymentBox ? (
          <OptionsDisplay
            setOpenPaymentBox={setOpenPaymentBox}
            setPosts={setPosts}
            posts={posts}
            visiblePosts={visiblePosts}
          />
        ) : (
          <button
            className="payment-options-button"
            onClick={() => setOpenPaymentBox(true)}
          >
            <CreditCardIcon className="h-6 w-6" />
          </button>
        )}
      </div>
      {state?.feedCompleted && (
        <>
          <SnackBar message="Feed was already completed" />
        </>
      )}
    </>
  );
};

export default Feed;
