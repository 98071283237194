import { useEffect, useState } from "react";
import axios from "axios";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import Box from "@mui/material/Box";
import { FaRegComment } from "react-icons/fa6";
import Alert from "@mui/material/Alert";
import TextComponent from "../TextComponent";

import MediaComponent from "../MediaComponent";
import { use } from "react";
import CommentBox from "./CommentBox";

const labels = {
  0.5: "EH",
  1: "Need Work+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function ServiceRequestFeed() {
  const [serviceRequests, setServiceRequests] = useState(null);
  const [ratingValue, setRatingValue] = useState(null);
  const [hover, setHover] = useState(-1);
  const [error, setError] = useState("");
  const [commentBoxIsOpen, setCommentBoxIsOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [commentBoxID, setCommentBoxID] = useState(null);

  const [comments, setComments] = useState(null);
  const [commentSuccess, setCommentSuccess] = useState((false, null));
  const [ratingSucess, setRatingSuccess] = useState((false, null));
  const [activeCommentRequestId, setActiveCommentRequestId] = useState(null);

  const handleSubmitComment = async (e, requestId) => {
    e.preventDefault();
    console.log("comment: ", comment, "id: ", requestId);

    if (comment.trim()) {
      try {
        await axios
          .post(
            "https://genz-staging.feeltiptop.com/api/sellers/addServiceComment",
            {
              comment: comment,
              requestId: requestId,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            },
          )
          .then((res) => {
            console.log("this is our data: ", res);
            const data = res.data.added_message;
            console.log("this is our data: ", data);
            setComments([data, ...comments]);

            setCommentSuccess((true, requestId));
          });
      } catch (error) {
        console.error("Error submitting comment:", error);
        setError(error);
      }

      setComment("");
    }
  };

  const fetchComments = async (requestId) => {
    console.log("request id in fetchComments: ", requestId);
    setComments(null);
    try {
      await axios
        .get(
          `https://genz-staging.feeltiptop.com/api/sellers/getServiceComments?requestId=${requestId}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          },
        )
        .then((res) => {
          console.log("this is our data: ", res.data);
          setComments(res.data.comments);
          setActiveCommentRequestId(requestId);
        });
    } catch (error) {
      console.error("Error fetching comments:", error);
      setError(error);
    }
  };

  useEffect(() => {
    if (commentBoxIsOpen && commentBoxID) {
      fetchComments();
    }
    if (commentBoxIsOpen == false) {
      setComments([]);
      setCommentBoxID(null);
    }
  }, [commentBoxIsOpen]);

  useEffect(() => {
    setComments(null);
    const fetchServiceRequests = async () => {
      try {
        await axios
          .get("https://genz-staging.feeltiptop.com/api/sellers/getRequestsToRate", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            console.log("this is our data: ", response.data);
            console.log(response.data.requests);
            const data = response.data.requests;
            // setRatingValue(data.rating);
            setServiceRequests(response.data.requests);
          });
      } catch (error) {
        console.error("Error fetching service requests:", error);
        setError(error);
      }
    };

    fetchServiceRequests();
  }, []);

  const deleteServiceRequestFromArray = (requestId) => {
    const updatedServiceRequests = serviceRequests.filter(
      (request) => request.requestID !== requestId,
    );

    setServiceRequests(updatedServiceRequests);
  };

  const submitRating = async (rating, requestId) => {
    console.log("params being passed", rating, requestId);
    if (rating === null) {
      alert("Please select a rating before submitting");
      return;
    }

    if (requestId === null) {
      alert("Sorry there was an error, please try again");
      return;
    }
    try {
      await axios
        .post(
          "https://genz-staging.feeltiptop.com/api/sellers/rateRequest",
          {
            requestId: requestId,
            rating: rating,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          },
        )
        .then((response) => {
          console.log("this is our data: ", response.data);
          if (response.data.success) {
            alert("Rating submitted successfully");
            // deleteServiceRequestFromArray(requestId);
          } else {
            alert("Sorry there was an error, please try again");
          }
        });
    } catch (error) {
      console.error("Error submitting rating:", error);
      setError(error);
    }
  };

  if (serviceRequests === null) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mx-auto max-w-2xl space-y-6 p-2">
      {/* <h1 className="mb-6 text-center text-2xl font-bold">Rate</h1> */}

      {serviceRequests.length === 0 && (
        <div className="flex justify-center">
          <p className="text-lg text-gray-600">No reviews to display</p>
        </div>
      )}

      {serviceRequests.map((request, index) => (
        <div
          key={request.requestID}
          className="flex-start flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white p-4 shadow-md transition-all duration-200 hover:shadow-lg"
        >
          {/* Header section */}
          <div className="flex flex-col justify-between border-b border-gray-100 p-6">
            <div className="flex-start flex">
              <div className="flex w-full justify-between">
                <h2 className="mb-2 h-auto text-2xl font-semibold text-gray-800">
                  {request.title}
                </h2>

                <h2 className="mb-2 h-auto text-lg font-semibold text-gray-800">
                  <span>
                    {request.days_since_created === "0"
                      ? "today"
                      : `${request.days_since_created} day(s) ago`}
                  </span>
                </h2>
              </div>
            </div>
            {/*Descriptio section */}
            <div className="text-start">
              <p className="w-full break-words text-gray-600">
                <TextComponent assetContent={{ html: request.description }} />
              </p>
            </div>
            {request.mediaURL != "" && (
              <MediaComponent mediaLink={request.mediaURL} />
            )}

            <div className="mt-4 flex justify-between">
              <div className="flex items-center">
                <img
                  src={request.user_profile_url}
                  alt="Profile"
                  className="h-8 w-8 rounded-full"
                />
                <span className="ml-2 text-sm font-medium text-gray-700">
                  {request.username}
                </span>
              </div>
            </div>
          </div>

          {/* Content section */}
          <div className="flex justify-between space-y-4 p-6">
            <div className="flex items-center justify-between">
              <span className="text-lg font-medium text-green-600">
                {request.price} Points
              </span>
            </div>

            {request.review ? (
              <div className="mt-4 rounded-lg bg-gray-50 p-4">
                <p className="italic text-gray-700">"{request.review}"</p>
              </div>
            ) : (
              <div className="mt-4 rounded-lg bg-gray-50 p-2">
                <p className="italic text-gray-700">
                  {request.rating === null ? (
                    "No ratings yet"
                  ) : (
                    <div>
                      <p className="text-lg font-semibold text-gray-800">
                        {request.rating} / 5.00
                      </p>
                    </div>
                  )}
                </p>
              </div>
            )}
          </div>

          <div className="flex items-center space-x-4">
            {/* Rating and Submit Button Section */}
            <div className="flex w-full items-center justify-between space-x-1">
              <div className="flex flex-col space-y-2">
                <Rating
                  name="hover-feedback"
                  defaultValue={2.5}
                  precision={0.5}
                  value={ratingValue}
                  onChange={(event, newValue) => {
                    console.log("new value: ", newValue);
                    setRatingValue(newValue);
                  }}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                  emptyIcon={
                    <StarIcon className="opacity-55" fontSize="inherit" />
                  }
                />

                <button
                  className="rounded bg-green-500 px-4 py-2 font-bold text-white hover:bg-green-700"
                  onClick={() => submitRating(ratingValue, request.requestID)}
                >
                  Submit Rating
                </button>
              </div>

              <button>
                <FaRegComment
                  className="text-2xl text-gray-600 hover:bg-slate-200"
                  onClick={() => fetchComments(request.requestID)}
                />
              </button>
              <button
                className="rounded bg-green-500 px-4 py-2 font-bold text-white hover:bg-green-700"
                onClick={() => deleteServiceRequestFromArray(request.requestID)}
              >
                Done
              </button>
            </div>

            {/* Comment Icon */}
            <div>
              {activeCommentRequestId === request.requestID &&
                comments &&
                comments.length >= 0 && (
                  <CommentBox
                    comments={comments}
                    setComments={setComments}
                    setComment={setComment}
                    comment={comment}
                    setCommentBoxIsOpen={setCommentBoxIsOpen}
                    commentBoxIsOpen={commentBoxIsOpen}
                    setCommentBoxID={setCommentBoxID}
                    commentBoxID={commentBoxID}
                    handleSubmitComment={handleSubmitComment}
                    request={request}
                    requestID={request.requestID}
                  />
                )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ServiceRequestFeed;
