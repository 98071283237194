import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

const ClassCalendar = ({ userId }) => {
  const [classes, setClasses] = useState([]);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  useEffect(() => {
    // Fetch classes data for the given userId
    axios
      .get(`https://genz-staging.feeltiptop.com/api/users/${userId}/classes`)
      .then((response) => {
        setClasses(response.data);
      })
      .catch((error) => console.error("Error fetching classes:", error));
  }, [userId]);

  // Helper function to convert time to 12-hour format
  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    const formattedHour = hour % 12 || 12;
    const period = hour >= 12 ? "PM" : "AM";
    return `${formattedHour}:${minute} ${period}`;
  };

  // Helper function to sort classes by start time
  const sortClassesByTime = (classes) => {
    return classes.sort((a, b) => {
      const timeA = a.StartTime.split(":").map(Number);
      const timeB = b.StartTime.split(":").map(Number);
      return timeA[0] - timeB[0] || timeA[1] - timeB[1];
    });
  };
  const goToClassSchedule = () => {
    navigate("/classschedule", {
      state: {
        userId, // Pass userId through state
      },
    });
  };

  const renderClassesForDay = (day) => {
    // Filter and sort classes for the specific day
    const classesForDay = classes
      .filter((classItem) =>
        classItem.Schedule.some((schedule) => schedule.DayOfWeek === day),
      )
      .map((classItem) => ({
        ...classItem,
        Schedule: classItem.Schedule.filter(
          (schedule) => schedule.DayOfWeek === day,
        ),
      }));

    // Sort classes by start time for each day
    const sortedClassesForDay = sortClassesByTime(
      classesForDay.flatMap((classItem) =>
        classItem.Schedule.map((schedule) => ({
          ClassName: classItem.ClassName,
          StartTime: schedule.StartTime,
          EndTime: schedule.EndTime,
        })),
      ),
    );

    return sortedClassesForDay.map((classItem, index) => (
      <div
        key={index}
        className="mb-2 rounded-md bg-blue-200 p-4 text-blue-900 shadow-md"
      >
        <h3 className="text-lg font-bold">{classItem.ClassName}</h3>
        <p className="text-sm">
          {formatTime(classItem.StartTime)} - {formatTime(classItem.EndTime)}
        </p>
      </div>
    ));
  };

  return (
    <div className="p-4">
      <button
        onClick={goToClassSchedule}
        className="mb-4 rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
      >
        Go to Class Schedule
      </button>
      <div className="mb-4 flex justify-center">
        {daysOfWeek.map((day) => (
          <div
            key={day}
            className="w-1/5 border-b pb-2 text-center text-xl font-bold text-gray-700"
          >
            {day}
          </div>
        ))}
      </div>

      <div className="grid grid-cols-5 gap-4">
        {daysOfWeek.map((day) => (
          <div key={day} className="col-span-1">
            {renderClassesForDay(day)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClassCalendar;
