import { AiOutlineClose } from "react-icons/ai";
import CheckBalance from "../../routes/CheckPoints";
import ServiceRequest from "../../routes/ServiceRequest";
import UpdateRequest from "../../routes/UpdateRequest";
import UploadResume from "../../routes/UploadResume"; 
import UploadCalendar from "../../routes/UploadCalendar";
import ReferralLink from "./ReferralLink";

function OptionsDisplay({ setOpenPaymentBox, setPosts, posts, visiblePosts }) {
  const itemStyle =
    "bg-white cursor-pointer rounded-lg p-2 text-center hover:transform hover:scale-105 transition-transform duration-200 shadow-md";

  const insertAtEnd = (serviceTitle) => {
    switch (serviceTitle) {
      case "Show 360 Points":
        setPosts((prevPosts) => [...prevPosts, <CheckBalance />]);
        break;
      case "Request Service":
        setPosts((prevPosts) => [...prevPosts, <ServiceRequest />]);
        break;
      case "Update Service Price":
        setPosts((prevPosts) => [...prevPosts, <UpdateRequest />]);
        break;
      case "Upload Resume":
        setPosts((prevPosts) => [...prevPosts, <UploadResume />]);
        break;
      case "Upload Calendar":
        setPosts((prevPosts) => [...prevPosts, <UploadCalendar />]);
        break;
      case "View Referral Link":
        setPosts((prevPosts) => [...prevPosts, <ReferralLink />]);
        break;
      default:
        console.log("Invalid service title");
    }
  };

  const handleAllPaymentRequest = (e) => {
    e.preventDefault();
    const serviceTitle = e.target.innerText;

    const insertIndex = Math.min(
      visiblePosts[visiblePosts.length - 1] + 1,
      posts.length - 1,
    );

    if (!insertIndex) {
      insertAtEnd(serviceTitle);
      return;
    }

    switch (serviceTitle) {
      case "Show 360 Points":
        setPosts((prevPosts) => [
          ...prevPosts.slice(0, insertIndex),
          <CheckBalance key="check-points" />,
          ...prevPosts.slice(insertIndex),
        ]);
        break;
      case "Request Service":
        setPosts((prevPosts) => [
          ...prevPosts.slice(0, insertIndex),
          <ServiceRequest key="service-request" serviceTitle={serviceTitle} />,
          ...prevPosts.slice(insertIndex),
        ]);
        break;
      case "Update Service Price":
        setPosts((prevPosts) => [
          ...prevPosts.slice(0, insertIndex),
          <UpdateRequest key="update-request" serviceTitle={serviceTitle} />,
          ...prevPosts.slice(insertIndex),
        ]);
        break;
      case "Upload Resume":
        setPosts((prevPosts) => [
          ...prevPosts.slice(0, insertIndex),
          <UploadResume key="upload-resume" />,
          ...prevPosts.slice(insertIndex),
        ]);
        break;
      case "Upload Calendar":
        setPosts((prevPosts) => [
          ...prevPosts.slice(0, insertIndex),
          <UploadCalendar key="upload-calendar" />,
          ...prevPosts.slice(insertIndex),
        ]);
        break;
      case "View Referral Link":
        setPosts((prevPosts) => [
          ...prevPosts.slice(0, insertIndex),
          <ReferralLink />,
          ...prevPosts.slice(insertIndex),
        ]);
      default:
        console.log("Invalid service title");
    }
  };

  return (
    <div className="absolute right-0 bottom-0 flex flex-col border-2 border-gray-200 bg-white rounded-lg shadow-lg p-4 space-y-4 transition-all duration-300 ease-in-out">
      <div className="flex justify-between items-center w-full mb-2">
        <h2 className="text-xl font-bold">YOUR 360 CARD</h2>
        <AiOutlineClose
          className="cursor-pointer text-gray-500 hover:text-gray-700"
          onClick={() => setOpenPaymentBox(false)}
        />
      </div>
      <div className="w-full flex flex-col gap-2">
        <div onClick={(e) => handleAllPaymentRequest(e)} className={itemStyle}>
          Show 360 Points
        </div>
        <div onClick={(e) => handleAllPaymentRequest(e)} className={itemStyle}>
          Request Service
        </div>
        <div onClick={(e) => handleAllPaymentRequest(e)} className={itemStyle}>
          Update Service Price
        </div>
        <div onClick={(e) => handleAllPaymentRequest(e)} className={itemStyle}>
          Upload Resume
        </div>
        <div onClick={(e) => handleAllPaymentRequest(e)} className={itemStyle}>
          Upload Calendar
        </div>
        <div onClick={(e) => handleAllPaymentRequest(e)} className={itemStyle}>
          View Referral Link
        </div>
      </div>
    </div>
  );
}

export default OptionsDisplay;