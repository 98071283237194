import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import TextComponent from '../components/TextComponent';
import MediaComponent from '../components/MediaComponent';
import PollComponent from '../components/PollComponent';
import SpeechAssessmentComponent from '../components/SpeechAssessmentComponent';
import { encrypttoHEX, decryptfromHEX } from './encodedecode.js';

const formatTimestamp = (timestamp) => {
  if (!timestamp) return 'Pending';
  try {
    const utcTimestamp = timestamp.endsWith('Z') ? timestamp : timestamp + 'Z';
    const date = new Date(utcTimestamp);
    
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date');
    }

    return date.toLocaleString(undefined, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    });
  } catch (error) {
    console.error('Error formatting timestamp:', error, timestamp);
    return 'Invalid Date';
  }
};
const protocol = window.location.protocol;
const hostname = window.location.hostname;
const port = window.location.port;

const baseURL = `${protocol}//${hostname}${port ? ":" + port : ""}/`;


const FulfillmentTimeline = ({ fulfillment, reactionId, requestId, assetName }) => {
  const [error, setError] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);

  const handleCreateFulfillmentFeed = async () => {
    try {
      setIsCreating(true);
      setError(null);
      
      const response = await axios.post(
        'https://genz-staging.feeltiptop.com/api/feed/create_fulfillment',
        {
          assetName: assetName,
          reactionId: reactionId,
          requestId: requestId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          }
        }
      );

      if (response.data.success) {
        const feedData = {
          feedID: response.data.feedId
        };
        const encryptedFeedId = encrypttoHEX(JSON.stringify(feedData));
        axios.put(`https://genz-staging.feeltiptop.com/api/feed/${response.data.feedId}/update_session_link`, 
          {
              SessionLink: `${baseURL}SpecificFeedConstruction?p=${encryptedFeedId}`,
          },
          {
              headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  'Content-Type': 'application/json',
              },
          }
        )
        .then(response => {
            console.log('Session link updated successfully:', response.data);
        })
        .catch(error => {
            console.error('Error updating session link:', error);
        });
      
        window.open(`/SpecificFeedConstruction?p=${encryptedFeedId}`, '_blank');
        
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error('Error creating fulfillment feed:', error);
      if (error.response?.status === 403) {
        setShowNotificationModal(true);
      } else {
        setError(error.response?.data?.message || 'An error occurred while creating the fulfillment feed');
      }
    } finally {
      setIsCreating(false);
    }
  };

  if (!fulfillment && !fulfillment?.feedLink) {
    const availableStatus = {
      status: 'Available',
      feedLink: null,
      inProgressTimestamp: null,
      completedTimestamp: null,
      seller: null
    };
    fulfillment = availableStatus;
  }

  const timelineSteps = [
    { status: 'Available', label: 'Available', time: null },
    { status: 'InProgress', label: 'In Progress', time: fulfillment.inProgressTimestamp },
    { status: 'Completed', label: 'Completed', time: fulfillment.completedTimestamp }
  ];
  
  const effectiveStatus = !fulfillment.feedLink ? 'Available' : fulfillment.status;
  const currentIndex = timelineSteps.findIndex(step => step.status === effectiveStatus);

  return (
    <>
      <div className="p-4 bg-gray-50 rounded-lg mt-4">
        {fulfillment.seller && (effectiveStatus === 'InProgress' || effectiveStatus === 'Completed') && (
          <div className="flex items-center mb-3">
            <span className="text-sm text-gray-600">
              {fulfillment.seller.firstName} {fulfillment.seller.lastName} (@{fulfillment.seller.userName})
            </span>
          </div>
        )}

        <h4 className="text-lg font-semibold mb-3">Fulfillment Status</h4>
        <div className="flex justify-between items-center">
          {timelineSteps.map((step, index) => {
            const isActive = index <= currentIndex;
            const isCurrentStep = step.status === effectiveStatus;
            
            return (
              <div key={step.status} className="flex flex-col items-center flex-1">
                <div 
                  className={`w-6 h-6 rounded-full flex items-center justify-center
                    ${isCurrentStep ? 'bg-blue-600 ring-4 ring-blue-100' :
                      isActive ? 'bg-blue-500' : 'bg-gray-300'
                    }`}
                >
                  {isActive && (
                    <div className="text-white text-xs">✓</div>
                  )}
                </div>
                <div className={`mt-2 text-sm font-medium ${
                  isActive ? 'text-blue-600' : 'text-gray-500'
                }`}>
                  {step.label}
                </div>
                <div className="text-xs text-gray-500">
                  {step.time ? formatTimestamp(step.time) : 'Pending'}
                </div>
              </div>
            );
          })}
        </div>
        
        {error && (
          <div className="mt-4 p-3 bg-red-50 text-red-700 rounded-lg">
            {error}
          </div>
        )}

        {effectiveStatus === 'Available' ? (
          <button
            className={`mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors ${
              isCreating ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleCreateFulfillmentFeed}
            disabled={isCreating}
          >
            {isCreating ? 'Creating...' : 'Create Fulfillment Feed'}
          </button>
        ) : (
          <button
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            onClick={() => window.open(fulfillment.feedLink, '_blank')}
          >
            {effectiveStatus === 'InProgress' ? 'View Progress' : 'View Completed Feed'}
          </button>
        )}
      </div>

      {showNotificationModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
            <h3 className="text-xl font-semibold mb-4">Seller Registration Required</h3>
            <p className="text-gray-600 mb-6">
              To create fulfillment feeds, you need to register as a seller first. Please register as a seller to continue.
            </p>
            
            <div className="flex justify-end">
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                onClick={() => setShowNotificationModal(false)}
              >
                Got it
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const PollSummary = ({ assetContent, reactions }) => {
  if (!assetContent || assetContent.assetType !== 'poll') return null;

  const pollResponses = reactions.filter(r => r.reaction.reactionDetails.pollChoice);
  const totalResponses = pollResponses.length;
  
  const allOptions = Object.values(assetContent.selections || {});
  
  const responseCounts = allOptions.reduce((acc, option) => {
    acc[option] = pollResponses.filter(r => 
      r.reaction.reactionDetails.pollChoice === option
    ).length;
    return acc;
  }, {});

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-6">
      <h3 className="text-xl font-bold mb-4">Poll Results</h3>
      <div className="space-y-4">
        {allOptions.map((option) => {
          const count = responseCounts[option] || 0;
          const percentage = totalResponses > 0 ? (count / totalResponses) * 100 : 0;
          
          return (
            <div key={option} className="relative">
              <div className="flex justify-between mb-2">
                <span className="font-medium">{option}</span>
                <span>{count} ({percentage.toFixed(1)}%)</span>
              </div>
              <div className="w-full bg-gray-100 rounded-lg h-8 relative overflow-hidden">
                <div
                  className="absolute top-0 left-0 h-full bg-blue-500 rounded-lg transition-all duration-500"
                  style={{ width: `${percentage}%` }}
                />
                <div className="absolute inset-0 flex items-center justify-center text-sm font-medium">
                  {option}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="mt-4 text-sm text-gray-600">
        Total Responses: {totalResponses}
      </div>
    </div>
  );
};

import DOMPurify from 'dompurify';

const ServiceRequestCard = ({ request, selectedCategory }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("token");

  const filteredCategories = categories.filter((category) =>
    selectedCategory ? category === selectedCategory : true
  );

  const fulfillmentData = request.fulfillment
    ? {
        status: request.fulfillment.status || "Available",
        feedLink: request.fulfillment.feedLink,
        inProgressTimestamp: request.fulfillment.inProgressTimestamp,
        completedTimestamp: request.fulfillment.completedTimestamp,
        seller: request.fulfillment.seller,
      }
    : {
        status: "Available",
        feedLink: null,
        inProgressTimestamp: null,
        completedTimestamp: null,
        seller: null,
      };

  // Configure DOMPurify to allow iframe tags
  const cleanDescription = DOMPurify.sanitize(request.description, {
    ALLOWED_TAGS: ["p", "img", "iframe", "pre", "code"],
  });

  useEffect(() => {
    const fetchCategories = async () => {
      if (!request?.requestID) {
        setError("Request ID is missing.");
        setLoading(false);
        return;
      }
  
      if (!token) {
        setError("User is not authenticated.");
        setLoading(false);
        return;
      }
  
      try {
        console.log("Fetching categories for Request ID:", request.requestID);
  
        const response = await axios.get(
          "https://genz-staging.feeltiptop.com/api/payment/get-request-category",
          {
            params: { requestID: request.requestID },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        console.log("Categories API Response:", response.data);
        setCategories(response.data.categories);
      } catch (err) {
        console.error("Error fetching categories:", err.response || err.message);
        setError(err.response ? err.response.data.error : "Something went wrong");
      } finally {
        setLoading(false);
      }
    };
  
    if (request?.requestID) {
      fetchCategories();
    }
  }, [request?.requestID, token]);  


  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-4">
      <div className="flex justify-between items-start mb-4">
        <div>
          <h3 className="text-xl font-semibold">Service Request</h3>
          <p className="text-gray-600">
            By {request.user.firstName} {request.user.lastName} ({request.user.userName})
          </p>
        </div>
        <div className="text-sm text-gray-500">
          🕒 {new Date(request.dateCreated).toLocaleString()}
        </div>
      </div>

      <div className="space-y-2 mb-4">
        <div
          className="mt-6 text-left"
          dangerouslySetInnerHTML={{
            __html: cleanDescription,
          }}
        />
        <p className="text-lg font-semibold text-green-600">
          Price: {request.price} Points
        </p>
      </div>

      {loading ? (
        <p>Loading categories...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        filteredCategories.length > 0 && (
          <div className="mt-4">
            <h4 className="text-lg font-semibold">Categories:</h4>
            <div className="flex flex-wrap gap-4 mt-2">
              {filteredCategories.map((category, index) => (
                <div
                  key={index}
                  className="bg-gray-200 rounded-lg p-2 text-center text-gray-800 font-medium"
                >
                  {category}
                </div>
              ))}
            </div>
          </div>
        )
      )}

      <FulfillmentTimeline
        fulfillment={fulfillmentData}
        reactionId={null}
        requestId={request.requestID}
        assetName={`Service Request ${request.requestID}`}
      />
    </div>
  );
};


const AssetReactionCard = ({ reaction }) => (
  <div className="bg-white rounded-lg shadow-md p-6 mb-4">
    <div className="flex justify-between items-start mb-4">
      <div>
        <span className="font-medium">{reaction.user.firstName} {reaction.user.lastName}</span>
        <span className="text-gray-500 ml-2">@{reaction.user.userName}</span>
      </div>
      <div className="text-sm text-gray-500">
        🕒 {formatTimestamp(reaction.reaction.reactionTime)}
      </div>
    </div>

    <div className="space-y-4">
      {reaction.reaction.reactionDetails.ranking && (
        <div>
          <span className="font-medium">Rating:</span> {reaction.reaction.reactionDetails.ranking}/5
        </div>
      )}
      
      {reaction.reaction.reactionDetails.comment && (
        <div className="bg-gray-50 rounded-lg p-4">
          {reaction.reaction.reactionDetails.comment}
        </div>
      )}
      
      {reaction.reaction.reactionDetails.pollChoice && (
        <div className="bg-blue-50 text-blue-700 rounded-lg p-4">
          Selected: {reaction.reaction.reactionDetails.pollChoice}
        </div>
      )}
      
      {reaction.reaction.reactionDetails.media && (
        <div className="mt-2">
          <MediaComponent mediaLink={`https://genz-staging.feeltiptop.com/${reaction.reaction.reactionDetails.media}`} />
        </div>
      )}

      <FulfillmentTimeline 
        fulfillment={reaction.reaction.reactionDetails.fulfillmentFeedStatus ? {
          status: reaction.reaction.reactionDetails.fulfillmentFeedStatus,
          feedLink: reaction.reaction.reactionDetails.fulfillmentFeedLink,
          inProgressTimestamp: reaction.reaction.reactionDetails.fulfillment?.inProgressTimestamp,
          completedTimestamp: reaction.reaction.reactionDetails.fulfillment?.completedTimestamp,
          seller: reaction.reaction.reactionDetails.fulfillment?.seller
        } : null}
        reactionId={reaction.reaction.reactionID}
        assetName={reaction.asset.assetName}
      />
    </div>
  </div>
);

const AssetReactions = () => {
  const [assets, setAssets] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null); // New state for selected category
  const [reactions, setReactions] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isServiceRequest, setIsServiceRequest] = useState(false);
  const [categories, setCategories] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchAssets();
    fetchCategories(); 
  }, []);

  const fetchAssets = async () => {
    try {
      const response = await axios.get(`https://genz-staging.feeltiptop.com/api/getAssetReactions`);
      const formattedAssets = [
        { value: "service_request", label: "Service Requests" },
        ...response.data.map(asset => ({
          value: asset.AssetID,
          label: `${asset.AssetName}: ${asset.Caption}`
        }))
      ];
      setAssets(formattedAssets);
    } catch (err) {
      setError('Failed to fetch assets. Please try again.');
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `https://genz-staging.feeltiptop.com/api/get-categories`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const categoriesData = Object.keys(response.data).map((categoryName) => ({
        categoryName,
        requests: response.data[categoryName],
      }));

      setCategories(categoriesData);
    } catch (err) {
      setError('Failed to fetch categories. Please try again.');
    }
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const fetchReactions = async (assetId) => {
    setLoading(true);
    setError('');
    try {
      let response;
      if (assetId === "service_request") {
        response = await axios.get(`https://genz-staging.feeltiptop.com/api/service_request_reaction`);
        setIsServiceRequest(true);
      } else {
        response = await axios.get(`https://genz-staging.feeltiptop.com/api/asset_reaction`, {
          params: { assetID: assetId }
        });
        setIsServiceRequest(false);
      }

      if (response.data.success) {
        setReactions(response.data.reactions);
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      setError('Failed to fetch reactions. Please try again.');
    }
    setLoading(false);
  };

  const handleAssetSelect = (selectedOption) => {
    setSelectedAsset(selectedOption);
    setSelectedCategory(null); // Reset category when asset changes
    if (selectedOption) {
      fetchReactions(selectedOption.value);
    } else {
      setReactions([]);
      setError('');
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="mb-6">
        <Select
          options={assets}
          value={selectedAsset}
          onChange={handleAssetSelect}
          placeholder="Search and select an asset"
          isClearable
          className="text-base"
        />
      </div>

     
    {selectedAsset && selectedAsset.value === "service_request" && (
      <div className="mb-6">
        <select
          value={selectedCategory}
          onChange={handleCategoryChange}
          className="form-select"
        >
          <option value="">Select Category</option>
          {categories.map((category, index) => (
            <option key={index} value={category.categoryName}>
              {category.categoryName}
            </option>
          ))}
        </select>
      </div>
    )}

    {selectedCategory && categories && categories.length > 0 && (
      <div className="space-y-6">
        <h2 className="text-2xl font-bold mb-4">Service Requests in {selectedCategory}</h2>
        {console.log("Requests for selected category:", 
          categories.find((category) => category.categoryName === selectedCategory)?.requests)} 
        
        {categories
          .find((category) => category.categoryName === selectedCategory)
          ?.requests?.length > 0 ? (
            categories
              .find((category) => category.categoryName === selectedCategory)
              .requests.map((request) => {
                console.log("Rendering request:", request);  // Log request data
                return (
                  <ServiceRequestCard
                    key={request.RequestID}
                    request={request}
                    selectedCategory={selectedCategory}
                  />
                );
              })
          ) : (
            <p>No service requests available for this category.</p>
          )}
      </div>
    )}

  
      {error && (
        <div className="bg-red-50 text-red-700 p-4 rounded-lg mb-6">
          <strong>Error:</strong> {error}
        </div>
      )}
  
      {loading && (
        <div className="text-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 mx-auto"></div>
          <div className="mt-2 text-gray-600">Loading...</div>
        </div>
      )}
  
      {selectedAsset && reactions.length > 0 && (
        <div>
          {isServiceRequest ? (
            <div className="space-y-6">
              <h2 className="text-2xl font-bold mb-4">All Service Requests</h2>
              {reactions.map((request) => (
                console.log("Rendering request for second div:", request),
                <ServiceRequestCard key={request.requestID} request={request} />
              ))}
            </div>
          ) : (
            <div className="space-y-6">
              <div className="bg-white rounded-lg shadow-md p-6 mb-6">
                <div className="flex justify-between items-start mb-4">
                  <div>
                    <h2 className="text-2xl font-bold">{reactions[0].asset.assetName}</h2>
                    <p className="text-gray-600">{reactions[0].feed.name}</p>
                    {reactions[0].feed.status && (
                      <p className="text-blue-600">Status: {reactions[0].feed.status}</p>
                    )}
                  </div>
                  <div className="text-sm text-gray-500">
                    🕒 Created: {formatTimestamp(reactions[0].asset.assetCreationTime)}
                  </div>
                </div>
  
                <div className="flex items-center mb-4">
                  <div className="w-10 h-10 rounded-full overflow-hidden mr-3">
                    <img
                      src={reactions[0].brand.brandProfilePicture ? 
                        `https://genz-staging.feeltiptop.com/${reactions[0].brand.brandProfilePicture}` :
                        "https://via.placeholder.com/40"
                      }
                      alt={reactions[0].brand.brandName}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <span className="font-medium">{reactions[0].brand.brandName}</span>
                </div>
  
                {reactions[0].asset.assetContent && (
                  <>
                    {reactions[0].asset.assetContent.assetType === 'poll' && (
                      <PollSummary 
                        assetContent={reactions[0].asset.assetContent}
                        reactions={reactions}
                      />
                    )}
                    
                    <div className="mt-4">
                      {reactions[0].asset.assetContent.assetType === 'text' && (
                        <div className="prose prose-sm max-w-none">
                          <TextComponent assetContent={typeof reactions[0].asset.assetContent === 'string' ? 
                            JSON.parse(reactions[0].asset.assetContent) : 
                            reactions[0].asset.assetContent} 
                          />
                        </div>
                      )}
                      {reactions[0].asset.assetContent.assetType === 'media' && (
                        <div className="relative aspect-video">
                          <MediaComponent mediaLink={reactions[0].asset.assetContent.mediaContent} />
                        </div>
                      )}
                      {reactions[0].asset.assetContent.assetType === 'SpeechAsset' && (
                        <div className="relative aspect-video">
                          <SpeechAssessmentComponent
                            mediaLink={reactions[0].asset.assetContent.mediaContent}
                            activeAssetId={reactions[0].asset.assetID}
                            feedID={reactions[0].feed.feedID}
                            caption={reactions[0].asset.caption}
                            token={token}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
  
                {reactions[0].feed.status && (
                  <div className="mt-6 p-4 bg-gray-50 rounded-lg">
                    <h4 className="text-lg font-semibold mb-3">Feed Timeline</h4>
                    <div className="grid grid-cols-1 gap-2">
                      <div className="flex justify-between text-sm">
                        <span className="font-medium">Session Start:</span>
                        <span>{formatTimestamp(reactions[0].feed.sessionStartTime)}</span>
                      </div>
                      {reactions[0].feed.inProgressTimestamp && (
                        <div className="flex justify-between text-sm">
                          <span className="font-medium">Started:</span>
                          <span>{formatTimestamp(reactions[0].feed.inProgressTimestamp)}</span>
                        </div>
                      )}
                      {reactions[0].feed.completedTimestamp && (
                        <div className="flex justify-between text-sm">
                          <span className="font-medium">Completed:</span>
                          <span>{formatTimestamp(reactions[0].feed.completedTimestamp)}</span>
                        </div>
                      )}
                      <div className="flex justify-between text-sm">
                        <span className="font-medium">Session End:</span>
                        <span>{formatTimestamp(reactions[0].feed.sessionEndTime)}</span>
                      </div>
                    </div>
                    <div className="mt-4 flex justify-between text-sm">
                      <div>
                        <span className="font-medium">Max Started:</span> {reactions[0].feed.maxStarted || 'N/A'}
                      </div>
                      <div>
                        <span className="font-medium">Max Completed:</span> {reactions[0].feed.maxCompleted || 'N/A'}
                      </div>
                    </div>
                  </div>
                )}
              </div>
  
              <h3 className="text-xl font-bold mb-4">Reactions</h3>
              {reactions.map((reaction) => (
                <AssetReactionCard 
                  key={reaction.reaction.reactionID} 
                  reaction={{
                    ...reaction,
                    reaction: {
                      ...reaction.reaction,
                      reactionDetails: {
                        ...reaction.reaction.reactionDetails,
                        fulfillment: reaction.reaction.reactionDetails.fulfillment ? {
                          ...reaction.reaction.reactionDetails.fulfillment,
                          status: reaction.reaction.reactionDetails.fulfillmentFeedStatus,
                          feedLink: reaction.reaction.reactionDetails.fulfillmentFeedLink
                        } : null
                      }
                    }
                  }}
                />
              ))}
            </div>
          )}
        </div>
      )}
  
      {selectedAsset && reactions.length === 0 && !loading && (
        <div className="text-center py-12 bg-gray-50 rounded-lg">
          <p className="text-xl text-gray-600">No reactions found</p>
        </div>
      )}
  
      {!selectedAsset && !loading && (
        <div className="text-center py-12 bg-gray-50 rounded-lg">
          <p className="text-xl text-gray-600">Select an asset to view reactions</p>
        </div>
      )}
    </div>
  );
};

export default AssetReactions;