import React, { useState } from "react";
import axios from "axios";
import "../App.css";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../assets/images/GenZ_Logo.svg";

const profileContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "20px",
  backgroundColor: "#F7E0D9",
  position: "relative",
  fontFamily: "Acumin-RPro",
};

const headerStyle = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  padding: "10px 0",
  borderBottom: "2px solid #E63946",
};

const inputStyle = {
  margin: "10px 0",
  padding: "10px",
  width: "300px",
  border: "1px solid #E63946",
  borderRadius: "23px",
  backgroundColor: "#FFFFFF",
  fontFamily: "Acumin-RPro",
};

const labelStyle = {
  fontWeight: "bold",
  color: "#E63946",
  display: "block", // This will make labels appear above input fields
  margin: "10px 0",
};

const logoStyle = {
  width: "60px",
};

const backToFeedStyle = {
  textDecoration: "none",
  margin: "20px 0",
  fontFamily: "Acumin-RPro",
  color: "#E63946",
};

const Profile = (props) => {
  const [image, setImage] = useState(null);
  const [error, setError] = useState("");
  const [currentUser, setCurrentUser] = useState("");

  /*
    useEffect(() => {
        axios.get('http://example.com/api/currentUser')
            .then(response => {
                setCurrentUser(response.data.currentUser);
            })
            .catch(error => {
                console.error('Error fetching current user:', error);
            });
    }, []);

    axios
        .get("https://genz-staging.feeltiptop.com/api/asset/getPosts", {
          params: { feedID: currentFeedID },
          headers: { Authorization: "Bearer " + props.token },
        })
        .then((response) => {
          response.data.access_token &&
            props.setToken(response.data.access_token);
          setPosts(response.data);
        })
        .catch((error) => {
          setError(error);
        });
    
        */

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "Washington DC",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const ethnic = [
    "American Indian or Alaska Native",
    "Asian",
    "Black or African American",
    "Caucasian or White",
    "Hispanic or Latinx",
    "Middle Eastern or North African",
    "Native Hawaiian or other Pacific Islander",
    "Prefer not to say",
  ];

  const genders = ["Man", "Woman", "Nonbinary", "Other", "Prefer not to say"];

  const isValidFileType = (filename) => {
    return /\.(jpg|jpeg|png|gif|tiff)$/i.test(filename);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && isValidFileType(file.name)) {
      setError(""); // Clear any previous error messages
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setError(
        "Invalid file type. Please select an image file (jpg, jpeg, png, gif, tiff).",
      );
      setImage(null); // Clear the image if the file type is invalid
    }
  };

  return (
    <div style={profileContainerStyle}>
      <div style={headerStyle}>
        <h2>Profile</h2>
        {currentUser ? (
          <h4>Current user logged in: {currentUser}</h4>
        ) : (
          <h4>No user logged in</h4>
        )}
        {/* <img src={logo} alt="Logo" style={logoStyle} /> */}
      </div>

      <div>
        <label style={labelStyle} htmlFor="firstName">
          First Name:
        </label>
        <input style={inputStyle} type="text" id="firstName" />
      </div>
      <div>
        <label style={labelStyle} htmlFor="lastName">
          Last Name:
        </label>
        <input style={inputStyle} type="text" id="lastName" />
      </div>
      <div>
        <label style={labelStyle} htmlFor="phone">
          Phone Number:
        </label>
        <input style={inputStyle} type="tel" id="phone" />
      </div>
      <div>
        <label style={labelStyle} htmlFor="email">
          Email:
        </label>
        <input style={inputStyle} type="email" id="email" />
      </div>
      <div>
        <label style={labelStyle} htmlFor="username">
          Username:
        </label>
        <input style={inputStyle} type="text" id="Username" />
      </div>
      <div>
        <label style={labelStyle} htmlFor="gender">
          Gender:
        </label>
        <select style={inputStyle} id="gender">
          <option value="">Select a Gender</option>
          {genders.map((state, index) => (
            <option key={index} value={state}>
              {state}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label style={labelStyle} htmlFor="Ethnicity">
          Ethnicity:
        </label>
        <select style={inputStyle} id="ethnicity">
          <option value="">Select an Ethnicity</option>
          {ethnic.map((state, index) => (
            <option key={index} value={state}>
              {state}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label style={labelStyle} htmlFor="state">
          State:
        </label>
        <select style={inputStyle} id="state">
          <option value="">Select a state</option>
          {states.map((state, index) => (
            <option key={index} value={state}>
              {state}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label style={labelStyle} htmlFor="dob">
          Date of Birth:
        </label>
        <input style={inputStyle} type="date" id="dob" />
      </div>
      <div style={{ textAlign: "left" }}>
        <label style={labelStyle}>Interests:</label>
        <div>
          <input
            type="checkbox"
            id="clothing"
            name="interests"
            value="clothing"
          />
          <label htmlFor="clothing">Clothing</label>
        </div>
        <div>
          <input type="checkbox" id="food" name="interests" value="food" />
          <label htmlFor="food">Food</label>
        </div>
        <div>
          <input type="checkbox" id="sports" name="interests" value="sports" />
          <label htmlFor="sports">Sports</label>
        </div>
        <div>
          <input type="checkbox" id="style" name="interests" value="style" />
          <label htmlFor="style">Style</label>
        </div>
        <div>
          <input
            type="checkbox"
            id="clothing"
            name="interests"
            value="clothing"
          />
          <label htmlFor="clothing">Clothing</label>
        </div>
        <div>
          <input type="checkbox" id="food" name="interests" value="food" />
          <label htmlFor="food">Food</label>
        </div>
        <div>
          <input type="checkbox" id="sports" name="interests" value="sports" />
          <label htmlFor="sports">Sports</label>
        </div>
        <div>
          <input type="checkbox" id="style" name="interests" value="style" />
          <label htmlFor="style">Style</label>
        </div>
      </div>
    </div>
  );
};

export default Profile;
