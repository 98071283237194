import React, { useState } from "react";
import { Form, Button, InputGroup, FormControl, Alert, Spinner, Table } from "react-bootstrap";
import { encrypttoHEX } from "./encodedecode.js";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const LinkGenerator = () => {
  const [feedID, setFeedID] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [includeCredentials, setIncludeCredentials] = useState(false);
  const [generatedLink, setGeneratedLink] = useState("");
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState("");
  
  const [batchFile, setBatchFile] = useState(null);
  const [batchData, setBatchData] = useState([]);
  const [processedData, setProcessedData] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [batchProgress, setBatchProgress] = useState(0);
  const [showPreview, setShowPreview] = useState(false);

  const generateLink = () => {
    if (!feedID || isNaN(feedID) || parseInt(feedID) <= 0) {
      setError("Please enter a valid Feed ID (positive number)");
      return;
    }

    if (includeCredentials && (!username || !password)) {
      setError("Both username and password are required for auto-login links");
      return;
    }

    setError("");

    try {
      const linkData = {
        feedID: parseInt(feedID),
      };

      if (includeCredentials) {
        linkData.username = username;
        linkData.password = password;
      }

      const encryptedData = encrypttoHEX(JSON.stringify(linkData));
      
      const baseUrl = window.location.origin;
      const generatedUrl = `${baseUrl}/login?p=${encryptedData}`;
      
      setGeneratedLink(generatedUrl);
    } catch (error) {
      console.error("Error generating link:", error);
      setError("Failed to generate link. Please try again.");
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(generatedLink).then(
      () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
      },
      (err) => {
        console.error("Failed to copy: ", err);
      }
    );
  };

  const handleBatchFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setBatchFile(null);
      setBatchData([]);
      return;
    }

    setBatchFile(file);
    parseCSVFile(file);
  };

  const parseCSVFile = (file) => {
    const reader = new FileReader();
    
    reader.onload = (e) => {
      try {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false });
        
        const requiredColumns = ["Username", "Password", "FirstName", "LastName", "Email"];
        const headers = Object.keys(jsonData[0] || {});
        
        const missingColumns = requiredColumns.filter(
          col => !headers.includes(col)
        );
        
        if (missingColumns.length > 0) {
          setError(`File is missing required columns: ${missingColumns.join(", ")}`);
          setBatchData([]);
          return;
        }
        
        setBatchData(jsonData);
        setError("");
        console.log("Parsed file data:", jsonData);
      } catch (error) {
        console.error("Error parsing file:", error);
        setError("Failed to parse file. Please check the format.");
        setBatchData([]);
      }
    };
    
    reader.onerror = (error) => {
      console.error("Error reading file:", error);
      setError("Failed to read file. Please try again.");
      setBatchData([]);
    };
    
    reader.readAsBinaryString(file);
  };

  const processBatch = () => {
    if (!feedID || isNaN(feedID) || parseInt(feedID) <= 0) {
      setError("Please enter a valid Feed ID (positive number)");
      return;
    }

    if (batchData.length === 0) {
      setError("Please upload a valid CSV file with user data");
      return;
    }

    setIsProcessing(true);
    setBatchProgress(0);
    setError("");
    
    const processed = [];
    const total = batchData.length;
    
    batchData.forEach((user, index) => {
      try {
        const linkData = {
          feedID: parseInt(feedID),
          username: user.Username,
          password: user.Password
        };

        const encryptedData = encrypttoHEX(JSON.stringify(linkData));
        const baseUrl = window.location.origin;
        const autoLoginLink = `${baseUrl}/login?p=${encryptedData}`;
        
        processed.push({
          ...user,
          AutoLoginLink: autoLoginLink
        });
        
        const progress = Math.round(((index + 1) / total) * 100);
        setBatchProgress(progress);
        
      } catch (error) {
        console.error(`Error processing user ${user.Username}:`, error);
        processed.push({
          ...user,
          AutoLoginLink: `Error: ${error.message || "Failed to generate link"}`
        });
      }
    });
    
    setProcessedData(processed);
    setIsProcessing(false);
    setShowPreview(true);
  };

  const downloadBatchLinks = () => {
    if (processedData.length === 0) return;
    
    const worksheet = XLSX.utils.json_to_sheet(processedData);
    
    const csvOutput = XLSX.utils.sheet_to_csv(worksheet);
    
    const blob = new Blob([csvOutput], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, "user_accounts_with_links.csv");
  };

  return (
    <div className="container mt-5">
      <h2>Feed Link Generator</h2>
      
      <Form className="mt-4">
        <Form.Group className="mb-3">
          <Form.Label>Feed ID</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter Feed ID"
            value={feedID}
            onChange={(e) => setFeedID(e.target.value)}
            required
          />
        </Form.Group>

        <div className="mb-4 p-3 border rounded">
          <h4>Generate Single Link</h4>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Include auto-login credentials"
              checked={includeCredentials}
              onChange={(e) => setIncludeCredentials(e.target.checked)}
            />
          </Form.Group>

          {includeCredentials && (
            <>
              <Form.Group className="mb-3">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <Form.Text className="text-warning">
                  Warning: Including passwords in links has security implications. Use with caution.
                </Form.Text>
              </Form.Group>
            </>
          )}

          <Button variant="primary" onClick={generateLink}>
            Generate Link
          </Button>
        </div>

        <div className="mb-4 p-3 border rounded">
          <h4>Generate Batch Links</h4>
          <p className="text-muted">Upload Excel file generated from the User Import tool to create auto-login links for all users.</p>
          
          <Form.Group className="mb-3">
            <Form.Label>Upload User File</Form.Label>
            <Form.Control
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={handleBatchFileChange}
              disabled={isProcessing}
            />
            <Form.Text className="text-muted">
              Excel or CSV file should include Username, Password, FirstName, LastName, and Email columns.
            </Form.Text>
          </Form.Group>

          {batchData.length > 0 && (
            <div className="mb-3">
              <p>Found {batchData.length} users in the CSV file</p>
              <Button 
                variant="primary" 
                onClick={processBatch} 
                disabled={isProcessing}
              >
                {isProcessing ? 'Processing...' : 'Generate Links for All Users'}
              </Button>
            </div>
          )}

          {isProcessing && (
            <div className="mb-3">
              <p className="mb-2">Processing users... {batchProgress}%</p>
              <div className="progress">
                <div 
                  className="progress-bar" 
                  role="progressbar" 
                  style={{ width: `${batchProgress}%` }}
                  aria-valuenow={batchProgress} 
                  aria-valuemin="0" 
                  aria-valuemax="100"
                />
              </div>
            </div>
          )}
        </div>

        {error && <Alert variant="danger">{error}</Alert>}

        {generatedLink && (
          <div className="mt-4">
            <h4>Generated Link:</h4>
            <InputGroup className="mb-3">
              <FormControl
                value={generatedLink}
                readOnly
              />
              <Button 
                variant={copied ? "success" : "outline-secondary"} 
                onClick={copyToClipboard}
              >
                {copied ? "Copied!" : "Copy"}
              </Button>
            </InputGroup>
            
            <Alert variant="info">
              Share this link with participants to give them direct access to the feed.
              {includeCredentials && " They will be logged in automatically using the provided credentials."}
            </Alert>
          </div>
        )}

        {showPreview && processedData.length > 0 && (
          <div className="mt-4">
            <h4>Batch Results Preview</h4>
            <p>Generated {processedData.length} links. Use the button below to download the complete CSV.</p>
            
            <div className="table-responsive mb-3">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Username</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Auto-Login Link</th>
                  </tr>
                </thead>
                <tbody>
                  {processedData.slice(0, 5).map((user, index) => (
                    <tr key={index}>
                      <td>{user.Username}</td>
                      <td>{user.FirstName}</td>
                      <td>{user.LastName}</td>
                      <td>{user.Email}</td>
                      <td className="text-truncate" style={{ maxWidth: "200px" }}>
                        {user.AutoLoginLink.startsWith("Error") ? 
                          <span className="text-danger">{user.AutoLoginLink}</span> : 
                          user.AutoLoginLink
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {processedData.length > 5 && (
                <p className="text-muted">Showing first 5 of {processedData.length} results...</p>
              )}
            </div>
            
            <Button 
              variant="success" 
              onClick={downloadBatchLinks} 
              className="mb-3"
            >
              Download Complete CSV with Links
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default LinkGenerator;