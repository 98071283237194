import React, { useState } from 'react';
import { FaCalendarAlt, FaFileUpload, FaDownload, FaInfoCircle } from 'react-icons/fa';

function Syllabus() {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setError("Please select a file to upload.");
      return;
    }
    setError(null);
    setUploading(true);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await fetch("https://genz-staging.feeltiptop.com/api/syllabus/upload", {
        method: "POST",
        body: formData,
      });

      const data = await res.json();
      if (!res.ok) {
        setError(data.error || "An error occurred.");
      } else {
        setResponseData(data);
      }
    } catch (err) {
      setError("An error occurred while uploading the file.");
    } finally {
      setUploading(false);
    }
  };

  // Format date for better display
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-100 to-gray-200 py-10 px-4">
      <div className="max-w-4xl mx-auto">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
          <div className="bg-blue-600 text-white p-6">
            <h1 className="text-3xl font-bold">Syllabus Analyzer</h1>
            <p className="mt-2 opacity-90">Upload your syllabus to extract important dates and information</p>
          </div>
          
          <div className="p-6">
            <form onSubmit={handleSubmit} className="mb-6">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Upload Syllabus (PDF or DOCX)
                </label>
                <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center hover:bg-gray-50 transition-colors">
                  <FaFileUpload className="mx-auto text-4xl text-gray-400 mb-3" />
                  <input
                    type="file"
                    accept=".pdf,.docx"
                    onChange={handleFileChange}
                    className="hidden"
                    id="file-upload"
                  />
                  <label htmlFor="file-upload" className="cursor-pointer text-blue-600 hover:text-blue-800">
                    {file ? file.name : "Click to select a file"}
                  </label>
                  <p className="text-sm text-gray-500 mt-1">or drag and drop here</p>
                </div>
              </div>
              <button
                type="submit"
                disabled={uploading}
                className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-lg transition-colors flex items-center justify-center"
              >
                {uploading ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Processing...
                  </>
                ) : (
                  "Analyze Syllabus"
                )}
              </button>
            </form>
            
            {error && (
              <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6" role="alert">
                <p className="font-bold">Error</p>
                <p>{error}</p>
              </div>
            )}
          </div>
        </div>

        {responseData && responseData.json_output && (
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="bg-green-600 text-white p-6">
              <h2 className="text-2xl font-bold flex items-center">
                <FaInfoCircle className="mr-2" /> Extracted Data
              </h2>
              <p className="mt-2 opacity-90">Important dates and information from your syllabus</p>
            </div>
            
            <div className="p-6">
              {responseData.json_output.sorted && responseData.json_output.sorted.length > 0 ? (
                <div className="grid gap-4 md:grid-cols-2">
                  {responseData.json_output.sorted.map((item, index) => (
                    <div key={index} className="bg-gray-50 rounded-lg p-4 border border-gray-200 hover:shadow-md transition-shadow">
                      <div className="flex items-center text-blue-600 mb-2">
                        <FaCalendarAlt className="mr-2" />
                        <span className="font-semibold">{formatDate(item.date)}</span>
                      </div>
                      <p className="text-gray-700">{item.information}</p>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500 italic">No important dates found in the syllabus.</p>
              )}
              
              {responseData.ics_file && (
                <div className="mt-8 text-center">
                  <a 
                    href={`/syllabus/download/ics/${responseData.ics_file.split('/').pop()}`} 
                    className="inline-flex items-center bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg transition-colors"
                    download
                  >
                    <FaDownload className="mr-2" />
                    Download Calendar File (.ics)
                  </a>
                  <p className="text-sm text-gray-500 mt-2">
                    Import these dates directly into your calendar app
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Syllabus;
