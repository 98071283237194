import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Booking = ({ userId }) => {
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [availability, setAvailability] = useState(null); // 'free' or 'booked'
  const [bookingDetails, setBookingDetails] = useState(null); // e.g. { price, title }
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  /**
   * 1) Fetch the user's current calendar events when
   * the component mounts or userId changes.
   */
  useEffect(() => {
    if (!userId) return;
    axios
      .get(`https://genz-staging.feeltiptop.com/api/calendar-events?userId=${userId}`)
      .then((res) => {
        setCalendarEvents(res.data);
      })
      .catch((err) => {
        console.error('Error fetching calendar events:', err);
        setError('Could not fetch your calendar events. Please try again later.');
      });
  }, [userId]);

  /**
   * 2) Check if the selected time is available.
   *    This calls /api/check-availability with { userId, date, time }
   */
  const checkAvailability = async () => {
    if (!userId || !selectedDate || !selectedTime) {
      setError('Please enter a valid date and time.');
      return;
    }

    setLoading(true);
    setError('');
    try {
      const response = await axios.post(`https://genz-staging.feeltiptop.com/api/check-availability`, {
        userId,
        date: selectedDate,
        time: selectedTime,
      });

      // The backend should return:
      // { available: true, bookingDetails?: { price, title } }
      // or { available: false } if booked.
      if (response.data.available) {
        setAvailability('free');
        setBookingDetails(response.data.bookingDetails || null);
      } else {
        setAvailability('booked');
        setBookingDetails(null);
      }
    } catch (err) {
      console.error('Availability check failed:', err);
      setError('Error checking availability. Please try again later.');
    }
    setLoading(false);
  };

  /**
   * 3) Book the time slot directly if it's free.
   *    Calls /api/book-time with { userId, date, time }
   */
  const bookTime = async () => {
    if (!userId || !selectedDate || !selectedTime) {
      setError('Please enter a valid date and time.');
      return;
    }

    setError('');
    try {
      const response = await axios.post(`https://genz-staging.feeltiptop.com/api/book-time`, {
        userId,
        date: selectedDate,
        time: selectedTime,
      });
      // The backend should return { success: true } or { success: false, error: "..." }
      if (response.data.success) {
        // Refresh calendar events after booking
        const eventsRes = await axios.get(`https://genz-staging.feeltiptop.com/api/calendar-events?userId=${userId}`);
        setCalendarEvents(eventsRes.data);
        alert('Time booked successfully!');
        setAvailability(null);
        setBookingDetails(null);
      } else {
        alert(response.data.error || 'Booking failed. Please try again.');
      }
    } catch (err) {
      console.error('Booking error:', err);
      setError('Error booking time. Please try again later.');
    }
  };

  /**
   * 4) Send a booking request if the slot is not free (or if you want the user to approve).
   *    Calls /api/send-booking-request with { userId, date, time }
   */
  const sendBookingRequest = async () => {
    if (!userId || !selectedDate || !selectedTime) {
      setError('Please enter a valid date and time.');
      return;
    }

    setError('');
    try {
      const response = await axios.post(`https://genz-staging.feeltiptop.com/api/send-booking-request`, {
        userId,
        date: selectedDate,
        time: selectedTime,
      });
      // The backend should return { success: true } or { success: false, error: "..." }
      if (response.data.success) {
        alert('Booking request sent!');
        setAvailability(null);
        setBookingDetails(null);
      } else {
        alert(response.data.error || 'Failed to send booking request.');
      }
    } catch (err) {
      console.error('Booking request error:', err);
      setError('Error sending booking request. Please try again later.');
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-8">
      <h2 className="text-3xl font-bold text-gray-800 mb-8 text-center">Booking Management</h2>
      
      {/* Calendar View Section */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <h3 className="text-xl font-semibold text-gray-700 mb-4">Your Calendar</h3>
        <div className="max-h-60 overflow-y-auto">
          {calendarEvents && calendarEvents.length > 0 ? (
            <ul className="space-y-2">
              {calendarEvents.map((event) => (
                <li 
                  key={event.EventID}
                  className="flex items-center p-3 bg-gray-50 rounded-md hover:bg-gray-100 transition-colors"
                >
                  <span className="text-blue-600 font-medium">{event.EventDate}</span>
                  <span className="mx-2 text-gray-500">|</span>
                  <span className="text-gray-700">{event.EventTime}</span>
                  <span className="mx-2 text-gray-500">-</span>
                  <span className="text-gray-800 font-medium">{event.EventName}</span>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500 text-center py-4">No events scheduled</p>
          )}
        </div>
      </div>

      {/* Booking Input Section */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <h3 className="text-xl font-semibold text-gray-700 mb-6">Book a Time</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Select Date
            </label>
            <input
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Select Time
            </label>
            <input
              type="time"
              value={selectedTime}
              onChange={(e) => setSelectedTime(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>
        <button
          onClick={checkAvailability}
          className="mt-6 w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
        >
          Check Availability
        </button>
      </div>

      {/* Status and Booking Options Section */}
      <div className="space-y-4">
        {loading && (
          <div className="text-center py-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
            <p className="mt-2 text-gray-600">Checking availability...</p>
          </div>
        )}

        {error && (
          <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded-md">
            <p className="text-red-700">{error}</p>
          </div>
        )}

        {availability === 'free' && bookingDetails && (
          <div className="bg-green-50 rounded-lg p-6 border border-green-200">
            <p className="text-gray-800 mb-4">
              This slot is available! The session is priced at{' '}
              <span className="font-semibold text-green-700">{bookingDetails.price} points</span>{' '}
              and is titled{' '}
              <span className="font-semibold text-gray-900">{bookingDetails.title}</span>.
            </p>
            <button
              onClick={bookTime}
              className="w-full bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-colors"
            >
              Book Now
            </button>
          </div>
        )}

        {availability === 'booked' && (
          <div className="bg-yellow-50 rounded-lg p-6 border border-yellow-200">
            <p className="text-gray-800 mb-4">
              This slot is already booked. Would you like to send a booking request?
            </p>
            <button
              onClick={sendBookingRequest}
              className="w-full bg-yellow-600 text-white py-2 px-4 rounded-md hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 transition-colors"
            >
              Send Request
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Booking;
