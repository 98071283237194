import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaSearch } from "react-icons/fa";

const MarketPlace = () => {
  const [sellers, setSellers] = useState([]);
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [allSellers, setAllSellers] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedSeller, setSelectedSeller] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [filterBy, setFilterBy] = useState("UserName"); // State to handle filter type

  // Fetch sellers from the backend on component mount
  useEffect(() => {
    fetchSellers();
  }, []);

  const fetchSellers = async () => {
    try {
      const response = await axios.get("https://genz-staging.feeltiptop.com/api/sellers");
  
      // Log the API response for debugging
      console.log("API Response:", response.data);
  
      // Use the sellers key to access the array
      const sellersWithTopics = await Promise.all(
        response.data.sellers.map(async (seller) => {
          const topics = await fetchUserTopics(seller.UserID);
          return { ...seller, Interests: topics }; // Add Interests to each seller
        })
      );
  
      // Update the state with sellers
      setSellers(sellersWithTopics);
      setAllSellers(sellersWithTopics);
    } catch (error) {
      console.error("Failed to fetch sellers:", error.message);
      setError("Failed to fetch sellers: " + error.message);
    }
  };

  const fetchUserTopics = async (userId) => {
    try {
      const response = await axios.get(
        `https://genz-staging.feeltiptop.com/api/users/${userId}/topics`
      );
      return response.data;
    } catch (error) {
      setError("Failed to fetch user topics: " + error.message);
      return [];
    }
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    if (searchTerm === "") {
      setSellers(allSellers); // Reset to all sellers if search term is empty
    } else if (filterBy === "UserName") {
      // Filter by UserName
      const filteredSellers = allSellers.filter((seller) =>
        seller.UserName?.toLowerCase().includes(searchTerm)
      );
      setSellers(filteredSellers);
    } else if (filterBy === "Interests") {
      // Filter by Interests
      const filteredSellers = allSellers.filter((seller) =>
        seller.Interests?.some((interest) =>
          interest.toLowerCase().includes(searchTerm)
        )
      );
      setSellers(filteredSellers);
    }
  };

  const handleInterestClick = (interest) => {
    setSearchTerm(interest); // Set search bar value
    setFilterBy("Interests"); // Automatically set filter to Interests
    handleSearch({ target: { value: interest } }); // Trigger the search function
  };

  const renderView = () => {
    switch (selectedOption) {
      case "Topics":
        return (
          <div>
            <h2 className="text-xl font-semibold">
              {selectedSeller.UserName}'s Interests:
            </h2>
            <div className="mt-2.5 flex flex-wrap gap-2.5">
              {selectedSeller.Interests && selectedSeller.Interests.length > 0 ? (
                selectedSeller.Interests.map((interest, index) => (
                  <span
                    key={index}
                    className={`${
                      hoveredIndex === index ? "bg-gray-400" : "bg-gray-300"
                    } cursor-pointer rounded-full px-4 py-2 text-sm font-medium text-gray-800 transition-colors duration-300 ease-in-out`}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    onClick={() => handleInterestClick(interest)}
                  >
                    {interest}
                  </span>
                ))
              ) : (
                <p>No interests available.</p>
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="mx-auto flex max-w-screen-xl flex-col items-center p-5">
      <h1 className="mb-5 text-3xl font-bold">MarketPlace Dashboard</h1>

      {/* Error Handling */}
      {error && <p className="text-red-500">{error}</p>}

      {/* Search Bar and Filter */}
      <div className="mb-5 flex w-full flex-col items-center justify-between gap-2.5 sm:flex-row sm:items-center">
        <div className="relative w-full sm:flex-1">
          <FaSearch className="pointer-events-none absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-400" />
          <input
            className="w-full rounded-md border border-gray-300 py-2 pl-10 pr-3 text-base"
            placeholder={`Search by ${filterBy}...`}
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>

        <select
          className="rounded-md border border-gray-300 px-3 py-2 sm:flex-1 sm:max-w-[200px]"
          value={filterBy}
          onChange={(e) => setFilterBy(e.target.value)}
        >
          <option value="UserName">Name</option>
          <option value="Interests">Interests</option>
        </select>
      </div>

      {/* Seller Cards */}
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
        {sellers.map((seller) => (
          <div
            key={seller.UserID}
            className="relative mx-auto w-[300px] transform cursor-pointer overflow-hidden rounded-lg border border-gray-200 bg-white p-6 text-center shadow-lg transition duration-300 hover:scale-105 hover:shadow-xl"
            onClick={() => {
              setSelectedSeller(seller);
              setSelectedOption("Topics");
            }}
          >
            <div className="flex flex-col items-center">
              <div className="relative mb-4 h-24 w-24">
                <img
                  src={
                    seller.ProfilePictureURL ||
                    "https://via.placeholder.com/150"
                  }
                  alt="Profile"
                  className="h-full w-full rounded-full border-4 border-blue-500 object-cover shadow-sm"
                />
              </div>
              <h3 className="text-lg font-semibold text-gray-800">
                {seller.UserName}
              </h3>
              <p className="text-sm text-gray-500">{seller.Email}</p>

              <div className="mt-4 flex flex-col items-center space-y-1">
                <p className="text-sm font-medium text-gray-700">
                  {seller.PhoneNumber}
                </p>
                <p className="text-sm text-gray-500">{seller.State}</p>
                <p className="text-sm text-gray-500">{seller.Ethnicity}</p>
                <p className="text-sm text-gray-500">{seller.Gender}</p>
                <p className="text-sm text-gray-500">
                  Rating: {seller.Rating || "N/A"}
                </p>
                <p className="text-sm text-gray-500">
                  Age Verified: {seller.AgeVerified}
                </p>
                <p className="text-sm text-gray-500">
                  Points Earned: {seller.PointsEarned}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Selected Seller Modal */}
      {selectedSeller && (
        <>
          <div
            className="fixed inset-0 z-50 bg-black/30"
            onClick={() => {
              setSelectedSeller(null);
              setSelectedOption("");
            }}
          />
          <div className="fixed left-1/2 top-1/2 z-50 max-h-[90vh] w-full max-w-screen-lg -translate-x-1/2 -translate-y-1/2 transform overflow-y-auto rounded-lg bg-white p-5 shadow-lg">
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
              <h2 className="mb-5 text-2xl font-semibold">Seller Profile</h2>
              <select
                className="cursor-pointer rounded-md bg-blue-500 px-4 py-2 text-base text-white hover:bg-blue-600"
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
              >
                <option value="Topics">Topics</option>
              </select>
            </div>
            {renderView()}
          </div>
        </>
      )}
    </div>
  );
};

export default MarketPlace;