import React from "react";

function PaymentHistory({ paymentRequests }) {
  return (
    <div className="rounded-lg bg-white p-6 shadow-md">
      <h4 className="mb-2 text-lg font-semibold text-blue-600">
        Past Payment Requests:
      </h4>
      <ul className="list-disc pl-6 text-black">
        {paymentRequests.length > 0 ? (
          paymentRequests.map((request) => (
            <li key={request.RequestID} className="mb-2">
              <span className="font-semibold">{request.Amount} USD</span> -{" "}
              {request.Status}{" "}
              <span className="text-sm text-gray-500">
                (Requested on{" "}
                {new Date(request.RequestDate).toLocaleDateString()})
              </span>
            </li>
          ))
        ) : (
          <p className="text-gray-500">No payment requests found.</p>
        )}
      </ul>
    </div>
  );
}

export default PaymentHistory;
