import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import { FaPlay, FaPause, FaSpinner } from "react-icons/fa"; // Importing play/pause and spinner icons
import axios from "axios";
import beepSound from "../assets/audio/beep-07a.mp3";

const SpeechAssessmentComponent = React.memo(function SpeechAssessmentComponent({ mediaLink, activeAssetId, caption, feedID, token }) {
  const [playCount, setPlayCount] = useState(3);  // Start from 3 as the maximum play count
  const [hasEnded, setHasEnded] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isSTTLoading, setIsSTTLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [scores, setScores] = useState(null);
  const [error, setError] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const playerRef = useRef(null);
  const recordingRef = useRef(false);
  let silenceTimeoutRef = useRef(null);

  const convertWebmBlobToWav = async (webmBlob) => {
    const audioContext = new AudioContext();
    const arrayBuffer = await webmBlob.arrayBuffer();

    // Decode audio data from the arrayBuffer
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    // Convert audioBuffer to WAV format
    const wavBlob = audioBufferToWav(audioBuffer);

    // Log first few bytes of WAV file to confirm RIFF header
    const wavArrayBuffer = await wavBlob.arrayBuffer();
    console.log(
      "First 4 bytes (should be 'RIFF'):",
      new Uint8Array(wavArrayBuffer).slice(0, 4),
    );

    return wavBlob;
  };

  const audioBufferToWav = (audioBuffer) => {
    const numOfChannels = audioBuffer.numberOfChannels;
    const sampleRate = audioBuffer.sampleRate;
    const format = 1; // PCM
    const bitDepth = 16;

    // Calculate buffer size
    const resultBuffer = new ArrayBuffer(
      44 + audioBuffer.length * numOfChannels * 2,
    );
    const view = new DataView(resultBuffer);

    // Write WAV headers
    writeString(view, 0, "RIFF");
    view.setUint32(4, 36 + audioBuffer.length * numOfChannels * 2, true);
    writeString(view, 8, "WAVE");
    writeString(view, 12, "fmt ");
    view.setUint32(16, 16, true); // PCM format
    view.setUint16(20, format, true);
    view.setUint16(22, numOfChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * numOfChannels * 2, true); // Byte rate
    view.setUint16(32, numOfChannels * 2, true); // Block align
    view.setUint16(34, bitDepth, true);
    writeString(view, 36, "data");
    view.setUint32(40, audioBuffer.length * numOfChannels * 2, true);

    // Write interleaved PCM samples
    let offset = 44;
    for (let i = 0; i < audioBuffer.length; i++) {
      for (let channel = 0; channel < numOfChannels; channel++) {
        const sample = Math.max(
          -1,
          Math.min(1, audioBuffer.getChannelData(channel)[i]),
        );
        view.setInt16(offset, sample * 0x7fff, true);
        offset += 2;
      }
    }

    return new Blob([view], { type: "audio/wav" });
  };

  // Helper function to write a string to DataView
  const writeString = (view, offset, string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };

  const isValidAudio = async (audioBlob) => {
    return new Promise((resolve, reject) => {
      try {
        const audioContext = new AudioContext();
        const reader = new FileReader();
        reader.readAsArrayBuffer(audioBlob);
        reader.onloadend = async () => {
          const audioBuffer = await audioContext.decodeAudioData(reader.result);
  
          // Check if the duration is greater than 1 second
          if (audioBuffer.duration <= 0.1) {
            console.log("Duration", audioBuffer.duration)
            resolve(false);
          }
  
          // Analyze audio data to check for significant volume (non-background noise)
          const channelData = audioBuffer.getChannelData(0); // Only one channel needed for basic analysis
          const averageVolume = channelData.reduce((sum, value) => sum + Math.abs(value), 0) / channelData.length;
          
          console.log(averageVolume)
  
          // Define a threshold for background noise
          const noiseThreshold = 0.0001;
          
          resolve(averageVolume > noiseThreshold);
        };
      } catch (error) {
        console.error("Error analyzing audio:", error);
        reject(error);
      }
    });
  };

  // Uses activeAssetId and audioBlob
  const submitSTT = async () => {
    setIsSTTLoading(true); // Set loading to true when starting the STT process
    setIsDisabled(true);
    if (activeAssetId && audioBlob) {
      try {
        // Preliminary check
        const isAudioValid = await isValidAudio(audioBlob);
        if (!isAudioValid) {
          setError("No audio captured during recording. Please check your microphone and try again.");
          setIsSTTLoading(false);
          setIsDisabled(false);
          return;
        }

        // Convert audio blob to WAV format
        const wavBlob = await convertWebmBlobToWav(audioBlob);
        const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
        const filename = `recording_${timestamp}.wav`;
        const audioFile = new File([wavBlob], filename, {
          type: "audio/wav",
          lastModified: new Date().getTime(),
        });

        // Then, call the Media Response API
        const formDataMediaResponse = new FormData();
        formDataMediaResponse.append("assetID", activeAssetId);
        formDataMediaResponse.append("feedID", feedID);
        formDataMediaResponse.append("media", audioFile);

        const responseMediaResponse = await axios.post(
          "https://genz-staging.feeltiptop.com/api/media_response",
          formDataMediaResponse,
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "multipart/form-data",
            },
          },
        );

        if (!responseMediaResponse.data.success) {
          console.error("Failed to submit media.");
          setIsDisabled(false);
          setError("Failed to submit media. Please reload the page.");
        } else {
          setIsDisabled(true);
          const reactionID = responseMediaResponse.data.media.reactionID
          
          // Call the Speech Assessment API
          const formDataSpeechAssessment = new FormData();
          formDataSpeechAssessment.append("caption", caption);
          formDataSpeechAssessment.append("assetID", activeAssetId);
          formDataSpeechAssessment.append("feedID", feedID);
          formDataSpeechAssessment.append("reactionID", reactionID);
          formDataSpeechAssessment.append("file", audioFile);
          const responseSpeechAssessment = await axios.post(
            "https://genz-staging.feeltiptop.com/api/speech_assessment",
            formDataSpeechAssessment,
            {
              headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "multipart/form-data",
              },
            },
          );

          // If successful, update scores
        setScores(responseSpeechAssessment.data.scores);

        }
      } catch (error) {
        console.error("Error submitting media:", error);
        setError("Your response was too short or silent. Please try again.");
        setIsDisabled(false);
      } finally {
        // Reset state and stop loading
        setIsSTTLoading(false); // Set loading to false after STT is done
        setAudioBlob(null); // Clear the audioBlob after submitting
      }
    } else {
      setError("Your response was too short. Please try again.");
      setIsSTTLoading(false);
      setIsDisabled(false);
    }
  };

  const startRecording = async () => {
    if (isDisabled) return;
    setError('');
  
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioContext = new AudioContext();
      await audioContext.resume();
    
      const analyser = audioContext.createAnalyser();
      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);
    
      analyser.fftSize = 1024;
      const dataArray = new Float32Array(analyser.fftSize);
    
      mediaRecorderRef.current = new MediaRecorder(stream);
    
      // Event handler for audio data
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };
    
      // Event handler for when recording stops
      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: "audio/webm" });
        setAudioBlob(audioBlob);  
        audioChunksRef.current = [];
        if (mediaRecorderRef.current.stream) {
          mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
        }
      };
    
      mediaRecorderRef.current.start();
      recordingRef.current = true;
    
      silenceTimeoutRef.current = setTimeout(() => {
        if (recordingRef.current) {
          stopRecording();
        }
      }, 30000); // 30 seconds
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };
  
  const stopRecording = () => {
    console.log("isRecording")
    console.log(isRecording)
    if (!recordingRef.current) return;
    
    console.log("Stopping recording...");
    
    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current); // Clear the timeout
      silenceTimeoutRef.current = null;
    }
    
    mediaRecorderRef.current.stop();
    setIsRecording(false);
    recordingRef.current = false;
    setPlayCount(playCount - 1);
  
    if (mediaRecorderRef.current.stream) {
      const tracks = mediaRecorderRef.current.stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  
    console.log("Recording stopped.");
  };  

  // Trigger submitSTT when audioBlob is set
  useEffect(() => {
    if (audioBlob) {
      submitSTT(); // Call submitSTT only when audioBlob is available
    }
  }, [audioBlob]);

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  // Handle end of audio
  const handleEnd = async () => {
      await startRecording();
      await delay(1500);
      await setIsPlaying(false);
      await setIsRecording(true);
      const beep = new Audio(beepSound);
      await beep.play();
  };
  
  const togglePlayPauseWithBeep = () => {
    if (isPlaying) {
      setIsPlaying(false);
    } else if (isRecording) {
      stopRecording(); // Decrease play count after the audio ends
    } else {
      setIsPlaying(true);
      setHasEnded(false);
    }
  };

  if (!mediaLink || !mediaLink.endsWith(".wav")) {
    return <div>No valid media link provided</div>;
  }

  const fullUrl = `https://genz-staging.feeltiptop.com/${mediaLink}`;

  return (
    <div className="flex flex-col items-center">
      {/* Audio Player */}
      <ReactPlayer
        ref={playerRef}
        url={fullUrl}
        controls={false} // Disable default controls
        playing={isPlaying}
        volume={1} // Set max volume
        muted={false} // Ensure it's not muted
        onEnded={handleEnd}
        height="10px"
        width="100%"
        config={{
          file: {
            attributes: {
              controlsList: "nodownload noremoteplayback", // Disable download and remote playback
              disablePictureInPicture: true, // Disable PiP
            },
          },
        }}
      />
  
      {/* Buttons */}
      <div className="flex space-x-4 mb-5">
        <button
          onClick={togglePlayPauseWithBeep}
          disabled={playCount <= 0 || isDisabled || isSTTLoading}
          className={`px-4 py-2 font-semibold rounded-md flex items-center justify-center text-white
            ${isRecording ? 'bg-[#ff2a2f] hover:bg-[#ff4f55]' : 'bg-green-500 hover:bg-green-600'} 
            disabled:bg-gray-400`}
        >

          {/* Display appropriate button text */}
          {isPlaying ? (
            "Playing Audio..."
          ) : isRecording && !isSTTLoading ? (
            "Submit Response"
          ) : isSTTLoading ? (
            "Loading..."
          ) : isDisabled ? (
            "Response Processed!"
          ) : (
            "Play Audio"
          )}

        </button>
      </div>

      {/* Playback Limit Message */}
      {playCount <= 0 && (
        <div className="text-[#ff2a2f] font-medium mt-2">
          Playback limit reached!
        </div>
      )}
  
      {/* Reset to play after the audio ends */}
      {playCount < 3 && playCount > 0 && !hasEnded && !isDisabled && (
        <div className="text-blue-500 font-medium mt-2">
          You have {playCount} playback attempts left!
        </div>
      )}
  
      {/* Error Message */}
      {error && <div className="text-[#ff2a2f] font-medium mt-2">{error}</div>}
  
      {/* Displaying Scores */}
      {scores && !isSTTLoading && (
        <div className="w-full max-w-md mx-auto bg-white rounded-lg p-4">
          {/* Divider */}
          <hr className="border-gray-300 my-6 w-full max-w-md" />
          <h2 className="text-xl font-semibold text-gray-800 mb-4">
            Assessment Scores
          </h2>
  
          {/* Expandable Scores Section */}
          <ExpandableScores scores={scores} />
  
          {/* Overall Score */}
          <div className="flex justify-between items-center mt-4">
            <span className="text-gray-600 font-medium">Overall Score</span>
            <span className="text-gray-800 font-semibold">
              {Math.trunc(scores["Weighted Score"] * 100) / 100}
            </span>
          </div>
        </div>
      )}
    </div>
  );
  
  // Expandable Scores Component
  function ExpandableScores({ scores }) {
    const [isExpanded, setIsExpanded] = React.useState(false);
  
    return (
      <div>
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-[#ff2a2f] hover:underline font-medium mb-4"
        >
          {isExpanded ? "Show Less" : "Show More"}
        </button>
  
        {isExpanded && (
          <div className="space-y-3">
            {Object.entries(scores).map(([scoreLabel, scoreValue]) => (
              scoreLabel !== "Weighted Score" && (
                <div key={scoreLabel} className="flex justify-between items-center">
                  <span className="text-gray-600 font-medium">{scoreLabel}</span>
                  <span className="text-gray-800 font-semibold">
                    {Math.trunc(scoreValue * 100) / 100}
                  </span>
                </div>
              )
            ))}
          </div>
        )}
      </div>
    );
  }  
});

export default SpeechAssessmentComponent;
