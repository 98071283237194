import React, { useState } from "react";
import axios from "axios";

const SellerUpload = () => {
  const [video, setVideo] = useState(null);
  const [picture, setPicture] = useState(null);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [videoTooltipVisible, setVideoTooltipVisible] = useState(false);
  const [pictureTooltipVisible, setPictureTooltipVisible] = useState(false);

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("video/")) {
      setVideo(file);
    } else {
      alert("Please upload a valid video file.");
    }
  };

  const handlePictureChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setPicture(file);
    } else {
      alert("Please upload a valid image file (JPEG or PNG).");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("video", video);
    formData.append("picture", picture);

    try {
      const response = await axios.post(
        "https://genz-staging.feeltiptop.com/api/sellers/verify-age",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );

      // Log the entire response to verify its structure
      console.log("Response:", response.data);

      const { success } = response.data;

      // Check only the success status
      if (success) {
        setMessage(
          "Congratulations! You are eligible to be a seller. Our team will review your video and notify you within 1 to 2 business days.",
        );
      } else {
        setMessage(
          "Thank you for providing the material. Our team will review your materials to make the final decision and notify you within 1 to 2 business days.",
        );
      }
    } catch (error) {
      console.error("Error verifying:", error);
      setMessage(
        "An error occurred during the verification process. Please try again later.",
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-4">
      <h1 className="mb-4 text-2xl font-bold">
        Upload Your Introduction Video and Profile Picture
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="mb-1 block">
            Introduction Video:
            <span
              className="relative ml-2 inline-block cursor-pointer"
              onMouseEnter={() => setVideoTooltipVisible(true)}
              onMouseLeave={() => setVideoTooltipVisible(false)}
            >
              <span className="text-blue-600">ℹ️</span>
              {videoTooltipVisible && (
                <span className="absolute left-full top-1/2 ml-2 w-72 -translate-y-1/2 transform rounded-md bg-gray-700 p-2 text-sm text-white shadow-lg">
                  Please ensure your video is clear, focused on your face, and
                  free from distractions or background noise.
                  <br />
                  <strong>Recommended Duration:</strong> 2 minutes.
                  <br />
                  Use this opportunity to provide a brief introduction about
                  yourself, emphasizing your key attributes and skills.
                </span>
              )}
            </span>
          </label>
          <input
            type="file"
            accept="video/*"
            onChange={handleVideoChange}
            className="w-full rounded-md border p-2"
          />
        </div>

        <div className="mb-4">
          <label className="mb-1 block">
            Profile Picture:
            <span
              className="relative ml-2 inline-block cursor-pointer"
              onMouseEnter={() => setPictureTooltipVisible(true)}
              onMouseLeave={() => setPictureTooltipVisible(false)}
            >
              <span className="text-blue-600">ℹ️</span>
              {pictureTooltipVisible && (
                <span className="absolute left-full top-1/2 ml-2 w-72 -translate-y-1/2 transform rounded-md bg-gray-700 p-2 text-sm text-white shadow-lg">
                  For optimal results, please ensure your face is clearly
                  visible in the image, similar to a passport photo.
                  <br />
                  <strong>Guidelines:</strong>
                  <ul className="list-inside list-disc pl-5">
                    <li>Use a close-up shot where your face is centered.</li>
                    <li>Ensure good lighting; avoid shadows and glare.</li>
                    <li>
                      Choose a plain background to enhance focus on your face.
                    </li>
                  </ul>
                  The image must be in <strong>JPEG or PNG</strong> format for
                  best results.
                </span>
              )}
            </span>
          </label>
          <input
            type="file"
            accept="image/*"
            onChange={handlePictureChange}
            className="w-full rounded-md border p-2"
          />
        </div>

        <button
          type="submit"
          className={`rounded-md bg-blue-600 p-2 text-white hover:bg-blue-700 ${isLoading ? "cursor-not-allowed opacity-50" : ""}`}
          disabled={isLoading}
        >
          {isLoading ? "Uploading..." : "Upload"}
        </button>
      </form>

      {message && (
        <div
          className={`mt-4 p-2 text-sm ${message.includes("Congratulations") ? "text-green-600" : "text-red-600"}`}
        >
          {message}
        </div>
      )}
    </div>
  );
};

export default SellerUpload;
