import React, { useState, useEffect } from "react";

const PollComponent = ({
  assetContent,
  token,
  activeAssetId,
  feedID,
  userID,
  setToken,
}) => {
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [surveyResponse, setSurveyResponse] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shuffledChoices, setShuffledChoices] = useState([]);
  const [error, setError] = useState(null);
  const [quizResult, setQuizResult] = useState(null);
  const [message, setMessage] = useState("");
  const [maxSelections, setMaxSelections] = useState(1);

  useEffect(() => {
    if (
      assetContent.pollType === "limited-multi" &&
      assetContent.maxSelections
    ) {
      setMaxSelections(assetContent.maxSelections);
    }
  }, [assetContent]);

  useEffect(() => {
    if (selectedAnswers.length > 0) {
      handleSubmit();
    }
  }, [selectedAnswers]);

  const handleAnswerChange = (value) => {
    if (
      assetContent.pollType === "multi" ||
      assetContent.pollType === "limited-multi"
    ) {
      setSelectedAnswers((prev) => {
        if (prev.includes(value)) {
          return prev.filter((v) => v !== value);
        } else if (
          assetContent.pollType === "limited-multi" &&
          prev.length >= maxSelections
        ) {
          setError(`You can only select up to ${maxSelections} options.`);
          return prev;
        } else {
          return [...prev, value];
        }
      });
    } else {
      setSelectedAnswers([value]);
    }
    setError(null);
  };

  const saveToLocalStorage = (key, value) => {
    let feedState = JSON.parse(localStorage.getItem("feedState"));
    if (feedState.feedID === feedID) {
      //Update the feedState with the new poll response
      feedState[key] = value;
    }
    localStorage.setItem("feedState", JSON.stringify(feedState));
  };

  const handleSurveyResponseChange = (e) => {
    setSurveyResponse(e.target.value);
    setError(null);
  };

  const submitSingleResponse = async (choice) => {
    try {
      if (!choice || choice.trim() === "") {
        throw new Error("Choice cannot be empty.");
      }

      const pollResponseData = {
        assetID: activeAssetId,
        feedID: feedID,
        pollChoice: choice,
      };

      const response = await fetch("https://genz-staging.feeltiptop.com/api/poll_response", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(pollResponseData),
      });

      const data = await response.json();

      if (data.access_token) {
        setToken(data.access_token);
      }

      if (data.success) {
        setMessage(data.message);
        try {
          saveToLocalStorage(activeAssetId, choices);
        } catch (error) {
          // console.error("Error saving to local storage:", error);
        }
        return true;
      } else {
        console.error("Server responded with failure:", data.message);
        return false;
      }
    } catch (error) {
      console.error("Error submitting poll response:", error);
      return false;
    }
  };

  const submitMultiResponses = async (choices) => {
    try {
      if (!choices || choices.length === 0) {
        throw new Error("At least one choice must be selected.");
      }

      const pollResponseData = {
        assetID: activeAssetId,
        feedID: feedID,
        pollChoices: choices,
        pollType: "multi",
      };

      const response = await fetch("https://genz-staging.feeltiptop.com/api/poll_response", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(pollResponseData),
      });

      const data = await response.json();

      if (data.access_token) {
        setToken(data.access_token);
      }

      if (data.success) {
        setMessage(data.message);
        try {
          saveToLocalStorage(activeAssetId, choices);
        } catch (error) {
          // console.error("Error saving to local storage:", error);
        }
        return true;
      } else {
        console.error("Server responded with failure:", data.message);
        return false;
      }
    } catch (error) {
      console.error("Error submitting poll responses:", error);
      return false;
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setError(null);

    try {
      if (assetContent.pollType === "free_response") {
        if (surveyResponse && surveyResponse.trim()) {
          const success = await submitSingleResponse(surveyResponse);
          if (success) {
            setIsSubmitted(true);
          } else {
            setError("Failed to submit response. Please try again.");
          }
        } else {
          const success = await submitSingleResponse("NA");
          if (success) {
            setIsSubmitted(true);
          } else {
            setError("Failed to submit response. Please try again.");
          }
        }
      } else if (selectedAnswers.length > 0) {
        if (
          assetContent.pollType === "multi" ||
          assetContent.pollType === "limited-multi"
        ) {
          const result = await submitMultiResponses(selectedAnswers);
          if (result) {
            setIsSubmitted(true);
          } else {
            setError("Failed to submit poll responses.");
          }
        } else {
          const success = await submitSingleResponse(selectedAnswers[0]);
          if (success) {
            if (
              assetContent.pollType === "quiz" ||
              assetContent.pollType === "binary"
            ) {
              const selectedKey = Object.entries(assetContent.selections).find(
                ([_, value]) => value === selectedAnswers[0],
              )?.[0];
              const result = selectedKey === assetContent.answer;
              setQuizResult(result);
            }
            setIsSubmitted(true);
          } else {
            setError("Failed to submit response. Please try again.");
          }
        }
      }
    } catch (err) {
      setError("An unexpected error occurred. Please try again.");
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    setIsSubmitted(false);
    setIsSubmitting(false);
    setError(null);
    setQuizResult(null);

    if (assetContent.pollType !== "free_response" && assetContent.selections) {
      const choices = Object.entries(assetContent.selections);
      // const shuffled = [...choices].sort(() => Math.random() - 0.5);
      setShuffledChoices(choices); // Temporarily disable shuffling
    }
  }, [assetContent.question]);

  useEffect(() => {
    const feedState = JSON.parse(localStorage.getItem("feedState"));
    if (feedState && feedState.feedID === feedID) {
      console.log("Feed state found in local storage:", feedState);
      console.log("asset content: ", assetContent);
      const storedResponse = feedState[activeAssetId];
      console.log("stored response: ", storedResponse);
      if (assetContent.pollType.trim() === "free_response") {
        console.log("setting survey response to ", storedResponse);
        setSurveyResponse(storedResponse || "");
      } else {
        console.log("setting selected answers to ", storedResponse);
        if (typeof storedResponse === "string") {
          setSelectedAnswers([storedResponse]);
        } else {
          setSelectedAnswers(storedResponse || []);
        }
      }
    }
  }, [feedID]);

  const getCorrectAnswerText = () => {
    if (assetContent.answer && assetContent.selections) {
      return assetContent.selections[assetContent.answer];
    }
    return "";
  };

  const isOptionSelected = (value) => {
    return selectedAnswers.includes(value);
  };

  return (
    <div className="mx-auto w-full max-w-2xl p-6 font-sans">
      <h2 className="mb-6 break-words text-center text-xl font-semibold text-gray-800">
        {assetContent.question}
        {assetContent.pollType === "limited-multi" && (
          <span className="mt-2 block text-sm font-normal text-gray-600">
            (select up to {maxSelections} choices)
          </span>
        )}
      </h2>

      {assetContent.pollType === "free_response" ? (
        <div className="mb-4">
          <textarea
            className="min-h-32 w-full resize-none rounded-lg border border-gray-300 p-3 focus:border-transparent focus:ring-2 focus:ring-blue-500"
            value={surveyResponse}
            onChange={handleSurveyResponseChange}
            onBlur={handleSubmit}
            placeholder="Enter your response here..."
          />
        </div>
      ) : (
        <div className="space-y-3">
          {shuffledChoices.map(([key, value]) => (
            <button
              key={key}
              onClick={() => handleAnswerChange(value)}
              className={`w-full rounded-lg border-2 p-4 text-left transition-all duration-200 ${
                isOptionSelected(value)
                  ? "border-blue-500 bg-blue-50 shadow-sm"
                  : "border-gray-300 bg-white hover:border-blue-200 hover:bg-gray-50"
              }`}
              disabled={
                assetContent.pollType === "limited-multi" &&
                selectedAnswers.length >= maxSelections &&
                !isOptionSelected(value)
              }
            >
              <div className="flex items-center">
                <div
                  className={`mr-3 flex h-5 w-5 items-center justify-center rounded-${
                    assetContent.pollType === "multi" ||
                    assetContent.pollType === "limited-multi"
                      ? "sm"
                      : "full"
                  } border-2 ${
                    isOptionSelected(value)
                      ? "border-blue-500 bg-blue-500"
                      : "border-gray-400"
                  }`}
                >
                  {isOptionSelected(value) && (
                    <div className="text-white">
                      {assetContent.pollType === "multi" ||
                      assetContent.pollType === "limited-multi"
                        ? "✓"
                        : ""}
                    </div>
                  )}
                </div>
                <span
                  className={`${isOptionSelected(value) ? "font-medium" : ""}`}
                >
                  {value}
                </span>
              </div>
            </button>
          ))}
        </div>
      )}

      {isSubmitted && !error && (
        <p className="mt-6 text-center text-lg font-semibold text-green-600">
          {"Thank you for your response!"}
        </p>
      )}

      {isSubmitted &&
        !error &&
        (assetContent.pollType === "quiz" ||
          assetContent.pollType === "binary") && (
          <p
            className={`mt-4 text-center text-lg font-semibold ${
              quizResult ? "text-green-600" : "text-red-600"
            }`}
          >
            {quizResult
              ? "Correct!"
              : `Incorrect. The correct answer was "${getCorrectAnswerText()}"`}
          </p>
        )}

      {error && (
        <p className="mt-4 break-words text-center text-red-600">{error}</p>
      )}
    </div>
  );
};

export default PollComponent;
