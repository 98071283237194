import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import backArrow from "../assets/images/arrow_back.svg";
import logo from "../assets/images/GenZ_Logo.svg";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import CryptoJS from "crypto-js";
import axios from "axios";
import GenZLandingPageWallpaper from "../assets/images/GenZLandingPageWallpaper.svg";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";

const SignUp = (props) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [accept, setAccept] = useState(false);
  const [consent, setConsent] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [error, setError] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  const [emailAvailable, setEmailAvailable] = useState(true);
  const [phoneNumberAvailable, setPhoneNumberAvailable] = useState(true);
  const [checkingAvailability, setCheckingAvailability] = useState(false);
  //const [secretCode, setSecretCode] = useState(""); 
  //const [paymentAmount, setPaymentAmount] = useState("");
  const navigate = useNavigate();

  const [tooltipVisible, setTooltipVisible] = useState(false);
  /*
  const handleInputChange = (e) => {
    let value = e.target.value.trim(); // Trim spaces

    if (value === "") {
        setPaymentAmount("0");
        return;
    }

    // Allow only numbers (including 0) and limit length
    if (/^\d+$/.test(value) && value.length <= 8) {
        setPaymentAmount(value);
    }
  };
  */

  document.title = "GenZ Sign Up";

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const checkAvailability = useCallback((field, value, setter) => {
    if (value.length > 0) {
      setCheckingAvailability(true);
      axios
        .post("https://genz-staging.feeltiptop.com/api/user/checkAvailability", {
          field,
          value,
        })
        .then((response) => {
          setter(response.data.available);
          setCheckingAvailability(false);
        })
        .catch((error) => {
          console.error("Error checking availability:", error);
          setCheckingAvailability(false);
        });
    } else {
      setter(true);
    }
  }, []);

  const checkUsernameAvailability = useCallback(
    debounce(
      (value) => checkAvailability("username", value, setUsernameAvailable),
      1500,
    ),
    [debounce, checkAvailability],
  );
  const checkEmailAvailability = useCallback(
    debounce(
      (value) => checkAvailability("email", value, setEmailAvailable),
      1500,
    ),
    [debounce, checkAvailability],
  );
  const checkPhoneNumberAvailability = useCallback(
    debounce(
      (value) =>
        checkAvailability("phoneNumber", value, setPhoneNumberAvailable),
      1500,
    ),
    [debounce, checkAvailability],
  );

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const checkPassword = (pw) => {
    const decimal =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    return pw !== "" && decimal.test(pw);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 10);
    setPhoneNumber(value);
    checkPhoneNumberAvailability(value);
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setUsername(value);
    if (value.length >= 3) {
      checkUsernameAvailability(value);
    }
  };

  const [showOptions, setShowOptions] = useState(false);
  const [selectedCard, setSelectedCard] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [cardComplete, setCardComplete] = useState(false);
  const [dob, setDob] = useState(""); // Date of Birth state
  const [isDobValid, setIsDobValid] = useState(true);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const elements = useElements();
  const stripe = useStripe();
  /*
  const handleCardSelect = (card) => {
    setSelectedCard(card);
    setShowOptions(false);
    // setDob("");
  };
  */
  const [query, setQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isReferred, setIsReferred] = useState(false);
  const [referrerId, setReferrerId] = useState(null);
  const [isValidReferrer, setIsValidReferrer] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const referrer = params.get("referrer");

    if (referrer) {
      setIsReferred(true);
      setReferrerId(referrer);

      axios
        .get(`https://genz-staging.feeltiptop.com/api/user/get-referrer?referrer=${referrer}`)
        .then((response) => {
          if (response.data.success) {
            const referrerData = response.data.referrer;
            const combinedQuery = `${referrerData.firstName} ${referrerData.lastName} (${referrerData.Email})`;
            setQuery(combinedQuery);
            setIsValidReferrer(true);

            // Auto-fetch users based on combined query
            axios
              .get(
                `https://genz-staging.feeltiptop.com/api/user/search-user?query=${combinedQuery}`,
              )
              .then((searchResponse) => {
                const results = searchResponse.data;
                setFilteredUsers(results);
                // Auto-select if the combined query matches exactly one user
                if (results.length === 1) {
                  handleUserSelect(results[0]);
                }
              })
              .catch((error) => {
                console.error("Error fetching users for auto-select:", error);
              });
          } else {
            setIsValidReferrer(false);
            console.error(response.data.message);
          }
        })
        .catch((error) => {
          setIsValidReferrer(false);
          console.error("Error fetching referrer details:", error);
        });
    }
  }, [location]);

  const fetchUsers = debounce(async (searchQuery) => {
    if (searchQuery.length >= 4) {
      try {
        const response = await axios.get(
          `https://genz-staging.feeltiptop.com/api/user/search-user?query=${searchQuery}`,
        );
        setFilteredUsers(response.data);
      } catch (error) {
        console.error("Error fetching user list:", error);
      }
    } else {
      setFilteredUsers([]);
    }
  }, 300);

  const handleChange = (e) => {
    const searchQuery = e.target.value;
    setQuery(searchQuery);
    fetchUsers(searchQuery);

    if (searchQuery === "") {
      setReferrerId(null);
    }
  };

  const handleUserSelect = (user) => {
    setQuery(`${user.firstName} ${user.lastName} (${user.Email})`);
    setIsInputFocused(false);
    setFilteredUsers([]);
    setReferrerId(user.UserID);
  };

  const [emailExistsInCandidates, setEmailExistsInCandidates] = useState(false);
  const [isSpecialEmail, setIsSpecialEmail] = useState(false);
  /*
  useEffect(() => {
    if (isSpecialEmail) {
      setPaymentAmount("0");
    }
  }, [isSpecialEmail]);
  */
  const checkEmailExistsInCandidates = async (email) => {
    try {
      const response = await axios.post("https://genz-staging.feeltiptop.com/api/user/checkCandidate", { email });
      const exists = response.data.exists;
      setEmailExistsInCandidates(exists);
      setIsSpecialEmail(exists);
      return exists; 
    } catch (error) {
      console.error("Error checking email in candidates:", error);
      setEmailExistsInCandidates(false);
      setIsSpecialEmail(false);
      return false; 
    }
  };  

  const handleSignUp = async () => {
    console.log("SignUp process started");
    // Determine if the app is in test mode
    const isTestMode = process.env.REACT_APP_TEST_MODE === "true";
    console.log("Test Mode:", isTestMode);

    const isSpecialEmail = await checkEmailExistsInCandidates(email);
    console.log("Special Email Exists:", isSpecialEmail);

    setSelectedCard(isSpecialEmail ? "Seller" : "Buyer");
    
    setError(""); // Clear any previous errors
    const hashedPassword = CryptoJS.SHA256(password).toString();

    if (!checkPassword(password)) {
      setError(
        "Password does not meet the criteria. Please ensure it is at least 8 characters long and contains at least one lowercase letter, one uppercase letter, one number, and one special character.",
      );
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    if (!accept || !consent || !confirm) {
      setError(
        "Please accept the terms and conditions and consent to receive text messages.",
      );
      return;
    }

    if (!usernameAvailable || !emailAvailable || !phoneNumberAvailable) {
      setError("Please ensure all fields are available.");
      return;
    }

    try {
      // Get the access token
      const response = await axios.post("https://genz-staging.feeltiptop.com/api/token", {
        passCode: "4zPoQ5KAkR",
      });

      props.setToken(response.data.access_token);

      // Prepare user payload
      const userPayload = {
        phoneNumber: phoneNumber || null,
        userName: username,
        password: hashedPassword,
        firstName,
        lastName,
        email,
        termsAndConditions: accept ? "Accepted" : "Not Accepted",
        textMessaging: consent ? "Consented" : "Not Consented",
        ConfirmAge: confirm ? "Confirmed" : "Not Confirmed",
        selectedCard: isSpecialEmail ? "Seller" : "Buyer",
        //paymentAmount: paymentAmount,
        // dob: dob,
        referredBy: referrerId,
        activated: true,
        // secretCode: secretCode || null,
      };

      console.log("Payload: ", userPayload)

      // Directly create user account
      await axios.post(
        "https://genz-staging.feeltiptop.com/api/user/addUser",
        userPayload,
        {
          headers: {
            Authorization: `Bearer ${response.data.access_token}`,
          },
        },
      );

      setMessage("User account created successfully without payment!");
      setMessageType("success");
      navigate("/");
    } catch (error) {
      setError("Unable to sign up - Error " + (error.message || error));
      console.error("Error:", error);
    } finally {
      setButtonClicked(false);
    }
    return;


    console.log("Proceeding to payment processing logic.");

    // Payment processing logic only runs if not in test mode
    const cardElement = elements.getElement(CardElement);
    const baseAmount = 100;
    //const paymentAmountValue = parseInt(paymentAmount, 10); // Convert user input to integer

    if (!isTestMode && (!stripe || !elements)) {
      setError("Stripe.js has not loaded yet. Please try again later.");
      return;
    }

    if (!cardElement) {
      console.log("!Card Element: ", !cardElement);
      setError("Card Element is not initialized.");
      return;
    }

    /*
    if (isNaN(paymentAmountValue) || paymentAmountValue < 0) {
      console.log("Payment Amount: ", paymentAmount);
      setError("Please enter a valid payment amount (minimum is $0).");
      return;
    }
    */
    const totalAmount = baseAmount;// + paymentAmountValue
    /*
    // Validate date of birth for sellers
    if (selectedCard.includes("Seller") && !dob) {
      setError("You must enter your date of birth to sign up as a seller.");
      return;
    }


    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    if (selectedCard.includes("Seller") && (age < 16 || age > 28)) {
      setError(
        "You must be born between 1997 and 2012 to sign up as a seller.",
      );
      return;
    }
    */
    
    if (!buttonClicked) {
      setButtonClicked(true);
      setError("");

      const hashedPassword = CryptoJS.SHA256(password).toString();

      try {
        // Get the access token
        const response = await axios.post("https://genz-staging.feeltiptop.com/api/token", {
          passCode: "4zPoQ5KAkR",
        });

        props.setToken(response.data.access_token);

        // Prepare user payload for the database
        const userPayload = {
          phoneNumber: phoneNumber || null,
          userName: username,
          password: hashedPassword,
          firstName,
          lastName,
          email,
          termsAndConditions: accept ? "Accepted" : "Not Accepted",
          textMessaging: consent ? "Consented" : "Not Consented",
          selectedCard: selectedCard,
          // paymentAmount: paymentAmount,
          // dob: dob,
          referredBy: referrerId,
          // secretCode: secretCode || null,
          activated: true
        };

        console.log("User Payload:", userPayload);

        // Create the payment intent
        const paymentIntentResponse = await axios.post(
          "https://genz-staging.feeltiptop.com/api/payment/create-payment-intent",
          { amount: totalAmount * 100 },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          },
        );

        const { clientSecret } = paymentIntentResponse.data;

        if (clientSecret && stripe && cardElement) {
          const { error } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: cardElement,
            },
          });

          if (error) {
            setMessage("Payment failed: " + error.message);
            setMessageType("error");
          } else {
            // Payment succeeded, create the user account
            await axios.post(
              "https://genz-staging.feeltiptop.com/api/user/addUser",
              userPayload,
              {
                headers: {
                  Authorization: `Bearer ${response.data.access_token}`,
                },
              },
            );
            setMessage("User account created successfully!");
            setMessageType("success");
            navigate("/");
          }
        } else {
          setMessage("Failed to create payment intent.");
          setMessageType("error");
        }
      } catch (error) {
        setError("Unable to sign up - Error " + (error.message || error));
        console.error("Error:", error);
      } finally {
        setButtonClicked(false);
      }
    }
  };

  const isTestMode = process.env.REACT_APP_TEST_MODE === "true";

  const isFormValid =
    username &&
    email &&
    firstName &&
    lastName &&
    password &&
    confirmPassword &&
    accept &&
    consent &&
    confirm &&
    usernameAvailable &&
    emailAvailable &&
    phoneNumberAvailable &&
    !checkingAvailability &&
    // selectedCard &&
    //(isTestMode || cardComplete || isSpecialEmail || selectedCard.includes("Buyer")) &&
    (!isReferred || referrerId);
  

  return (
    <div
      className="flex min-h-screen items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: `url(${GenZLandingPageWallpaper})` }}
    >
      <div className="z-10 w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
        <div className="mb-3">
          <Link to="/">
            <img
              src={backArrow}
              alt="Back"
              className="h-6 w-6 hover:scale-105"
            />
          </Link>
        </div>
        <h2 className="mb-4 text-center text-2xl font-bold">
          Create Your Account
        </h2>
        <div className="mb-3 w-full">
          <input
            type="text"
            placeholder="Username"
            className={`w-full rounded-md bg-gray-100 p-2 text-sm ${usernameAvailable ? "" : "border border-red-500"} ${focusedInput === "username" ? "border border-black" : ""}`}
            value={username}
            onChange={handleUsernameChange}
            onFocus={() => setFocusedInput("username")}
            onBlur={() => setFocusedInput(null)}
          />
          {!usernameAvailable && (
            <p className="mt-2 text-xs text-red-500">Username already exists</p>
          )}
        </div>
        <div className="mb-3 w-full">
          <input
            type="email"
            placeholder="Email"
            className={`w-full rounded-md bg-gray-100 p-2 text-sm ${emailExistsInCandidates ? "border border-green-500" : ""} ${emailAvailable ? "" : "border border-red-500"} ${focusedInput === "email" ? "border border-black" : ""}`}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              checkEmailAvailability(e.target.value);
              checkEmailExistsInCandidates(e.target.value);
            }}
            onFocus={() => setFocusedInput("email")}
            onBlur={() => setFocusedInput(null)}
          />
          {!emailAvailable && (
            <p className="mt-2 text-xs text-red-500">Email already exists</p>
          )}
          {emailExistsInCandidates && (
            <p className="mt-2 text-xs text-green-500">You will be designated a seller with this email.</p>
          )}
        </div>
        <div className="mb-3 w-full">
          <input
            type="text"
            placeholder="First Name"
            className="w-full rounded-md bg-gray-100 p-2 text-sm"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            onFocus={() => setFocusedInput("firstName")}
            onBlur={() => setFocusedInput(null)}
          />
        </div>
        <div className="mb-3 w-full">
          <input
            type="text"
            placeholder="Last Name"
            className="w-full rounded-md bg-gray-100 p-2 text-sm"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            onFocus={() => setFocusedInput("lastName")}
            onBlur={() => setFocusedInput(null)}
          />
        </div>
        <div className="relative mb-4 w-full">
          <input
            type="tel"
            placeholder="Phone Number (Optional)"
            className={`w-full rounded-md bg-gray-100 p-2 text-sm ${phoneNumberAvailable ? "" : "border border-red-500"} ${focusedInput === "phoneNumber" ? "border border-black" : ""}`}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            onFocus={() => setFocusedInput("phoneNumber")}
            onBlur={() => setFocusedInput(null)}
            maxLength={10}
          />
          {!phoneNumberAvailable && (
            <p className="text-xs text-red-500">Phone number already exists</p>
          )}
        </div>
        <div className="relative mb-4 w-full">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            className={`w-full rounded-md bg-gray-100 p-2 text-sm ${focusedInput === "password" ? "border border-black" : ""}`}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onFocus={() => setFocusedInput("password")}
            onBlur={() => setFocusedInput(null)}
          />
          <button
            onClick={() => setShowPassword(!showPassword)}
            className="absolute right-3 top-1/2 -translate-y-1/2 transform"
          >
            {showPassword ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
          </button>
        </div>
        <div className="relative mb-4 w-full">
          <input
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Confirm Password"
            className={`w-full rounded-md bg-gray-100 p-2 text-sm ${focusedInput === "confirmPassword" ? "border border-black" : ""}`}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            onFocus={() => setFocusedInput("confirmPassword")}
            onBlur={() => setFocusedInput(null)}
          />
          <button
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            className="absolute right-3 top-1/2 -translate-y-1/2 transform"
          >
            {showConfirmPassword ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
          </button>
        </div>

        
        {/*
        <div className="relative mb-4 w-full">
          <div
            onClick={() => setShowOptions(!showOptions)}
            className="flex w-full cursor-pointer items-center rounded-md border border-gray-300 bg-gray-100 p-2 text-sm"
          >
            <span className="flex-1">
              {selectedCard || "Select Your Card"}
            </span>
            <div
              className="relative ml-2 cursor-pointer"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <InformationCircleIcon className="h-5 w-5 text-blue-500" />

              {showTooltip && (
                <div
                  className="absolute left-1/2 z-10 mt-1 -translate-x-1/2 transform rounded-md bg-gray-700 p-2 text-xs text-white"
                  style={{ width: "10rem" }}
                >
                  You need to purchase this card in order to make an account.
                  The price for both card is $100
                </div>
              )}
            </div>
          </div>

          {showOptions && (
            <div className="absolute left-0 z-10 w-full border border-gray-300 bg-white">
              <div
                onClick={() => handleCardSelect("360 Buyer Card ($0)")}
                className="cursor-pointer p-2 hover:bg-gray-100"
              >
                360 Buyer Card ($0)
              </div>
              <div
                onClick={() => handleCardSelect("360 Seller Card ($100)")}
                className="cursor-pointer p-2 hover:bg-gray-100"
              >
                360 Seller Card ($100)
              </div>
            </div>
          )}
        </div>
        
        {selectedCard.includes("Seller") && (
          <div className="mb-3 w-full">
            <label htmlFor="dob" className="block text-sm font-medium text-gray-700 text-left">
              Enter Birthdate:
            </label>
            <input
              id="dob"
              type="date"
              placeholder="Select your date of birth"
              value={dob}
              max={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? "0" : ""}${new Date().getMonth() + 1}-${new Date().getDate() < 10 ? "0" : ""}${new Date().getDate()}`}
              onChange={(e) => {
                const inputDate = e.target.value;
                setDob(inputDate);

                if (inputDate) {
                  const selectedDate = new Date(inputDate);
                  const year = selectedDate.getFullYear();

                  // Validate year to be within the Gen Z range (1997 - 2012)
                  const isYearValid = year >= 1997 && year <= 2012;

                  // Prevent future dates
                  const isFutureDate = selectedDate > new Date();

                  setIsDobValid(isYearValid && !isFutureDate);
                } else {
                  setIsDobValid(false); // Invalid if no date is selected
                }
              }}
              onInput={(e) => {
                // Restrict birth year to 4 digits while typing
                const parts = e.target.value.split("-");
                if (parts[0]?.length > 4) {
                  e.target.value = `${parts[0].slice(0, 4)}-${parts[1] || ""}-${parts[2] || ""}`;
                }
              }}
              className={`mt-1 w-full rounded-md bg-gray-100 p-2 text-sm focus:ring focus:ring-indigo-200 ${
                isDobValid === false ? "border border-red-500" : ""
              }`}
            />
            {isDobValid === false && (
              <p className="mt-2 text-xs text-red-500">
                Please enter a valid birthdate between 1997 and 2012.
              </p>
            )}
          </div>
        )}
        
        {!isSpecialEmail && (
          <div className="relative mb-4 w-full">
            <input
              type="text"
              value={paymentAmount}
              onChange={handleInputChange}
              placeholder="Add balance to your 360 card"
              className="w-full rounded-md bg-gray-100 p-2 pr-10 text-sm"
            />
            <div className="absolute right-2 top-0 flex h-full items-center">
              <div
                className="cursor-pointer"
                onMouseEnter={() => setTooltipVisible(true)}
                onMouseLeave={() => setTooltipVisible(false)}
              >
                <InformationCircleIcon className="h-5 w-5 text-blue-500" />
              </div>
              {tooltipVisible && (
                <div
                  className="absolute right-0 top-5 mb-6 translate-x-full rounded-md bg-gray-700 p-2 text-xs text-white shadow-lg"
                  style={{ width: "12rem" }}
                >
                  Add funds to earn 360 points, which can be used to get any type
                  of services. A dollar is worth 100 points.
                </div>
              )}
            </div>
          </div>
        )}
        
        <div className="mb-3 w-full">
          <input
            type="text"
            placeholder="Enter Secret Code (Optional)"
            className="w-full rounded-md bg-gray-100 p-2 text-sm"
            value={secretCode}
            onChange={(e) => setSecretCode(e.target.value)}
          />
        </div>
      
        <div className="mb-4">
          {!isTestMode && !isSpecialEmail && selectedCard && selectedCard.includes("Seller") && (
            <CardElement
              className="rounded-md bg-gray-100 p-2"
              onChange={(event) => setCardComplete(event.complete)}
            />
          )}
        </div>
          */}

                  
        <div className="w-full">
          <div className="mb-2 flex items-center">
            <label htmlFor="referral" className="flex text-xs">
              <input
                type="checkbox"
                id="referral"
                className="mr-2"
                checked={isReferred}
                onChange={(e) => setIsReferred(e.target.checked)}
                disabled={isValidReferrer}
              />
              Please check the box if someone referred you.
            </label>
          </div>

          {isReferred && (
            <div className="relative mb-4 w-full">
              <input
                type="text"
                value={query}
                onChange={handleChange}
                placeholder="Search referrers by email, first name, or last name."
                className={`w-full rounded-md bg-gray-100 p-2 text-sm ${filteredUsers.length > 0 ? "border border-black" : ""}`}
                onFocus={() => setIsInputFocused(true)}
                onBlur={() => setTimeout(() => setIsInputFocused(false), 200)}
                disabled={isValidReferrer} // Lock input if referrer is set from URL and valid
              />
              {isInputFocused && filteredUsers.length > 0 && (
                <ul className="absolute z-10 mt-1 max-h-60 w-full overflow-y-auto border bg-white">
                  {filteredUsers.map((user) => (
                    <li
                      key={user.email}
                      onMouseDown={() => handleUserSelect(user)}
                      className="cursor-pointer p-2 hover:bg-gray-200"
                    >
                      {user.firstName} {user.lastName} ({user.Email})
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>

        <div className="w-full space-y-3">
          <label className="flex gap-2 text-xs">
            <input
              type="checkbox"
              className=""
              checked={confirm}
              onChange={() => setConfirm(!confirm)}
            />
            I confirm that I am at least 18 years old.
          </label>
          <label className="flex gap-2 text-xs">
            <input
              type="checkbox"
              className=""
              checked={accept}
              onChange={() => setAccept(!accept)}
            />
            I accept the Terms and Conditions.
          </label>
          <label className="flex gap-2 text-xs">
            <input
              type="checkbox"
              className=""
              checked={consent}
              onChange={() => setConsent(!consent)}
            />
            I consent to receiving messages from 360.
          </label>
        </div>

        {error && (
          <div className="m-2 text-xs text-red-500">
            <p>{error}</p>
          </div>
        )}
        <button
          onClick={handleSignUp}
          className={`mt-4 w-full rounded bg-blue-500 p-2 text-white ${!isFormValid ? "cursor-not-allowed opacity-50" : ""}`}
          disabled={!isFormValid}
        >
          Sign Up
        </button>
      </div>
    </div>
  );
};

export default SignUp;
